import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import { editRole, getRole } from "api/roles"
import ResultHandler from "components/ResultHandler"
import FormButtonHandler from "components/FormButtonHandler"
import { getAllPermissions } from "api/admins"
import AccordionItem from "./AccordionItem"
import useCustomValidation from "../validationSchema"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "hooks/useGetInstence"
import { usePagination } from "hooks/usePagination"
import SearchSelectBox from "components/SearchSelectBox"
import { getStagesDropdown } from "api/stages"
import Select from "react-select"

const EditRole = () => {
  const { t } = useTranslation()
  let navigate = useHistory()
  const { roleSchema } = useCustomValidation()

  const params = useParams()
  const [editedRole, setEditedRole] = useState(null)
  const [stages, setStages] = useState([])
  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getRole,
    params.id,
    response => {
      setEditedRole({
        ...response.data.data.role,
        Permissions: response.data.data.role.Permissions.map(p => p.id),
      })
      setStages(
        response.data.data.role.Stages.map(s => {
          return { value: s.id, label: s.name }
        })
      )
    }
  )

  const { setData, loading, error, success } = useResponse(editRole, () => {
    navigate.push("/roles")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: editedRole ? editedRole.name : "",
      editTextOnly: editedRole
        ? editedRole.settings.editTextOnly
          ? true
          : false
        : false,

      permissions: editedRole ? editedRole.Permissions : "",
      types:
        editedRole && editedRole.settings
          ? editedRole.settings.types.map(t => {
              return { label: t, value: t }
            })
          : "",
    },
    validationSchema: roleSchema,
    onSubmit: values => {
      let data = {
        ...values,
        stages: stages.map(s => s.value),
        settings: {
          types: values.types.map(t => t.value),
          editTextOnly: values.editTextOnly,
        },
      }
      setData({ type: "edit", id: params.id, data })
    },
  })

  // get all permissions

  const { loading: permissionsLoading, response: permissionsResponse } =
    usePagination(getAllPermissions)

  function setPermissions(data) {
    validation.setFieldValue("permissions", data)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Roles")}
            breadcrumbItem={t("btns.Edit") + " " + t("pages.Role")}
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedRole && permissionsResponse.data && (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              {t("forms.Name")}
                            </Label>
                            <Input
                              name="name"
                              placeholder={t("forms.Name")}
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <FormGroup>
                            <Label>{t("pages.Stages")}</Label>
                            <SearchSelectBox
                              isMulti={true}
                              value={stages}
                              type="stages"
                              label="name"
                              api={getStagesDropdown}
                              setValue={data => {
                                setStages(data)
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>{t("forms.Type")}</Label>
                            <Select
                              placeholder={t("forms.Select")+" ..."}
                              value={validation.values.types}
                              onChange={data => {
                                validation.setFieldValue("types", data)
                              }}
                              isClearable={true}
                              onFocus={validation.handleFocus}
                              onBlur={validation.handleBlur}
                              isMulti={true}
                              options={[
                                { value: "post", label: t("forms.Post") },
                                {
                                  value: "infographic",
                                  label: t("forms.Infographic"),
                                },
                                { value: "video", label: t("forms.Video") },
                                { value: "album", label: t("forms.Album") },
                              ]}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type="checkbox"
                              checked={validation.values.editTextOnly}
                              onChange={validation.handleChange}
                              name="editTextOnly"
                            />
                            <Label style={{ marginRight: "10px" }}>
                              تعديل النصوص فقط
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                              {t("forms.Permissions")}
                            </Label>{" "}
                            <br />
                            {validation.errors.permissions && (
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: "0.25rem",
                                  fontSize: "80%",
                                  color: "#f46a6a",
                                }}
                              >
                                {validation.errors.permissions}
                              </div>
                            )}
                            <div className="accordion" id="accordion">
                              {permissionsResponse.data.data.categories.map(
                                cat => {
                                  return (
                                    <AccordionItem
                                      permissions={
                                        validation.values.permissions
                                      }
                                      setPermissions={setPermissions}
                                      key={cat.name}
                                      data={cat}
                                    />
                                  )
                                }
                              )}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormButtonHandler btnTxt="Edit" loading={loading} />
                    </Form>
                  )}

                  <ResultHandler
                    loading={instenceLoading}
                    error={error}
                    success={success}
                    successMsg={"Updated Successfully!"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditRole

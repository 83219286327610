import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Collapse,
} from "reactstrap"

import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import { editStage, getStage } from "api/stages"
import ResultHandler from "components/ResultHandler"
import FormButtonHandler from "components/FormButtonHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import useCustomValidation from "../validationSchema"
import { useGetInstence } from "hooks/useGetInstence"
import TypeRules from "./TypeRules"

const EditStage = () => {
  const { t } = useTranslation()
  let navigate = useHistory()
  const { stageSchema } = useCustomValidation()

  const params = useParams()

  const [editedStage, setEditedStage] = useState(null)

  const [postRules, setPostRules] = useState()
  const [infographicRules, setInfographicRules] = useState(null)
  const [videoRules, setVideoRules] = useState(null)
  const [albumRules, setAlbumRules] = useState()

  const [postDefaultRules, setPostDefaultRules] = useState(null)
  const [postDefaultAuthorValid, setPostDefaultAuthorValid] = useState(null)

  const [infographicDefaultRules, setInfographicDefaultRules] = useState(null)
  const [infographicDefaultAuthorValid, setInfographicDefaultAuthorValid] =
    useState(null)

  const [videoDefaultRules, setVideoDefaultRules] = useState(null)
  const [videoDefaultAuthorValid, setVideoDefaultAuthorValid] = useState(null)

  const [albumDefaultRules, setAlbumDefaultRules] = useState(null)
  const [albumDefaultAuthorValid, setAlbumDefaultAuthorValid] = useState(null)

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getStage,
    params.id,
    response => {
      setPostDefaultRules({
        Title: response.data.data.stage.validationRules.post.title
          ? "required:" +
            (response.data.data.stage.validationRules.post.title.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minLength:" +
            (response.data.data.stage.validationRules.post.title.includes(
              "minLength"
            )
              ? response.data.data.stage.validationRules.post.title
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minLength"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxLength:" +
            (response.data.data.stage.validationRules.post.title.includes(
              "maxLength"
            )
              ? response.data.data.stage.validationRules.post.title
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxLength"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minLength:|maxLength:",

        Content: response.data.data.stage.validationRules.post.postContent
          ? "required:" +
            (response.data.data.stage.validationRules.post.postContent.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minContent:" +
            (response.data.data.stage.validationRules.post.postContent.includes(
              "minContent"
            )
              ? response.data.data.stage.validationRules.post.postContent
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minContent"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxContent:" +
            (response.data.data.stage.validationRules.post.postContent.includes(
              "maxContent"
            )
              ? response.data.data.stage.validationRules.post.postContent
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxContent"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minContent:|maxContent:",

        Slug: response.data.data.stage.validationRules.post.slug
          ? "required:" +
            (response.data.data.stage.validationRules.post.slug.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minLength:" +
            (response.data.data.stage.validationRules.post.slug.includes(
              "minLength"
            )
              ? response.data.data.stage.validationRules.post.slug
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minLength"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxLength:" +
            (response.data.data.stage.validationRules.post.slug.includes(
              "maxLength"
            )
              ? response.data.data.stage.validationRules.post.slug
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxLength"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minLength:|maxLength:",

        Summary: response.data.data.stage.validationRules.post.summary
          ? "required:" +
            (response.data.data.stage.validationRules.post.summary.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minLength:" +
            (response.data.data.stage.validationRules.post.summary.includes(
              "minLength"
            )
              ? response.data.data.stage.validationRules.post.summary
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minLength"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxLength:" +
            (response.data.data.stage.validationRules.post.summary.includes(
              "maxLength"
            )
              ? response.data.data.stage.validationRules.post.summary
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxLength"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minLength:|maxLength:",

        Tags:
          "required:" +
          (response.data.data.stage.validationRules.post.Tags ? "true" : ""),
        Categories:
          "required:" +
          (response.data.data.stage.validationRules.post.Categories
            ? "true"
            : ""),
        Image:
          "required:" +
          (response.data.data.stage.validationRules.post.mainImage_url
            ? "true"
            : ""),
      })

      setPostDefaultAuthorValid({
        checked: response.data.data.stage.validationRules.post.role
          ? true
          : false,
        role: response.data.data.stage.validationRules.post.role
          ? {
              label: response.data.data.stage.validationRules.post.role.name,
              value: response.data.data.stage.validationRules.post.role.id,
            }
          : null,
      })

      setInfographicDefaultRules({
        Title: response.data.data.stage.validationRules.infographic.title
          ? "required:" +
            (response.data.data.stage.validationRules.infographic.title.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minLength:" +
            (response.data.data.stage.validationRules.infographic.title.includes(
              "minLength"
            )
              ? response.data.data.stage.validationRules.infographic.title
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minLength"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxLength:" +
            (response.data.data.stage.validationRules.infographic.title.includes(
              "maxLength"
            )
              ? response.data.data.stage.validationRules.infographic.title
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxLength"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minLength:|maxLength:",

        Content: response.data.data.stage.validationRules.infographic
          .postContent
          ? "required:" +
            (response.data.data.stage.validationRules.infographic.postContent.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minContent:" +
            (response.data.data.stage.validationRules.infographic.postContent.includes(
              "minContent"
            )
              ? response.data.data.stage.validationRules.infographic.postContent
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minContent"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxContent:" +
            (response.data.data.stage.validationRules.infographic.postContent.includes(
              "maxContent"
            )
              ? response.data.data.stage.validationRules.infographic.postContent
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxContent"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minContent:|maxContent:",

        Slug: response.data.data.stage.validationRules.infographic.slug
          ? "required:" +
            (response.data.data.stage.validationRules.infographic.slug.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minLength:" +
            (response.data.data.stage.validationRules.infographic.slug.includes(
              "minLength"
            )
              ? response.data.data.stage.validationRules.infographic.slug
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minLength"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxLength:" +
            (response.data.data.stage.validationRules.infographic.slug.includes(
              "maxLength"
            )
              ? response.data.data.stage.validationRules.infographic.slug
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxLength"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minLength:|maxLength:",

        Summary: response.data.data.stage.validationRules.infographic.summary
          ? "required:" +
            (response.data.data.stage.validationRules.infographic.summary.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minLength:" +
            (response.data.data.stage.validationRules.infographic.summary.includes(
              "minLength"
            )
              ? response.data.data.stage.validationRules.infographic.summary
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minLength"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxLength:" +
            (response.data.data.stage.validationRules.infographic.summary.includes(
              "maxLength"
            )
              ? response.data.data.stage.validationRules.infographic.summary
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxLength"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minLength:|maxLength:",

        Tags:
          "required:" +
          (response.data.data.stage.validationRules.infographic.Tags
            ? "true"
            : ""),
        Categories:
          "required:" +
          (response.data.data.stage.validationRules.infographic.Categories
            ? "true"
            : ""),
        Image:
          "required:" +
          (response.data.data.stage.validationRules.infographic.mainImage_url
            ? "true"
            : ""),
      })

      setInfographicDefaultAuthorValid({
        checked: response.data.data.stage.validationRules.infographic.role
          ? true
          : false,
        role: response.data.data.stage.validationRules.infographic.role
          ? {
              label:
                response.data.data.stage.validationRules.infographic.role.name,
              value:
                response.data.data.stage.validationRules.infographic.role.id,
            }
          : null,
      })

      setVideoDefaultRules({
        Title: response.data.data.stage.validationRules.video.title
          ? "required:" +
            (response.data.data.stage.validationRules.video.title.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minLength:" +
            (response.data.data.stage.validationRules.video.title.includes(
              "minLength"
            )
              ? response.data.data.stage.validationRules.video.title
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minLength"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxLength:" +
            (response.data.data.stage.validationRules.video.title.includes(
              "maxLength"
            )
              ? response.data.data.stage.validationRules.video.title
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxLength"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minLength:|maxLength:",

        Content: response.data.data.stage.validationRules.video.postContent
          ? "required:" +
            (response.data.data.stage.validationRules.video.postContent.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minContent:" +
            (response.data.data.stage.validationRules.video.postContent.includes(
              "minContent"
            )
              ? response.data.data.stage.validationRules.video.postContent
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minContent"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxContent:" +
            (response.data.data.stage.validationRules.video.postContent.includes(
              "maxContent"
            )
              ? response.data.data.stage.validationRules.video.postContent
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxContent"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minContent:|maxContent:",

        Slug: response.data.data.stage.validationRules.video.slug
          ? "required:" +
            (response.data.data.stage.validationRules.video.slug.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minLength:" +
            (response.data.data.stage.validationRules.video.slug.includes(
              "minLength"
            )
              ? response.data.data.stage.validationRules.video.slug
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minLength"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxLength:" +
            (response.data.data.stage.validationRules.video.slug.includes(
              "maxLength"
            )
              ? response.data.data.stage.validationRules.video.slug
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxLength"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minLength:|maxLength:",

        Summary: response.data.data.stage.validationRules.video.summary
          ? "required:" +
            (response.data.data.stage.validationRules.video.summary.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minLength:" +
            (response.data.data.stage.validationRules.video.summary.includes(
              "minLength"
            )
              ? response.data.data.stage.validationRules.video.summary
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minLength"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxLength:" +
            (response.data.data.stage.validationRules.video.summary.includes(
              "maxLength"
            )
              ? response.data.data.stage.validationRules.video.summary
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxLength"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minLength:|maxLength:",

        Tags:
          "required:" +
          (response.data.data.stage.validationRules.video.Tags ? "true" : ""),
        Categories:
          "required:" +
          (response.data.data.stage.validationRules.video.Categories
            ? "true"
            : ""),
        Image:
          "required:" +
          (response.data.data.stage.validationRules.video.mainImage_url
            ? "true"
            : ""),
      })

      setVideoDefaultAuthorValid({
        checked: response.data.data.stage.validationRules.video.role
          ? true
          : false,
        role: response.data.data.stage.validationRules.video.role
          ? {
              label: response.data.data.stage.validationRules.video.role.name,
              value: response.data.data.stage.validationRules.video.role.id,
            }
          : null,
      })

      setAlbumDefaultRules({
        Title: response.data.data.stage.validationRules?.album?.title
          ? "required:" +
            (response.data.data.stage.validationRules?.album?.title.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minLength:" +
            (response.data.data.stage.validationRules?.album?.title.includes(
              "minLength"
            )
              ? response.data.data.stage.validationRules?.album?.title
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minLength"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxLength:" +
            (response.data.data.stage.validationRules?.album?.title.includes(
              "maxLength"
            )
              ? response.data.data.stage.validationRules?.album?.title
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxLength"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minLength:|maxLength:",

        Content: response.data.data.stage.validationRules?.album?.postContent
          ? "required:" +
            (response.data.data.stage.validationRules?.album?.postContent.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minContent:" +
            (response.data.data.stage.validationRules?.album?.postContent.includes(
              "minContent"
            )
              ? response.data.data.stage.validationRules?.album?.postContent
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minContent"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxContent:" +
            (response.data.data.stage.validationRules?.album?.postContent.includes(
              "maxContent"
            )
              ? response.data.data.stage.validationRules?.album?.postContent
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxContent"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minContent:|maxContent:",

        Slug: response.data.data.stage.validationRules?.album?.slug
          ? "required:" +
            (response.data.data.stage.validationRules?.album?.slug.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minLength:" +
            (response.data.data.stage.validationRules?.album?.slug.includes(
              "minLength"
            )
              ? response.data.data.stage.validationRules?.album?.slug
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minLength"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxLength:" +
            (response.data.data.stage.validationRules?.album?.slug.includes(
              "maxLength"
            )
              ? response.data.data.stage.validationRules?.album?.slug
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxLength"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minLength:|maxLength:",

        Summary: response.data.data.stage.validationRules?.album?.summary
          ? "required:" +
            (response.data.data.stage.validationRules?.album?.summary.includes(
              "required"
            )
              ? "true"
              : "") +
            "|" +
            "minLength:" +
            (response.data.data.stage.validationRules?.album?.summary.includes(
              "minLength"
            )
              ? response.data.data.stage.validationRules?.album?.summary
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "minLength"
                  })[0]
                  .split(":")[1]
              : "") +
            "|" +
            "maxLength:" +
            (response.data.data.stage.validationRules?.album?.summary.includes(
              "maxLength"
            )
              ? response.data.data.stage.validationRules?.album?.summary
                  .split("|")
                  .filter(r => {
                    return r != "required" && r.split(":")[0] === "maxLength"
                  })[0]
                  .split(":")[1]
              : "")
          : "required:|minLength:|maxLength:",

        Tags:
          "required:" +
          (response.data.data.stage.validationRules?.album?.Tags ? "true" : ""),
        Categories:
          "required:" +
          (response.data.data.stage.validationRules?.album?.Categories
            ? "true"
            : ""),
        Image:
          "required:" +
          (response.data.data.stage.validationRules?.album?.mainImage_url
            ? "true"
            : ""),
      })

      setAlbumDefaultAuthorValid({
        checked: response.data.data.stage.validationRules?.album?.role
          ? true
          : false,
        role: response.data.data.stage.validationRules?.album?.role
          ? {
              label: response.data.data.stage.validationRules?.album?.role.name,
              value: response.data.data.stage.validationRules?.album?.role.id,
            }
          : null,
      })

      setEditedStage(response.data.data.stage)
    }
  )

  const { setData, loading, error, success } = useResponse(editStage, () => {
    navigate.push("/stages")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: editedStage ? editedStage.name : "",
      name_en: editedStage ? editedStage.name_en : "",
    },
    validationSchema: stageSchema,
    onSubmit: values => {
      let data = {
        name: values.name,
        name_en: values.name_en,
        validation_rules: {
          post: postRules,
          infographic: infographicRules,
          video: videoRules,
          album: albumRules,
        },
      }
      setData({ type: "edit", id: params.id, data })
    },
  })

  return (
    <React.Fragment>
      <div className="page-content stage">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Stages")}
            breadcrumbItem={t("btns.Edit") + " " + t("pages.Stage")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedStage && (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              {t("forms.NameInArabic")}
                            </Label>
                            <Input
                              name="name"
                              placeholder={t("forms.NameInArabic")}
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              {t("forms.NameInEnglish")}
                            </Label>
                            <Input
                              name="name_en"
                              placeholder={t("forms.NameInEnglish")}
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name_en || ""}
                              invalid={
                                validation.touched.name_en &&
                                validation.errors.name_en
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name_en &&
                            validation.errors.name_en ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name_en}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="12" className="mb-4">
                          <TypeRules
                            type={"Post"}
                            defaultRules={postDefaultRules}
                            defaultAuthors={postDefaultAuthorValid}
                            setApiRules={setPostRules}
                          />
                        </Col>
                        <Col md="12" className="mb-4">
                          <TypeRules
                            type={"Infographic"}
                            defaultRules={infographicDefaultRules}
                            defaultAuthors={infographicDefaultAuthorValid}
                            setApiRules={setInfographicRules}
                          />
                        </Col>
                        <Col md="12" className="mb-4">
                          <TypeRules
                            type={"Video"}
                            defaultRules={videoDefaultRules}
                            defaultAuthors={videoDefaultAuthorValid}
                            setApiRules={setVideoRules}
                          />
                        </Col>
                        <Col md="12" className="mb-4">
                          <TypeRules
                            type={"Album"}
                            defaultRules={albumDefaultRules}
                            defaultAuthors={albumDefaultAuthorValid}
                            setApiRules={setAlbumRules}
                          />
                        </Col>
                      </Row>

                      <FormButtonHandler btnTxt="Edit" loading={loading} />
                    </Form>
                  )}

                  <ResultHandler
                    loading={instenceLoading}
                    error={error}
                    success={success}
                    successMsg={"Updated Successfully!"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditStage

import { myAxios } from "./axios"

export function getCategories(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      name: data ? data.txt : null,
    },
  }
  return myAxios.get("/categories", config)
}

export function getCategoriesDropdown(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage: 20,
      name: data ? data.txt : null,
      parentsOnly: data ? data.parentsOnly : null,
      isInternal: data ? (data.isInternal ? true : null) : null,
    },
    headers: {
      
    }
  }

  if( data.lang != 'undefined' ){
    config['headers']['Accept-Language'] = data ? (data.lang ? data.lang : "ar") : "ar"
  }

  return myAxios.get("/dropdown/categories", config)
}

export function getCategory(id) {
  return myAxios.get("/categories/" + id)
}

export function addCategory(data) {
  return myAxios.post("/categories/create", data)
}

export function editCategory(id, data) {
  return myAxios.post("/categories/" + id + "/update", data)
}

export function deleteCategory(id) {
  return myAxios.post("/categories/" + id + "/delete")
}

export function getMonitorCategories(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      name: data ? data.txt : null,
    },
  }
  return myAxios.get("/categories/allowed-categories", config)
}

import { addFolder } from "api/file-manager"
import FormButtonHandler from "components/FormButtonHandler"
import ResultHandler from "components/ResultHandler"
import { useFormik } from "formik"
import { useResponse } from "hooks/useResponse"
import React, { useState, useEffect } from "react"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import useCustomValidation from "../validationSchema"

const CreateFolderModal = props => {
  const { t } = useTranslation()
  const {folderSchema} = useCustomValidation()
  const { loading, error, success, setLoading, setError, setSuccess, setData } =
    useResponse(addFolder)

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: folderSchema,
    onSubmit: values => {
      let data = {
        name: values.name,
      }
      setData({ type: "add", data: { ...data, parentId: props.parentId } })
    },
  })

  useEffect(() => {
    if (success) {
      props.onCloseClick()
      props.reloadContentMeth()
      setError("")
      setSuccess(false)
    }
  }, [success])

  return (
    <Modal isOpen={props.show} toggle={props.onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <h2>{t("btns.Add") + " " + t("modals.Folder")}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">{t("forms.Name")}</Label>
                  <Input
                    name="name"
                    placeholder={t("forms.Name")}
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            <ResultHandler
              error={error}
              success={success}
              successMsg={"Created Successfully!"}
            />

            <div className="text-center mt-3">
              <FormButtonHandler btnTxt="Add" loading={loading} />
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={props.onCloseClick}
              >
                {t("btns.Cancel")}
              </button>
            </div>
          </Form>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default CreateFolderModal

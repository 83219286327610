import React from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { useTranslation } from "react-i18next"

const StartNewModal = ({ show, handleChooseStartChoice }) => {
  const { t } = useTranslation()

  return (
    <Modal size="lg" isOpen={show} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i className="bx bx-question-mark" style={{ fontSize: "9em" }} />
              <h2>{t("modals.Do You Want To Complete the last post")}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={() => {
                  handleChooseStartChoice(true)
                }}
              >
                {t("modals.Start New Post")}
              </button>
              <button
                type="button"
                className="btn btn-primary btn-lg ms-2"
                onClick={() => {
                  handleChooseStartChoice(false)
                }}
              >
                {t("modals.Complete the old")}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default StartNewModal

import React, { useEffect, useState } from "react"

// Formik validation
import { useFormik } from "formik"

import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalBody,
} from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import FormButtonHandler from "components/FormButtonHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "hooks/useGetInstence"
import { editUrgentPost, getUrgentPost } from "api/posts"
import useCustomValidation  from "../../validationSchema"

const EditUrgentPost = ({ postId, show, reloadPosts, setShow }) => {
  const { t } = useTranslation()
  const { urgentSchema } = useCustomValidation()

  const [editedUrgentPost, setEditedUrgentPost] = useState(null)

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getUrgentPost,
    postId,
    response => {
      setEditedUrgentPost(response.data.data.post)
    }
  )

  const { setData, loading, error, success } = useResponse(
    editUrgentPost,
    () => {
      setShow(false)
      reloadPosts()
    }
  )

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: editedUrgentPost ? editedUrgentPost.title : "",
      language: editedUrgentPost ? editedUrgentPost.language : "ar",
    },
    validationSchema: urgentSchema,
    onSubmit: data => {
      setData({ id: postId, data, type: "edit" })
    },
  })

  function handleClose() {
    setShow(false)
  }

  return (
    <Modal isOpen={show} toggle={handleClose} centered={true}>
      <ModalBody className="py-5 px-5">
        {editedUrgentPost && (
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">{t("forms.Title")}</Label>
                  <Input
                    name="title"
                    placeholder={t("forms.Title")}
                    type="text"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.title || ""}
                    invalid={
                      validation.touched.title && validation.errors.title
                        ? true
                        : false
                    }
                  />
                  {validation.touched.title && validation.errors.title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.title}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                {/*<FormGroup className="mb-3">
                <Label>{t("forms.Language")}</Label>

                <select
                  name="language"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.language || ""}
                >
                  <option value="ar">العربية</option>
                  <option value="en">English</option>
                </select>
                
                  </FormGroup>*/}
              </Col>
            </Row>

            <FormButtonHandler btnTxt="Edit" loading={loading} />
          </Form>
        )}

        <ResultHandler
          loading={instenceLoading}
          error={error}
          success={success}
          successMsg={"Updated Successfully!"}
        />
      </ModalBody>
    </Modal>
  )
}

export default EditUrgentPost

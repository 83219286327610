import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import ChooseImage from "components/ChooseImage"
import { addPresenter } from "api/presenters"
import FormButtonHandler from "components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"

const AddPresenter = () => {
  const { t } = useTranslation()
  const { presenterSchema } = useCustomValidation()
  const { setData, loading, error, success } = useResponse(addPresenter)

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      summary: "",
      img: "",
    },
    validationSchema: presenterSchema,
    onSubmit: values => {
      let data = {
        name: values.name,
        summary: values.summary,
        image_url: values.img,
      }

      setData({ data, type: "add" })
    },
  })

  let navigate = useHistory()

  useEffect(() => {
    if (success) {
      navigate.push("/presenters")
    }
  }, [success])

  function handleChooseImage(url) {
    validation.setFieldValue("img", url)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Presenters")}
            breadcrumbItem={t("btns.Add") + " " + t("pages.Presenter")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label>{t("forms.Name")}</Label>
                          <Input
                            name="name"
                            placeholder={t("forms.Name")}
                            type="text"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label>{t("forms.Summary")}</Label>
                          <Input
                            name="summary"
                            placeholder={t("forms.Summary")}
                            type="textarea"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.summary || ""}
                            invalid={
                              validation.touched.summary &&
                              validation.errors.summary
                                ? true
                                : false
                            }
                          />
                          {validation.touched.summary &&
                          validation.errors.summary ? (
                            <FormFeedback type="invalid">
                              {validation.errors.summary}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <ChooseImage
                          name="img"
                          image={validation.values.img}
                          error={
                            validation.touched.img && validation.errors.img
                          }
                          handleChooseImage={handleChooseImage}
                        />
                      </Col>
                    </Row>

                    <FormButtonHandler btnTxt="Add" loading={loading} />

                    <ResultHandler
                      error={error}
                      success={success}
                      successMsg={"Created Successfully!"}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddPresenter

import React, { useState } from "react"
import DeleteModal from "components/Common/DeleteModal"
import { usePagination } from "hooks/usePagination"
import ResultHandler from "components/ResultHandler"
import PaginationHandler from "components/PaginationHandler"
import { deleteVideo, getVideos } from "api/videos"
import TableHeaderHandler from "components/TableHeaderHandler"
import { PERMISSIONS } from "api/permissions"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import TableAction from "components/TableAction"
import EmptyData from "components/EmptyData"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import AddVideoModal from "./AddVideoModal"
import EditVideoModal from "./EditVideoModal"
import { Button, Input } from "reactstrap"
import ShowVideoModal from "./ShowVideoModal"
import ImageColumn from "./ImageColumn"

export default function VideosTable() {
  const { t } = useTranslation()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
    setMeta,
  } = usePagination(getVideos)

  // filters

  const [filters, setFilters] = useState({
    txt: null,
    status: null,
  })

  function handleSearch() {
    setMeta({ page: 1, payload: filters })
  }

  // delete

  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }

  const [deletingVideo, setDeletingVideo] = useState(null)

  const { setData } = useResponse(deleteVideo, () => {
    setShow(false)
    setDeletingVideo(null)
    setPayload({})
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingVideo })
  }

  // add
  const [addShow, setAddShow] = useState(false)

  // edit
  const [video, setVideo] = useState(null)
  const [editShow, setEditShow] = useState(false)

  // video show

  const [videoShow, setVideoShow] = useState(false)

  // const status

  const statuses = {
    pending: "warning",
    succeeded: "success",
    failed: "danger",
  }

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.VIDEO_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        handleLinkClick={() => {
          setAddShow(true)
        }}
      >
        <div
          className="d-flex tableFilters"
          style={{ flexWrap: "wrap", marginBottom: "15px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("forms.Title")}:
            <Input
              name="title"
              style={{ marginRight: "10px" }}
              placeholder={t("forms.Title")}
              type="text"
              className="form-control"
              onChange={e => {
                setFilters({
                  ...filters,
                  txt: e.target.value,
                })
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("forms.Status")}:
            <select
              style={{ marginRight: "10px", width: "100%" }}
              onChange={e => {
                setFilters({
                  ...filters,
                  status: e.target.value === "all" ? null : e.target.value,
                })
              }}
              className="form-select"
            >
              <option value={"all"}>{t("forms.All")}</option>
              <option value={"succeeded"}>{"forms.Succeeded"}</option>
              <option value={"failed"}>{"forms.Failed"}</option>
              <option value={"pending"}>{"forms.Pending"}</option>
            </select>
          </div>
          <div>
            <Button onClick={handleSearch}>{t("btns.Search")}</Button>
          </div>
        </div>
      </TableHeaderHandler>

      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                <Th style={{ width: "10%" }}>{t("forms.Image")}</Th>
                <Th style={{ width: "40%" }}>{t("forms.Title")}</Th>
                <Th style={{ width: "20%" }}>{t("forms.Status")}</Th>
                <Th style={{ width: "30%" }}>{t("tables.Action")}</Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <Tbody>
                {response.data.videos.map(row => {
                  return (
                    <Tr key={row.id}>
                      <Td>
                        <ImageColumn id={row.id} />
                      </Td>
                      <Td
                        style={{ cursor: "pointer" }}
                      >
                        {row.title}
                      </Td>
                      <Td>
                        <span
                          className={
                            "badge rounded-pill font-size-13 badge-soft-" +
                            statuses[row.status]
                          }
                        >
                          {row.status}
                        </span>
                      </Td>
                      <Td>
                        <TableAction
                          hasEdit={false}
                          hasDelete={true}
                          editPermission={PERMISSIONS.VIDEO_EDIT}
                          deletePermission={PERMISSIONS.VIDEO_DELETE}
                          type="video"
                          id={row.id}
                          setShow={setShow}
                          setDeleteIntitiy={setDeletingVideo}
                        />
                        {JSON.parse(
                          localStorage.getItem("permissions")
                        ).includes(PERMISSIONS.VIDEO_EDIT) && (
                          <Button
                            color="primary"
                            onClick={() => {
                              setVideo(row)
                              setEditShow(true)
                            }}
                          >
                            <span
                              className={
                                isMobile ? "responsive-button-label" : ""
                              }
                            >
                              {t("btns.Edit")}
                            </span>

                            <i className="bx bx-edit font-size-16 align-middle me-1" />
                          </Button>
                        )}
                        <Button onClick={() => {
                          setVideo(row)
                          setVideoShow(true)
                        }}>
                          <i className="bx bx-play font-size-16 align-middle me-1" />
                        </Button>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          {!loading && (
            <EmptyData
              empty={response.data && response.data.videos.length === 0}
              msg="No Videos Found."
            />
          )}

          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />

          {addShow && (
            <AddVideoModal
              show={addShow}
              setShow={setAddShow}
              reloadTable={() => {
                setPayload({})
                setAddShow(false)
              }}
            />
          )}

          {editShow && (
            <EditVideoModal
              show={editShow}
              setShow={setEditShow}
              reloadTable={() => {
                setPayload({})
                setEditShow(false)
              }}
              videoId={video.id}
            />
          )}

          {videoShow && (
            <ShowVideoModal
              show={videoShow}
              onCloseClick={() => {
                setVideoShow(false)
              }}
              videoId={video.id}
            />
          )}

          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          <ResultHandler error={error} loading={loading} />
        </div>
      </div>
    </>
  )
}

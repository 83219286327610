import SlugInput from "components/SlugInput"
import React, { useRef, useState } from "react"
import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap"
import Board from "../../../../../packages/react-kanban/index"
import ContentCard from "./ContentCard"
import { useTranslation } from "react-i18next"
import usePost from "hooks/usePost"
import { getKickers } from "api/kickers"
import SearchSelectBox from "components/SearchSelectBox"
import { extractKeyWords, getPostsDropdown } from "api/posts"
import CreatableSelect from "react-select/creatable"
import { getCategoriesDropdown } from "api/categories"
import ChooseVideo from "pages/dashboard/videos/ChooseVideo"
import TagsSelect from "./TagsSelect"

export default function PostForm({ validation, disabled, postId }) {
  const { t } = useTranslation()

  const lastCard = useRef()

  const {
    contentsBoard: board,
    setBoard,
    handleCardMove,
    handleResetBoard,
    addContent,
    removeContent,
    onEditorStateChange,
    setImage,
    setImageComment,
    setSliderImg,
    addSliderImg,
    removeSliderImg,
    setSliderImgComment,
    generateRawContent,
  } = usePost(validation)

  // dropdown

  const [open, setOpen] = useState(false)

  // keywords

  function getKeyWords() {
    if (generateRawContent().length > 0) {
      extractKeyWords({ text: generateRawContent() })
        .then(res => {
          validation.setFieldValue("keywords", [
            ...(validation.values.keywords ? validation.values.keywords : []),
            ...res.data
              .filter(
                obj =>
                  !validation.values.keywords
                    .map(k => k.value)
                    .includes(obj.json.matchedText)
              )
              .map(obj => {
                return {
                  value: obj.json.matchedText,
                  label: obj.json.matchedText,
                }
              }),
          ])
        })
        .catch(err => {})
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          <FormGroup>
            <Label>{t("forms.Title")}</Label>
            <Input
              name="title"
              placeholder={t("forms.Title")}
              type="text"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.title || ""}
              invalid={
                validation.touched.title && validation.errors.title
                  ? true
                  : false
              }
            />
            {validation.touched.title && validation.errors.title ? (
              <FormFeedback type="invalid">
                {validation.errors.title}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            {/*<Col md="3">
              <FormGroup className="mb-3">
                <Label>{t("forms.Language")}</Label>
                <select
                  className="form-control"
                  name="language"
                  value={validation.values.language}
                  onChange={validation.handleChange}
                  disabled={disabled}
                >
                  <option value="en">English</option>
                  <option value="ar">Arabic</option>
                </select>
              </FormGroup>
            </Col>*/}
            <Col md="6">
              <FormGroup className="mb-3">
                <Label>{t("forms.Type")}</Label>
                <select
                  name="type"
                  className="form-control"
                  value={validation.values.type}
                  onChange={e => {
                    validation.handleChange(e)
                    if (["infographic", "album"].includes(e.target.value)) {
                      setBoard({
                        columns: [
                          {
                            id: 1,
                            title: "",
                            cards: [
                              {
                                type: "slider",
                                order: 1,
                                id: 1,
                                content: "",
                                cardRef: null,
                                meta: [],
                                status: "new",
                              },
                            ],
                          },
                        ],
                      })
                    } else {
                      handleResetBoard()
                    }
                  }}
                  disabled={disabled}
                >
                  <option value="post">{t("forms.Post")}</option>
                  <option value="infographic">{t("forms.Infographic")}</option>
                  <option value="album">{t("forms.Album")}</option>
                  <option value="video">{t("forms.Video")}</option>
                </select>
              </FormGroup>
            </Col>
            <Col md="6">
              <SlugInput
                validation={validation}
                source={validation.values.title}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      {validation.values.type === "post" && (
        <Card>
          <CardBody>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Label>{t("forms.Content")}</Label>

              <Dropdown
                disabled={disabled}
                isOpen={open}
                toggle={() => setOpen(!open)}
              >
                <DropdownToggle
                  type="button"
                  tag="button"
                  className="btn btn-primary"
                >
                  {t("btns.Add")} <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      addContent("html", lastCard)
                    }}
                  >
                    {t("forms.Text")}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      addContent("img", lastCard)
                    }}
                  >
                    {t("forms.Image")}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      addContent("embeded", lastCard)
                    }}
                  >
                    {t("forms.embeded")}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      addContent("code", lastCard)
                    }}
                  >
                    Embeded Code
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      addContent("slider", lastCard)
                    }}
                  >
                    {t("forms.Slider")}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      addContent("video", lastCard)
                    }}
                  >
                    {t("forms.Video")}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {board && board.columns[0].cards.length > 0 && (
              <div
                style={{ marginTop: "10px", marginBottom: "-15px" }}
                className="accordion"
              >
                <div className="accordion" id="accordion">
                  <Board
                    onCardDragEnd={handleCardMove}
                    renderCard={content => (
                      <ContentCard
                        disabled={disabled}
                        setSliderImg={setSliderImg}
                        removeSliderImg={removeSliderImg}
                        addSliderImg={addSliderImg}
                        setSliderImgComment={setSliderImgComment}
                        defaultContent={
                          content.meta?.entityMap ? content.meta : null
                        }
                        setImage={setImage}
                        setImageComment={setImageComment}
                        onEditorStateChange={onEditorStateChange}
                        removeContent={removeContent}
                        content={content}
                      />
                    )}
                    disableColumnDrag
                    disableCardDrag={disabled}
                  >
                    {board}
                  </Board>
                </div>
              </div>
            )}
            <div
              className="refCard"
              ref={lastCard}
              style={{
                width: "100%",
                height: "1px",
              }}
            ></div>
          </CardBody>
        </Card>
      )}

      {validation.values.type === "video" && (
        <Card>
          <CardBody>
            <Label>{t("forms.Video")}</Label>
            <ChooseVideo
              editable={true}
              handleSelectVideo={embeded => {
                setBoard({
                  columns: [
                    {
                      ...board.columns[0],
                      cards: [
                        {
                          type: "embeded",
                          order: 1,
                          id: 1,
                          content: embeded,
                          meta: [],
                          status: "new",
                          isOpen: true,
                        },
                      ],
                    },
                  ],
                })
              }}
              disabled={disabled}
              video={board.columns[0].cards.length > 0 ? board.columns[0].cards[0].content :null}
            />
          </CardBody>
        </Card>
      )}

      {["infographic", "album"].includes(validation.values.type) && (
        <Card className="accordion">
          <CardBody>
            <ContentCard
              disabled={disabled}
              hideRemoveBtn={true}
              setSliderImg={setSliderImg}
              removeSliderImg={removeSliderImg}
              setSliderImgComment={setSliderImgComment}
              addSliderImg={addSliderImg}
              setImageComment={setImageComment}
              setImage={setImage}
              onEditorStateChange={onEditorStateChange}
              removeContent={removeContent}
              content={
                board.columns[0].cards[0]
                  ? board.columns[0].cards[0]
                  : {
                      type: "slider",
                      order: 1,
                      id: 1,
                      content: "",
                      cardRef: null,
                      meta: [],
                      status: "new",
                    }
              }
            />
          </CardBody>
        </Card>
      )}

      <Card>
        <CardBody>
          <FormGroup>
            <Label>{t("pages.Categories")}</Label>
            <SearchSelectBox
              isMulti={false}
              disabled={disabled}
              value={validation.values.categories}
              type="categories"
              label="name"
              error={
                validation.touched.categories && validation.errors.categories
              }
              api={getCategoriesDropdown}
              apiMeta={{ lang: validation.values.language }}
              setValue={data => {
                validation.setFieldValue("categories", data)
                validation.setFieldValue("kickers", null)
              }}
            />
          </FormGroup>
          {validation.values.categories && (
            <FormGroup>
              <Label>{t("pages.Kickers")}</Label>
              <SearchSelectBox
                disabled={disabled}
                value={validation.values.kickers}
                type="kickers"
                label="name"
                error={validation.touched.kickers && validation.errors.kickers}
                api={getKickers}
                apiMeta={
                  validation.values.categories
                    ? { id: validation.values.categories.value }
                    : { id: 0 }
                }
                setValue={data => {
                  validation.setFieldValue("kickers", data)
                }}
              />
            </FormGroup>
          )}
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <FormGroup>
            <Label>{t("forms.Related Posts")}</Label>
            <SearchSelectBox
              disabled={disabled}
              isMulti={true}
              value={validation.values.related_posts}
              type="posts"
              label="title"
              exclude={postId ? postId : null}
              api={getPostsDropdown}
              setValue={data => {
                validation.setFieldValue("related_posts", data)
              }}
            />
          </FormGroup>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <FormGroup>
            <Label>{t("forms.Keywords")}</Label>
            <InputGroup style={{ display: "flex", flexWrap: "nowrap" }}>
              <InputGroupText onClick={getKeyWords}>
                <i className="bx bx-pencil"></i>
              </InputGroupText>
              <div style={{ width: "100%" }}>
                <CreatableSelect
                  isDisabled={disabled}
                  placeholder={t("forms.Select")+" ..."}
                  isClearable
                  onCreateOption={newKey => {
                    validation.setFieldValue("keywords", [
                      ...(validation.values.keywords
                        ? validation.values.keywords
                        : []),
                      { value: newKey, label: newKey },
                    ])
                  }}
                  value={validation.values.keywords}
                  onChange={data => {
                    validation.setFieldValue("keywords", data)
                  }}
                  isMulti={true}
                  options={[]}
                />
              </div>
            </InputGroup>
          </FormGroup>
        </CardBody>
      </Card>

      <TagsSelect validation={validation} disabled={disabled} />
    </>
  )
}

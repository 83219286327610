import notificationState from "atom/notification"
import unreadState from "atom/unread"
import React, { useEffect } from "react"

import Pusher from "react-pusher"
import { useRecoilState } from "recoil"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

export default function Notification() {
  const [notifications, setNotification] = useRecoilState(notificationState)
  const [unread, setUnread] = useRecoilState(unreadState)

  function showToast(title, message) {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 5000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: false,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    }

    toastr.success(message, title)
  }

  function getUserId() {
    return JSON.parse(localStorage.getItem("user")).id
  }

  return (
    <>
      {localStorage.getItem("user") && (
        <Pusher
          channel={"myChannel-" + getUserId()}
          event={"notification"}
          onUpdate={data => {
            setUnread(e => e + 1)
            setNotification(n => [...n, data])
            showToast(data.payload.data.title, data.payload.data.description)
          }}
        />
      )}
    </>
  )
}

import { getNotifications, readNotification } from "api/admins"
import { usePagination } from "hooks/usePagination"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroller"
import { Link, useHistory } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import moment from "moment"
import { useResponse } from "hooks/useResponse"
import NotificationCard from "components/CommonForBoth/NotificationCard"
export default function AllNotifications() {
  const { t } = useTranslation()
  const navigate = useHistory()

  const [allNotifications, setAllNotifications] = useState([])
  const {
    setMeta,
    loading: notificationsLoading,
    error: notificationsError,
    response: notificationsResponse,
    page,
    setPage,
    totalPages,
  } = usePagination(getNotifications)

  useEffect(() => {
    if (
      notificationsResponse &&
      notificationsResponse.data &&
      notificationsResponse.data.notifications.length > 0 &&
      !allNotifications
        .map(p => p.id)
        .includes(notificationsResponse.data.notifications[0].id)
    ) {
      setAllNotifications([
        ...allNotifications,
        ...notificationsResponse.data.notifications,
      ])
    }
  }, [notificationsResponse])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Card style={{ width: "50%", margin: "auto" }}>
          <CardBody>
            <h4>{t("pages.Notifications")}</h4>
            <InfiniteScroll
              initialLoad={false}
              loadMore={() => {
                setPage(p => p + 1)
              }}
              hasMore={page < totalPages - 1}
              loader={<div className="loader" key={0}></div>}
            >
              {allNotifications.map(n => {
                return <NotificationCard key={n.id} n={n} />
              })}
            </InfiniteScroll>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

import { changeAdminStatus, deleteAdmin, getMyAdmins } from "api/admins"
import React, { useState } from "react"
import { usePagination } from "../../../../hooks/usePagination"
import ResultHandler from "components/ResultHandler"
import PaginationHandler from "components/PaginationHandler"
import TableHeaderHandler from "components/TableHeaderHandler"
import { PERMISSIONS } from "api/permissions"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import EmptyData from "components/EmptyData"
import { getRolesDropdown } from "api/roles"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useResponse } from "hooks/useResponse"
import DeleteModal from "components/Common/DeleteModal"
import TableAction from "components/TableAction"
import { Button } from "reactstrap"
import CustomImage from "components/CustomImage"

export default function MyAdminsTable() {
  const { t } = useTranslation()

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setMeta,
    setPayload
  } = usePagination(getMyAdmins)
  const {
    response: rolesResponse,
    error: rolesError,
    loading: rolesLoading,
  } = usePagination(getRolesDropdown)

  const navigate = useHistory()

  // modal
  const [admin, setAdmin] = useState(null)

  function onCloseClick() {
    setShow(false)
    setStatusShow(false)
  }

  // delete 

  const [show, setShow] = useState(false)

  const { setData } = useResponse(deleteAdmin, () => {
    setShow(false)
    setPayload(null)
  })


  function onDeleteClick() {
    setData({ type: "add", data: deletingAdmin })
  }

  // change status

  const [statusShow, setStatusShow] = useState(false)

  const { setData: changeStatus } = useResponse(changeAdminStatus, () => {
    setStatusShow(false)
    setPayload(null)
  })

  function handleOpenChangeStatus(id) {
    setAdmin(id)
    setStatusShow(true)
  }

  function handleChangeStutus() {
    changeStatus({ type: "add", data: admin })
  }

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.ADMIN_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl="/add-admin"
      >
        <div className="tableFilters">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            {t("pages.Role")}:
            <select
              style={{ marginRight: "10px" }}
              onChange={e => {
                if (e.target.value !== "all") {
                  setMeta({ page: 1, payload: e.target.value })
                } else {
                  setMeta({ page: 1, payload: null })
                }
              }}
              className="form-select"
            >
              <option value={"all"}>{t("forms.All")}</option>
              {rolesResponse &&
                rolesResponse.data &&
                rolesResponse.data.roles.map(r => {
                  return (
                    <option value={r.id} key={r.id}>
                      {r.name}
                    </option>
                  )
                })}
            </select>
          </div>
        </div>

      </TableHeaderHandler>

      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                <Th style={{ width: "10%" }}>
                  {t("forms.Image")}
                </Th>
                <Th style={{ width: "15%" }}>{t("forms.Name")}</Th>
                <Th style={{ width: "20%" }}>{t("forms.Email")}</Th>


                <Th style={{ width: "15%" }}>{t("forms.Status")}</Th>
                <Th style={{ width: "5%" }}>{t("forms.Published Posts")}</Th>

                <Th style={{ width: "5%" }}>{t("forms.Draft Posts")}</Th>
                <Th style={{ width: "10%" }}>{t("forms.Role")}</Th>
                <Th style={{ width: "20%" }}>
                  {t("tables.Action")}
                </Th>
              </Tr>
            </Thead>

            {!loading && !error && (
              <Tbody>
                {response.data.admins.map(row => {
                  return (
                    <Tr key={row.id}>
                      <Td>
                        {row.profile_pic_url ? (
                          <CustomImage
                            src={row.profile_pic_url}
                            className="rounded avatar-sm"
                            alt=""
                          />
                        ) : (
                          <div className="avatar-sm">
                            <span className="avatar-title rounded bg-primary text-white font-size-16">
                              {row.username.charAt(0)}
                            </span>
                          </div>
                        )}
                      </Td>
                      <Td>{row.username}</Td>
                      <Td>{row.email}</Td>



                      <Td>
                        {localStorage.getItem("permissions").includes(PERMISSIONS.ADMIN_STATUS) &&
                          <>
                            {!row.active &&
                              <Button color="primary" onClick={() => { handleOpenChangeStatus(row.id) }}>{t("btns.Activate")}</Button>
                            }
                            {row.active &&
                              <Button color="danger" onClick={() => { handleOpenChangeStatus(row.id) }}>{t("btns.Deactive")}</Button>
                            }
                          </>
                        }

                      </Td>
                      <Td>{row.countPublishedPosts ? row.countPublishedPosts : t("forms.Not Found")}</Td>

                      <Td>{row.countDraftPosts ? row.countDraftPosts : t("forms.Not Found")}</Td>
                      <Td>{row.Role ? row.Role.name : t("forms.Not Found")}</Td>
                      <Td>
                        <TableAction
                          hasEdit={true}
                          hasDelete={true}
                          editPermission={PERMISSIONS.ADMIN_EDIT}
                          deletePermission={PERMISSIONS.ADMIN_DELETE}
                          type="admin"
                          id={row.id}
                          setShow={setShow}
                          setDeleteIntitiy={setAdmin}
                        />
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          {!loading && !error && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}

          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />

          <DeleteModal
            show={statusShow}
            onDeleteClick={handleChangeStutus}
            onCloseClick={onCloseClick}
          />

          {!loading && (
            <EmptyData
              empty={response.data && response.data.admins.length === 0}
              msg="No Admins Found."
            />
          )}

          <ResultHandler error={error} loading={loading} />
        </div>
      </div>
    </>
  )
}

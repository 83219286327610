import { deletePost, getPosts } from "api/posts"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { usePagination } from "../../../../hooks/usePagination"
import { Button, Input } from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import ResultHandler from "components/ResultHandler"
import PaginationHandler from "components/PaginationHandler"
import TableHeaderHandler from "components/TableHeaderHandler"
import { PERMISSIONS } from "api/permissions"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import EmptyData from "components/EmptyData"
import PostAction from "./PostAction"
import "./PostsTable.css"
import { getStagesDropdown } from "api/stages"
import { getCategoriesDropdown } from "api/categories"
import placeholderImg from "../../../../assets/images/post_placeholder.jpg"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import i18next from "i18next"
import CustomImage from "components/CustomImage"

export default function PostsTable({ created_by }) {
  const { t } = useTranslation()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setMeta,
    setPayload,
  } = usePagination(getPosts, { created_by })

  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }

  const [deletingPost, setDeletingPost] = useState(null)

  const {
    setData,
    success: deleteSuccess,
    setSuccess: setDeleteSuccess,
    loading: deleteLoading,
  } = useResponse(deletePost, () => {
    setTimeout(() => {
      const allDeleted = deletedPosts.concat([deletingPost])
      setDeletedPosts([...deletedPosts, deletingPost])
      if (
        response.data.posts.filter(p => !allDeleted.includes(p.id)).length ===
          0 &&
        page > 1
      ) {
        setPage(page - 1)
      }
      if (
        response.data.posts.filter(p => !allDeleted.includes(p.id)).length ===
          0 &&
        page === 1 &&
        page < totalPages
      ) {
        setPayload({ txt: "" })
        setPage(1)
      }
      setDeletingPost(null)
      setDeleteSuccess(false)
    }, 300)
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingPost })
    setShow(false)
  }

  const [deletedPosts, setDeletedPosts] = useState([])

  // filters

  const [filters, setFilters] = useState({
    categories: null,
    stages: null,
    txt: null,
    type: null,
    language: null,
    created_by,
  })

  function handleSearch() {
    setMeta({ page: 1, payload: filters })
  }

  const {
    response: stagesResponse,
    error: stagesError,
    loading: stagesLoading,
  } = usePagination(getStagesDropdown)

  const {
    response: categoriesResponse,
    error: categoriesError,
    loading: categoriesLoading,
  } = usePagination(getCategoriesDropdown, {lang: "en,ar"})

  // move

  const [moveSuccess, setMoveSuccess] = useState(false)

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.POSTS_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl="/add-post"
      >
        <div
          className="d-flex tableFilters"
          style={{ flexWrap: "wrap", marginBottom: "15px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("forms.Title")}:
            <Input
              name="title"
              style={{ marginRight: "10px" }}
              placeholder={t("forms.Title")}
              type="text"
              className="form-control"
              onChange={e => {
                setFilters({
                  ...filters,
                  txt: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("pages.Stage")}:
            <select
              style={{ marginRight: "10px", width: "100%" }}
              onChange={e => {
                setFilters({
                  ...filters,
                  stages: e.target.value === "all" ? null : [e.target.value],
                })
              }}
              className="form-select"
            >
              <option value={"all"}>{t("forms.All")}</option>
              {stagesResponse &&
                stagesResponse.data &&
                stagesResponse.data.stages.map(r => {
                  return (
                    <option value={r.id} key={r.id}>
                      {r.name}
                    </option>
                  )
                })}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("pages.Category")}:
            <select
              style={{ marginRight: "10px", width: "100%" }}
              onChange={e => {
                setFilters({
                  ...filters,
                  categories:
                    e.target.value === "all" ? null : [e.target.value],
                })
              }}
              className="form-select"
            >
              <option value={"all"}>{t("forms.All")}</option>
              {categoriesResponse &&
                categoriesResponse.data &&
                categoriesResponse.data.categories.map(r => {
                  return (
                    <option value={r.id} key={r.id}>
                      {r.name}
                    </option>
                  )
                })}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("forms.Type")}:
            <select
              style={{ marginRight: "10px", width: "100%" }}
              onChange={e => {
                setFilters({
                  ...filters,
                  type: e.target.value === "all" ? null : [e.target.value],
                })
              }}
              className="form-select"
            >
              <option value={"all"}>{t("forms.All")}</option>
              <option value={"post"}>{t("forms.Post")}</option>
              <option value={"infographic"}>{t("forms.Infographic")}</option>
              <option value={"video"}>{t("forms.Video")}</option>
              <option value={"album"}>{t("forms.Album")}</option>
            </select>
          </div>
          {/*<div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("forms.Language")}:
            <select
              style={{ marginRight: "10px", width: "100%" }}
              onChange={e => {
                setFilters({
                  ...filters,
                  language: e.target.value === "all" ? null : [e.target.value],
                })
              }}
              className="form-select"
            >
              <option value={"all"}>{t("forms.All")}</option>
              <option value={"ar"}>العربية</option>
              <option value={"en"}>English</option>
            </select>
            </div>*/}
          <div>
            <Button onClick={handleSearch}>{t("btns.Search")}</Button>
          </div>
        </div>
      </TableHeaderHandler>

      <div className="table-rep-plugin">
        <div className="table-responsive postsTable">
          <ResultHandler
            success={moveSuccess}
            successMsg="Moved Successfully!"
          />
          <Table className="table table-striped table-bordered">
            {response.data && !loading && (
              <>
                <Thead className="table-light table-nowrap">
                  <Tr>
                    <Th className="loading" style={{ width: "108px" }}></Th>

                    <Th style={{ width: "5%" }}>{t("forms.Image")}</Th>

                    <Th style={{ width: "20%" }}>{t("forms.Title")}</Th>
                    <Th style={{ width: "10%" }}>{t("forms.Type")}</Th>
                    <Th style={{ width: "10%" }}>{t("pages.Stage")}</Th>

                    <Th style={{ width: "10%" }}>{t("pages.Categories")}</Th>

                    <Th style={{ width: "20%" }}>{t("forms.Authors")}</Th>
                    <Th style={{ width: "5%" }}>{t("tables.Action")}</Th>
                    <Th style={{ width: "20%" }}>{t("btns.Back")}</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {response.data.posts.map(row => {
                    return (
                      <Tr
                        key={row.id}
                        className={
                          (deletingPost == row.id && deleteLoading
                            ? "deleting "
                            : "") +
                          (deletedPosts.includes(row.id) ? "deleted" : "")
                        }
                        style={{
                          transform:
                            deletingPost == row.id && deleteSuccess
                              ? "translateX("+ (i18next.language === 'ar' ?  "-" : "") +"100%)"
                              : deletingPost == row.id && deleteLoading
                              ? "translateX("+(i18next.language === 'ar' ?  "-" : "")+"10%)"
                              : "",
                        }}
                      >
                        <Td
                          className="loading"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "0px",
                            paddingTop: "18px",
                            width: "108px",
                            margin: "0px",
                          }}
                        >
                          <span className="loader"></span>
                        </Td>
                        <Td>
                          <CustomImage
                            src={
                              row.mainImage_url
                                ? row.mainImage_url
                                : placeholderImg
                            }
                            alt=""
                            className="d-block rounded"
                            style={{
                              width: "40px",
                              height: "40px",
                              margin: "0px",
                            }}
                          />
                        </Td>
                        <Td>{row.title}</Td>
                        <Td>
                          {row.type &&
                            t(
                              "forms." +
                                row.type.charAt(0).toUpperCase() +
                                row.type.slice(1)
                            )}
                        </Td>
                        <Td>{row.Stage ? row.Stage.name : t("Draft")}</Td>
                        <PostAction
                          setMoveSuccess={setMoveSuccess}
                          authors={row.Authors}
                          reloadPosts={() => {
                            setPayload({ txt: "" })
                          }}
                          row={row}
                          handleDelete={id => {
                            setShow(true)
                            setDeletingPost(id)
                          }}
                        />
                      </Tr>
                    )
                  })}
                </Tbody>
              </>
            )}
          </Table>

          {!loading && !deleteLoading && (
            <EmptyData
              empty={
                response.data &&
                response.data.posts.filter(p => !deletedPosts.includes(p.id))
                  .length === 0
              }
              msg="No Posts Found."
            />
          )}
          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />

          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          <ResultHandler error={error} loading={loading} />
        </div>
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody, Container, Form } from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import { editKicker, getKicker } from "api/kickers"
import FormButtonHandler from "components/FormButtonHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "hooks/useGetInstence"
import KickerForm from "./KickerForm"
import useCustomValidation from "pages/dashboard/validationSchema"

const EditKicker = () => {
  let navigate = useHistory()
  const { kickerSchema } = useCustomValidation()

  const { t } = useTranslation()

  const params = useParams()
  const [editedKicker, setEditedKicker] = useState(null)

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getKicker,
    params.id,
    response => {
      setEditedKicker(response.data.data.kicker)
    }
  )

  const { setData, loading, error, success } = useResponse(editKicker, () => {
    navigate.push("/kickers")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: editedKicker ? editedKicker.name : "",
      image_url: editedKicker ? editedKicker.image_url : "",
      language: editedKicker ? editedKicker.language : "",
      slug: editedKicker ? editedKicker.slug : "",
      category: editedKicker
        ? { value: editedKicker.Category.id, label: editedKicker.Category.name }
        : null,
    },
    validationSchema: kickerSchema,
    onSubmit: values => {
      let data = {
        ...values,
        categoryId: values.category.value,
      }
      setData({ type: "edit", id: params.id, data })
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Kickers")}
            breadcrumbItem={t("btns.Edit") + " " + t("pages.Kicker")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedKicker && (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <KickerForm validation={validation} />

                      <FormButtonHandler btnTxt="Edit" loading={loading} />
                    </Form>
                  )}

                  <ResultHandler
                    loading={instenceLoading}
                    error={error}
                    success={success}
                    successMsg={"Updated Successfully!"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditKicker

import React, { useEffect, useState } from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import ReactDiffViewer from "react-diff-viewer"
import { useTranslation } from "react-i18next"
import "./CompareVersionsModal.css"
import moment from "moment"
import CustomImage from "components/CustomImage"

const CompareVersionsModal = props => {
  const { t } = useTranslation()

  const oldVersion =
    props.versions[0].createdAt > props.versions[1].createdAt
      ? props.versions[0]
      : props.versions[1]

  const newVersion =
    props.versions[0].createdAt > props.versions[1].createdAt
      ? props.versions[1]
      : props.versions[0]

  const greatesVersion =
    props.versions[0].postVersionContent.length >
    props.versions[1].postVersionContent.length
      ? props.versions[0]
      : props.versions[1]

  const greatesLength =
    props.versions[0].postVersionContent.length >
    props.versions[1].postVersionContent.length
      ? props.versions[0].postVersionContent.length
      : props.versions[1].postVersionContent.length

  const smallestLength =
    props.versions[0].postVersionContent.length >
    props.versions[1].postVersionContent.length
      ? props.versions[1].postVersionContent.length
      : props.versions[0].postVersionContent.length

  const [oldContents, setOldContents] = useState(oldVersion.postVersionContent)
  const [newContents, setNewContents] = useState(newVersion.postVersionContent)

  useEffect(() => {
    for (let i = 0; i < greatesLength - smallestLength; i++) {
      if (
        oldVersion.postVersionContent.length >
        newVersion.postVersionContent.length
      ) {
        setNewContents([
          ...newContents,
          { type: oldContents[i].type, content: "", raw_content: "" },
        ])
      }
      if (
        newVersion.postVersionContent.length >
        oldVersion.postVersionContent.length
      ) {
        setOldContents([
          ...oldContents,
          { type: newContents[i].type, content: "", raw_content: "" },
        ])
      }
    }
  }, [])

  return (
    <Modal
      isOpen={props.show}
      toggle={props.onCloseClick}
      centered={true}
      size={"xl"}
    >
      <ModalBody className="py-5 px-5 compareVersions">
        <Row>
          <Col lg={12}>
            <div className="text-center" style={{ marginBottom: "20px" }}>
              <h2> {t("btns.Compare") + " " + t("btns.Versions")}</h2>
            </div>
          </Col>
          <Col
            style={{
              direction: oldVersion.language === "ar" ? "rtl" : "ltr",
            }}
          >
            {oldVersion && newVersion && (
              <>
                <Row style={{ marginBottom: "20px" }}>
                  <Col xs={6}>
                    {moment(newVersion.createdAt).format("h:mm YYYY-MM-DD")}
                  </Col>
                  <Col xs={6}>
                    {moment(oldVersion.createdAt).format("h:mm YYYY-MM-DD")}
                  </Col>
                </Row>
                <div style={{ marginBottom: "20px" }}>
                  <h5>{t("forms.Title")}</h5>
                  <ReactDiffViewer
                    compareMethod="diffWords"
                    styles={{}}
                    showDiffOnly={false}
                    newValue={oldVersion.title}
                    oldValue={newVersion.title}
                    splitView={true}
                    hideLineNumbers={true}
                  />
                </div>
                {newContents.length === oldContents.length && (
                  <div style={{ marginBottom: "20px" }}>
                    <h5>{t("forms.Content")}</h5>
                    {greatesVersion.postVersionContent.map((x, i) => {
                      if (
                        oldContents[i].type === "html" &&
                        newContents[i].type === "html"
                      ) {
                        return (
                          <ReactDiffViewer
                            compareMethod="diffWords"
                            showDiffOnly={false}
                            newValue={oldContents[i].raw_content}
                            oldValue={newContents[i].raw_content}
                            splitView={true}
                            hideLineNumbers={true}
                            key={"versionContent-" + i}
                          />
                        )
                      } else if (
                        oldContents[i].type === "img" &&
                        newContents[i].type === "img"
                      ) {
                        return (
                          <Row
                            key={"versionContent-" + i}
                            style={{ marginTop: "10px" }}
                          >
                            <Col md={6} style={{ padding: "0px" }}>
                              {newVersion.postVersionContent[i] && (
                                <CustomImage
                                  src={newContents[i].content}
                                  style={{ width: "200px", height: "200px" }}
                                  alt=""
                                />
                              )}
                            </Col>
                            <Col md={6}>
                              <CustomImage
                                src={oldContents[i].content}
                                style={{ width: "200px", height: "200px" }}
                                alt=""
                              />
                            </Col>
                          </Row>
                        )
                      } else if (
                        oldContents[i].type === "html" &&
                        newContents[i].type === "img"
                      ) {
                        return (
                          <Row
                            key={"versionContent-" + i}
                            style={{ marginTop: "10px" }}
                          >
                            <Col md={6} style={{ padding: "0px" }}>
                              <CustomImage
                                src={newContents[i].content}
                                style={{ width: "200px", height: "200px" }}
                                alt=""
                              />
                            </Col>
                            <Col md={6}>
                              <ReactDiffViewer
                                compareMethod="diffWords"
                                newValue={oldContents[i].raw_content}
                                oldValue={newContents[i].raw_content}
                                splitView={false}
                                hideLineNumbers={true}
                              />
                            </Col>
                          </Row>
                        )
                      } else if (
                        oldContents[i].type === "img" &&
                        newContents[i].type === "html"
                      ) {
                        return (
                          <Row
                            key={"versionContent-" + i}
                            style={{ marginTop: "10px" }}
                          >
                            <Col md={6} style={{ padding: "0px" }}>
                              <ReactDiffViewer
                                compareMethod="diffWords"
                                newValue={oldContents[i].raw_content}
                                oldValue={newContents[i].raw_content}
                                splitView={false}
                                hideLineNumbers={true}
                              />
                            </Col>
                            <Col md={6}>
                              <CustomImage
                                src={oldContents[i].content}
                                style={{ width: "200px", height: "200px" }}
                                alt=""
                              />
                            </Col>
                          </Row>
                        )
                      }
                    })}
                  </div>
                )}
                <div style={{ marginBottom: "20px" }}>
                  <h5>{t("forms.Slug")}</h5>
                  <ReactDiffViewer
                    compareMethod="diffWords"
                    newValue={oldVersion.slug || ""}
                    oldValue={newVersion.slug || ""}
                    splitView={true}
                    showDiffOnly={false}
                    hideLineNumbers={true}
                  />
                </div>
                <div>
                  <h5>{t("forms.Summary")}</h5>
                  <ReactDiffViewer
                    compareMethod="diffWords"
                    newValue={oldVersion.summary || ""}
                    oldValue={newVersion.summary || ""}
                    splitView={true}
                    showDiffOnly={false}
                    hideLineNumbers={true}
                  />
                </div>
              </>
            )}
            <div className="text-center mt-5">
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={props.onCloseClick}
              >
                {t("btns.Cancel")}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default CompareVersionsModal

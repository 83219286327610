import React from "react"
//import components
import Breadcrumbs from "components/Common/Breadcrumb"

import PostsTable from "./PostsTable"
import { useTranslation } from "react-i18next"
export default function PostsList() {
  const { t } = useTranslation()
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.Posts")} />
        <PostsTable />
      </div>
    </div>
  )
}

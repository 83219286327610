import {
  Card,
  CardBody,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Label,
  Button,
} from "reactstrap"
import classnames from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PRIORITIES } from "utils/constants"
import { getStages } from "api/stages"
import { getTags, getTopTags } from "api/tags"
import { usePagination } from "hooks/usePagination"
import { getMonitorCategories } from "api/categories"

export default function FiltersSidebar({ setFilters, stages, setStages }) {
  const { t } = useTranslation()

  const [catActiveTab, setCatActiveTab] = useState("1")
  const toggleCatTab = tab => {
    if (catActiveTab !== tab) {
      setCatActiveTab(tab)
    }
  }

  const [tagActiveTab, setTagActiveTab] = useState("1")
  const toggleTagTab = tab => {
    if (tagActiveTab !== tab) {
      setTagActiveTab(tab)
    }
  }

  // title search

  const [titleSearchTxt, setTitleSearchTxt] = useState("")

  // date filter
  //const [dates, setDates] = useState([])

  // stages filter
  const [stagesLoading, setStagesLoading] = useState(true)
  const [stagesError, setStagesError] = useState(null)
  const [filteredStages, setFilteredStages] = useState(null)
  useEffect(() => {
    getStages()
      .then(res => {
        setStages(res.data.data.stages)
        setStagesLoading(false)
      })
      .catch(({ response }) => {
        setStagesError(response.data.errors[0])
        setStagesLoading(false)
      })
  }, [])

  // tags filter
  const [topTags, setTopTags] = useState(null)
  const [tagsLoading, setTagsLoading] = useState(true)
  const [tagsError, setTagsError] = useState(null)
  const [filteredTags, setFilteredTags] = useState(null)
  useEffect(() => {
    // top tags
    getTopTags()
      .then(res => {
        setTopTags(res.data.data.tags)
        setTagsLoading(false)
      })
      .catch(({ response }) => {
        setTagsError(response.data.errors[0])
        setTagsLoading(false)
      })
  }, [])

  // search tags
  const [tagsSearchTxt, setTagsSearchTxt] = useState("")
  const {
    loading: tagsSearchLoading,
    error: tagsSearchError,
    response: tagsSearchResponse,
    setPayload: setTagsSearch,
  } = usePagination(getTags)

  // cats filter
  const [topCats, setTopCats] = useState(null)
  const [CatsLoading, setCatsLoading] = useState(true)
  const [CatsError, setCatsError] = useState(null)
  const [filteredCats, setFilteredCats] = useState(null)
  useEffect(() => {
    // top tags
    getMonitorCategories(null, { page: 1, perPage: 10 })
      .then(res => {
        setTopCats(res.data.categories)
        setCatsLoading(false)
      })
      .catch(({ response }) => {
        setCatsError(response.data.errors[0])
        setCatsLoading(false)
      })
  }, [])

  // search cats
  const [catsSearchTxt, setCatsSearchTxt] = useState("")
  const {
    loading: catsSearchLoading,
    error: catsSearchError,
    response: catsSearchResponse,
    setPayload: setCatsSearch,
  } = usePagination(getMonitorCategories)

  // priority filter

  const [filteredPriorities, setFilteredPriorities] = useState(null)

  // get posts

  function submitFilters() {
    setFilters({
      txt: titleSearchTxt,
      stages: filteredStages,
      categories: filteredCats && filteredCats.map(s => s.id),
      tags: filteredTags && filteredTags.map(s => s.id),
      priorities: filteredPriorities,
    })
  }

  return (
    <Card>
      <CardBody>
        <div className="filter">
          <h5 className="font-size-14 mb-2">{t("forms.Search")}</h5>
          <div
            className="search-box"
            style={{ border: "1px solid #495867", borderRadius: "20px" }}
          >
            <div className="position-relative">
              <i className="bx bx-search-alt search-icon" />
              <Input
                value={titleSearchTxt}
                onChange={e => {
                  setTitleSearchTxt(e.target.value)
                }}
                type="text"
                className="form-control border-0"
                placeholder={t("forms.Search") + "..."}
              />
            </div>
          </div>
        </div>

        {stages && (
          <div className="filter stagesFilter">
            <h5 className="font-size-14 mb-2">{t("pages.Stages")}</h5>

            <ul
              style={{
                borderRight: "2px solid #d7d7d7",
                paddingTop: "10px",
                paddingBottom: "10px",
                position: "relative",
              }}
              className="verti-timeline list-unstyled"
            >
              {stages.map(s => {
                return (
                  <li
                    key={s.id}
                    onClick={() => {
                      if (filteredStages && filteredStages.includes(s.id)) {
                        setFilteredStages(
                          filteredStages.filter(st => st != s.id)
                        )
                      } else {
                        setFilteredStages([
                          ...(filteredStages ? filteredStages : []),
                          s.id,
                        ])
                      }
                    }}
                    className={
                      filteredStages && filteredStages.includes(s.id)
                        ? "event-list active"
                        : "event-list"
                    }
                  >
                    <div className="event-timeline-dot">
                      <i
                        style={{ fontSize: "20px", backgroundColor: "white" }}
                        className="bx bx-left-arrow-circle"
                      />
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h6 style={{ margin: "0px" }}>{s.name}</h6>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        )}

        <div className="filter">
          <h5 className="font-size-14 mb-2">{t("forms.Priority")}</h5>
          {PRIORITIES.map(p => {
            return (
              <div key={p.num} className="form-check mt-2">
                <Input
                  onChange={() => {
                    if (
                      filteredPriorities &&
                      filteredPriorities.includes(p.num)
                    ) {
                      setFilteredPriorities(
                        filteredPriorities.filter(pr => pr != p.num)
                      )
                    } else {
                      setFilteredPriorities([
                        ...(filteredPriorities ? filteredPriorities : []),
                        p.num,
                      ])
                    }
                  }}
                  type="checkbox"
                  className="form-check-input"
                />
                <Label className="form-check-label">{p.label}</Label>
              </div>
            )
          })}
        </div>

        <div className="filter">
          <h5 className="font-size-14 mb-2">{t("pages.Categories")}</h5>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {filteredCats &&
              filteredCats.map(t => {
                return (
                  <span
                    onClick={() => {
                      setFilteredCats(filteredCats.filter(st => st.id != t.id))
                    }}
                    className="active tag"
                    key={t.id}
                  >
                    {t.name}
                  </span>
                )
              })}
          </div>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", fontSize: "12px" }}
                className={classnames({
                  active: catActiveTab === "1",
                })}
                onClick={() => {
                  toggleCatTab("1")
                }}
              >
                {t("monitor.Most Used")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", fontSize: "12px" }}
                className={classnames({
                  active: catActiveTab === "2",
                })}
                onClick={() => {
                  toggleCatTab("2")
                }}
              >
                {t("monitor.All")}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={catActiveTab} className="pt-3 text-muted">
            <TabPane tabId="1">
              {topCats && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "-15px",
                  }}
                >
                  {topCats
                    .filter(
                      t =>
                        (filteredCats &&
                          !filteredCats.map(tg => tg.id).includes(t.id)) ||
                        !filteredCats
                    )
                    .map(t => {
                      return (
                        <span
                          onClick={() => {
                            if (
                              filteredCats &&
                              filteredCats.map(g => g.id).includes(t.id)
                            ) {
                              setFilteredCats(
                                filteredCats.filter(st => st.id != t.id)
                              )
                            } else {
                              setFilteredCats([
                                ...(filteredCats ? filteredCats : []),
                                t,
                              ])
                            }
                          }}
                          className={
                            filteredCats &&
                            filteredCats.map(g => g.id).includes(t.id)
                              ? "tag active"
                              : "tag"
                          }
                          key={t.id}
                        >
                          {t.name}
                        </span>
                      )
                    })}
                </div>
              )}
            </TabPane>

            <TabPane tabId="2">
              <div
                className="search-box"
                style={{
                  border: "1px solid #495867",
                  borderRadius: "20px",
                  marginBottom: "15px",
                }}
              >
                <div className="position-relative">
                  <i className="bx bx-search-alt search-icon" />
                  <Input
                    type="text"
                    onChange={e => {
                      setCatsSearchTxt(e.target.value)
                      setCatsSearch({ txt: e.target.value })
                    }}
                    value={catsSearchTxt}
                    className="form-control border-0"
                    placeholder={t("forms.Search") + "..."}
                  />
                </div>
              </div>

              {catsSearchTxt && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginBottom: "-15px",
                    }}
                  >
                    {catsSearchResponse.data.categories
                      .filter(
                        t =>
                          (filteredCats &&
                            !filteredCats.map(g => g.id).includes(t.id)) ||
                          !filteredCats
                      )
                      .map(t => {
                        return (
                          <span
                            key={t.id}
                            onClick={() => {
                              if (
                                filteredCats &&
                                filteredCats.map(g => g.id).includes(t.id)
                              ) {
                                setFilteredCats(
                                  filteredCats.filter(st => st.id != t.id)
                                )
                              } else {
                                setFilteredCats([
                                  ...(filteredCats ? filteredCats : []),
                                  t,
                                ])
                              }
                            }}
                            className={
                              filteredCats &&
                              filteredCats.map(g => g.id).includes(t.id)
                                ? "tag active"
                                : "tag"
                            }
                          >
                            {t.name}
                          </span>
                        )
                      })}
                  </div>
                </>
              )}
            </TabPane>
          </TabContent>
        </div>

        <div className="filter">
          <h5 className="font-size-14 mb-2">{t("forms.Tags")}</h5>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {filteredTags &&
              filteredTags.map(t => {
                return (
                  <span
                    onClick={() => {
                      setFilteredTags(filteredTags.filter(st => st.id != t.id))
                    }}
                    className="active tag"
                    key={t.id}
                  >
                    {t.name}
                  </span>
                )
              })}
          </div>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", fontSize: "12px" }}
                className={classnames({
                  active: tagActiveTab === "1",
                })}
                onClick={() => {
                  toggleTagTab("1")
                }}
              >
                {t("monitor.Most Used")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", fontSize: "12px" }}
                className={classnames({
                  active: tagActiveTab === "2",
                })}
                onClick={() => {
                  toggleTagTab("2")
                }}
              >
                {t("monitor.All")}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={tagActiveTab} className="pt-3 text-muted">
            <TabPane tabId="1">
              {topTags && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "-15px",
                  }}
                >
                  {topTags
                    .filter(
                      t =>
                        (filteredTags &&
                          !filteredTags.map(tg => tg.id).includes(t.id)) ||
                        !filteredTags
                    )
                    .map(t => {
                      return (
                        <span
                          onClick={() => {
                            if (
                              filteredTags &&
                              filteredTags.map(g => g.id).includes(t.id)
                            ) {
                              setFilteredTags(
                                filteredTags.filter(st => st.id != t.id)
                              )
                            } else {
                              setFilteredTags([
                                ...(filteredTags ? filteredTags : []),
                                t,
                              ])
                            }
                          }}
                          className={
                            filteredTags &&
                            filteredTags.map(g => g.id).includes(t.id)
                              ? "tag active"
                              : "tag"
                          }
                          key={t.id}
                        >
                          {t.name}
                        </span>
                      )
                    })}
                </div>
              )}
            </TabPane>

            <TabPane tabId="2">
              <div
                className="search-box"
                style={{
                  border: "1px solid #495867",
                  borderRadius: "20px",
                  marginBottom: "15px",
                }}
              >
                <div className="position-relative">
                  <i className="bx bx-search-alt search-icon" />
                  <Input
                    type="text"
                    onChange={e => {
                      setTagsSearchTxt(e.target.value)
                      setTagsSearch({ txt: e.target.value })
                    }}
                    value={tagsSearchTxt}
                    className="form-control border-0"
                    placeholder={t("forms.Search") + "..."}
                  />
                </div>
              </div>

              {tagsSearchTxt && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginBottom: "-15px",
                    }}
                  >
                    {tagsSearchResponse.data.tags
                      .filter(
                        t =>
                          (filteredTags &&
                            !filteredTags.map(g => g.id).includes(t.id)) ||
                          !filteredTags
                      )
                      .map(t => {
                        return (
                          <span
                            key={t.id}
                            onClick={() => {
                              if (
                                filteredTags &&
                                filteredTags.map(g => g.id).includes(t.id)
                              ) {
                                setFilteredTags(
                                  filteredTags.filter(st => st.id != t.id)
                                )
                              } else {
                                setFilteredTags([
                                  ...(filteredTags ? filteredTags : []),
                                  t,
                                ])
                              }
                            }}
                            className={
                              filteredTags &&
                              filteredTags.map(g => g.id).includes(t.id)
                                ? "tag active"
                                : "tag"
                            }
                          >
                            {t.name}
                          </span>
                        )
                      })}
                  </div>
                </>
              )}
            </TabPane>
          </TabContent>
        </div>

        <Button onClick={submitFilters} color="primary">
          {t("btns.Search")}
        </Button>
      </CardBody>
    </Card>
  )
}

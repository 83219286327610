import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Board, { moveCard } from "../../../../packages/react-kanban/index"
import "../../../../packages/react-kanban/styles.scss"
import PostCard from "./PostCard"
import "./StagesBoard.css"
import "assets/scss/tasks.scss"
import { movePost } from "api/posts"
import { getMyStages } from "api/stages"
import ResultHandler from "../../../../components/ResultHandler"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"

const StagesBoard = () => {
  const { t } = useTranslation()

  // board states

  const [controlledBoard, setBoard] = useState(null)
  const [boardLoading, setBoardLoading] = useState(true)
  const [boardError, setBoardError] = useState("")

  // get stages

  function loadStages() {
    getMyStages()
      .then(res => {
        const columns = res.data.data.stages.map(s => {
          return { id: s.id, title: s.name, cards: [] }
        })
        setBoard({ columns })
      })
      .catch(({ response }) => {
        setBoardError(response.data.errors[0])
        setBoardLoading(false)
      })
  }

  useEffect(() => {
    loadStages()
  }, [])

  // get posts

  function loadStagePosts(stageId, posts) {
    setBoard({
      columns: controlledBoard.columns.map(s => {
        if (s.id === stageId) {
          return { ...s, cards: posts }
        } else {
          return s
        }
      }),
    })
  }

  // move

  const {
    setData: setMovePost,
    loading: moveLoading,
    error: moveError,
    setError: setMoveError,
    success: moveSuccess,
    setSuccess: setMoveSuccess,
  } = useResponse(movePost, () => {})

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(controlledBoard, source, destination)
    let targetId = destination.toColumnId
    let fromColumnId = source.fromColumnId
    let postId = _card.id

    const moveToPerm =
      JSON.parse(localStorage.getItem("permissions")).filter(per => {
        return per === "permission-stage-" + targetId + "-move-to"
      }).length > 0
    const moveFromPerm =
      JSON.parse(localStorage.getItem("permissions")).filter(per => {
        return per === "permission-stage-" + fromColumnId + "-move-from"
      }).length > 0

    if (moveToPerm && moveFromPerm) {
      setBoard(updatedBoard)
      setMovePost({ type: "add", data: { postId, targetId } })
    } else {
      setMoveError("ليس لديك الصلاحية لذلك")
      setMoveSuccess(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ direction: "ltr" }}>
        <Container fluid>
          <ResultHandler
            error={boardError}
            loading={!controlledBoard && boardLoading}
          />

          <ResultHandler
            success={moveSuccess}
            successMsg="Moved Successfully!"
            error={moveError}
            loading={moveLoading}
          />

          {controlledBoard && (
            <Board
              loadStagePosts={loadStagePosts}
              onCardDragEnd={handleCardMove}
              renderCard={content => (
                <PostCard reloadBoard={loadStagePosts} content={content} />
              )}
              disableColumnDrag
            >
              {controlledBoard}
            </Board>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default StagesBoard

import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Button,
} from "reactstrap"
import { useFormik } from "formik"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import FormButtonHandler from "components/FormButtonHandler"
import ChooseImage from "components/ChooseImage"
import { editProfile, getProfile } from "api/admins"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import QRModal from "./QRModal"
import { create2FA } from "api/auth"

import DisableQRModal from "./DisableQRModal"
import useCustomValidation from "../validationSchema"

const EditProfile = () => {
  const { t } = useTranslation()
  const { editProfileSchema } = useCustomValidation()
  const [editedAdmin, setEditedAdmin] = useState(null)
  const [editedAdminLoading, setEditedAdminLoading] = useState(true)
  const [editedAdminError, setEditedAdminError] = useState("")

  function handleChooseImage(url) {
    validation.setFieldValue("img", url)
  }

  useEffect(() => {
    getProfile()
      .then(res => {
        setEditedAdmin(res.data.data.me)
        setEditedAdminLoading(false)
      })
      .catch(err => {
        editedAdminError(err.response.data.errors[0])
        setEditedAdminLoading(false)
      })
  }, [])

  //posting

  const { success, error, loading, setData } = useResponse(
    editProfile,
    ({ data }) => {
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("user")),
          username: data.db_admin.username,
          profile_pic_url: data.db_admin.profile_pic_url,
        })
      )
      navigate.push("/home")
    }
  )

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: editedAdmin ? editedAdmin.username : "",
      email: editedAdmin ? editedAdmin.email : "",
      password: null,
      img: editedAdmin ? editedAdmin.profile_pic_url : "",
    },
    validationSchema: editProfileSchema,
    onSubmit: values => {
      let data = {
        email: values.email,
        password: values.password,
        username: values.username,
        profile_pic_url: values.img,
      }
      setData({ data, type: "add" })
    },
  })

  let navigate = useHistory()

  // enable 2fa

  const [enableShow, setEnableShow] = useState(false)
  const [twoFALoading, setTwoFaLoading] = useState(false)
  const [qr, setQr] = useState(null)
  const [secret, setSecret] = useState(null)

  function handleEnable2FA() {
    create2FA()
      .then(res => {
        setEnableShow(true)
        setQr(res.data.data.qr)
        setSecret(res.data.data.secret)
      })
      .catch(err => {
      })
  }

  // disable 2fa

  const [disableShow, setDisableShow] = useState(false)

  function handleDisable2FA() {
    setDisableShow(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title=""
            breadcrumbItem={t("btns.Edit") + " " + t("pages.Profile")}
          />
          <h6 style={{ marginBottom: "30px" }}>
            {t("pages.Role")} : {JSON.parse(localStorage.getItem("user")).Role.name}
          </h6>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <ResultHandler
                    loading={editedAdminLoading}
                    error={editedAdminError}
                  />
                  {editedAdmin && (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              {t("forms.Email")}
                            </Label>
                            <Input
                              disabled
                              name="email"
                              placeholder={t("forms.Email")}
                              type="email"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                  validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                              validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label>
                              {t("forms.Username")}
                            </Label>
                            <Input
                              name="username"
                              placeholder={t("forms.Username")}
                              type="text"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username &&
                                  validation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.username &&
                              validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label>
                              {t("forms.Password")}
                            </Label>
                            <Input
                              name="password"
                              placeholder={t("forms.Password")}
                              type="password"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                            />
                            {validation.touched.password &&
                              validation.errors.password ? (
                              <div className="invalid-feedback" style={{ display: "block" }} type="invalid">
                                {validation.errors.password}
                              </div>
                            ) : null}

                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <ChooseImage
                            name="img"
                            image={validation.values.img}
                            error={
                              validation.touched.img && validation.errors.img
                            }
                            handleChooseImage={handleChooseImage}
                          />
                        </Col>
                        <Col
                          md={12}
                          style={{ marginBottom: "20px", textAlign: "center" }}
                        >
                          {!twoFALoading && editedAdmin.twoFaEnabled && (
                            <Button
                              className="btn-lg"
                              onClick={handleDisable2FA}
                              color="danger"
                            >
                              {t("btns.Disable Two Factor Auth")}
                            </Button>
                          )}
                          {!twoFALoading && !editedAdmin.twoFaEnabled && (
                            <Button
                              className="btn-lg"
                              onClick={handleEnable2FA}
                              color="success"
                            >
                              {t("btns.Activate Two Factor Auth")}
                            </Button>
                          )}
                          {twoFALoading && (
                            <Button className="btn-lg" type="button">
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                              {t("btns.Loading")}
                            </Button>
                          )}
                        </Col>
                      </Row>

                      <FormButtonHandler btnTxt="Edit" loading={loading} />

                      <ResultHandler
                        error={error}
                        success={success}
                        successMsg={"Updated Successfully!"}
                      />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <QRModal
        qr={qr}
        editedAdmin={editedAdmin}
        setEditedAdmin={setEditedAdmin}
        secret={secret}
        show={enableShow}
        onCloseClick={() => {
          setEnableShow(false)
        }}
      />
      <DisableQRModal
        editedAdmin={editedAdmin}
        setEditedAdmin={setEditedAdmin}
        show={disableShow}
        onCloseClick={() => {
          setDisableShow(false)
        }}
      />
    </React.Fragment>
  )
}

export default EditProfile

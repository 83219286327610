import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { RecoilRoot, useRecoilState } from "recoil"

import { I18nextProvider, initReactI18next } from "react-i18next"

import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import Notification from "components/VerticalLayout/Notification"
import i18next from "i18next"

import common_ar from "./translations/ar/common.json"
import common_en from "./translations/en/common.json"


Sentry.init({
  dsn: "https://7b2e8dcccc5b42e98b2f9f8adec061f8@sentry.beemena.com/2",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'ar', // language to use
  resources: {
    ar: {
      translation: common_ar, // 'common' is our custom namespace
    },
    en: {
      translation: common_en, // 'common' is our custom namespace
    },
  },
  fallbackLng: "en",
})

ReactDOM.render(
  <RecoilRoot>
    <React.Fragment>
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <Notification />
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </React.Fragment>
  </RecoilRoot>,
  document.getElementById("root")
)

import React from "react"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ArchiveTable from "./ArchiveTable"
import { useTranslation } from "react-i18next"

export default function ArchiveList() {
  const { t } = useTranslation()
  return (
    <div className="page-content">
      <div className="container-fluid">
      <Breadcrumbs title="" breadcrumbItem={t("pages.Archive")} />
        <ArchiveTable />
      </div>
    </div>
  )
}

import { useEffect, useState } from "react"

export function usePagination(apiPromise, data = null, initialLoad=true) {
  const [response, setResponse] = useState([])

  const [loading, setLoading] = useState(true)

  const [error, setError] = useState("")

  const [meta, setMeta] = useState({
    disabled: !initialLoad,
    page: 1,
    payload: data,
  })

  const [perPage, setPerPage] = useState(10)

  const [totalItems, settotalItems] = useState(0)

  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    if(!meta.disabled){
      setLoading(true)
      apiPromise(meta.payload, { page: meta.page, perPage })
        .then(res => {
          if (res) {
            setResponse(res)
          } else {
            setResponse({})
          }
          if (res.data.pageInfo) {
            settotalItems(res.data.pageInfo.totalItems)
            setTotalPages(res.data.pageInfo.totalPages)
          }
          setLoading(false)
        })
        .catch(err => {
          if (err.response) {
            setError(err.response.data.errors[0])
          }
          setLoading(false)
        })
    }

  }, [meta, perPage])

  return {
    response,
    loading,
    error,
    setPage: x => {
      setMeta({ ...meta, page: x })
    },
    page: meta.page,
    setPerPage,
    totalItems,
    totalPages,
    setPayload: x => {
      setMeta({ ...meta, payload: x })
    },
    setMeta,
    setMetaKey: (key, value) => {setMeta({...meta, payload: {...(meta.payload),[key]: value}})},
    meta,
    
  }
}

import React, { useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Modal,
  ModalBody,
  Button,
} from "reactstrap"
import { useFormik } from "formik"
import ResultHandler from "components/ResultHandler"
import { editVideo, getVideo } from "api/videos"
import FormButtonHandler from "components/FormButtonHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "hooks/useGetInstence"
import VideoForm from "./VideoForm"
import useCustomValidation from "pages/dashboard/validationSchema"

const EditVideoModal = ({ show, setShow, videoId, reloadTable }) => {
  const { t } = useTranslation()
  const {videoSchema} = useCustomValidation()

  const [editedVideo, setEditedVideo] = useState(null)

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getVideo,
    videoId,
    response => {
      setEditedVideo(response.data.data.video)
    }
  )

  const { setData, loading, error, success } = useResponse(
    editVideo,
    reloadTable
  )

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: editedVideo ? editedVideo.title : "",
    },
    validationSchema: videoSchema,
    onSubmit: data => {
      setData({ type: "edit", id: videoId, data })
    },
  })

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        setShow(false)
      }}
      centered={true}
    >
      <ModalBody className="py-5 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <h2>{t("btns.Edit") + " " + t("pages.Video")}</h2>
            </div>
          </Col>
          <Col xl="12">
            {editedVideo && (
              <Form
                className="needs-validation"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <VideoForm validation={validation} />

                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <FormButtonHandler btnTxt="Edit" loading={loading} />
                  <Button
                    className="btn-lg"
                    onClick={() => {
                      setShow(false)
                    }}
                  >
                    {t("btns.Cancel")}
                  </Button>
                </div>
              </Form>
            )}

            <ResultHandler
              loading={instenceLoading}
              error={error}
              success={success}
              successMsg={"Updated Successfully!"}
            />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default EditVideoModal

import { myAxios } from "./axios"

export function getTags(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      name: data ? data.txt : null,
    },
  }
  return myAxios.get("/tags", config)
}

export function getTopTags() {
  return myAxios.get("/tags/top10")
}

export function createTag(data) {
  return myAxios.post("/tags/create", data)
}

import SearchSelectBox from "components/SearchSelectBox"
import React, { useState, useEffect } from "react"
import Board, { moveCard } from "../../../../packages/react-kanban/index"
import FeaturedCard from "./FeaturedCard"

export default function FeaturedBoard({
  settingCategory,
  settingKey,
  type,
  response,
  api,
  apiMeta,
  label,
  board,
  setBoard,
}) {
  const category = settingCategory ? settingCategory : "home"
  useEffect(() => {
    // featch saved data
    if (response.data) {
      setBoard({
        columns: [
          {
            ...board.columns[0],
            cards: (response.data.data[category] && response.data.data[category][settingKey])
              ? response.data.data[category][settingKey].map(c => {
                  return { ...c, id: c.value }
                })
              : [],
          },
        ],
      })
    }
  }, [response])

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(board, source, destination)
    setBoard(updatedBoard)
  }

  function handleAddCard(option) {
    if (!board.columns[0].cards.map(c => c.value).includes(option[0].value)) {
      setBoard({
        columns: [
          {
            ...board.columns[0],
            cards: [
              ...board.columns[0].cards,
              { ...option[0], layout: "grid", id: option[0].value },
            ],
          },
        ],
      })
    }
  }

  function handleRemoveCard(id) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.filter(cat => {
            return cat.value != id
          }),
        },
      ],
    })
  }

  // for category

  function handleChangeCategoryLayout(id, value) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(cat => {
            if (cat.value === id) {
              return { ...cat, layout: value }
            } else {
              return cat
            }
          }),
        },
      ],
    })
  }

  function handleChangeCategoryPostsNum(id, value) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(cat => {
            if (cat.value === id) {
              return { ...cat, postsNum: value }
            } else {
              return cat
            }
          }),
        },
      ],
    })
  }

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <SearchSelectBox
          value={null}
          setValue={option => {
            handleAddCard(option)
          }}
          error={false}
          handleFocus={() => {}}
          handleBlur={() => {}}
          isMulti={true}
          api={api}
          apiMeta={apiMeta}
          type={type}
          label={label}
          name={type} // for formik
        />
      </div>

      <Board
        onCardDragEnd={handleCardMove}
        renderCard={content => (
          <FeaturedCard
            handleRemoveCard={handleRemoveCard}
            handleChangeCategoryLayout={handleChangeCategoryLayout}
            handleChangeCategoryPostsNum={handleChangeCategoryPostsNum}
            content={content}
            homeCategories={settingKey == "categories" ? true : false}
          />
        )}
        disableColumnDrag
      >
        {board}
      </Board>
    </>
  )
}

import React, { useState } from "react"
import { Button, Col, Modal, ModalBody, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import AuthCode from 'react-auth-code-input';
import "../../AuthCode.css"
import { disable2FA } from "api/auth";

const DisableQRModal = props => {

  const { t } = useTranslation()

  const [code, setCode] = useState('')

  function handleTypeCode(value) {
    setCode(value)
  }

  const [loading, setLoading] = useState(false)

  function handleDisable() {
    setLoading(true)
    disable2FA({ auth_code: code }).then((res) => {
      props.onCloseClick()
      setLoading(false)
      props.setEditedAdmin({ ...props.editedAdmin, twoFaEnabled: false })
    }).catch((err) => {
      setLoading(false)
    })
  }

  return (
    <Modal isOpen={props.show} toggle={props.onCloseClick} centered={true}>
      <ModalBody className="py-5 px-5">
        <Row>
          <Col md={12} style={{ textAlign: "center" }}>
            <h4>{"modals.Please Enter The Code"}</h4>
            <AuthCode containerClassName="d-flex codeRow" inputClassName="codeInput form-control" autoFocus={true} onChange={handleTypeCode} allowedCharacters='numeric' />
          </Col>
          <Col md={12}>
            <div className="text-center mt-3">
              {!loading &&
                <Button color="success" className="btn-lg" onClick={handleDisable}>
                  {"btns.Confirm"}
                </Button>
              }
              {loading &&
                <Button className="btn-lg" type="button">
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  {t("btns.Loading")}
                </Button>
              }
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={props.onCloseClick}
              >
                {t("btns.Cancel")}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default DisableQRModal

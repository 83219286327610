import React from "react"
//import components
import Breadcrumbs from "components/Common/Breadcrumb"
import { useTranslation } from "react-i18next"
import TrashTable from "./TrashTable"
export default function TrashList() {
  const { t } = useTranslation()
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.Trash")} />
        <TrashTable />
      </div>
    </div>
  )
}

import { myAxios } from "./axios"

/////////////////// FOLDERS

export function getFolder(payload, { page, perPage }) {
  const params = {
    params: {
      name: payload.searchTxt,
      page,
      perPage: 50,
    },
  }

  if (payload.id) {
    return myAxios.get("/media-manager/folders/" + payload.id, params)
  } else {
    return myAxios.get("/media-manager/folders", params)
  }
}

export function addFolder(data) {
  return myAxios.post("/media-manager/folders/create-folder", data)
}

export function editFolder(id, data) {
  return myAxios.post("/media-manager/folders/" + id + "/update", data)
}

export function deleteFolder(id) {
  return myAxios.post("/media-manager/folders/" + id + "/delete")
}

///////////////// FILES

export function uploadFile(data) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("token"),
    },
  }

  return myAxios.post("/media-manager/files/upload-file", data, config)
}

export function deleteFile(id) {
  return myAxios.post("/media-manager/files/" + id + "/delete")
}

export function editFile(id, data) {
  return myAxios.post("/media-manager/files/" + id + "/update", data)
}

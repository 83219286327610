import placeholderImg from "../../../assets/images/post_placeholder.jpg"
import React, {useState, useEffect} from "react"
import { getVideoThumbnail } from "api/videos"
import { useResponse } from "hooks/useResponse"
import CustomImage from "components/CustomImage"

export default function ImageColumn({id}){

    const [img, setImg] = useState(null)
    const {loading , success, error, setData} = useResponse(getVideoThumbnail, (res) => {
        setImg(res.data?.base_link)
    })

    useEffect(() => {
        setData({type: "add", data: id})
    }, [id])

    return(
        <CustomImage
            src={img ? img : placeholderImg}
            alt=""
            className="d-block rounded"
            style={{
            width: "40px",
            height: "40px",
            margin: "0px",
            }}
        />
    )
}
import CustomImage from "components/CustomImage"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Tooltip } from "reactstrap"

export default function AuthorTooltip(props) {
  const [open, setOpen] = useState(false)

  return (
    <Link to={"/get-admin/" + props.author.id}>
      {props.author.profile_pic_url ? (
        <CustomImage
          id={"target" + props.postId + props.author.id}
          src={props.author.profile_pic_url}
          className="rounded-circle avatar-xs"
          alt=""
        />
      ) : (
        <div
          className="avatar-xs"
          id={"target" + props.postId + props.author.id}
        >
          <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
            {props.author.username.charAt(0)}
          </span>
        </div>
      )}
      <Tooltip
        placement={props.direction ? props.direction : "bottom"}
        isOpen={open}
        target={"target" + props.postId + props.author.id}
        toggle={() => {
          setOpen(!open)
        }}
      >
        {props.author.username}
      </Tooltip>
    </Link>
  )
}

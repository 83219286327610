import React, { useEffect, useState } from "react"
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Tooltip,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { PERMISSIONS } from "api/permissions"
import { Link, useHistory } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"
import { useResponse } from "hooks/useResponse"
import { movePost } from "api/posts"
import AuthorTooltip from "../board/AuthorTooltip"
import { map } from "lodash"
import ChooseAuthorsModal from "../board/ChooseAuthorsModal"
import { Td } from "react-super-responsive-table"

export default function PostAction({
  row,
  handleDelete,
  reloadPosts,
  authors,
  disableMove,
  setMoveSuccess,
}) {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false) // more btn
  const navigate = useHistory()

  // move
  const [moveOpen, setMoveOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [stageId, setStageId] = useState(null)

  function onCloseClick() {
    setShow(false)
  }

  function handleOpenMove(id) {
    setShow(true)
    setStageId(id)
    setMoveSuccess(false)
  }

  const { success, error, loading, setData } = useResponse(movePost, res => {
    reloadPosts()
    setMoveSuccess(true)
    setTimeout(() => {
      setMoveSuccess(false)
    }, 1200)
  })

  function onMoveClick() {
    setData({
      type: "add",
      data: {
        postId: row.id,
        targetId: stageId,
      },
    })

    onCloseClick()
  }

  // authors modal
  const [authorsModalShow, setAuthorsModalShow] = useState(false)

  function handleCloseModal() {
    setAuthorsModalShow(false)
  }

  // categories tooltip

  const [categoriesTool, setcategoriesTool] = useState(false)

  return (
    <>
      <Td>
        <div className="tdBefore">{t("pages.Categories")}</div>

        {row.Categories.length > 0 && (
          <>
            <span className="badge badge-pill font-size-11 badge-soft-warning">
              {row.Categories[0].name}
            </span>
            {row.Categories.length > 1 && (
              <>
                <span
                  id={"target-post-" + row.id}
                  style={{ marginRight: "10px" }}
                  className="badge badge-pill font-size-11 badge-soft-warning"
                >
                  + {row.Categories.length - 1}
                </span>
                <Tooltip
                  placement="bottom"
                  isOpen={categoriesTool}
                  target={"target-post-" + row.id}
                  toggle={() => {
                    setcategoriesTool(!categoriesTool)
                  }}
                >
                  {row.Categories.filter((c, i) => i != 0).map(c => {
                    return (
                      <p key={c.id} style={{ marginBottom: "5px" }}>
                        {c.name}
                      </p>
                    )
                  })}
                </Tooltip>
              </>
            )}
          </>
        )}
        {row.Categories.length === 0 && <>{t("forms.Not Found")}</>}
      </Td>

      <Td>
        <div className="tdBefore">{t("forms.Authors")}</div>

        <div className="avatar-group">
          {map(authors, auther => (
            <div className="avatar-group-item" key={auther.id}>
              <div to={"/get-admin/" + auther.id} className="d-inline-block">
                <AuthorTooltip postId={row.id} author={auther} />
              </div>
            </div>
          ))}

          <div
            className="avatar-group-item"
            onClick={() => {
              setAuthorsModalShow(true)
            }}
            key={0}
          >
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                +
              </span>
            </div>
          </div>
        </div>
      </Td>

      <Td>
        <div className="tdBefore">{t("tables.Action")}</div>

        <Dropdown direction="left" isOpen={open} toggle={() => setOpen(!open)}>
          <DropdownToggle>
            <i className="mdi mdi-dots-horizontal"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <a
                href={
                  process.env.REACT_APP_FRONT_URL +
                  "/blog/" +
                  row.id +
                  "/" +
                  row.slug
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="bx bx-edit font-size-16 align-middle me-1" />
                {t("btns.Live View")}
              </a>
            </DropdownItem>
            {row.canShow && (
              <DropdownItem
                onClick={() => {
                  window.open("/view-post/" + row.id, "_blank")
                }}
              >
                <i className="bx bx-edit font-size-16 align-middle me-1" />

                {t("btns.View")}
              </DropdownItem>
            )}
            <DropdownItem
              onClick={() => {
                navigate.push("/post-history/" + row.id)
              }}
            >
              <i className="bx bx-calendar font-size-16 align-middle me-1" />

              {t("pages.Post History")}
            </DropdownItem>
            {row.canEdit && (
              <DropdownItem
                onClick={() => {
                  window.open("/edit-post/" + row.id, "_blank")
                }}
              >
                <i className="bx bx-edit font-size-16 align-middle me-1" />

                {t("btns.Edit")}
              </DropdownItem>
            )}
            {row.canEdit && (
              <DropdownItem
                onClick={() => {
                  handleDelete(row.id)
                }}
              >
                <i className="bx bx-trash font-size-16 align-middle me-1" />
                {t("btns.Delete")}
              </DropdownItem>
            )}

            {(localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.POSTS_VERSIONS_ALL) ||
              (localStorage
                .getItem("permissions")
                .includes(PERMISSIONS.POSTS_VERSIONS_MINE) &&
                row.isMine)) && (
              <DropdownItem
                onClick={() => {
                  navigate.push("post-versions/" + row.id)
                }}
              >
                <i className="bx bx-time font-size-16 align-middle me-1" />

                {t("btns.Versions")}
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </Td>

      {!disableMove && (
        <Td>
          <div className="tdBefore">{t("btns.Back")}</div>
          {!loading && (
            <>
              {row.Stage &&
                JSON.parse(localStorage.getItem("fromStages"))
                  .map(s => s.id)
                  .includes(row.Stage.id) &&
                JSON.parse(localStorage.getItem("toStages")).filter(
                  s => s.order < row.Stage.order
                ).length > 1 && (
                  <Dropdown
                    isOpen={moveOpen}
                    toggle={() => setMoveOpen(!moveOpen)}
                  >
                    <DropdownToggle
                      type="button"
                      tag="button"
                      className="btn btn-primary"
                    >
                      <i className="mdi mdi-chevron-down" />
                      {t("btns.Back")}
                    </DropdownToggle>
                    <DropdownMenu>
                      {JSON.parse(localStorage.getItem("toStages"))
                        .filter(s => {
                          return s.order < row.Stage.order
                        })
                        .map(s => {
                          return (
                            <DropdownItem
                              key={s.id}
                              onClick={() => {
                                handleOpenMove(s.id)
                              }}
                            >
                              {s.name}
                            </DropdownItem>
                          )
                        })}
                    </DropdownMenu>
                  </Dropdown>
                )}

              {row.Stage &&
                JSON.parse(localStorage.getItem("fromStages"))
                  .map(s => s.id)
                  .includes(row.Stage.id) &&
                JSON.parse(localStorage.getItem("toStages")).filter(
                  s => s.order < row.Stage.order
                ).length === 1 && (
                  <Button
                    color="primary"
                    onClick={() => {
                      handleOpenMove(
                        JSON.parse(localStorage.getItem("toStages")).filter(
                          s => s.order < row.Stage.order
                        )[0].id
                      )
                    }}
                  >
                    {
                      JSON.parse(localStorage.getItem("toStages")).filter(
                        s => s.order < row.Stage.order
                      )[0].name
                    }
                  </Button>
                )}

              {(!row.Stage ||
                !JSON.parse(localStorage.getItem("fromStages"))
                  .map(s => s.id)
                  .includes(row.Stage.id) ||
                JSON.parse(localStorage.getItem("toStages")).filter(s => {
                  return s.order < row.Stage.order
                }).length === 0) && <>{t("btns.You Can't Move This Post Back")}</>}
            </>
          )}

          {loading && <div>{t("forms.Wait")}</div>}
          {error && (
            <>
              <div style={{ display: "block", color: "red", width: "100%" }}>
                {error}
              </div>
            </>
          )}
        </Td>
      )}

      {authorsModalShow && (
        <ChooseAuthorsModal
          authors={authors}
          postId={row.id}
          show={authorsModalShow}
          onCloseClick={handleCloseModal}
          reloadPosts={reloadPosts}
          urgent={false}
        />
      )}

      <DeleteModal
        show={show}
        onDeleteClick={onMoveClick}
        onCloseClick={onCloseClick}
      />
    </>
  )
}

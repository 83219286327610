import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { map } from "lodash"
import { useTranslation } from "react-i18next"
import { Tooltip } from "reactstrap"

//Import Images
import ChooseAuthorsModal from "./ChooseAuthorsModal"
import AuthorTooltip from "./AuthorTooltip"
import classNames from "classnames"

const PostCard = props => {
  const { t } = useTranslation()

  const data = props.content

  // authors modal
  const [show, setShow] = useState(false)

  function handleCloseModal() {
    setShow(false)
  }

  const [open, setOpen] = useState(false) // date tooltip

  return (
    <React.Fragment>
      <Card className="task-box">
        <CardBody className="borad-width">
          <div className="float-end ms-2">
            {data.publishedAt && (
              <>
                <span
                  id={"post" + data.id}
                  className={classNames(
                    "badge rounded-pill badge-soft-warning font-size-12"
                  )}
                >
                  {t("pages.Scheduleed")}
                </span>
                <Tooltip
                  placement="bottom"
                  isOpen={open}
                  target={"post" + data.id}
                  toggle={() => {
                    setOpen(!open)
                  }}
                >
                  {data.publishedAt}
                </Tooltip>
              </>
            )}
          </div>
          <div>
            <h5 className="font-size-15">
              <Link to="#" className="text-dark" style={{ display: "flex" }}>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data.title}
                </span>
                <UncontrolledDropdown direction="left">
                  <DropdownToggle
                    color="white"
                    className="btn btn-link text-muted mt-n2"
                  >
                    <i className="mdi mdi-dots-horizontal"></i>
                  </DropdownToggle>

                  <DropdownMenu>
                    <Link
                      to={"/edit-post/" + data.id}
                      className="dropdown-item"
                    >
                      {t("btns.Edit")}
                    </Link>
                    <Link
                      className="dropdown-item"
                      to={"/post-history/" + data.id}
                    >
                      {t("pages.Post History")}
                    </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Link>
            </h5>
            <p
              style={{
                marginBottom: "0px",
                maxWidth: "300px",
                height: "3em",
                overflow: "hidden",
                textOverflow: "-o-ellipsis-lastline",
              }}
              className="text-muted"
            >
              {data.summary}
            </p>
          </div>

          <div className="avatar-group float-start">
            {map(data.Authors, auther => (
              <div className="avatar-group-item" key={auther.id}>
                <div className="d-inline-block">
                  <AuthorTooltip postId={data.id} author={auther} />
                </div>
              </div>
            ))}

            <div
              className="avatar-group-item"
              onClick={() => {
                setShow(true)
              }}
              key={0}
            >
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                  +
                </span>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {show && (
        <ChooseAuthorsModal
          authors={data.Authors}
          postId={data.id}
          show={show}
          onCloseClick={handleCloseModal}
          reloadPosts={() => {
            props.reloadBoard()
          }}
        />
      )}
    </React.Fragment>
  )
}

export default PostCard

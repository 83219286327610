import React from "react"
import { getSettings, updateSettings } from "api/settings"
import { usePagination } from "hooks/usePagination"
import FormButtonHandler from "components/FormButtonHandler"
import ResultHandler from "components/ResultHandler"
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { useFormik } from "formik"
import useCustomValidation from "../validationSchema"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import ChooseImage from "components/ChooseImage"

export default function FooterSettings({ lang }) {
  const { t } = useTranslation()
  const {footerSettingsSchema} = useCustomValidation()
  const settingCategory = "footer" + (lang === "en" ? "_" + lang : "")
  const { response, loading, error } = usePagination(
    getSettings,
    settingCategory
  )
  const {
    setData: setUpdateSettings,
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useResponse(updateSettings)

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      text: response.data ? response.data.data[settingCategory].text : "",
      copyright: response.data
        ? response.data.data[settingCategory].copyright
        : "",
      facebookUrl: response.data
        ? response.data.data[settingCategory].social?.facebook
        : "",
      youtubeUrl: response.data
        ? response.data.data[settingCategory].social?.youtube
        : "",
      twitterUrl: response.data
        ? response.data.data[settingCategory].social?.twitter
        : "",
      instagramUrl: response.data
        ? response.data.data[settingCategory].social?.instagram
        : "",
      logo: response.data ? response.data.data[settingCategory].logo : "",
    },
    validationSchema: footerSettingsSchema,
    onSubmit: values => {
      setUpdateSettings({
        type: "edit",
        id: settingCategory,
        data: { ...values, social: { facebook: values.facebookUrl, instagram: values.instagramUrl, twitter: values.twitterUrl, youtube: values.youtubeUrl } },
      })
    },
  })

  return (
    <>
      <Form
        className="needs-validation"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <ResultHandler loading={loading} />
        {response.data && (
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>{t("forms.Text")}</Label>
                <Input
                  type="text"
                  name="text"
                  value={validation.values.text}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>{t("forms.Copyright")}</Label>
                <Input
                  type="text"
                  name="copyright"
                  value={validation.values.copyright}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <ChooseImage
                image={validation.values.logo}
                handleChooseImage={img => {
                  validation.setFieldValue("logo", img)
                }}
              />
            </Col>
            <Col md={6}>
              <FormGroup className="mb-2">
                <Label>Facebook</Label>
                <Input
                  type="text"
                  name="facebookUrl"
                  value={validation.values.facebookUrl}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </FormGroup>
              <FormGroup className="mb-2">
                <Label>Instagram</Label>
                <Input
                  type="text"
                  name="instagramUrl"
                  value={validation.values.instagramUrl}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </FormGroup>
              <FormGroup className="mb-2">
                <Label>Twitter</Label>
                <Input
                  type="text"
                  name="twitterUrl"
                  value={validation.values.twitterUrl}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </FormGroup>
              <FormGroup className="mb-2">
                <Label>Youtube</Label>
                <Input
                  type="text"
                  name="youtubeUrl"
                  value={validation.values.youtubeUrl}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {!loading && (
          <FormButtonHandler btnTxt="Save" loading={updateLoading} />
        )}
      </Form>

      <ResultHandler
        error={updateError}
        success={updateSuccess}
        successMsg={"Updated Successfully!"}
      />
    </>
  )
}

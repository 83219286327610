import React, { useEffect, lazy } from "react"

import { Switch, BrowserRouter as Router, Route } from "react-router-dom"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss

import Pages404 from "components/PageNotFound"
import "./crc.css"
import "./assets/scss/theme-rtl.scss"
// import './assets/scss/theme.scss'

////

import { RecoilRoot, useRecoilState } from "recoil"

import authState from "./atom/auth"
import i18next from "i18next"
import moment from "moment"
const App = () => {
  const [auth, setAuth] = useRecoilState(authState)

  useEffect(() => {
    if (auth) {
      i18next.changeLanguage(JSON.parse(localStorage.getItem("user")).language)
      document.documentElement.setAttribute(
        "lang",
        JSON.parse(localStorage.getItem("user")).language
      )
      if( JSON.parse(localStorage.getItem("user")).language === 'ar'){
        require("moment/locale/ar")
      }

    } else {
      //i18next.changeLanguage("ar")
      //document.documentElement.setAttribute("lang", "ar")
    }
  }, [auth])

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              token={route.permission ? route.permission : null}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              token={route.permission ? route.permission : null}
              isAuthProtected={true}
              exact
            />
          ))}

          <Route path="*" exact={true} component={Pages404} />
        </Switch>
      </Router>
    </React.Fragment>
  )
}

export default App

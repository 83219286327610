import { movePost } from "api/posts"
import { getStagesDropdown } from "api/stages"
import SearchSelectBox from "components/SearchSelectBox"
import { useResponse } from "hooks/useResponse"
import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { usePagination } from "hooks/usePagination"

export default function StageSelect(props) {
  const { t } = useTranslation()

  const { success, error, loading, setData } = useResponse(movePost)

  function movePostFunc(id) {
    setData({
      type: "add",
      data: { postId: props.postId, targetId: id },
    })
  }

  useEffect(() => {
    if (success) {
      props.setSuccess()
      props.reload()
    }
  }, [success])

  const [open, setOpen] = useState(false) // move dropdown

  return (
    <>
      {!loading && (
        <>
          {JSON.parse(localStorage.getItem("toStages")).length > 1 && (
            <Dropdown
              direction="left"
              isOpen={open}
              toggle={() => setOpen(!open)}
            >
              <DropdownToggle
                type="button"
                tag="button"
                className="btn btn-primary"
              >
                {t("btns.Add")} <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                {JSON.parse(localStorage.getItem("toStages")).map(s => {
                  return (
                    <DropdownItem
                      key={s.id}
                      onClick={() => {
                        movePostFunc(s.id)
                      }}
                    >
                      {s.name}
                    </DropdownItem>
                  )
                })}
              </DropdownMenu>
            </Dropdown>
          )}

          {JSON.parse(localStorage.getItem("toStages")).length === 1 && (
            <Button
              style={{ display: "block" }}
              onClick={() => {
                movePostFunc(JSON.parse(localStorage.getItem("toStages"))[0].id)
              }}
            >
              {JSON.parse(localStorage.getItem("toStages"))[0].name}
            </Button>
          )}
        </>
      )}
      {loading && <div>{t("forms.Wait")}</div>}
      {error && (
        <>
          <div style={{ display: "block", color: "red", width: "100%" }}>
            {error}
          </div>
        </>
      )}
    </>
  )
}

import React from "react"
import { CardBody, Card, Modal, ModalBody, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import CustomImage from "components/CustomImage"

const ShowImgModal = ({ show, onCloseClick, imgUrl }) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-5 px-5">
        <i
          onClick={onCloseClick}
          style={{
            position: "absolute",
            top: "20px",
            right: "40px",
            fontSize: "30px",
            cursor: "pointer",
          }}
          className="mdi mdi-close"
        ></i>
        <Card>
          <CardBody>
            <CustomImage src={imgUrl} style={{ width: "100%" }} />
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  )
}

export default ShowImgModal

import moment from "moment"
import React, { useEffect, useState } from "react"
import { Carousel, CarouselItem, CarouselControl } from "reactstrap"
import "./History.css"
import CustomImage from "components/CustomImage"

export default function HorizontalTimeline(props) {
  const [items, setItems] = useState([])

  useEffect(() => {
    let arr = []
    let total = []

    props.response.forEach((s, ind) => {
      arr.push(s)
      if (arr.length === 3 || ind === props.response.length - 1) {
        total.push(arr)
        arr = []
      }
    })

    setItems(total)
  }, [])

  const [activeIndex, setActiveIndex] = useState(0)

  function next() {
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  function previous() {
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  return (
    <div className="hori-timeline postHistory ">
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        {items.map((item, index) => {
          return (
            <CarouselItem key={index}>
              <div
                className="owl-carousel owl-theme  navs-carousel events"
                id="timeline-carousel"
                style={{ whiteSpace: "nowrap" }}
              >
                {item.map(s => {
                  return (
                    <div
                      key={s.id}
                      className="item event-list"
                      style={{
                        display: "inline-block",
                        width: "calc(100% / 3)",
                      }}
                    >
                      <div>
                        <div className="event-date">
                          <div className="text-primary mb-1">
                            {moment(s.createdAt).format("h:mm YYYY-MM-DD")}
                          </div>
                          <h5 className="mb-4">
                            {s.new_stage ? s.new_stage.name : "مسودة"}
                          </h5>
                        </div>
                        <div className="event-down-icon">
                          {s.Admin.profile_pic_url && (
                            <CustomImage
                              src={s.Admin.profile_pic_url}
                              style={{ margin: "auto", position: "relative" }}
                              className="rounded-circle avatar-xs"
                              alt=""
                            />
                          )}
                          {!s.Admin.profile_pic_url && (
                            <div
                              className="avatar-xs"
                              style={{ margin: "auto", position: "relative" }}
                            >
                              <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                                {s.Admin.username.charAt(0)}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="mt-3 px-3">
                          <p
                            className="text-muted"
                            style={{ whiteSpace: "normal" }}
                          >
                            {s.Admin.username}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </CarouselItem>
          )
        })}
        {activeIndex > 0 && (
          <CarouselControl
            direction="next"
            directionText=""
            onClickHandler={previous}
          />
        )}
        {activeIndex < items.length - 1 && (
          <CarouselControl
            direction="prev"
            directionText=""
            onClickHandler={next}
          />
        )}
      </Carousel>
    </div>
  )
}

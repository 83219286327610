import { removeAuthors, removeUrgentAuthors } from "api/posts"
import { useResponse } from "hooks/useResponse"
import { PERMISSIONS } from "api/permissions"
import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, CardText, Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import CustomImage from "components/CustomImage"

export default function Author({
  postId,
  author,
  handleRemove,
  reloadData,
  urgent,
}) {
  const { t } = useTranslation()

  const {
    setData: deleteAuthor,
    success: removeSuccessfully,
    loading: removeLoading,
  } = useResponse(urgent ? removeUrgentAuthors : removeAuthors, () => {
    reloadData({})
  })

  function removeAuthor(id) {
    const data = {
      post_id: postId,
      author_id: id,
    }
    deleteAuthor({ type: "add", data })
    if (handleRemove) {
      handleRemove()
    }
  }

  return (
    <Card>
      <CardBody
        className="d-flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="d-flex">
          {author.profile_pic_url ? (
            <CustomImage
              src={author.profile_pic_url}
              className="rounded avatar-sm"
              alt=""
            />
          ) : (
            <div className="avatar-sm">
              <span className="avatar-title rounded bg-primary text-white font-size-16">
                {author.username.charAt(0)}
              </span>
            </div>
          )}
          <div style={{ marginRight: "15px" }}>
            <CardTitle className="mt-0 mb-0">{author.username}</CardTitle>
            <CardText>{author.email}</CardText>
          </div>
        </div>

        {localStorage
          .getItem("permissions")
          .includes(PERMISSIONS.POST_REMOVE_AUTHOR) && (
          <>
            {removeLoading && (
              <Button type="button">
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                {t("btns.Loading")}
              </Button>
            )}
            {!removeLoading && (
              <Button
                color="danger"
                onClick={() => {
                  removeAuthor(author.id)
                }}
                type="button"
              >
                {t("btns.Remove")}
              </Button>
            )}
          </>
        )}
      </CardBody>
    </Card>
  )
}

import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
import { useFormik } from "formik"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import FormButtonHandler from "components/FormButtonHandler"
import { editAdmin, getAdmin } from "api/admins"
import { getRolesDropdown } from "api/roles"
import { getCategoriesDropdown } from "api/categories"
import SearchSelectBox from "components/SearchSelectBox"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "hooks/useGetInstence"
import useCustomValidation from "../validationSchema"

const EditAdmin = () => {
  const { t } = useTranslation()
  const { editAdminSchema } = useCustomValidation()

  const [editedAdmin, setEditedAdmin] = useState(null)

  const { id: adminId } = useParams()

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getAdmin,
    adminId,
    res => {
      setEditedAdmin({
        ...res.data.data.admin,
        Role: res.data.data.admin.Role
          ? {
              value: res.data.data.admin.Role.id,
              label: res.data.data.admin.Role.name,
            }
          : null,
        Categories: res.data.data.admin.Categories
          ? res.data.data.admin.Categories.map(a => {
              return { value: a.id, label: a.name }
            })
          : null,
      })
    }
  )

  //posting

  const { setData, loading, error, success } = useResponse(editAdmin)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      username: editedAdmin ? editedAdmin.username : "",
      email: editedAdmin ? editedAdmin.email : "",
      categories: editedAdmin ? editedAdmin.Categories : null,
      role: editedAdmin ? editedAdmin.Role : null,
      password: null,
      language: editedAdmin ? editedAdmin.language : "ar",
    },
    validationSchema: editAdminSchema,
    onSubmit: values => {
      let data = {
        ...values,
        role_id: values.role.value,
        Categories: values.categories
          ? values.categories.map(c => c.value)
          : null,
      }
      setData({ id: adminId, data, type: "edit" })
    },
  })

  let navigate = useHistory()

  useEffect(() => {
    if (success) {
      navigate.push("/admins")
    }
  }, [success])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title=""
            breadcrumbItem={t("btns.Edit") + " " + t("pages.Admin")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <ResultHandler loading={instenceLoading} />
                  {editedAdmin && (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              {t("forms.Username")}
                            </Label>
                            <Input
                              name="username"
                              placeholder={t("forms.Username")}
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username &&
                                validation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.username &&
                            validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              {t("forms.Email")}
                            </Label>
                            <Input
                              disabled
                              name="email"
                              placeholder={t("forms.Email")}
                              type="email"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                              {t("forms.Password")}
                            </Label>
                            <Input
                              name="password"
                              placeholder={t("forms.Password")}
                              type="password"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                              {t("pages.Categories")}
                            </Label>
                            <SearchSelectBox
                              value={validation.values.categories || null}
                              setValue={option => {
                                validation.setFieldValue("categories", option)
                              }}
                              error={
                                validation.touched.categories &&
                                validation.errors.categories
                              }
                              handleFocus={() => {
                                validation.setFieldTouched("categories")
                              }}
                              handleBlur={validation.handleBlur}
                              isMulti={true}
                              api={getCategoriesDropdown}
                              apiMeta={{ isInternal: true }}
                              type="categories"
                              label="name"
                              name="categories" // for formik
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                              {t("pages.Role")}
                            </Label>
                            <SearchSelectBox
                              name="role"
                              error={
                                validation.touched.role &&
                                validation.errors.role
                              }
                              isMulti={false}
                              value={validation.values.role || null}
                              type="roles"
                              label="name"
                              api={getRolesDropdown}
                              handleFocus={() => {
                                validation.setFieldTouched("role")
                              }}
                              handleBlur={validation.handleBlur}
                              setValue={option => {
                                validation.setFieldValue("role", option)
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <FormGroup className="mb-3">
                          <Label>{t("forms.Language")}</Label>

                          <select
                            name="language"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.language || ""}
                          >
                            <option value="ar">العربية</option>
                            <option value="en">English</option>
                          </select>
                          
                        </FormGroup>
                      </Row>

                      <FormButtonHandler btnTxt="Edit" loading={loading} />

                      <ResultHandler
                        error={error}
                        success={success}
                        successMsg={"Updated Successfully!"}
                      />
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditAdmin

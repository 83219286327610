import React, { useEffect, useRef, useState } from "react"

import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Label,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap"
import { useFormik } from "formik"
import "./Editor.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Prompt, useHistory, useParams } from "react-router-dom"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getPost, editPost, getPostsDropdown } from "api/posts"
import { getCategoriesDropdown } from "api/categories"
import { extractKeyWords } from "api/posts"
import ResultHandler from "components/ResultHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import useCustomValidation from "../../validationSchema"
import { useGetInstence } from "hooks/useGetInstence"

import { useRecoilState } from "recoil"
import imgUpload from "atom/imgUpload"
import usePost from "hooks/usePost"
import FormSide from "./add-post/FormSide"
import PostForm from "./add-post/PostForm"

export default function EditPost() {
  const { t } = useTranslation()
  const navigate = useHistory()
  const {postSchema} = useCustomValidation()
  // posting result states

  const { setData, setError, loading, error, success } = useResponse(
    editPost,
    () => {
      navigate.push("/posts")
    }
  )

  // getting post data

  const params = useParams()
  const [initialContents, setInitialContents] = useState({})
  const [initialData, setInitialData] = useState({})

  const [initialStage, setInitialStage] = useState(null)
  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getPost,
    params.id,
    response => {
      setInitialStage(response.data.data.post.Stage)
      setInitialContents({
        columns: [
          {
            id: 1,
            title: "",
            cards: response.data.data.post.postContent.map(c => {
              if (c.type == "slider") {
                return { ...c, meta: c.meta.imgs, status: "default" }
              } else {
                return c
              }
            }),
          },
        ],
      })
      setInitialData(p => ({
        ...response.data.data.post,
        //contents: response.data.data.post.postContent,
        tags: response.data.data.post.Tags.map(c => {
          return { label: c.name, value: c.id }
        }),
        related_posts: response.data.data.post.related_posts.map(c => {
          return { label: c.title, value: c.id }
        }),
        categories: response.data.data.post.Categories.map(c => {
          return { label: c.name, value: c.id }
        }),
        keywords: response.data.data.post.keywords?.split(",").map(k => {
          return { label: k, value: k }
        }),

        kickers: response.data.data.post.Kickers.map(c => {
          return { label: c.name, value: c.id }
        }),
      }))
    }
  )

  // posting

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...initialData,
      stageId: null, // not used in form
      stage_id: initialData.stageId, // used in form
      writer: initialData
        ? initialData.writer
          ? initialData.writer?.username
            ? {
                label: initialData.writer.username,
                value: initialData.writer.id,
              }
            : { label: initialData.writer, value: initialData.writer }
          : null
        : null,
      categories: initialData
        ? initialData.categories?.length > 0
          ? initialData.categories[0]
          : null
        : null,
      keywords: initialData
        ? initialData.keywords?.length > 0
          ? initialData.keywords
          : []
        : [],

      kickers:
        initialData && initialData.kickers ? initialData.kickers[0] : null,
      tags: initialData ? initialData.tags : [],
      related_posts: initialData ? initialData.related_posts : [],
      contents: initialContents,
    },
    validationSchema: postSchema,
    onSubmit: values => {
      setData({
        type: "edit",
        id: params.id,
        data: {
          ...values,
          stageId: values.stage_id,
          contents: null, // remove card ref error
          postContent: board.columns[0].cards.map((c, i) => {
            return {
              ...c,
              order: i,
              meta: c.type === "slider" ? { imgs: c.meta } : c.meta,
              cardRef: null,
            }
          }),
          raw_content: generateRawContent(),
          writer: isNaN(values.writer.value)
            ? values.writer.value
            : parseInt(values.writer.value),
          related_posts: values.related_posts.map(cat => cat.value),
          Categories: [values.categories?.value],
          keywords: values.keywords.map(cat => cat.value).join(","),
          Kickers: [values.kickers?.value], //for now we only allow one kicker per post
          Tags: values.tags.map(cat => cat.value),
        },
      })
    },
  })

  // for uploading img modal

  const [name, setName] = useRecoilState(imgUpload)

  useEffect(() => {
    setName(validation.values.title)
  }, [validation.values])

  ////data

  const {
    contentsBoard: board,
    setBoard,
    handleCardMove,
    handleResetBoard,
    addContent,
    removeContent,
    onEditorStateChange,
    generateRawContent,
    setImage,
    setImageComment,
    setSliderImg,
    addSliderImg,
    removeSliderImg,
    setSliderImgComment,
  } = usePost(validation)

  // pusher

  const [counter, setCounter] = useState(10)
  const [lock, setLock] = useState("false")

  /*let c = 10
  useEffect(() => {
    const pusherClient = new Pusher("ef1b12954ee5a0340edd", {
      channelAuthorization: {
        endpoint: process.env.REACT_APP_AXIOS + "/pusher/auth",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      },
      cluster: "eu",
    })

    setPusherClient(pusherClient)
    const presenceChannel = pusherClient.subscribe(
      "presence-crc-lockPost" + params.id
    )
    presenceChannel.bind("pusher:subscription_succeeded", members => {

      if (members.count === 1) {
        setLock("false")
      } else {
        setLock("true")
        pusherClient.unsubscribe("presence-crc-lockPost" + params.id)
        const counting = setInterval(() => {
          if (c > 0) {
            setCounter(c)
          } else if (c === 0) {
            navigate.push("/posts")
            clearInterval(counting)
          }
          c -= 1
        }, 1000)
      }
    })

    return () => {
      pusherClient.unsubscribe("presence-crc-lockPost" + params.id)
      c = 0
    }
  }, [])*/

  /// edit text only

  const disabled = JSON.parse(localStorage.getItem("user")).Role.settings
    .editTextOnly

  // leave confirm

  window.onbeforeunload = function () {
    if (validation.dirty) {
      return false
    }
  }

  window.onunload = function () {
    if (validation.dirty) {
      return false
    }
  }
  // TODO : remove it when the new refactor is merged
  function getKeyWords() {
    if (generateRawContent().length > 0) {
      extractKeyWords({ text: generateRawContent() })
        .then(res => {
          validation.setFieldValue("keywords", [
            ...(validation.values.keywords ? validation.values.keywords : []),
            ...res.data
              .filter(
                obj =>
                  !validation.values.keywords
                    .map(k => k.value)
                    .includes(obj.json.matchedText)
              )
              .map(obj => {
                return {
                  value: obj.json.matchedText,
                  label: obj.json.matchedText,
                }
              }),
          ])
        })
        .catch(err => {})
    }
  }

  return (
    <React.Fragment>
      <Prompt
        when={validation.dirty}
        message="Are you sure you want to leave?"
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t("pages.Posts")}
            breadcrumbItem={t("btns.Edit") + " " + t("pages.Post")}
          />
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <ResultHandler loading={instenceLoading} />
            <ResultHandler
              error={error}
              success={success}
              successMsg={"Updated Successfully!"}
            />

            {lock === "false" && !instenceLoading && (
              <Row>
                <Col xl="8">
                  <PostForm
                    validation={validation}
                    disabled={disabled}
                    postId={params.id}
                  />
                </Col>
                <Col className="accordion" xl="4">
                  <FormSide
                    isEdit={true}
                    postData={initialData}
                    postId={params.id}
                    setPostData={id => {
                      validation.setFieldValue("stage_id", id)
                    }}
                    loading={loading}
                    disabled={disabled}
                    initialStage={initialStage}
                    validation={validation}
                  />
                </Col>
              </Row>
            )}

            {/*lock === "true" && (
              <Card>
                <CardBody style={{ textAlign: "center" }}>
                  <h5 style={{ marginTop: "20px" }}>
                    {t(
                      "messages.Another Admin Is Currently Editing This Post, Redirect Back To Posts In"
                    )}
                    {counter + " "}
                    ثوانى
                  </h5>
                  <img style={{ width: "60%" }} src={LockImg} />
                </CardBody>
              </Card>
            )*/}
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

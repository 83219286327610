import { myAxios } from "./axios"

export function getStages(data, config) {
  return myAxios.get("/stages")
}

export function getStagesDropdown(data, config) {
  return myAxios.get("/dropdown/stages")
}

export function getMyStages() {
  return myAxios.get("/stages/my-stages")
}
export function getStage(id) {
  return myAxios.get("/stages/" + id)
}

export function addStage(data) {
  return myAxios.post("/stages/create", data)
}

export function editStage(id, data) {
  return myAxios.post("/stages/" + id + "/update", data)
}

export function deleteStage(id) {
  return myAxios.post("/stages/" + id + "/delete")
}

export function updateOrders(data) {
  return myAxios.post("/stages/orders", data)
}

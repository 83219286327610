import React from "react"
import { Button, Col, Form, Input, Modal, ModalBody, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import { createTask } from "api/posts"
import { useFormik } from "formik"
import ResultHandler from "components/ResultHandler"
import useCustomValidation from "pages/dashboard/validationSchema"

export default function AddTaskModal({
  postId,
  parent_id,
  show,
  onCloseClick,
  reloadTasks,
}) {
  const {t} = useTranslation()

  const { taskSchema } = useCustomValidation()
  const { loading, error, success, setData } = useResponse(createTask, () => {
    onCloseClick()
    reloadTasks()
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      text: "",
    },
    validationSchema: taskSchema,
    onSubmit: values => {
      setData({
        type: "edit",
        id: postId,
        data: {
          text: values.text,
          parent_id,
        },
      })
    },
  })

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-5 px-5">
        <Form className="needs-validation">
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <h2>{t("modals.Add Task")}</h2>
              </div>
            </Col>
            <Col lg={12}>
              <label>{t("forms.Content")}</label>
              <Input
                name="text"
                type="textarea"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.text || ""}
                invalid={
                  validation.touched.text && validation.errors.text
                    ? true
                    : false
                }
                placeholder={t("forms.Content")}
                className="form-control"
                style={{ minHeight: "130px" }}
              />
            </Col>
            <Col>
              <ResultHandler error={error} />
              <div className="text-center mt-3">
                {!loading && <Button
                  className="btn btn-success btn-lg ms-2"
                  onClick={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  {t("btns.Add")}
                </Button>}
                {loading &&
                  <Button className="btn-lg" type="button">
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                    {t("btns.Loading")}
                  </Button>
                }
                <Button
                  type="button"
                  className="btn btn-danger btn-lg ms-2"
                  onClick={onCloseClick}
                >
                  {t("btns.Cancel")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

import ChooseImage from "components/ChooseImage"
import React, { useEffect, useState } from "react"
import { PRIORITIES } from "utils/constants"
import TasksList from "../../tasks/TasksList"
import {
  Label,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Collapse,
  FormFeedback,
  Input,
} from "reactstrap"
import AuthorsList from "../../authors/AuthorsList"
import ResultHandler from "components/ResultHandler"
import classnames from "classnames"
import { useTranslation } from "react-i18next"

import { getAdminsDropdown } from "api/admins"
import CreatableSelect from "react-select/creatable"
import { usePagination } from "hooks/usePagination"

export default function FormSide({
  isEdit,
  postData,
  setPostData,
  loading,
  disabled,
  initialStage,
  validation,
  postId,
}) {
  const { t } = useTranslation()

  // sidebar accordions

  const [accOpen, setAccOpen] = useState([
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    true,
  ])

  const [moveOpen, setMoveOpen] = useState(false)

  function handleAccordion(i) {
    setAccOpen(
      accOpen.map((a, inx) => {
        if (inx === i) {
          return !a
        } else {
          return a
        }
      })
    )
  }

  // tasks

  const [notDoneTasks, setNotDoneTasks] = useState(0)

  // admins dropdown

  const [adminsSearchTxt, setAdminsSearchTxt] = useState("")
  const { response: adminsResponse, setPayload: setAdminsPayload } =
    usePagination(getAdminsDropdown)

  useEffect(() => {
    setAdminsPayload({ txt: adminsSearchTxt })
  }, [adminsSearchTxt])

  return (
    <>
      <div
        className="accordion-item"
        style={{ marginBottom: "15px", backgroundColor: "white" }}
      >
        <h2
          className={
            "accordion-header " +
            classnames("accordion-button", "fw-medium", {
              collapsed: !accOpen[0],
            })
          }
          onClick={() => {
            handleAccordion(0)
          }}
        >
          {t("btns.Publish")}
        </h2>
        <Collapse isOpen={accOpen[0]} className="accordion-collapse">
          <div className="accordion-body">
            {!loading && (
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div className="form-check form-switch form-switch-md">
                  <input
                    type="checkbox"
                    onChange={() => {
                      validation.setFieldValue(
                        "publishedAt",
                        validation.values.publishedAt ? null : true
                      )
                    }}
                    disabled={disabled}
                    className="form-check-input"
                    checked={validation.values.publishedAt ? true : false}
                  />
                  <Label>{t("forms.publishType")}</Label>
                </div>
                <div className="form-check form-switch form-switch-md">
                  <input
                    name="special"
                    type="checkbox"
                    onChange={validation.handleChange}
                    disabled={disabled}
                    className="form-check-input"
                    checked={validation.values.special}
                  />

                  <Label>{t("forms.Special")}</Label>
                </div>
                <div className="form-check form-switch form-switch-md">
                  <input
                    name="is_pinned"
                    type="checkbox"
                    onChange={validation.handleChange}
                    disabled={disabled}
                    className="form-check-input"
                    checked={validation.values.is_pinned}
                  />

                  <Label>{t("forms.is_pinned")}</Label>
                </div>
              </div>
            )}
            {!loading && validation.values.publishedAt && (
              <>
                <Label>{t("forms.publishDate")}</Label>
                <input
                  onChange={validation.handleChange}
                  name="publishedAt"
                  value={
                    validation.values.publishedAt
                      ? validation.values.publishedAt.length > 0
                        ? validation.values.publishedAt.replace("Z", "")
                        : null
                      : null
                  }
                  className="form-control"
                  type="datetime-local"
                  disabled={disabled}
                />
              </>
            )}
            <div style={{ marginTop: "10px" }}>
              {!loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {isEdit && (
                    <>
                      {postData.Stage &&
                        JSON.parse(localStorage.getItem("fromStages"))
                          .map(s => s.id)
                          .includes(postData.Stage.id) &&
                        JSON.parse(localStorage.getItem("toStages")).filter(
                          s => s.order > postData.Stage.order
                        ).length > 1 && (
                          <Dropdown
                            isOpen={moveOpen}
                            toggle={() => setMoveOpen(!moveOpen)}
                          >
                            <DropdownToggle
                              type="button"
                              tag="button"
                              className="btn btn-primary"
                            >
                              <i className="mdi mdi-chevron-down" />
                              {initialStage
                                ? validation.values.stage_id === initialStage.id
                                  ? initialStage.name
                                  : JSON.parse(
                                      localStorage.getItem("toStages")
                                    ).filter(
                                      s => s.id === validation.values.stage_id
                                    )[0].name
                                : t("btns.Draft")}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  setPostData(initialStage?.id)
                                }}
                              >
                                {initialStage?.name}
                              </DropdownItem>
                              {JSON.parse(localStorage.getItem("toStages"))
                                .filter(s => {
                                  return s.order > postData.Stage.order
                                })
                                .map(s => {
                                  return (
                                    <DropdownItem
                                      key={s.id}
                                      onClick={() => {
                                        setPostData(s.id)
                                      }}
                                    >
                                      {s.name}
                                    </DropdownItem>
                                  )
                                })}
                            </DropdownMenu>
                          </Dropdown>
                        )}

                      {postData.Stage &&
                        JSON.parse(localStorage.getItem("fromStages"))
                          .map(s => s.id)
                          .includes(postData.Stage.id) &&
                        JSON.parse(localStorage.getItem("toStages")).filter(
                          s => s.order > postData.Stage.order
                        ).length === 1 && (
                          <Button
                            type="submit"
                            color="primary"
                            onClick={() => {
                              setPostData(
                                JSON.parse(
                                  localStorage.getItem("toStages")
                                ).filter(s => s.order > postData.Stage.order)[0]
                                  .id
                              )
                              validation.handleSubmit()
                            }}
                          >
                            {t("btns.Update").concat(" ") +
                              "ونقل الى" +
                              " " +
                              JSON.parse(
                                localStorage.getItem("toStages")
                              ).filter(s => s.order > postData.Stage.order)[0]
                                .name}
                          </Button>
                        )}

                      {!postData.Stage &&
                        JSON.parse(localStorage.getItem("toStages")).length >
                          1 && (
                          <Dropdown
                            isOpen={moveOpen}
                            toggle={() => setMoveOpen(!moveOpen)}
                          >
                            <DropdownToggle
                              type="button"
                              tag="button"
                              className="btn btn-primary"
                            >
                              <i className="mdi mdi-chevron-down" />
                              {validation.values.stage_id
                                ? JSON.parse(
                                    localStorage.getItem("toStages")
                                  ).filter(
                                    s => s.id === validation.values.stage_id
                                  )[0].name
                                : t("btns.Draft")}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  setPostData(null)
                                }}
                              >
                                {t("btns.Draft")}
                              </DropdownItem>
                              {JSON.parse(localStorage.getItem("toStages")).map(
                                s => {
                                  return (
                                    <DropdownItem
                                      key={s.id}
                                      onClick={() => {
                                        setPostData(s.id)
                                      }}
                                    >
                                      {s.name}
                                    </DropdownItem>
                                  )
                                }
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        )}

                      {!postData.Stage &&
                        JSON.parse(localStorage.getItem("toStages")).length ===
                          1 && (
                          <Button
                            type="submit"
                            color="primary"
                            onClick={() => {
                              setPostData(
                                JSON.parse(localStorage.getItem("toStages"))[0]
                                  .id
                              )
                              validation.handleSubmit()
                            }}
                          >
                            {t("btns.Update").concat(" ") +
                              t("froms.And Moved To") +
                              " " +
                              JSON.parse(localStorage.getItem("toStages"))[0]
                                .name}
                          </Button>
                        )}
                      <Button type="submit">{t("btns.Update")}</Button>
                    </>
                  )}
                  {!isEdit && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Button type="submit">{t("btns.Save")}</Button>
                      </div>
                    </>
                  )}
                </div>
              )}
              <ResultHandler loading={loading} />
            </div>
          </div>
        </Collapse>
      </div>
      <div
        className="accordion-item"
        style={{
          marginBottom: "15px",
          backgroundColor: "white",
          border: "1px solid rgba(0, 0, 0, 0.125)",
        }}
      >
        <h2
          className={
            "accordion-header " +
            classnames("accordion-button", "fw-medium", {
              collapsed: !accOpen[7],
            })
          }
          onClick={() => {
            handleAccordion(7)
          }}
        >
          {t("forms.Written By")}
        </h2>
        <Collapse isOpen={accOpen[7]} className="accordion-collapse">
          <div className="accordion-body">
            {adminsResponse.data && (
              <CreatableSelect
                placeholder={t("forms.Select")+" ..."}
                isClearable
                onCreateOption={val => {
                  validation.setFieldValue("writer", { label: val, value: val })
                }}
                value={validation.values.writer}
                onChange={data => {
                  validation.setFieldValue("writer", data)
                }}
                isMulti={false}
                inputValue={adminsSearchTxt}
                onInputChange={value => {
                  setAdminsSearchTxt(value)
                }}
                options={adminsResponse.data.admins.map(c => {
                  return { label: c.username, value: c.id }
                })}
              />
            )}
            {validation.touched.writer && validation.errors.writer ? (
              <div
                className="invalid-feedback"
                style={{ display: "block" }}
                type="invalid"
              >
                {validation.errors.writer}
              </div>
            ) : null}
          </div>
        </Collapse>
      </div>
      {isEdit && (
        <>
          <div
            className="accordion-item"
            style={{
              marginBottom: "15px",
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.125)",
            }}
          >
            <h2
              className={
                "accordion-header " +
                classnames("accordion-button", "fw-medium", {
                  collapsed: !accOpen[5],
                })
              }
              onClick={() => {
                handleAccordion(5)
              }}
            >
              {t("forms.Tasks")}
              <span style={{ marginRight: "10px" }}>
                {notDoneTasks > 0 && (
                  <span
                    className="badge me-1 badge-soft-danger"
                    style={{ fontSize: "12px", display: "block" }}
                  >
                    {notDoneTasks}
                  </span>
                )}
              </span>
            </h2>
            <Collapse isOpen={accOpen[5]} className="accordion-collapse">
              <div className="accordion-body">
                <TasksList setNotDoneTasks={setNotDoneTasks} postId={postId} />
              </div>
            </Collapse>
          </div>
          <div
            className="accordion-item"
            style={{
              marginBottom: "15px",
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.125)",
            }}
          >
            <h2
              className={
                "accordion-header " +
                classnames("accordion-button", "fw-medium", {
                  collapsed: !accOpen[6],
                })
              }
              onClick={() => {
                handleAccordion(6)
              }}
            >
              {t("forms.Authors")}
            </h2>
            <Collapse isOpen={accOpen[6]} className="accordion-collapse">
              <div className="accordion-body">
                <AuthorsList postId={postId} />
              </div>
            </Collapse>
          </div>
        </>
      )}
      <div
        className="accordion-item"
        style={{
          marginBottom: "15px",
          backgroundColor: "white",
          border: "1px solid rgba(0, 0, 0, 0.125)",
        }}
      >
        <h2
          className={
            "accordion-header " +
            classnames("accordion-button", "fw-medium", {
              collapsed: !accOpen[4],
            })
          }
          onClick={() => {
            handleAccordion(4)
          }}
        >
          {t("forms.Priority")}
        </h2>
        <Collapse isOpen={accOpen[4]} className="accordion-collapse">
          <div className="accordion-body">
            {PRIORITIES.map(p => {
              return (
                <div
                  key={p.num}
                  className={"form-check mb-3 form-radio-" + p.color}
                >
                  <input
                    type="radio"
                    name="priority"
                    className="form-check-input"
                    value={p.num}
                    onChange={validation.handleChange}
                    checked={validation.values.priority == p.num}
                    disabled={disabled}
                  />
                  <label className="form-check-label">{p.label}</label>
                </div>
              )
            })}
          </div>
        </Collapse>
      </div>
      <div
        className="accordion-item"
        style={{
          marginBottom: "15px",
          backgroundColor: "white",
          border: "1px solid rgba(0, 0, 0, 0.125)",
        }}
      >
        <h2
          className={
            "accordion-header " +
            classnames("accordion-button", "fw-medium", {
              collapsed: !accOpen[1],
            })
          }
          onClick={() => {
            handleAccordion(1)
          }}
        >
          {t("forms.Featured Image")}
        </h2>
        <Collapse isOpen={accOpen[1]} className="accordion-collapse">
          <div className="accordion-body">
            <ChooseImage
              hideLabel={true}
              error={null}
              handleChooseImage={url => {
                validation.setFieldValue("mainImage_url", url)
              }}
              disabled={disabled}
              image={validation.values.mainImage_url}
            />
            {validation.values.mainImage_url && (
              <>
                <Input
                  name="mainImage_comment"
                  style={{ marginTop: "10px" }}
                  placeholder={t("forms.Comment")}
                  type="text"
                  value={validation.values.mainImage_comment}
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.mainImage_comment &&
                    validation.errors.mainImage_comment
                      ? true
                      : false
                  }
                />
                {validation.touched.mainImage_comment &&
                validation.errors.mainImage_comment ? (
                  <FormFeedback type="invalid">
                    {validation.errors.mainImage_comment}
                  </FormFeedback>
                ) : null}
              </>
            )}
          </div>
        </Collapse>
      </div>
      {validation.values.type == "post" && (
        <div
          className="accordion-item"
          style={{
            marginBottom: "15px",
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.125)",
          }}
        >
          <h2
            className={
              "accordion-header " +
              classnames("accordion-button", "fw-medium", {
                collapsed: !accOpen[3],
              })
            }
            onClick={() => {
              handleAccordion(3)
            }}
          >
            {t("forms.Summary")}
          </h2>
          <Collapse isOpen={accOpen[3]} className="accordion-collapse">
            <div className="accordion-body">
              <textarea
                style={{ resize: "none", height: "120px" }}
                placeholder={t("forms.Summary")}
                type="text"
                value={validation.values.summary}
                onChange={validation.handleChange}
                className="form-control"
                name="summary"
              />
            </div>
          </Collapse>
        </div>
      )}
    </>
  )
}

import { addRelatedUrgentPost } from "api/posts"
import ResultHandler from "components/ResultHandler"
import { useResponse } from "hooks/useResponse"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Td } from "react-super-responsive-table"
import { Button } from "reactstrap"

export default function UrgentAction({ row }) {
  const { t } = useTranslation()
  const navigate = useHistory()

  // add related post
  const {
    loading: relateLoading,
    error: relateError,
    success: relateSuccess,
    setData: relatePost,
  } = useResponse(addRelatedUrgentPost, res => {
    navigate.push("/edit-post/" + res.post.id)
  })
  function handleAddRelatedPost(id) {
    relatePost({ type: "add", data: id })
  }
  return (
    <Td>
      <div className="tdBefore">
        {t("btns.Add Contents")}
      </div>
      {!row.relatedPost && !relateLoading && (
        <Button
          color="primary"
          onClick={() => {
            handleAddRelatedPost(row.id)
          }}
        >
          {t("btns.Add")}
        </Button>
      )}
      {row.relatedPost && !relateLoading && (
        <Button
          color="primary"
          onClick={() => {
            navigate.push("view-post/" + row.relatedPost.id)
          }}
        >
          {t("btns.View")}
        </Button>
      )}
      {relateLoading && (
        <Button type="button">
          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
          {t("btns.Loading")}
        </Button>
      )}
      <ResultHandler error={relateError} />
    </Td>
  )
}

import { useTranslation } from "react-i18next"

import React from "react"
//import components
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import MyAdminsTable from "./MyAdminsTable"

export default function MyAdminsList() {
  const { t } = useTranslation()

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.My Admins")} />
        <MyAdminsTable />
      </div>
    </div>
  )
}

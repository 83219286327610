import React from "react"
import { useReadingTime } from "react-reading-time-estimator"

export default function ReadingTime({ postContents }) {
  const {
    text, // 1 min read
    minutes, // 1
    words, // 168
    time, // 0.5309090909090909
  } = useReadingTime(
    postContents
      .map(c => {
        if (c.type === "html") {
          return c.raw_content
        } else {
          return ""
        }
      })
      .join(" ")
  )

  return <>{text}</>
}

import React, { useEffect, useState } from "react"

// Formik validation
import { useFormik } from "formik"

import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalBody,
  Button,
} from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import FormButtonHandler from "components/FormButtonHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import ResultHandler from "components/ResultHandler"
import { publishUrgentPost } from "api/posts"


const EditUrgentPost = ({ postId, action, show, reloadPosts, setShow }) => {

    const { t } = useTranslation()

    const [sound, setSound] = useState(false)

    const {
        loading: loading,
        error: error,
        success: sucess,
        setData: publishPost,
    } = useResponse(publishUrgentPost, () => {
        reloadPosts()
        setShow(false)
    })

  function handlePublish() {
    publishPost({ type: "edit", id: postId, data: { type: action, with_sound: sound } })
  }

  return (
    <Modal isOpen={show} toggle={() => setShow(false)} centered={true}>
      <ModalBody className="py-5 px-5">
         
            <Row>
                <Col lg={12}>
                    <div className="text-center mb-3">
                    {action === 'publish' && <h3>{t("modals.urgentPublishModal")}</h3>}
                    {action === 'unpublish' && <h3>{t("modals.urgentUnPublishModal")}</h3>}
                    </div>
                </Col>
                {action == 'publish' &&<Col md="12">
                    <FormGroup className="mb-3">
                        <div className="form-check form-switch form-switch-md" style={{width: 'fit-content',margin: 'auto'}}>
                            <input
                                type="checkbox"
                                
                                className="form-check-input"
                                onChange={() => setSound(!sound)}
                            />
                            <Label>{t("forms.urgentSound")}</Label>
                        </div>
                    </FormGroup>
                    
                </Col>}
            </Row>

            <ResultHandler error={error} />

            <div style={{margin: "auto", width: "fit-content"}}>
                <Button size="lg" style={{marginLeft: "15px"}} color="secondary" onClick={() => setShow(false)}>{t("btns.Cancel")}</Button>
                <FormButtonHandler btnTxt="Confirm" type="button" handleClick={handlePublish} loading={loading} />
            </div>

      
      </ModalBody>
    </Modal>
  )
}

export default EditUrgentPost

import { t } from "i18next"
import React, { useState } from "react"
import { Button, Input } from "reactstrap"
import VideosModal from "./VideosModal"

export default function ChooseVideo({ handleSelectVideo, disabled, video, editable }) { //// when editable is true this means it's textarea
  const [show, setShow] = useState(false)

  return (
    <>
      {!editable && <div
        className={"ChooseImage"}
        onClick={() => {
          if (!disabled) {
            setShow(true)
          }
        }}
        style={{ height: video ? "auto" : "200px" }}
      >
        {!video && (
          <div>
            <i className="bx bx-video-plus"></i>
          </div>
        )}
        {video && (
          <>
            <section
              contentEditable="true"
              dangerouslySetInnerHTML={{
                __html: video.replace("allowfullscreen", ""),
              }}
              style={{ width: "100%" }}
            ></section>

            <i
              onClick={() => {
                handleSelectVideo(null)
              }}
              style={{
                position: "absolute",
                top: "15px",
                right: "20px",
                fontSize: "20px",
                cursor: "pointer",
                display: "block",
              }}
              className="mdi mdi-close"
            ></i>
          </>
        )}
      </div>}

      { editable &&
        <>
          <Button color="primary" style={{
                display: 'block',
                marginBottom: '10px'
          }} onClick={() => {
            if (!disabled) {
              setShow(true)
            }
          }}>
            {t("btns.Upload")}
          </Button>
          <Input
            type="textarea"
            value={video}
            onChange={e => {
              handleSelectVideo(e.target.value)
            }}
            disabled={disabled}
            style={{marginBottom: "10px"}}
          />
          {video && <div
          style={{width: "100%"}}
            dangerouslySetInnerHTML={{
              __html: video
            }}
          />}
        </>
      }

      {show && (
        <VideosModal
          handleSelectVideo={handleSelectVideo}
          show={show}
          onCloseClick={() => {
            setShow(false)
          }}
        />
      )}
    </>
  )
}

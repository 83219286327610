import React, { useEffect, useRef, useState } from "react"
import classnames from "classnames"
import { Collapse, Tooltip } from "reactstrap"
import PermissionDescription from "./PermissionDescription"

export default function AccordionItem(props) {
  const catInput = useRef()
  const [indeterminate, setIndeterminate] = useState(false)
  const [catChecked, setCatChecked] = useState(
    props.data.Permissions.filter(per => {
      return props.permissions.includes(per.id)
    }).length === props.data.Permissions.length
  )

  useEffect(() => {
    setCatChecked(
      props.data.Permissions.filter(per => {
        return props.permissions.includes(per.id)
      }).length === props.data.Permissions.length
    )
  }, [props.permissions])

  function handleCheckPerm(e) {
    const value = parseInt(e.target.value)
    if (props.permissions.includes(value)) {
      if (
        props.data.Permissions.filter(per => {
          return props.permissions.includes(per.id)
        }).length -
          1 >
        0
      ) {
        catInput.current.indeterminate = true
        setIndeterminate(s => true)
      } else {
        catInput.current.indeterminate = false
        setIndeterminate(s => false)
      }
      props.setPermissions(
        props.permissions.filter(p => {
          return p != value
        })
      )
    }
    if (!props.permissions.includes(value)) {
      if (
        props.data.Permissions.filter(per => {
          return props.permissions.includes(per.id)
        }).length +
          1 <
        props.data.Permissions.length
      ) {
        catInput.current.indeterminate = true
        setIndeterminate(s => true)
      } else {
        catInput.current.indeterminate = false
        setIndeterminate(s => false)
      }
      props.setPermissions(props.permissions.concat([value]))
    }
  }

  function handleCheckCategory() {
    if (catChecked) {
      setCatChecked(false)
      props.setPermissions(
        props.permissions.filter(p => {
          return !props.data.Permissions.map(per => per.id).includes(p)
        })
      )
    }
    if (!catChecked) {
      if (indeterminate) {
        catInput.current.indeterminate = false
        setIndeterminate(s => false)
        props.setPermissions(
          props.permissions.filter(p => {
            return !props.data.Permissions.map(per => per.id).includes(p)
          })
        )
      } else {
        setCatChecked(true)
        props.setPermissions(
          props.permissions.concat(props.data.Permissions.map(per => per.id))
        )
      }
    }
  }

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (catInput) {
      if (
        props.data.Permissions.filter(per => {
          return props.permissions.includes(per.id)
        }).length < props.data.Permissions.length &&
        props.data.Permissions.filter(per => {
          return props.permissions.includes(per.id)
        }).length > 0
      ) {
        catInput.current.indeterminate = true
        setIndeterminate(s => true)
      }
    }
  }, [catInput, props])

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button
          className={classnames("accordion-button", "fw-medium", {
            collapsed: !open,
          })}
          type="button"
          onClick={() => {
            setOpen(!open)
          }}
          style={{ cursor: "pointer" }}
        >
          <input
            ref={catInput}
            checked={catChecked}
            onChange={handleCheckCategory}
            style={{ marginLeft: "10px" }}
            className="form-check-input"
            type="checkbox"
          />
          {props.data.name}
        </button>
      </h2>

      <Collapse isOpen={open} className="accordion-collapse">
        <div className="accordion-body">
          <div className="text-muted">
            {props.data.Permissions.map(p => {
              return (
                <div key={p.id}>
                  <input
                    checked={props.permissions.includes(p.id)}
                    onChange={handleCheckPerm}
                    style={{ marginLeft: "10px" }}
                    key={p.id}
                    value={p.id}
                    className="form-check-input"
                    type="checkbox"
                  />
                  <PermissionDescription
                    id={p.id}
                    name={p.name}
                    description={p.description}
                  />
                  <br />
                </div>
              )
            })}
          </div>
        </div>
      </Collapse>
    </div>
  )
}

import { getProgram } from "api/programs"
import ResultHandler from "components/ResultHandler"
import { useGetInstence } from "hooks/useGetInstence"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import EpisodesList from "./episodes/EpisodesList"
import CustomImage from "components/CustomImage"

export default function ProgramPreview({ }) {
  const params = useParams()
  const [program, setProgram] = useState(null)

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getProgram,
    params.id,
    response => {
      setProgram(response.data.data.program)
    }
  )
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {program && (
            <>
              <Card>
                <div
                  className="bg-primary bg-soft"
                  style={{ height: "200px" }}
                >
                  <CustomImage src={program.image_url} style={{ width: "100%", height: "100%", objectFit: "cover" }} />

                </div>
                <CardBody>
                  <div className="d-flex" style={{ marginTop: "-30px" }}>
                    {program.Presenters.map(p => {
                      return <div className="avatar-md profile-user-wid mb-3" style={{ marginLeft: "10px" }} key={p.id}>
                        <CustomImage
                          style={{
                            width: "70px",
                            height: "70px",
                            objectFit: "cover",
                          }}
                          alt=""
                          className="img-thumbnail rounded-circle"
                          src={p.image_url}
                        />
                      </div>
                    })

                    }

                  </div>
                  <h5 className="font-size-15">{program.name}</h5>
                  <p className="text-muted mb-0">{program.summary}</p>
                </CardBody>
              </Card>
              <EpisodesList programId={params.id} />
            </>
          )}
          <ResultHandler loading={instenceLoading} error={instenceError} />
        </Container>
      </div>
    </React.Fragment>
  )
}


import React, { useState } from "react"
import { Tooltip } from "reactstrap"

export default function PermissionDescription(props){

    const [open, setOpen] = useState(false)

    return(
        <>
        <label>
          {props.name} 
          <i style={{marginRight: "15px"}} id={"target"+props.id} className="fas fa-question-circle"></i>
        </label>
        <Tooltip
          placement="left"
          isOpen={open}
          target={"target"+props.id}
          toggle={() => {
            setOpen(!open)
          }}
        >
          {props.description}
        </Tooltip>
        </>
    )
}
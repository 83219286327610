export const COLORS = {
  navyBlue: "#0C0256",
  flightBlue: "#110BE3",
  grey: "#C2C4C5",
  white: "#FFFFFF",
  royalGold: "#7D6636",
  lightGold: "#D0A954",
  darkRed: "#990202",
  horizonRed: "#CA1411",
}

export const PRIORITIES = [
  { color: "danger", label: "خبر", num: 1 },
  { color: "info", label: "تقرير", num: 2 },
  { color: "success", label: "تحقيق", num: 3 },
  { color: "primary", label: "حوار صحفي", num: 4 },
]

export const STATUSES = [
  { color: "warning", label: "pending", icon: "bx bx-error-circle" },
  { color: "info", label: "in_progress", icon: "bx bx-timer" },
  { color: "success", label: "done", icon: "bx bx-check" },
]

export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY
export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER

import { myAxios } from "./axios"

export function getVideos(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      title: data ? data.txt : null,
      status: data ? data.status : null,
    },
  }
  return myAxios.get("/videos", config)
}

export function getVideo(id) {
  return myAxios.get("/videos/" + id)
}

export function getVideoThumbnail(videoId) {
  return myAxios.get("/videos/" + videoId+"/thumbnail")
}

export function addVideo(data) {
  const conig = {
    onUploadProgress: data.handleUploadProgress,
    timeout: 60*60*1000*2
  }
  return myAxios.post("/videos/create", data.data, conig)
}

export function editVideo(id, data) {
  return myAxios.post("/videos/" + id + "/update", data)
}

export function deleteVideo(id) {
  return myAxios.post("/videos/" + id + "/delete")
}

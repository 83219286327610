import React, { useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import { convertFromRaw, convertToRaw, EditorState } from "draft-js"
import { useTranslation } from "react-i18next"

export default function ContentEditor(props) {

  const {t} = useTranslation()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    if (props.defaultContent) {
      let defaultContent = props.defaultContent //editedPost.json_content
      const DBEditorState = convertFromRaw(defaultContent)
      setEditorState(EditorState.createWithContent(DBEditorState))
    }
  }, [])

  function handlePastedText(text, html, editorState) {
    //console.log("test: ", convertToRaw(editorState.getCurrentContent()))
    //return true to block paste anything
  }

  return (
    <>
      <Editor
        toolbar={{
          options: [
            "inline",
            "textAlign",
            "blockType",
            //"fontSize",
            //"fontFamily",
            "list",
            //"colorPicker",
            "link",
            //"embedded",
            "history",
          ],
          /*image: {
            component: EditorImageModal
          },*/
        }}
        handlePastedText={handlePastedText}
        textAlignment="right"
        placeholder={t("forms.Write Something")}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={editorState => {
          props.onEditorStateChange(editorState, props.order)
          setEditorState(editorState)
        }}
      />
    </>
  )
}

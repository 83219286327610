import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { Button, Col, Form, Modal, ModalBody, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import CropImageModal from "./CropImageModal"
import UploadImgCard from "./UploadImgCard"
import { useRecoilState } from "recoil"
import imgUpload from "atom/imgUpload"

const UploadFileModal = props => {
  const { t } = useTranslation()

  const [fileManagerUpdated, setFileManagerUpdated] = useState(false)

  const [selectedFiles, setselectedFiles] = useState([])

  const [instenceName] = useRecoilState(imgUpload)

  const [uploading, setUploading] = useState(false)

  function handleAcceptedFiles(files) {
    files.map((file, i) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        fileName: instenceName ? instenceName + "-" + i : "",
        uploaded: false,
      })
    })
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleUpload() {
    setselectedFiles(
      selectedFiles.map(f => {
        Object.assign(f, {
          uploaded: true,
        })
        return f
      })
    )
    setFileManagerUpdated(true)
    setUploading(true)
  }

  function handleUploadSuccess(i) {
    setselectedFiles(s =>
      s.filter((fl, ind) => {
        return ind != i
      })
    )
  }

  useEffect(() => {
    if (uploading && selectedFiles.length === 0) {
      props.onCloseClick()

      props.reloadContentMeth()
    }
  }, [uploading, selectedFiles])

  function handleChangeUploadeStatuse(i) {
    setselectedFiles(
      selectedFiles.map((f, ind) => {
        if (ind === i) {
          Object.assign(f, {
            uploaded: false,
          })
          return f
        } else {
          return f
        }
      })
    )
  }

  function handleChangeUploadedFilename(i, value) {
    setselectedFiles(
      selectedFiles.map((f, ind) => {
        if (ind === i) {
          Object.assign(f, {
            fileName: value,
          })
          return f
        } else {
          return f
        }
      })
    )
  }

  function handleChangeUploadedFileAlt(i, value) {
    setselectedFiles(
      selectedFiles.map((f, ind) => {
        if (ind === i) {
          Object.assign(f, {
            alt_text: value,
          })
          return f
        } else {
          return f
        }
      })
    )
  }

  // CROP IMAGE

  const [file, setFile] = useState(null)
  const [selectedFileIndex, setSelectedFileIndex] = useState(null)
  function handleCrop(f) {
    if (f) {
      setselectedFiles(
        selectedFiles.map((fl, i) => {
          if (i == selectedFileIndex) {
            return Object.assign(f.file, {
              preview: URL.createObjectURL(f.file),
              formattedSize: formatBytes(f.file.size),
              fileName: fl.fileName,
              uploaded: fl.uploaded,
            })
          } else {
            return fl
          }
        })
      )
    }
  }

  return (
    <>
      <Modal
        isOpen={props.show}
        toggle={() => {
          props.onCloseClick()
          if (fileManagerUpdated) {
            props.reloadContentMeth()
          }
        }}
        centered={true}
      >
        <ModalBody className="py-5 px-5">
          <Row>
            <Col lg={12}>
              <div className="text-center" style={{ marginBottom: "20px" }}>
                <h2>
                  {t("btns.Upload")} {t("modals.File")}
                </h2>
              </div>
            </Col>

            <Form>
              <Dropzone
                onDrop={acceptedFiles => {
                  handleAcceptedFiles(acceptedFiles)
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>{t("modals.Drop files here or click to upload")}</h4>
                    </div>
                  </div>
                )}
              </Dropzone>

              <div className="dropzone-previews mt-4 mb-4" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <UploadImgCard
                      key={i + "-file"}
                      f={f}
                      handleUploadSuccess={() => {
                        handleUploadSuccess(i)
                      }}
                      handleChangeUploadeStatuse={() => {
                        handleChangeUploadeStatuse(i)
                      }}
                      handleChangeUploadedFilename={value => {
                        handleChangeUploadedFilename(i, value)
                      }}
                      handleChangeUploadedFileAlt={value => {
                        handleChangeUploadedFileAlt(i, value)
                      }}
                      setFile={setFile} // crop
                      setSelectedFileIndex={() => {
                        setSelectedFileIndex(i)
                      }}
                      parentId={props.parentId}
                    />
                  )
                })}
              </div>
              <div style={{ margin: "auto", width: "fit-content" }}>
                {selectedFiles.length > 0 && (
                  <Button
                    className="btn-lg"
                    onClick={handleUpload}
                    color="primary"
                  >
                    {t("btns.Upload")}
                  </Button>
                )}
                <Button
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    props.onCloseClick()
                    if (fileManagerUpdated) {
                      props.reloadContentMeth()
                    }
                  }}
                  className="btn-lg"
                  type="button"
                  color={"danger"}
                >
                  {t("btns.Cancel")}
                </Button>
              </div>
            </Form>
          </Row>
        </ModalBody>
      </Modal>
      {file && <CropImageModal onConfirm={handleCrop} file={file} />}
    </>
  )
}

export default UploadFileModal

import { myAxios } from "./axios"

export function getStadiums(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      name: data ? data.txt : null,
    },
  }
  return myAxios.get("/stadiums", config)
}

export function getStadium(id) {
  return myAxios.get("/stadiums/" + id)
}

export function addStadium(data) {
  return myAxios.post("/stadiums/create", data)
}

export function editStadium(id, data) {
  return myAxios.post("/stadiums/" + id + "/update", data)
}

export function deleteStadium(id) {
  return myAxios.post("/stadiums/" + id + "/delete")
}

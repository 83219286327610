import React from "react"
import { STATUSES } from "utils/constants"
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

import { useTranslation } from "react-i18next"
import "./tasks.css"
import CustomImage from "components/CustomImage"

export default function Task({
  task,
  isSub,
  setAddTaskShow,
  setEditTaskShow,
  setDeleteTaskShow,
  setTask,
}) {
  const { t } = useTranslation()

  return (
    <div
      className={isSub ? "d-flex task subTask" : "task d-flex"}
      style={{
        paddingRight: isSub ? "36px" : "0px",
      }}
    >
      {task.Admin.profile_pic_url && (
        <CustomImage
          style={{
            position: "relative",
            zIndex: 1,
          }}
          className="d-flex me-3 rounded-circle"
          src={task.Admin.profile_pic_url}
          height="36"
          width={36}
        />
      )}
      {!task.Admin.profile_pic_url && (
        <div
          style={{
            width: "36px",
            height: "36px",
            position: "relative",
            zIndex: 1,
          }}
          className="d-flex me-3 rounded-circle avatar-title bg-primary text-white font-size-16"
        >
          {task.Admin.username.charAt(0)}
        </div>
      )}
      <div className="flex-grow-1 chat-user-box" style={{ width: "80%" }}>
        <p
          className="user-title m-0"
          style={{
            display: "flex",
          }}
        >
          <span>{task.Admin.username}</span>

          <UncontrolledDropdown>
            <DropdownToggle
              color="white"
              className="btn btn-link text-muted mt-n2"
            >
              <i className="mdi mdi-dots-horizontal"></i>
            </DropdownToggle>

            <DropdownMenu
              style={{
                left: "0px",
                right: "initial",
              }}
            >
              {!isSub && (
                <DropdownItem
                  onClick={() => {
                    setAddTaskShow(true)
                    setTask(task)
                  }}
                >
                  {t("btns.Add Subtask")}
                </DropdownItem>
              )}
              <DropdownItem
                onClick={() => {
                  setEditTaskShow(true)
                  setTask(task)
                }}
              >
                {t("btns.Edit")}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setDeleteTaskShow(true)
                  setTask(task)
                }}
              >
                {t("btns.Delete")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          {/*<div>
            {STATUSES.filter(s => s.label !== task.status).map(s => {
              return (
                <span
                  onClick={() => {
                    handleChangeTask(task.id, s.label)
                  }}
                  key={s.label}
                  className={
                    "badge rounded-circle font-size-13 badge-soft-" + s.color
                  }
                  style={{
                    marginLeft: "5px",
                    width: "23px",
                    height: "23px",
                    cursor: "pointer",
                    lineHeight: "20px",
                  }}
                >
                  <i className={s.icon}></i>
                </span>
              )
            })}
          </div>*/}
          {/*<span
            style={{
              fontSize: "12px",
              display: "block",
            }}
            className={
              "badge me-1 badge-soft-" +
              STATUSES.filter(s => s.label === task.status)[0].color
            }
          >
            {t("status." + task.status)}
          </span>*/}
        </div>
        <p className="text-muted">{task.text}</p>
      </div>
    </div>
  )
}

import React, { useState } from "react"
import "./AuthCode.css"
import { Row, Col, CardBody, Card, Container, Form, Button } from "reactstrap"

import { Link, useHistory } from "react-router-dom"

// Formik validation
import ResultHandler from "components/ResultHandler"
import AuthCode from "react-auth-code-input"
import { twoFactorAuth } from "api/auth"
import { useRecoilState } from "recoil"
import authState from "atom/auth"
import { myAxios } from "api/axios"

export default function AuthCodeForm() {
  myAxios.interceptors.request.use(config => {
    config.headers["Authorization"] = localStorage.getItem("token")
    return config
  })

  const [isLogged, setIsLogged] = useRecoilState(authState)

  const [error, setError] = useState("")
  const [loading, setLoading] = useState("")

  const [code, setCode] = useState(null)

  function handleTypeCode(value) {
    setCode(value)
  }

  const history = useHistory()

  function handleSubmit(e) {
    e.preventDefault()

    if (code && code.length === 6) {
      setLoading(true)
      twoFactorAuth({ auth_code: code })
        .then(({ data }) => {
          localStorage.setItem(
            "fromStages",
            JSON.stringify(data.data.admin.fromStages)
          )
          localStorage.setItem(
            "toStages",
            JSON.stringify(data.data.admin.toStages)
          )
          localStorage.setItem("token", data.data.token)
          document.cookie = process.env.REACT_APP_COOKIE_PERIFIX + "_token=" + data.data.token + ";domain=" + process.env.REACT_APP_DOMAIN + ";path=/"
          localStorage.setItem("user", JSON.stringify(data.data.admin))
          let perms = data.data.admin.Role.Permissions.map(p => p.token)
          localStorage.setItem("permissions", JSON.stringify(perms))
          setIsLogged(true)
          setLoading(false)
          history.push("/home")
        })
        .catch(response => {
          if (response.response.status === 401) {
            history.push("/login")
            localStorage.removeItem("token")
          } else {
            setError(response.response.data.errors[0])
            setLoading(false)
            setTimeout(() => {
              setError("")
            }, 500)
          }
        })
    } else {
      setError("يلزم ادخال الرمز كامل")
      setTimeout(() => {
        setError("")
      }, 1500)
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5
                          style={{ marginBottom: "0px" }}
                          className="text-primary"
                        >
                          قم بادخال الرمز المرسل
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form className="form-horizontal">
                      <AuthCode
                        containerClassName="d-flex codeRow"
                        inputClassName="codeInput form-control"
                        autoFocus={true}
                        onChange={handleTypeCode}
                        allowedCharacters="numeric"
                      />

                      <ResultHandler
                        error={
                          error
                            ? error
                            : code
                              ? code.length < 6
                                ? "يلزم ادخال الرمز كامل"
                                : ""
                              : ""
                        }
                      />

                      <div className="d-grid">
                        {loading && (
                          <Button type="button">
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                            تحميل
                          </Button>
                        )}
                        {!loading && (
                          <Button
                            onClick={handleSubmit}
                            color="primary"
                            type="submit"
                          >
                            تسجيل دخول
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

import React, { useCallback, useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useTranslation } from "react-i18next"
import { usePagination } from "hooks/usePagination"
import ResultHandler from "components/ResultHandler"
import EmptyData from "components/EmptyData"
import AuditCard from "./AuditCard"
import { getAdminsDropdown, getHistoryLogs } from "api/admins"
import InfiniteScroll from "react-infinite-scroller"
import { Button, Input } from "reactstrap"
import SearchSelectBox from "components/SearchSelectBox"
const debounce = (func, delay) => {
  let debounceTimer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}
export default function AuditList() {
  const { t } = useTranslation()

  const [allLogs, setAllLogs] = useState([])

  const {
    response,
    loading,
    totalPages,
    error,
    setPayload,
    setMeta,
    page,
    setPage,
    meta,
  } = usePagination(getHistoryLogs)

  useEffect(() => {
    if (response.data) {
      setAllLogs([...allLogs, ...response.data.audits])
    }
  }, [response])

  // filters

  const [filters, setFilters] = useState({
    operatorId: null,
    model: "",
    search: "",
    event: "",
    from: "",
    to: "",
  })

  const events = [
    "Create",
    "Update",
    "Delete",
    "Publish",
    "Unpublish",
    "Create-post-from-urgent",
    "Move-post",
    "Move-post-to-trash",
    "Restore",
    "Empty-trash",
    "Move-Urgent-To-Archive",
    "Login",
    "Logout",
    "Assign-post-author",
  ]

  const models = [
    "Admin",
    "Article",
    "urgentPost",
    "Category",
    "Stage",
    "Role",
    "Post",
    "Setting",
    "Tag",
  ]

  // admins dropdown

  const [adminsSearchTxt, setAdminsSearchTxt] = useState("")
  const { response: adminsResponse, setPayload: setAdminsPayload } =
    usePagination(getAdminsDropdown)

  useEffect(() => {
    setAdminsPayload({ txt: adminsSearchTxt })
  }, [adminsSearchTxt])

  // fiunction

  function filterAudit() {
    setMeta({ page: 1, payload: filters })
    setAllLogs([])
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.Audit")} />
        <ResultHandler error={error} loading={loading} />
        {!loading && (
          <>
            <div
              className="d-flex"
              style={{ flexWrap: "wrap", marginBottom: "15px" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("forms.Title")}
                <Input
                  name="title"
                  style={{ marginRight: "10px" }}
                  placeholder={t("forms.Title")}
                  type="text"
                  className="form-control"
                  value={filters.search}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      search: e.target.value,
                    })
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                {t("forms.From")}
                <input
                  type={"date"}
                  value={filters.from}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      from: e.target.value,
                    })
                  }}
                  className="form-control"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                {t("forms.To")}
                <input
                  type={"date"}
                  value={filters.to}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      to: e.target.value,
                    })
                  }}
                  className="form-control"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                {t("forms.Event")}
                <select
                  value={filters.event}
                  style={{ marginRight: "10px", width: "100%" }}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      event: e.target.value === "all" ? null : e.target.value,
                    })
                  }}
                  className="form-select"
                >
                  <option value={"all"}>{t("forms.All")}</option>

                  {events.map(e => {
                    return (
                      <option value={e} key={e}>
                        {e}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                Model
                <select
                  style={{ marginRight: "10px", width: "100%" }}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      model: e.target.value === "all" ? null : e.target.value,
                    })
                  }}
                  value={filters.model}
                  className="form-select"
                >
                  <option value={"all"}>{t("forms.All")}</option>
                  {models.map(e => {
                    return (
                      <option value={e} key={e}>
                        {e}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "15px",
                }}
              >
                Admin
                <div style={{ width: "100%", marginRight: "15px" }}>
                  <SearchSelectBox
                    value={filters.operatorId}
                    setValue={option => {
                      setFilters({
                        ...filters,
                        operatorId: option,
                      })
                    }}
                    error={null}
                    handleFocus={() => {}}
                    handleBlur={() => {}}
                    isMulti={false}
                    api={getAdminsDropdown}
                    type="admins"
                    label="username"
                    name="admins" // for formik
                  />
                </div>
              </div>
            </div>

            <Button style={{ marginBottom: "15px" }} onClick={filterAudit}>
              {t("btns.Search")}
            </Button>
          </>
        )}
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => {
            setMeta(m => ({ ...m, page: page + 1 }))
          }}
          hasMore={page < totalPages - 1}
          loader={<div className="loader" key={0}></div>}
        >
          {allLogs.map(l => {
            return <AuditCard key={"card-" + l.id} content={l} />
          })}
        </InfiniteScroll>
      </div>
    </div>
  )
}

import React, { useState } from "react"
import { Button, Col, Modal, ModalBody, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import AuthCode from "react-auth-code-input"
import "../../AuthCode.css"
import { enable2FA } from "api/auth"
import CustomImage from "components/CustomImage"

const QRModal = props => {
  const { t } = useTranslation()

  const [code, setCode] = useState("")

  function handleTypeCode(value) {
    setCode(value)
  }

  const [loading, setLoading] = useState(false)

  function send2FACode() {
    setLoading(true)
    enable2FA({ auth_code: code })
      .then(res => {
        props.onCloseClick()
        setLoading(false)
        props.setEditedAdmin({ ...props.editedAdmin, twoFaEnabled: true })
      })
      .catch(err => {
        setLoading(false)
      })
  }

  return (
    <Modal isOpen={props.show} toggle={props.onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col style={{ textAlign: "center" }}>
            <h4>{t("Please Scan The QR Code")}</h4>

            <p>
              {t("modals.You Can Use google authinticator To Be Able To Activate Two Factor Auth")}
            </p>

            <CustomImage style={{ marginBottom: "15px" }} src={props.qr} />

            <h5>{props.secret}</h5>

            <AuthCode
              containerClassName="d-flex codeRow"
              inputClassName="codeInput form-control"
              autoFocus={true}
              onChange={handleTypeCode}
              allowedCharacters="numeric"
            />
          </Col>
          <Col>
            <div className="text-center mt-3">
              {!loading && (
                <Button
                  className="btn-lg"
                  color="success"
                  onClick={send2FACode}
                >
                  {t("btns.Confirm")}
                </Button>
              )}
              {loading && (
                <Button className="btn-lg" type="button">
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  {t("btns.Loading")}
                </Button>
              )}
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={props.onCloseClick}
              >
                {t("btns.Cancel")}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default QRModal

import { myAxios } from "./axios"

export function getCounters() {
  return myAxios.get("/dashboard/counters")
}

export function getDateCounters() {
  return myAxios.get("/dashboard/day-counters")
}

export function getStagePostsGraph() {
  return myAxios.get("/dashboard/graph/stage-posts")
}

export function getCategoryPostsGraph() {
  return myAxios.get("/dashboard/graph/category-posts")
}

export function getRoleAdminsGraph() {
  return myAxios.get("/dashboard/graph/role-admins")
}

export function getTopTags() {
  return myAxios.get("/dashboard/graph/tag-posts")
}

export function getDatePosts(data) {
  return myAxios.post("/dashboard/graph/posts-of-date", data)
}

import React, { useState } from "react"
import { Button, Card, CardBody, Collapse } from "reactstrap"
import { useTranslation } from "react-i18next"
import moment from "moment"

import classnames from "classnames"
import CustomImage from "components/CustomImage"
export default function AuditCard(props) {
  const data = props.content
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const admin = data.operator
  let modalField = "name"
  if (data.auditableType == "Admin") {
    modalField = "usename"
  }
  if (
    data.auditableType == "Article" ||
    data.auditableType == "Post" ||
    data.auditableType == "UrgentPost"
  ) {
    modalField = "title"
  }
  function getLogData(modal, data, action) {
    let sentence =
      "قام ب" +
      t("audit." + action) +
      " " +
      (!["Login", "Logout"].includes(action) ? t("pages." + modal) : "") +
      " " +
      (!["Login", "Logout"].includes(action)
        ? data[modalField]
          ? data[modalField]
          : ""
        : "")

    return sentence
  }

  return (
    <div className="accordion">
      <div
        className="accordion-item"
        style={{
          marginBottom: "15px",
          backgroundColor: "white",
          border: "1px solid rgba(0, 0, 0, 0.125)",
        }}
      >
        <h2
          className={
            "accordion-header " +
            classnames("accordion-button", "fw-medium", {
              collapsed: !open,
            })
          }
          onClick={() => {
            setOpen(!open)
          }}
        >
          <span className="d-flex align-items-center gap-1">
            {admin.profile_pic_url ? (
              <CustomImage
                src={admin.profile_pic_url}
                className="rounded-circle avatar-sm"
                alt=""
              />
            ) : (
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                  {admin.username.charAt(0)}
                </span>
              </div>
            )}
            <h5 className="mb-0" style={{ marginRight: "15px" }}>
              {admin.username}
            </h5>

            <h5 className="mb-0" style={{ marginRight: "15px" }}>
              {getLogData(data.auditableType, data.data, data.event)}
            </h5>

            <h5 className="mb-0" style={{ marginRight: "15px" }}>
              <span style={{ marginLeft: "15px" }}>فى</span>
              {moment(data.createdAt).format("YYYY-MM-DD hh:mm")}
            </h5>
          </span>
        </h2>

        <Collapse isOpen={open} className="accordion-collapse">
          <div
            className="accordion-body d-flex"
            style={{ justifyContent: "space-around" }}
          >
            {data.meta && (
              <div style={{ width: "100%" }}>
                {Object.keys(data.meta)
                  .filter(d => d != "postContent")
                  .map(d => {
                    return (
                      <div key={data.id + "-" + d} className="d-flex">
                        <h5 style={{ color: "#ca1411" }}>{d} : </h5>

                        <h5>
                          {typeof data.meta[d] != "object" && data.meta[d]}
                        </h5>
                      </div>
                    )
                  })}
              </div>
            )}
            <section className="new">
              {data.data &&
                Object.keys(data.data)
                  .filter(d => d != "postContent")
                  .map(d => {
                    return (
                      <div key={data.id + "-" + d} className="d-flex">
                        <h5 style={{ color: "#ca1411" }}>{d} : </h5>

                        <h5>
                          {typeof data.data[d] != "object" && data.data[d]}
                        </h5>
                      </div>
                    )
                  })}
            </section>
            <section className="old">
              {data.oldData &&
                Object.keys(data.oldData)
                  .filter(d => d != "postContent")
                  .map(d => {
                    return (
                      <div key={data.id + "-" + d} className="d-flex">
                        <h5 style={{ color: "#ca1411" }}>{d} : </h5>
                        <h5>
                          {typeof data.oldData[d] != "object" &&
                            data.oldData[d]}
                        </h5>
                      </div>
                    )
                  })}
            </section>
          </div>
        </Collapse>
      </div>
    </div>
  )
}

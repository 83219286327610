import { createTag, getTags } from "api/tags"
import { usePagination } from "hooks/usePagination"
import draftToHtml from "draftjs-to-html"
import { convertToRaw } from "draft-js"
import Board, { moveCard } from "../packages/react-kanban/index"
import { useState, useEffect } from "react"

export default function usePost(validation) {
  // board

  let board = validation.values.contents

  function setBoard(newContents) {
    validation.setFieldValue("contents", newContents)
  }

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(board, source, destination)
    setBoard(updatedBoard)
  }

  function handleResetBoard() {
    // when changing the type
    setBoard({
      columns: [
        {
          id: 1,
          title: "",
          cards: [],
        },
      ],
    })
  }

  // content type choose dropdown
  function getLargestOrder() {
    let order = 0
    board.columns[0].cards.map(c => {
      if (c.order > order) {
        order = c.order
      }
    })

    return order
  }

  function addContent(type, ref) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: [
            ...board.columns[0].cards,
            {
              id: board.columns[0].cards.length > 0 ? getLargestOrder() + 1 : 1,
              order:
                board.columns[0].cards.length > 0 ? getLargestOrder() + 1 : 1,
              type,
              cardRef: null,
              content: "",
              status: "new",
              meta: type === "slider" ? [] : null,
              isOpen: true,
            },
          ],
        },
      ],
    })

    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    })
  }

  function removeContent(order) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.filter(c => c.order != order),
        },
      ],
    })
  }

  // text editor

  const onEditorStateChange = (editorState, order) => {
    let meta = convertToRaw(editorState.getCurrentContent())
    let plain = editorState.getCurrentContent().getPlainText("\n")
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(c => {
            if (c.order === order) {
              return {
                ...c,
                raw_content: plain,
                meta: meta,
                content: draftToHtml(meta),
              }
            } else {
              return c
            }
          }),
        },
      ],
    })
  }

  // generate raw_content

  function generateRawContent() {
    let txtContent = ""

    board.columns[0].cards.map(c => {
      if (c.type === "html") {
        if (c.meta) {
          c.meta.blocks.map(b => {
            txtContent += b.text
          })
          txtContent += "\n"
        }
      }
    })

    return txtContent
  }

  // set Image content

  function setImage(url, order) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(c => {
            if (c.order === order) {
              return { ...c, content: url }
            } else {
              return c
            }
          }),
        },
      ],
    })
  }

  function setImageComment(comment, order) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(c => {
            if (c.order === order) {
              return { ...c, meta: { comment } }
            } else {
              return c
            }
          }),
        },
      ],
    })
  }

  // slider set img

  function setSliderImg(url, order, imgOrder) {
    if (
      !board.columns[0].cards
        .filter(c => c.order === order)[0]
        .meta.filter(m => m.order === imgOrder)[0].imgUrl
    ) {
      setBoard({
        columns: [
          {
            ...board.columns[0],
            cards: board.columns[0].cards.map(c => {
              if (c.order === order) {
                return {
                  ...c,
                  meta: [
                    ...c.meta.map(m => {
                      if (m.order === imgOrder) {
                        return { ...m, imgUrl: url }
                      } else {
                        return m
                      }
                    }),
                    {
                      order: c.meta[c.meta.length - 1].order + 1,
                      imgUrl: null,
                      comment: "",
                    },
                  ],
                }
              } else {
                return c
              }
            }),
          },
        ],
      })
    } else {
      setBoard({
        columns: [
          {
            ...board.columns[0],
            cards: board.columns[0].cards.map(c => {
              if (c.order === order) {
                return {
                  ...c,
                  meta: c.meta.map(m => {
                    if (m.order === imgOrder) {
                      return { ...m, imgUrl: url, comment: "" }
                    } else {
                      return m
                    }
                  }),
                }
              } else {
                return c
              }
            }),
          },
        ],
      })
    }
  }

  function addSliderImg(url, order) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(c => {
            if (c.order === order) {
              return {
                ...c,
                meta: c.meta.concat([
                  {
                    order:
                      c.meta.length > 0
                        ? c.meta[c.meta.length - 1].order + 1
                        : 1,
                    imgUrl: url,
                  },
                ]),
              }
            } else {
              return c
            }
          }),
        },
      ],
    })
  }

  function removeSliderImg(order, imgOrder) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(c => {
            if (c.order === order) {
              return {
                ...c,
                meta: c.meta.filter(m => {
                  return m.order != imgOrder
                }),
              }
            } else {
              return c
            }
          }),
        },
      ],
    })
  }

  function setSliderImgComment(comment, order, imgOrder) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(c => {
            if (c.order === order) {
              return {
                ...c,
                meta: c.meta.map(m => {
                  if (m.order === imgOrder) {
                    return { ...m, comment }
                  } else {
                    return m
                  }
                }),
              }
            } else {
              return c
            }
          }),
        },
      ],
    })
  }


  return {
    contentsBoard: board,
    setBoard,
    handleCardMove,
    handleResetBoard,
    addContent,
    removeContent,
    onEditorStateChange,
    generateRawContent,
    setImage,
    setImageComment,
    setSliderImg,
    addSliderImg,
    removeSliderImg,
    setSliderImgComment,
  }
}

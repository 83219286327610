import { usePagination } from "hooks/usePagination"
import React, { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"

import Select from "react-select"
import "./style.css"
import debounce from "utils/debounce"



export default function SearchSelectBox(props) {
  const {t} = useTranslation()
  const [searchTxt, setSearchTxt] = useState("")
  const { response, setPayload, setMetaKey, meta, setMeta } = usePagination(props.api, props.apiMeta, false)
  
  useEffect(() => {
    
    setSearch(searchTxt)
    
  }, [searchTxt])
  


 
  
  // const processChange =useCallback(debounce(() => setMetaKey('txt', searchTxt)),[]);

  const setSearch = useCallback(debounce((e) => setMeta({ active:true, payload: {txt: e, ...props.apiMeta} })),[])
 
  return (
    <>
      {response.data && (
        <Select
          isDisabled={props.disabled}
          placeholder={t("forms.Select")+" ..."}
          value={props.value}
          onChange={data => {
            props.setValue(data)
          }}
          isClearable={true}
          onFocus={props.handleFocus}
          onBlur={props.handleBlur}
          isMulti={props.isMulti}
          inputValue={searchTxt}
          onInputChange={(t)=>{
            setSearchTxt(t);
          }}
          options={response.data[props.type]
            .filter(c => c.id != props.exclude)
            .map(c => {
              return { label: c[props.label], value: c.id }
            })}
          className={props.error ? "invalid" : ""}
        />
      )}
      {props.error ? (
        <div
          style={{
            width: "100%",
            marginTop: "0.25rem",
            fontSize: "80%",
            color: "#f46a6a",
          }}
        >
          {props.error}
        </div>
      ) : null}
    </>
  )
}

import ResultHandler from "components/ResultHandler"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useTranslation } from "react-i18next"

export default function Graph(props) {
  const { t } = useTranslation()

  const [categories, setCategories] = useState(null)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  const series = [
    {
      name: t("pages." + props.title),
      data,
    },
  ]

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: [props.color],
    xaxis: {
      categories,
      /*labels: {
            rotate: 0
          }*/
    },
    yaxis: {
      step: 50,
      title: {
        //text: "$ (thousands)",
      },
    },
    grid: {
      borderColor: props.color,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  useEffect(() => {
    let post = null
    if (props.dates) {
      post = {
        from: props.dates[0]
          ? props.dates[0].toLocaleDateString("en-us", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })
          : null,
        to: props.dates[1]
          ? props.dates[1].toLocaleDateString("en-us", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })
          : null,
      }
    }
    props
      .api(post)
      .then(res => {
        setCategories(res.data.data.labels)
        setData(res.data.data.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        setError("هناك خطأ!")
        props.handleHideIfError()
      })
  }, [props.dates])

  return (
    <>
      {!error && <ResultHandler loading={loading} error={error} />}

      {!error && !loading && categories && (
        <ReactApexChart
          options={options}
          series={series}
          type={props.type ? props.type : "bar"}
          height={"300px"}
        />
      )}
    </>
  )
}

import React from "react"
import { Button, Card, CardBody } from "reactstrap"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { PERMISSIONS } from "api/permissions"

export default function StageCard(props) {
  const data = props.content
  const { t } = useTranslation()
  let navigate = useHistory()

  return (
    <Card>
      <CardBody
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span className="d-flex align-items-center gap-1">
          <i className="bx bx-menu" style={{ fontSize: "1.25rem" }}></i>
          <h5 className="mb-0">{data.name}</h5>
        </span>
        <div className="btn-group btn-group-example" role="group">
          {localStorage
            .getItem("permissions")
            .includes(PERMISSIONS.STAGE_EDIT) && (
            <Button
              color="primary"
              onClick={() => {
                navigate.push("edit-stage/" + data.id)
              }}
            >
              {t("btns.Edit")}
              <i className="bx bx-edit font-size-16 align-middle me-1" />
            </Button>
          )}

          {localStorage
            .getItem("permissions")
            .includes(PERMISSIONS.STAGE_DELETE) && (
            <Button
              color="danger"
              onClick={() => {
                props.setShow(true)
                props.setDeleteIntitiy(data.id)
              }}
            >
              {t("btns.Delete")}
              <i className="bx bx-trash font-size-16 align-middle me-1" />
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

import React, { useState, useEffect } from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { useTranslation } from "react-i18next"

import AuthorsList from "../authors/AuthorsList"

const ChooseAuthorsModal = props => {
  const { t } = useTranslation()
  const [edited, setEdited] = useState(false)
  return (
    <Modal
      isOpen={props.show}
      toggle={() => {
        props.onCloseClick()
        if (edited && props.reloadPosts) {
          props.reloadPosts()
        }
      }}
      centered={true}
    >
      <ModalBody className="py-5 px-5">
        <>
          <Row>
            <Col lg={12}>
              <div className="text-center" style={{ marginBottom: "20px" }}>
                <h2>{t("modals.Assign Authors To Post")}</h2>
              </div>
            </Col>

            <Col md={12} style={{ marginTop: "20px" }}>
              <AuthorsList
                urgent={props.urgent}
                postId={props.postId}
                handleRemove={() => {
                  setEdited(true)
                }}
                handleAdd={() => {
                  setEdited(true)
                }}
              />
            </Col>
            <Col>
              <div className="text-center mt-3">
                <button
                  type="button"
                  className="btn btn-light btn-lg ms-2"
                  onClick={() => {
                    props.onCloseClick()
                    if (edited && props.reloadPosts) {
                      props.reloadPosts()
                    }
                  }}
                >
                  {t("btns.Cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </>
      </ModalBody>
    </Modal>
  )
}

export default ChooseAuthorsModal

import notificationState from "atom/notification"
import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import { useRecoilState } from "recoil"
import SimpleBar from "simplebar-react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import { readNotification } from "api/admins"
import unreadState from "atom/unread"
import NotificationCard from "../NotificationCard"
const NotificationDropdown = () => {
  const [menu, setMenu] = useState(false)
  const [notifications, setNotifications] = useRecoilState(notificationState)
  const [unread, setUnread] = useRecoilState(unreadState)

  const { t } = useTranslation()
  const { success, error, loading, setData } = useResponse(readNotification)

  function handleReadNotification() {
    setData({ type: "add", data: "all" })
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => {
          setMenu(!menu)
          setUnread(0)
          if (!menu) {
            handleReadNotification()
          }
        }}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell" />

          {unread > 0 && (
            <span className="badge bg-danger rounded-pill">{unread}</span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {t("pages.Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link to="/all-notifications" className="small">
                  {t("btns.showAll")}
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ maxHeight: "230px" }}>
            {notifications.map(n => {
              return <NotificationCard key={n.id} n={n} />
            })}
            {notifications.length === 0 && (
              <div style={{ padding: "10px", textAlign: "center" }}>
                
                {t("emptyMsgs.No Notifications Found.")}
              </div>
            )}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default NotificationDropdown

import React, { useState } from "react"
import { Card, CardBody, Col, Row, Container } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useTranslation } from "react-i18next"
import { usePagination } from "hooks/usePagination"
import { getPostHistory } from "api/posts"
import { useParams } from "react-router-dom"
import HorizontalTimeline from "./HorizontalTimeline"
import VerticalTimeline from "./VerticalTimeline"
import EmptyData from "components/EmptyData"

export default function PostHistory() {
  const { t } = useTranslation()

  const { postId } = useParams()

  const { loading, response, error, setPayload } = usePagination(
    getPostHistory,
    postId
  )

  const [view, setView] = useState("vertical")

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("pages.Post History")}
          breadcrumbItem={t("pages.Post History")}
        />

        <EmptyData
          empty={response.data && response.data.data.history.length === 0}
          msg="This Post Has No History."
        />

        {response.data && response.data.data.history.length != 0 && (
          <>

            <Row>
              <Col xl="12">
                <Card>
                  <CardBody style={{ paddingBottom: "55px" }}>
                    {response.data && view === "slider" && (
                      <HorizontalTimeline
                        response={response.data.data.history}
                      />
                    )}
                    {response.data && view != "slider" && (
                      <VerticalTimeline response={response.data.data.history} />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  )
}

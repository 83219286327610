import React, { useEffect, useState } from "react"

import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Form,
    Modal,
    ModalBody,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"
import classnames from 'classnames'
import { useFormik } from "formik"

import ResultHandler from "components/ResultHandler"

import FormButtonHandler from "components/FormButtonHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { addEpisode, editEpisode } from "api/programs"
import useCustomValidation from "pages/dashboard/validationSchema"
import ChooseImage from "components/ChooseImage"

import moment from "moment"
import ChooseVideo from "pages/dashboard/videos/ChooseVideo"

const CreateEpisodeModal = ({ show, reloadPrograms, programId, episodeData, handleClose }) => {
    const {episodeSchema} = useCustomValidation()

    const { t } = useTranslation()

    useEffect(() => {
        moment.locale('en')
    }, [])

    const { setData, loading, error, success } = useResponse(
        episodeData ? editEpisode : addEpisode,
        () => {
            handleClose()
            reloadPrograms()
        }
    )

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: episodeData ? episodeData.name : "",
            date: episodeData ? (new Date(episodeData.date)).toISOString().split("T")[0] : "",
            embedCode: episodeData ? episodeData.embedCode : "",
            image_url: episodeData ? episodeData.image_url : "",
            external_video: episodeData ? episodeData.external_video : false
        },
        validationSchema: episodeSchema,
        onSubmit: values => {

            const date = moment(new Date(values.date)).format('YYYY-MM-DD')
            let data = {
                ...values,
                date,
                programId,
            }
            if (episodeData) {
                setData({ data, id: episodeData.id, type: "edit" })
            } else {
                setData({ data, type: "add" })
            }
        },
    })

    return (
        <Modal size="xl" isOpen={show} toggle={handleClose} centered={true}>
            <ModalBody className="py-5 px-5">
                <Col lg={12}>
                    <div className="text-center" style={{ marginBottom: "20px" }}>
                        <h2>
                            {episodeData ? t("btns.Edit") : t("btns.Add")} {t("pages.Episode")}
                        </h2>
                    </div>
                </Col>
                <Form
                    className="needs-validation"
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                    }}
                >
                    <Row>
                        <Col md="6" className="mb-4">
                            <FormGroup className="mb-3">
                                <Label>{t("forms.Name")}</Label>
                                <Input
                                    name="name"
                                    placeholder={t("forms.Name")}
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name || ""}
                                    invalid={
                                        validation.touched.name && validation.errors.name
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.name && validation.errors.name ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.name}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <Label>{t('forms.Date')}</Label>
                                <InputGroup>

                                    <Input
                                        onChange={e => {
                                            validation.setFieldValue("date", e.target.value)
                                        }}
                                        value={validation.values.date}
                                        className="form-control"
                                        type="date"
                                        invalid={
                                            validation.touched.date && validation.errors.date
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.date && validation.errors.date &&
                                        <div className="invalid-feedback" style={{ display: "block" }} type="invalid">
                                            {validation.errors.date}
                                        </div>
                                    }
                                </InputGroup>
                            </FormGroup>
                            <ChooseImage
                                name="img"
                                image={validation.values.image_url}
                                error={
                                    validation.touched.image_url && validation.errors.image_url
                                }
                                handleChooseImage={(url) => {
                                    validation.setFieldValue("image_url", url)
                                }}
                            />
                        </Col>

                        <Col md="6">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer", background: "transparent" }}
                                        className={classnames({
                                            active: validation.values.external_video === false,
                                        })}
                                        onClick={() => {
                                            validation.setFieldValue('external_video', false)
                                            validation.setFieldValue('embedCode', episodeData.external_video === false ? episodeData.embedCode : '')
                                        }}
                                    >
                                        {t("forms.Video")}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer", background: "transparent" }}
                                        className={classnames({
                                            active: validation.values.external_video === true,
                                        })}
                                        onClick={() => {
                                            validation.setFieldValue('external_video', true)
                                            validation.setFieldValue('embedCode', '')
                                            validation.setFieldValue('embedCode', episodeData.external_video === true ? episodeData.embedCode : '')

                                        }}
                                    >
                                        {t("forms.embeded")}
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={validation.values.external_video === true ? "2" : "1"} className="p-3 text-muted">
                                {validation.values.external_video === false && (
                                    <TabPane tabId="1">
                                        <ChooseVideo
                                            handleSelectVideo={embeded => {
                                                validation.setFieldValue('embedCode', embeded)
                                            }}
                                            video={validation.values.embedCode}
                                        />
                                        {validation.touched.embedCode && validation.errors.embedCode ? (
                                              
                                                <div type="invalid" style={{display: "block"}} className="invalid-feedback">{validation.errors.embedCode}</div>
                                            ) : null}
                                    </TabPane>
                                )}
                                {validation.values.external_video === true && (
                                    <TabPane tabId="2">
                                        <FormGroup className="mb-3">
                                            <Label>{t("forms.Video")}</Label>
                                            <Input
                                                name="embedCode"
                                                placeholder={t("forms.Video")}
                                                type="text"
                                                className="form-control"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.embedCode || ""}
                                                invalid={
                                                    validation.touched.embedCode && validation.errors.embedCode
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.embedCode && validation.errors.embedCode ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.embedCode}
                                                </FormFeedback>
                                            ) : null}
                                            {validation.values.embedCode && <div
                                                dangerouslySetInnerHTML={{
                                                    __html: validation.values.embedCode,
                                                }}
                                                className="embededCode"
                                                style={{ width: "100%" }}
                                            />}
                                        </FormGroup>
                                    </TabPane>
                                )}
                            </TabContent>
                        </Col>

                        <Col xs={12}>
                            <FormButtonHandler btnTxt="Add" loading={loading} />
                        </Col>

                    </Row>

                    <ResultHandler
                        error={error}
                        success={success}
                        successMsg={"Created Successfully!"}
                    />
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default CreateEpisodeModal

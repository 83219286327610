
import React from "react"
//https://crc-public-bucket.s3.amazonaws.com/174ec5f0-0931-11ee-a193-df130b881256-file-1686581694392-170114998
const oldS3 = 'amazonaws.com';
const newS3 = 'https://fmcdn.alqaheranews.net/'

function getSrc(src){
    
    if(src.includes('crc-public-bucket') || src.includes('crc-media')){
        const objectKey = src.match(/[^\/]+$/g)[0];
        const newSrc = newS3+objectKey
        return newSrc
    }else{
        return src
    }
}

export default function CustomImage(props){


    return(
        <img {...props} src={getSrc(props.src)} />
    )
}
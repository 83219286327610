import { myAxios } from "./axios"

export function getPrograms(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
    },
  }
  return myAxios.get("/programs", config)
}

export function getProgram(id) {
  return myAxios.get("/programs/" + id)
}

export function addProgram(data) {
  return myAxios.post("/programs/create", data)
}

export function editProgram(id, data) {
  return myAxios.post("/programs/" + id + "/update", data)
}

export function publishProgram(id) {
  return myAxios.post("/programs/" + id + "/publish")
}


export function deleteProgram(id) {
  return myAxios.post("/programs/" + id + "/delete")
}

// episodes

export function getEpisodes(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
    },
  }
  return myAxios.get("/programs/" + data.id + "/episodes", config)
}

export function addEpisode(data) {
  return myAxios.post("/episodes/create", data)
}

export function editEpisode(id, data) {
  return myAxios.post("/episodes/" + id + "/update", data)
}

export function deleteEpisode(id) {
  return myAxios.post("/episodes/" + id + "/delete")
}


import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import "../Editor.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Breadcrumbs from "components/Common/Breadcrumb"
import { createPost } from "api/posts"
import { extractKeyWords, getPostsDropdown } from "api/posts"
import ResultHandler from "components/ResultHandler"

import { useResponse } from "hooks/useResponse"

import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  InputGroup,
  InputGroupText,
} from "reactstrap"
import { useRecoilState } from "recoil"
import imgUpload from "atom/imgUpload"
import usePost from "hooks/usePost"
import FormSide from "./FormSide"
import StartNewModal from "./StartNewModal"
import PostForm from "./PostForm"
import useCustomValidation from "pages/dashboard/validationSchema"

const AddPost = () => {
  const { t } = useTranslation()
  let navigate = useHistory()
  const {postSchema} = useCustomValidation()

  // posting result states

  const { setData, loading, error, success } = useResponse(createPost, () => {
    navigate.push("/archive")
    localStorage.removeItem('AddPostData')
  })

  // posting

  const [initialValues, setInitialValues] = useState(
    localStorage.getItem("AddPostData")
      ? JSON.parse(localStorage.getItem("AddPostData"))
      : {
          title: "",
          writer: null,
          slug: "",
          mainImage_comment: "",
          mainImage_url: "",
          language: "ar",
          type: "post",
          special: false,
          is_pinned: false,
          summary: "",
          stage_id: null,
          priority: 4,
          categories: null,
          keywords: [],
          related_posts: [],
          tags: [],
          publishedAt: null,
          contents: {
            columns: [
              {
                id: 1,
                title: "",
                cards: [],
              },
            ],
          },
        }
  )

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema: postSchema,
    onSubmit: values => {
      setData({
        type: "add",
        data: {
          ...values,
          writer: isNaN(values.writer.value)
            ? values.writer.value
            : parseInt(values.writer.value),
          contents: board.columns[0].cards.map((c, i) => {
            return {
              ...c,
              order: i,
              meta: c.type === "slider" ? { imgs: c.meta } : c.meta,
              cardRef: null,
            }
          }),
          raw_content: generateRawContent(),
          tags: values.tags.map(cat => cat.value),
          related_posts: values.related_posts.map(cat => cat.value),
          keywords: values.keywords.map(cat => cat.value).join(","),
          categories: [values.categories?.value],
          kickers: [values.kickers?.value], //for now we only allow one kicker per post
        },
      })
    },
  })

  const [name, setName] = useRecoilState(imgUpload) // for image upload

  useEffect(() => {
    setName(validation.values.title)
  }, [validation.values])

  const {
    contentsBoard: board,
    setBoard,
    handleCardMove,
    handleResetBoard,
    addContent,
    removeContent,
    onEditorStateChange,
    generateRawContent,
    setImage,
    setImageComment,
    setSliderImg,
    addSliderImg,
    removeSliderImg,
    setSliderImgComment,
  } = usePost(validation)

  // autosave

  const [startShow, setStartShow] = useState(false)

  function handleChooseStartChoice(choice) {
    setStartShow(false)
    if (choice) {
      setInitialValues({
        title: "",
        writer: null,
        slug: "",
        mainImage_comment: "",
        mainImage_url: "",
        language: "ar",
        type: "post",
        special: false,
        is_pinned: false,
        summary: "",
        stage_id: null,
        priority: 4,
        categories: null,
        keywords: [],
        related_posts: [],
        tags: [],
        publishedAt: null,
        contents: {
          columns: [
            {
              id: 1,
              title: "",
              cards: [],
            },
          ],
        },
      })
      localStorage.removeItem("AddPostData")
    } else {
      setInitialValues(
        localStorage.getItem("AddPostData")
          ? JSON.parse(localStorage.getItem("AddPostData"))
          : {
              title: "",
              writer: null,
              slug: "",
              mainImage_comment: "",
              mainImage_url: "",
              language: "ar",
              type: "post",
              special: false,
              is_pinned: false,
              summary: "",
              stage_id: null,
              priority: 4,
              categories: null,
              keywords: [],
              related_posts: [],
              tags: [],
              publishedAt: null,
              contents: {
                columns: [
                  {
                    id: 1,
                    title: "",
                    cards: [],
                  },
                ],
              },
            }
      )
    }
  }

  useEffect(() => {
    if (validation.dirty) {
      localStorage.setItem("AddPostData", JSON.stringify(validation.values))
    }
  }, [validation.values])

  useEffect(() => {
    if (localStorage.getItem("AddPostData")) {
      setStartShow(true)
    }
  }, [])

  // TODO : remove it when the new refactor is merged
  function getKeyWords() {
    if (generateRawContent().length > 0) {
      extractKeyWords({ text: generateRawContent() })
        .then(res => {
          validation.setFieldValue("keywords", [
            ...(validation.values.keywords ? validation.values.keywords : []),
            ...res.data
              .filter(
                obj =>
                  !validation.values.keywords
                    .map(k => k.value)
                    .includes(obj.json.matchedText)
              )
              .map(obj => {
                return {
                  value: obj.json.matchedText,
                  label: obj.json.matchedText,
                }
              }),
          ])
        })
        .catch(err => {})
    }
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("pages.Posts")}
          breadcrumbItem={t("btns.Add") + " " + t("pages.Post")}
        />
        <Form
          className="needs-validation"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col xl={12}>
              <ResultHandler
                error={error}
                success={success}
                successMsg={"Created Successfully!"}
              />
            </Col>
            <Col xl="8">
              <PostForm validation={validation} />
            </Col>
            <Col xl="4" className="accordion">
              <FormSide validation={validation} loading={loading} />
            </Col>
          </Row>
        </Form>
      </Container>
      <StartNewModal
        show={startShow}
        handleChooseStartChoice={handleChooseStartChoice}
      />
    </div>
  )
}

export default AddPost

import { deleteUrgentPost, getUrgentPosts, publishUrgentPost } from "api/posts"
import React, { useEffect, useRef, useState } from "react"
import { usePagination } from "../../../../hooks/usePagination"
import { Button, FormGroup } from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import ResultHandler from "components/ResultHandler"
import PaginationHandler from "components/PaginationHandler"
import TableHeaderHandler from "components/TableHeaderHandler"
import { PERMISSIONS } from "api/permissions"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import EmptyData from "components/EmptyData"
import "../crud/PostsTable.css"
import AuthorTooltip from "../board/AuthorTooltip"
import AddUrgentPost from "./AddUrgentPost"
import ChooseAuthorsModal from "../board/ChooseAuthorsModal"
import EditUrgentPost from "./EditUrgentPost"
import UrgentAction from "./UrgentAction"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import UrgentPublishModal from "./UrgentPublishModal"
import i18next from "i18next"

export default function UrgentTable() {
  const { t } = useTranslation()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getUrgentPosts)

  // delete modal
  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }

  const [deletingPost, setDeletingPost] = useState(null)

  const {
    setData,
    success: deleteSuccess,
    error: deleteError,
    setSuccess: setDeleteSuccess,
    loading: deleteLoading,
  } = useResponse(deleteUrgentPost, () => {
    setTimeout(() => {
      const allDeleted = deletedPosts.concat([deletingPost])
      setDeletedPosts([...deletedPosts, deletingPost])
      if (
        response.data.posts.filter(p => !allDeleted.includes(p.id)).length ===
          0 &&
        page > 1
      ) {
        setPage(page - 1)
      }
      if (
        response.data.posts.filter(p => !allDeleted.includes(p.id)).length ===
          0 &&
        page === 1 &&
        page < totalPages
      ) {
        setPayload({ txt: "" })
        setPage(1)
      }
      setDeletingPost(null)
      setDeleteSuccess(false)
    }, 300)
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingPost })
    setShow(false)
  }

  const [deletedPosts, setDeletedPosts] = useState([])

  // authors

  const [post, setPost] = useState(null)
  const [authorsModalShow, setAuthorsModalShow] = useState(false)

  // add modal

  const [addModalShow, setAddModalShow] = useState(false)

  //edit modal

  const [editModalShow, setEditModalShow] = useState(false)

  // publish modal

  const [publishModalShow, setPublishModalShow] = useState(false)

  

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.URGENT_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl="/add-urgent-post"
        addText="Add Urgent Post"
        handleLinkClick={() => {
          setAddModalShow(true)
        }}
      />

      <div className="table-rep-plugin">
        <div className="table-responsive postsTable">
          <Table className="table table-striped table-bordered">
            {response.data && !loading && (
              <>
                <Thead className="table-light table-nowrap">
                  <Tr>
                    <Th className="loading" style={{ width: "108px" }}></Th>

                    <Th style={{ width: "45%" }}>{t("forms.Title")}</Th>
                    <Th style={{ width: "5%" }}>{t("forms.Status")}</Th>
                    <Th style={{ width: "10%" }}>{t("btns.Add Contents")}</Th>
                    <Th style={{ width: "15%" }}>{t("forms.Authors")}</Th>
                    <Th style={{ width: "25%" }}>{t("tables.Action")}</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {response.data.posts.map(row => {
                    return (
                      <Tr
                        key={row.id}
                        className={
                          (deletingPost == row.id && deleteLoading
                            ? "deleting "
                            : "") +
                          (deletedPosts.includes(row.id) ? "deleted" : "")
                        }
                        style={{
                          transform:
                            deletingPost == row.id && deleteSuccess
                              ? "translateX("+ (i18next.language === 'ar' ?  "-" : "") +"100%)"
                              : deletingPost == row.id && deleteLoading
                              ? "translateX("+(i18next.language === 'ar' ?  "-" : "")+"10%)"
                              : "",
                        }}
                      >
                        <Td
                          className="loading"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "0px",
                            paddingTop: "18px",
                            width: "108px",
                            margin: "0px",
                          }}
                        >
                          <span className="loader"></span>
                        </Td>
                        <Td>{row.title}</Td>
                        <Td>{row.is_published ? t("status.published") : t("Draft")}</Td>
                        {localStorage
                          .getItem("permissions")
                          .includes(PERMISSIONS.POSTS_CREATE) && (
                          <UrgentAction row={row} />
                        )}

                        <Td>
                          <div className="avatar-group">
                            {row.Authors.map(auther => (
                              <div
                                className="avatar-group-item"
                                key={auther.id}
                              >
                                <div className="d-inline-block">
                                  <AuthorTooltip
                                    postId={row.id}
                                    author={auther}
                                  />
                                </div>
                              </div>
                            ))}

                            <div
                              className="avatar-group-item"
                              onClick={() => {
                                setAuthorsModalShow(true)
                                setPost(row)
                              }}
                              key={0}
                            >
                              <div className="avatar-xs">
                                <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                                  +
                                </span>
                              </div>
                            </div>
                          </div>
                        </Td>

                        <Td>
                          <Button
                            color="primary"
                            className="btn-edit-icon"
                            onClick={() => {
                              setEditModalShow(true)
                              setPost(row)
                            }}
                          >
                            <i className="bx bx-edit font-size-16 align-middle me-1" />
                          </Button>

                          <Button
                            color="danger"
                            onClick={() => {
                              setShow(true)
                              setDeletingPost(row.id)
                            }}
                          >
                            <i className="bx bx-trash font-size-16 align-middle" />
                          </Button>
                          {row.is_published && (
                            <Button
                              className="btn-cancel"
                              color="primary"
                              onClick={() => {
                                setPost({ ...row, action: "unpublish" })
                                setPublishModalShow(true)
                              }}
                            >
                              <span
                                className={
                                  isMobile ? "responsive-button-label" : ""
                                }
                              >
                                {t("btns.Unpublish")}
                              </span>
                              <i className="bx bx-window-close font-size-16 align-middle" />
                            </Button>
                          )}
                          {!row.is_published && (
                            <Button
                              color="primary"
                              onClick={() => {
                                setPost({ ...row, action: "publish" })
                                setPublishModalShow(true)
                              }}
                            >
                              <span
                                className={
                                  isMobile ? "responsive-button-label" : ""
                                }
                              >
                                {t("btns.Publish")}
                              </span>
                              <i className="bx bx-share font-size-16 align-middle" />
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </>
            )}
          </Table>

          {!loading && !deleteLoading && (
            <EmptyData
              empty={
                response.data &&
                response.data.posts.filter(p => !deletedPosts.includes(p.id))
                  .length === 0
              }
              msg="No Posts Found."
            />
          )}

          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />

          {publishModalShow && (
            <UrgentPublishModal
              postId={post.id}
              action={post.action}
              show={publishModalShow}
              setShow={setPublishModalShow}
              reloadPosts={() => {
                setPayload({ txt: "" })
              }}
              
            />
          )}

          {addModalShow && (
            <AddUrgentPost
              show={addModalShow}
              setShow={setAddModalShow}
              reloadPosts={() => {
                setPayload({ txt: "" })
              }}
            />
          )}

          {editModalShow && post && (
            <EditUrgentPost
              postId={post.id}
              show={editModalShow}
              setShow={setEditModalShow}
              reloadPosts={() => {
                setPayload({ txt: "" })
              }}
            />
          )}

          {authorsModalShow && (
            <ChooseAuthorsModal
              authors={post.Authors}
              postId={post.id}
              show={authorsModalShow}
              onCloseClick={() => {
                setAuthorsModalShow(false)
                setPost(null)
              }}
              reloadPosts={() => {
                setPayload({ txt: "" })
              }}
              urgent={true}
            />
          )}
          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          <ResultHandler error={error} loading={loading} />
          <ResultHandler error={deleteError} />
        </div>
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react"
import { Button, Collapse, FormGroup, Input, Label } from "reactstrap"
import classnames from "classnames"
import { useTranslation } from "react-i18next"
import { getCategoryKickers } from "api/kickers"

export default function NavItem({ content, handleChangeCustomLink, handleRemoveItem, handleCheckKicker }) {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  // for kickers
  const [kickers, setKickers] = useState([]) // kickers to choose from {for category type}
  useEffect(() => {
    if (content.type === 'category') {
      getCategoryKickers(content.id).then((res) => {
        setKickers(res.data.data.kickers)
      })
    }
  }, [content.id])

  return (
    <>
      <div className="accordion">
        <div
          className="accordion-item"
          style={{
            marginBottom: "15px",
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.125)",
          }}
        >
          <h2
            className={
              "accordion-header " +
              classnames("accordion-button", "fw-medium", {
                collapsed: !open,
              })
            }
            onClick={() => {
              setOpen(!open)
            }}
          >
            <Button
              style={{ marginLeft: "15px" }}
              color="danger"
              type="button"
              onClick={() => {
                handleRemoveItem(content.id)
              }}
            >
              <i className="bx bx-trash font-size-16 align-middle" />
            </Button>
            {content.name}
          </h2>
          <Collapse isOpen={open} className="accordion-collapse">
            <div className="accordion-body">
              {content.type == 'category' &&
                <div>
                  {kickers.map(kicker => {
                    return <div key={kicker.id} className="mb-2">
                      <input checked={(content.kickers || []).includes(kicker.id)} style={{ marginLeft: "10px" }} type={"checkbox"} onChange={() => handleCheckKicker(kicker.id, content.id)} />
                      <label className="mb-0">{kicker.name}</label>
                    </div>
                  })

                  }
                </div>
              }
              {content.type != 'category' &&
                <>
                  <FormGroup className="mb-3">
                    <Label>{t("forms.Name")}</Label>
                    <Input
                      name="name"
                      placeholder={t("forms.Name")}
                      type="text"
                      className="form-control"
                      value={content.name}
                      onChange={e => {
                        handleChangeCustomLink(content.id, 'name', e.target.value)
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>{t("forms.Url")}</Label>
                    <Input
                      name="url"
                      placeholder={t("forms.Url")}
                      type="text"
                      className="form-control"
                      value={content.url}
                      onChange={e => {
                        handleChangeCustomLink(content.id, 'url', e.target.value)

                      }}
                    />
                  </FormGroup>
                </>
              }
            </div>
          </Collapse>
        </div>
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap"

//redux

import { Link, useHistory } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import { useRecoilState } from "recoil"
import authState from "../atom/auth"
import { login } from "api/auth"
import ResultHandler from "components/ResultHandler"
import logo from "../assets/images/QN-logo-mini.png"
import useCustomValidation from "./dashboard/validationSchema"
import { useTranslation } from "react-i18next"
import CustomImage from "components/CustomImage"


const Login = () => {
  const {t} = useTranslation()
  const {loginSchema} = useCustomValidation()
  //meta title
  const [isLogged, setIsLogged] = useRecoilState(authState)
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: localStorage.getItem("email"),
      password: localStorage.getItem("password"),
    },
    validationSchema: loginSchema,
    onSubmit: values => {
      setLoading(true)
      let email = values.email
      let password = values.password
      login({ email, password})
        .then(({ data }) => {
          if (data.data.twoFaEnabled) {
            localStorage.setItem("token", data.data.token)
            history.push("/auth-code")
          } else {
            localStorage.setItem(
              "fromStages",
              JSON.stringify(data.data.admin.fromStages)
            )
            localStorage.setItem(
              "toStages",
              JSON.stringify(data.data.admin.toStages)
            )
            localStorage.setItem("token", data.data.token)
            localStorage.setItem("user", JSON.stringify(data.data.admin))

            document.cookie =
              process.env.REACT_APP_COOKIE_PERIFIX +
              "_token=" +
              data.data.token +
              ";domain=" +
              process.env.REACT_APP_DOMAIN +
              ";path=/"
            let perms = data.data.admin.Role.Permissions.map(p => p.token)
            localStorage.setItem("permissions", JSON.stringify(perms))
            setIsLogged(true)
            setLoading(false)

            history.push("/home")
          }
        })
        .catch(err => {
          setError(err.response.data.message)
          setLoading(false)
        })
    },
  })

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden login-box">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary mb-3">{t("login.welcome")} !</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <CustomImage src="" alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <CustomImage
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">{t("forms.Email")}</Label>
                        <Input
                          name="email"
                          className="form-control"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{t("forms.Password")}</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          {t("login.rememberMe")}
                        </label>
                      </div>

                      <div className="d-grid">
                        <ResultHandler error={error} />
                        {loading && (
                          <Button type="button">
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                            {t("login.loading")}
                          </Button>
                        )}
                        {!loading && (
                          <Button color="primary" type="submit">
                            {t("login.login")}
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login

import React, { useState } from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import FilesList from "pages/dashboard/file-manager/FilesList"
import { useTranslation } from "react-i18next"

export default function FileManagerModal(props) {
  const { t } = useTranslation()

  const [folderId, setFolderId] = useState(0) // root

  const [loading, setLoading] = useState(true)

  function handleOpen(id, name) {
    setFolderId(id)
    setLoading(true)
  }

  return (
    <Modal
      style={{ maxWidth: "80%" }}
      isOpen={props.open}
      toggle={props.onCloseClick}
    >
      <ModalBody className="py-5 px-5">
        <i
          onClick={props.onCloseClick}
          style={{
            position: "absolute",
            top: "20px",
            right: "40px",
            fontSize: "30px",
            cursor: "pointer",
          }}
          className="mdi mdi-close"
        ></i>
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <h2>{t("pages.Filemanager")}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <FilesList
            loading={loading}
            setLoading={setLoading}
            handleOpen={handleOpen}
            folderId={folderId}
            handleSelectFile={props.onSelectFile}
            isModal={true}
          />
        </Row>
      </ModalBody>
    </Modal>
  )
}

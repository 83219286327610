import React, { useEffect, useState } from "react"
import { getPostTasks, deleteTask } from "api/posts"
import ResultHandler from "components/ResultHandler"
import AddTaskModal from "../tasks/AddTaskModal"
import EditTaskModal from "../tasks/EditTaskModal"
import DeleteModal from "components/Common/DeleteModal"
import { useGetInstence } from "hooks/useGetInstence"
import { Button } from "reactstrap"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import Task from "./Task"
export default function TasksList({ postId, setNotDoneTasks }) {
  const { t } = useTranslation()

  // getting post tasks

  const [tasks, setTasks] = useState(null)

  const {
    tasksLoading,
    tasksError,
    tasksSuccess,
    setPayload: setTasksPayload,
  } = useGetInstence(getPostTasks, postId, response => {
    setTasks(response.data.data.tasks)
  })

  // add task modal

  const [addTaskShow, setAddTaskShow] = useState(false)
  const [task, setTask] = useState(null)
  function closeTasksModal() {
    setAddTaskShow(false)
    setEditTaskShow(false)
    setDeleteTaskShow(false)
    setTask(null)
  }
  // edit task modal

  const [editTaskShow, setEditTaskShow] = useState(false)

  // delete task modal

  const [deleteTaskShow, setDeleteTaskShow] = useState(false)

  const {
    setData: setDeleteTask,
    success: deleteSuccess,
    loading: deleteLoading,
  } = useResponse(deleteTask, () => {
    setTasksPayload({})
    setDeleteTaskShow(false)
  })

  function handleDeleteTask() {
    setDeleteTask({ type: "add", data: task.id })
  }

  // function to get not done tasks

  useEffect(() => {
    if (tasks) {
      let len = 0

      tasks.map(t => {
        len += 1

        if (t.subTasks) {
          len += t.subTasks.length
        }
      })
      setNotDoneTasks(len)
    }
  }, [tasks])

  return (
    <>
      {tasks && (
        <>
          <ResultHandler loading={tasksLoading} error={tasksError} />
          {!tasksLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  setAddTaskShow(true)
                }}
                type={"button"}
              >
                {t("btns.Add")}
              </Button>
            </div>
          )}
          <div style={{ marginTop: "20px" }}>
            {tasks.map(task => {
              return (
                <div className="taskGroup" key={"task-" + task.id}>
                  <Task
                    task={task}
                    setTask={setTask}
                    setDeleteTaskShow={setDeleteTaskShow}
                    setAddTaskShow={setAddTaskShow}
                    setEditTaskShow={setEditTaskShow}
                  />

                  {task.subTasks.map(subT => {
                    return (
                      <Task
                        isSub={true}
                        key={"subtask-" + subT.id}
                        task={subT}
                        setTask={setTask}
                        setDeleteTaskShow={setDeleteTaskShow}
                        setAddTaskShow={setAddTaskShow}
                        setEditTaskShow={setEditTaskShow}
                      />
                    )
                  })}
                </div>
              )
            })}
          </div>
        </>
      )}

      {addTaskShow && (
        <AddTaskModal
          show={addTaskShow}
          postId={postId}
          parent_id={task ? task.id : null}
          onCloseClick={() => {
            closeTasksModal()
          }}
          reloadTasks={() => {
            setTasksPayload({})
          }}
        />
      )}
      {task && (
        <>
          <DeleteModal
            show={deleteTaskShow}
            onCloseClick={() => {
              closeTasksModal()
            }}
            onDeleteClick={handleDeleteTask}
          />

          <EditTaskModal
            taskData={task}
            show={editTaskShow}
            onCloseClick={() => {
              closeTasksModal()
            }}
            reloadTasks={() => {
              setTasksPayload({})
            }}
          />
        </>
      )}
    </>
  )
}

export const PERMISSIONS = {
  ADMIN_VIEW: "permission-admin-view",
  ADMIN_EDIT: "permission-admin-edit",
  ADMIN_DELETE: "permission-admin-delete",
  ADMIN_CREATE: "permission-admin-create",
  ADMIN_STATUS: "permission-admin-change-status",
  MY_ADMIN_VIEW: "permission-admin-view-myAdmins",
  AUDIT_VIEW: "permission-audits-view",

  STAGE_VIEW: "permission-stage-view",
  STAGE_EDIT: "permission-stage-edit",
  STAGE_DELETE: "permission-stage-delete",
  STAGE_CREATE: "permission-stage-create",

  VIDEO_VIEW: "permission-videos-view",
  VIDEO_EDIT: "permission-videos-edit",
  VIDEO_DELETE: "permission-videos-delete",
  VIDEO_CREATE: "permission-videos-create",

  POLL_VIEW: "permission-poll-view",
  POLL_EDIT: "permission-poll-edit",
  POLL_DELETE: "permission-poll-delete",
  POLL_CREATE: "permission-poll-create",

  STADIUM_VIEW: "",
  STADIUM_EDIT: "",
  STADIUM_DELETE: "",
  STADIUM_CREATE: "",

  KICKER_VIEW: "permission-kicker-view",
  KICKER_EDIT: "permission-kicker-edit",
  KICKER_DELETE: "permission-kicker-create",
  KICKER_CREATE: "permission-kicker-delete",

  CATEGORY_VIEW: "permission-category-view",
  CATEGORY_EDIT: "permission-category-edit",
  CATEGORY_DELETE: "permission-category-delete",
  CATEGORY_CREATE: "permission-category-create",

  ROLE_VIEW: "permission-role-view",
  ROLE_EDIT: "permission-role-edit",
  ROLE_DELETE: "permission-role-delete",
  ROLE_CREATE: "permission-role-create",

  PROGRAM_VIEW: "permission-program-view",
  PROGRAM_EDIT: "permission-program-edit",
  PROGRAM_DELETE: "permission-program-delete",
  PROGRAM_CREATE: "permission-program-create",

  PRESENTER_VIEW: "permission-presenter-view",
  PRESENTER_EDIT: "permission-presenter-edit",
  PRESENTER_DELETE: "permission-presenter-delete",
  PRESENTER_CREATE: "permission-presenter-create",

  POSTS_CREATE: "permission-post-create",

  POSTS_EDIT_ALL: "permission-post-edit-all",
  POSTS_EDIT_MINE: "permission-post-edit-myPosts",

  POSTS_DELETE_ALL: "permission-post-delete-all",
  POSTS_DELETE_MINE: "permission-post-delete-myPosts",

  POSTS_VERSIONS_ALL: "permission-post-view-versions-all",
  POSTS_VERSIONS_MINE: "permission-post-view-versions-myPosts",

  POSTS_REVERT_ALL: "permission-post-revert-versions-myPosts",
  POSTS_REVERT_MINE: "permission-post-revert-versions-all",

  POST_ASSIGN_AUTHOR: "permission-post-assign-author",
  POST_REMOVE_AUTHOR: "permission-post-remove-author",

  URGENT_POSTS: "permission-",

  FILES_CREATE: "permission-media-create",
  FILES_DELETE: "permission-media-delete",
  SETTINGS_VIEW: "permission-settings-view",
  URGENT_VIEW_ALL: "permission-urgent-post-view-all",
  URGENT_VIEW_MINE: "ermission-urgent-post-view-myPosts",
  URGENT_CREATE: "permission-urgent-post-create",

  MONITOR_VIEW: "permission-monitor-view-all",
  TRASH_VIEW: "permission-post-view-trash",

  CREATOR_VIEW: "permission-creator-view",
  CREATOR_CREATE: "permission-creator-create",
  CREATOR_EDIT: "permission-creator-edit",
  CREATOR_DELETE: "permission-creator-delete",

  ARTICLE_VIEW: "permission-article-view",
  ARTICLE_CREATE: "permission-article-create",
  ARTICLE_EDIT: "permission-article-edit",
  ARTICLE_DELETE: "permission-article-delete",
  ARTICLE_PUBLISH: "permission-article-publish",
}

import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap"

export default function SlugInput({ validation, source }) {
  const { t } = useTranslation()

  function generateSlug() {
    validation.setFieldValue(
      "slug",
      source
        .replace(/[0-9]/g, "")
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
        .replace(/\s+/g, "-")
    )
  }


  return (
    <FormGroup className="mb-3">
      <Label>{t("forms.Slug")}</Label>
      <InputGroup>
        <InputGroupText onClick={generateSlug}>
          <i className="bx bx-pencil"></i>
        </InputGroupText>
        <Input
          value={validation.values.slug}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          name="slug"
          placeholder={t("forms.Slug")}
          type="text"
          className="form-control"
          invalid={
            validation.touched.slug && validation.errors.slug ? true : false
          }
        />
        {validation.touched.slug && validation.errors.slug ? (
          <FormFeedback type="invalid" style={{ width: "100%" }}>
            {validation.errors.slug}
          </FormFeedback>
        ) : null}
      </InputGroup>
    </FormGroup>
  )
}

import { myAxios } from "./axios"

export function getKickers(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      name: data ? data.txt : null,
      categoryId: data ? data.id : null,
    },
  }
  return myAxios.get("/kickers", config)
}

export function getKicker(id) {
  return myAxios.get("/kickers/" + id)
}

export function getCategoryKickers(id) {
  return myAxios.get("/categories/" + id + "/kickers")
}

export function addKicker(data) {
  return myAxios.post("/kickers/create", data)
}

export function editKicker(id, data) {
  return myAxios.post("/kickers/" + id + "/update", data)
}

export function deleteKicker(id) {
  return myAxios.post("/kickers/" + id + "/delete")
}

import React, { useState } from "react"
import DeleteModal from "components/Common/DeleteModal"
import { usePagination } from "hooks/usePagination"
import ResultHandler from "components/ResultHandler"
import PaginationHandler from "components/PaginationHandler"
import TableHeaderHandler from "components/TableHeaderHandler"
import { PERMISSIONS } from "api/permissions"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import TableAction from "components/TableAction"
import EmptyData from "components/EmptyData"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { deleteStadium, getStadiums } from "api/stadiums"
import CustomImage from "components/CustomImage"

export default function StadiumsTable() {
  const { t } = useTranslation()

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getStadiums)

  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }

  const [deletingStadium, setDeletingStadium] = useState(null)

  const { setData } = useResponse(deleteStadium, () => {
    setShow(false)
    setDeletingStadium(null)
    setPayload({})
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingStadium })
  }

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.STADIUM_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl="/add-stadium"
      />

      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                <Th style={{ width: "20%" }}>{t("forms.Image")}</Th>
                <Th style={{ width: "50%" }}>{t("forms.Name")}</Th>
                <Th style={{ width: "30%" }}>{t("tables.Action")}</Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <Tbody>
                {response.data.stadiums.map(row => {
                  return (
                    <Tr key={row.id}>
                      <Td>
                        <CustomImage src={row.image_url} width="40px" height="40px" />
                      </Td>
                      <Td>{row.name}</Td>
                      <Td>
                        <TableAction
                          hasEdit={true}
                          hasDelete={true}
                          editPermission={PERMISSIONS.STADIUM_EDIT}
                          deletePermission={PERMISSIONS.STADIUM_DELETE}
                          type="stadium"
                          id={row.id}
                          setShow={setShow}
                          setDeleteIntitiy={setDeletingStadium}
                        />
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          {!loading && (
            <EmptyData
              empty={response.data && response.data.stadiums.length === 0}
              msg="No Stadiums Found."
            />
          )}
          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />

          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          <ResultHandler error={error} loading={loading} />
        </div>
      </div>
    </>
  )
}

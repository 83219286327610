import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody, Container, Form } from "reactstrap"
import { useFormik } from "formik"

import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import FormButtonHandler from "components/FormButtonHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"

import KickerForm from "./KickerForm"
import useCustomValidation from "pages/dashboard/validationSchema"
import { addKicker } from "api/kickers"

const AddKicker = () => {
  const { t } = useTranslation()
  const { kickerSchema } = useCustomValidation()
  let navigate = useHistory()

  const { setData, loading, error, success } = useResponse(addKicker, () => {
    navigate.push("/kickers")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      category: null,
      image_url: "",
      language: "ar",
      slug: ""
    },
    validationSchema: kickerSchema,
    onSubmit: values => {
      let data = {
        ...values,
        categoryId: values.category.value,
      }
      setData({ data, type: "add" })
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Kickers")}
            breadcrumbItem={t("btns.Add") + " " + t("pages.Kicker")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <KickerForm validation={validation} />

                    <FormButtonHandler btnTxt="Add" loading={loading} />

                    <ResultHandler
                      error={error}
                      success={success}
                      successMsg={"Created Successfully!"}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddKicker

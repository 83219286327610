import React, { useState } from "react"

import { Row, Col, Form, Button } from "reactstrap"
import { useFormik } from "formik"
import { Modal, ModalBody } from "reactstrap"

import ResultHandler from "components/ResultHandler"
import FormButtonHandler from "components/FormButtonHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"

import VideoForm from "./VideoForm"
import useCustomValidation from "pages/dashboard/validationSchema"
import { addVideo } from "api/videos"
import Dropzone from "react-dropzone"

const AddVideoModal = ({ show, setShow, reloadTable }) => {
  const { t } = useTranslation()
  const {videoSchema} = useCustomValidation()
  const { setData, loading, error, success } = useResponse(
    addVideo,
    reloadTable
  )

  ///// upload progress
  const [uploadProgress, setUploadProgress] = useState(null)
  function handleUploadProgress(progressEvent){
    setUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100))
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: "",
    },
    validationSchema: videoSchema,
    onSubmit: values => {
      let data = new FormData()

      data.append("title", values.title)
      data.append("file", file)
      setData({ data: {data, handleUploadProgress}, type: "add" })
    },
  })

  // form data
  const [file, setFile] = useState(null)

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        setShow(false)
      }}
      centered={true}
    >
      <ModalBody className="py-5 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <h2>{t("btns.Add") + " " + t("pages.Video")}</h2>
            </div>
          </Col>
          <Col xl="12">
            <Form
              className="needs-validation"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <VideoForm isAdd validation={validation} />
              <div className={"mb-3"}>
                <Dropzone
                  maxFiles={1}
                  multiple={false}
                  onDrop={acceptedFiles => {
                    setFile(...acceptedFiles)
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          {!file && (
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          )}
                          {file && (
                            <i className="display-4 text-muted bx bx-check-double" />
                          )}
                        </div>
                        <h4>
                          {!file &&
                            t("modals.Drop files here or click to upload")}
                          {file && t("messages.Uploaded Successfully!")}
                        </h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>

              { uploadProgress &&           
                <div className="progress" style={{height: "30px", marginBottom: "20px"}}><div className="progress-bar bg-primary" style={{width: uploadProgress+'%'}}>{uploadProgress}%</div></div>
              }              

              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <FormButtonHandler type="button" handleClick={() => validation.handleSubmit()} btnTxt="Add" loading={loading} />
                <Button
                  className="btn-lg"
                  onClick={() => {
                    setShow(false)
                  }}
                >
                  {t("btns.Cancel")}
                </Button>
              </div>
              <ResultHandler
                error={error}
                success={success}
                successMsg={"Created Successfully!"}
              />
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default AddVideoModal

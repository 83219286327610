import React from "react"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ProgramsTable from "./ProgramsTable"
import { useTranslation } from "react-i18next"

export default function ProgramsList() {

  const { t } = useTranslation()
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.Programs")} />
        <ProgramsTable />
      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Board, { moveCard } from "../../../packages/react-kanban/index"
import { useTranslation } from "react-i18next"
import StageCard from "./StageCard"
import { usePagination } from "hooks/usePagination"
import { deleteStage, getStages, updateOrders } from "api/stages"
import DeleteModal from "components/Common/DeleteModal"
import ResultHandler from "components/ResultHandler"
import EmptyData from "components/EmptyData"
import { useResponse } from "hooks/useResponse"
import FormButtonHandler from "components/FormButtonHandler"

export default function StagesList() {
  const { t } = useTranslation()

  const [isEditing, setIsEditing] = useState(false)

  const { response, loading, error, setPayload } = usePagination(getStages)

  const [board, setBoard] = useState(null)

  useEffect(() => {
    if (response.data) {
      setBoard({
        columns: [
          {
            id: 1,
            title: "",
            cards: response.data.data.stages,
          },
        ],
      })
    }
  }, [response])

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(board, source, destination)
    setBoard(updatedBoard)
    setIsEditing(true)
  }

  // deleting

  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }

  const [deletingStage, setDeletingStage] = useState(null)

  const { setData } = useResponse(deleteStage, () => {
    setShow(false)
    setDeletingStage(null)
    setPayload({})
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingStage })
  }

  // update orders

  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    setData: updateStages,
  } = useResponse(updateOrders, () => {
    setIsEditing(false)
  })

  function handleUpdate() {
    updateStages({
      type: "add",
      data: { stages: board.columns[0].cards.map(c => c.id) },
    })
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.Stages")} />
        <ResultHandler error={error} loading={loading} />
        <ResultHandler
          error={updateError}
          successMsg="Updated Successfully!"
          success={updateSuccess}
        />
        {!loading && (
          <EmptyData
            empty={response.data && response.data.data.stages.length === 0}
            msg="No Stages Found."
          />
        )}

        {board && !loading && board.columns[0].cards.length > 0 && (
          <Board
            onCardDragEnd={handleCardMove}
            renderCard={content => (
              <StageCard
                setShow={value => {
                  setShow(value)
                }}
                setDeleteIntitiy={value => {
                  setDeletingStage(value)
                }}
                content={content}
              />
            )}
            disableColumnDrag
          >
            {board}
          </Board>
        )}

        {isEditing && (
          <FormButtonHandler
            type="button"
            handleClick={handleUpdate}
            loading={updateLoading}
            btnTxt="Edit"
          />
        )}
      </div>

      <DeleteModal
        show={show}
        onDeleteClick={onDeleteClick}
        onCloseClick={onCloseClick}
      />
    </div>
  )
}

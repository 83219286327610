import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody, Container, Form } from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import { editPoll, getPoll } from "api/polls"
import FormButtonHandler from "components/FormButtonHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "hooks/useGetInstence"
import PollForm from "./PollForm"
import useCustomValidation from "pages/dashboard/validationSchema"

const EditPoll = () => {
  let navigate = useHistory()
  const { pollSchema } = useCustomValidation()

  const { t } = useTranslation()

  const params = useParams()
  const [editedPoll, setEditedPoll] = useState(null)

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getPoll,
    params.id,
    response => {
      setEditedPoll(response.data.data.poll)
    }
  )

  const { setData, loading, error, success } = useResponse(editPoll, () => {
    navigate.push("/polls")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      text: editedPoll ? editedPoll.text : "",
      cover_url: editedPoll ? editedPoll.cover_url : "",
      competitors: editedPoll ? editedPoll.competitors : [],
      resultVisibility: editedPoll ? editedPoll.resultVisibility : false,
    },
    validationSchema: pollSchema,
    onSubmit: values => {
      let data = {
        ...values,
      }
      setData({ type: "edit", id: params.id, data })
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Polls")}
            breadcrumbItem={t("btns.Edit") + " " + t("pages.Poll")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedPoll && (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <PollForm validation={validation} />

                      <FormButtonHandler btnTxt="Edit" loading={loading} />
                    </Form>
                  )}

                  <ResultHandler
                    loading={instenceLoading}
                    error={error}
                    success={success}
                    successMsg={"Updated Successfully!"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditPoll

import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Input,
  Label,
  Card,
  FormGroup,
  FormFeedback,
} from "reactstrap"

import { useTranslation } from "react-i18next"

const VideoForm = ({ validation, isAdd }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <Col md="12">
        <FormGroup className="mb-3">
          <Label>{t("forms.Title")}</Label>
          <Input
            name="title"
            placeholder={t("forms.Title")}
            type="text"
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.title || ""}
            invalid={
              validation.touched.title && validation.errors.title ? true : false
            }
          />
          {validation.touched.title && validation.errors.title ? (
            <FormFeedback type="invalid">
              {validation.errors.title}
            </FormFeedback>
          ) : null}
        </FormGroup>
      </Col>
    </Row>
  )
}

export default VideoForm

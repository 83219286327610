import React, { useEffect, useRef, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useTranslation } from "react-i18next"
import FiltersSidebar from "./FiltersSidebar"
import "./Monitor.css"
import { usePagination } from "hooks/usePagination"
import { getMonitorPosts } from "api/posts"
import moment from "moment"
import MonitorPostCard from "./MonitorPostCard"
import InfiniteScroll from "react-infinite-scroller"
import { pusherClient } from "utils/pusherClient"
import ResultHandler from "components/ResultHandler"

export default function MonitorPusher() {
  const { t } = useTranslation()
  const [allPosts, setAllPosts] = useState([])

  const {
    setMeta,
    loading: postsLoading,
    error: postsError,
    response: postsResponse,
    setPayload: setPostsFilters,
    page,
    meta,
    totalPages,
  } = usePagination(getMonitorPosts, null, false)

  

  /*useEffect(() => {
    if (
      postsResponse &&
      postsResponse?.data &&
      postsResponse?.data?.posts?.length > 0 
      //&& !allPosts.map(p => p.id).includes(postsResponse.data.posts[0].id)
    ) {
      setAllPosts(posts => ([...posts, ...postsResponse.data.posts]))
    }
  }, [postsResponse])*/

  function getDiffDates(newD, oldD) {
    moment.relativeTimeThreshold('ss', 60);

    return moment.duration(moment(oldD).diff(newD)).humanize()
  }

  const [stages, setStages] = useState(null)

  ////// infinity

  const lastRef = useRef()

  // OBSERVER

  useEffect(() => {
    const callback = (observedElements) => {
      if(observedElements[0].isIntersecting){
        setMeta(m => ({ ...m, page: m.page + 1 }))
      }
    }
    const observer = new IntersectionObserver(callback, {})

      if(!postsLoading && lastRef && lastRef.current){
          observer.observe(lastRef.current)
      }

      return () => {
        if(observer && lastRef && lastRef.current)
          observer.unobserve(lastRef.current)
        }
  }, [lastRef, postsLoading])

  /// pusher

  const channelName = "post-created"

  function handlePushNewPost(newPostData) {
    setAllPosts(a => [newPostData, ...a])
  }

  useEffect(() => {

    const channel = pusherClient.subscribe(channelName)

    channel.bind(channelName, newPostData => {
      handlePushNewPost(newPostData)
    })

    return () => {
      pusherClient.unsubscribe(channelName)
    }
  }, [])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.Home")} />
        <Row className="monitor">
          <Col md="9" >

              {/*allPosts.map(p => {
                return (
                  <MonitorPostCard
                    key={p.id}
                    stages={stages}
                    getDiffDates={getDiffDates}
                    p={p}
                  />
                )
              })*/}
              
              <ResultHandler loading={postsLoading} />

              <div style={{backgroundColor: "transparent", width: "100%", height: "10px", marginBottom: 20}} ref={lastRef}></div>

          </Col>
          <Col md="3">
            <FiltersSidebar
              setStages={setStages}
              stages={stages}
              setFilters={f => {
                setAllPosts([])
                setMeta({ page: 1, payload: f })
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

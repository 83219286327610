import { deletePost, getArchive } from "api/posts"
import React, { useEffect, useState } from "react"
import { usePagination } from "../../../hooks/usePagination"
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import ResultHandler from "components/ResultHandler"
import PaginationHandler from "components/PaginationHandler"
import TableHeaderHandler from "components/TableHeaderHandler"
import { useTranslation } from "react-i18next"
import StageSelect from "./StageSelect"
import EmptyData from "components/EmptyData"
import { useHistory } from "react-router-dom"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useResponse } from "hooks/useResponse"
import DeleteModal from "components/Common/DeleteModal"
import TableAction from "components/TableAction"
import ArchiveAction from "./ArchiveAction"

export default function ArchiveTable() {
  const { t } = useTranslation()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getArchive)

  const navigate = useHistory()

  // move

  const [moveSuccess, setMoveSuccess] = useState(false)

  useEffect(() => {
    if (moveSuccess) {
      setTimeout(() => {
        setMoveSuccess(false)
      }, 400)
    }
  }, [moveSuccess])

  // delete

  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }

  const { setData } = useResponse(deletePost, () => {
    setShow(false)
    setPayload({ txt: "" })
  })

  const [deletingPost, setDeletingPost] = useState(null)

  function onDeleteClick() {
    setData({ type: "add", data: deletingPost })
  }

  return (
    <>
      <TableHeaderHandler setPage={setPage} setPerPage={setPerPage} addUrl="" />

      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                <Th style={{ width: "40%" }}>{t("forms.Title")}</Th>
                <Th style={{ width: "30%" }}>{t("btns.Move")}</Th>

                <Th style={{ width: "30%" }}>{t("tables.Action")}</Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <Tbody>
                {response.data.posts.map(row => {
                  return (
                    <Tr key={row.id}>
                      <Td>{row.title}</Td>
                      <Td>
                        <StageSelect
                          setSuccess={() => {
                            setMoveSuccess(true)
                          }}
                          reload={() => {
                            setPayload({})
                          }}
                          postId={row.id}
                        />
                      </Td>
                      <Td
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ArchiveAction row={row} />

                        {row.canEdit && (
                          <Button
                            color="danger"
                            onClick={() => {
                              setShow(true)
                              setDeletingPost(row.id)
                            }}
                          >
                            <i className="bx bx-trash font-size-16 align-middle" />
                          </Button>
                        )}

                        {row.canEdit && (
                          <Button
                            color="primary"
                            onClick={() => {
                              navigate.push("edit-post/" + row.id)
                            }}
                          >
                            <i className="bx bx-edit font-size-16 align-middle me-1" />
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          <ResultHandler
            error={error}
            loading={loading}
            success={moveSuccess}
            successMsg="Moved Successfully!"
          />

          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />

          {!loading && (
            <EmptyData
              empty={response.data && response.data.posts.length === 0}
              msg="No Posts Found."
            />
          )}

          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </>
  )
}

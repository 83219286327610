import { getPresenter } from "api/presenters"
import CustomImage from "components/CustomImage"
import ResultHandler from "components/ResultHandler"
import { useGetInstence } from "hooks/useGetInstence"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

export default function PresenterPreview({ }) {
  const params = useParams()
  const [presenter, setPresenter] = useState(null)

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getPresenter,
    params.id,
    response => {
      setPresenter(response.data.data.presenter)
    }
  )
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {presenter && (
            <Card>
              <div
                className="bg-primary bg-soft"
                style={{ height: "90px" }}
              ></div>
              <CardBody>
                <div
                  className="avatar-md profile-user-wid mb-3"
                  style={{ marginTop: "-55px" }}
                >
                  <CustomImage
                    style={{
                      width: "70px",
                      height: "70px",
                      objectFit: "cover",
                    }}
                    alt=""
                    className="img-thumbnail rounded-circle"
                    src={presenter.image_url}
                  />
                </div>
                <h5 className="font-size-15">{presenter.name}</h5>
                <p className="text-muted mb-0">{presenter.summary}</p>
              </CardBody>
            </Card>
          )}
          <ResultHandler loading={instenceLoading} error={instenceError} />
        </Container>
      </div>
    </React.Fragment>
  )
}

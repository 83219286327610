import React, { useState } from "react"
import { Button, Card, CardBody, Col, Modal, ModalBody, Row, Tooltip } from "reactstrap"
import { useTranslation } from "react-i18next"
import ResultHandler from "components/ResultHandler"
import { usePagination } from "hooks/usePagination"
import { getVideos } from "api/videos"
import PaginationHandler from "components/PaginationHandler"
import AddVideoModal from "./AddVideoModal"
import VideoCard from "./VideoCard"

export default function VideosModal({ show, onCloseClick, handleSelectVideo }) {
  const { t } = useTranslation()

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getVideos)

  const [addShow, setAddShow] = useState(false)

  return (
    <Modal style={{ maxWidth: "80%" }} isOpen={show} toggle={onCloseClick}>
      <ModalBody className="py-5 px-5">
        <i
          onClick={onCloseClick}
          style={{
            position: "absolute",
            top: "20px",
            right: "40px",
            fontSize: "30px",
            cursor: "pointer",
          }}
          className="mdi mdi-close"
        ></i>
        <Row>
          <Col lg={12}>
            <div className="text-center mb-3">
              <h2>{t("pages.Videos")}</h2>
              <div className="d-flex" style={{margin: "auto", width: "fit-content"}}>
                <Button
                  color="info"
                  onClick={() => {
                    setPayload({})
                  }}
                  style={{marginLeft: "10px"}}
                >
                  {t("btns.Refetch")}
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setAddShow(true)
                  }}
                >
                  {t("btns.Add")}
                </Button>
              </div>
              
            </div>
          </Col>
        </Row>
        <Row>
          {response.data && !loading && (
            <>
              {response.data.videos.map(row => {
                return (
                  <Col xl={2} key={row.id}>
                    <VideoCard video={row} onCloseClick={onCloseClick} handleSelectVideo={handleSelectVideo} />
                  </Col>
                )
              })}
              <PaginationHandler
                page={page}
                setPage={setPage}
                totalPages={totalPages}
              />
            </>
          )}
          {addShow && (
            <AddVideoModal
              show={addShow}
              setShow={setAddShow}
              reloadTable={() => {
                setPayload({})
                setAddShow(false)
              }}
            />
          )}
          <ResultHandler loading={loading} error={error} />
        </Row>
      </ModalBody>
    </Modal>
  )
}

import { useEffect, useState } from "react"

export const useResponse = (api, onSuccess) => {
  let [loading, setLoading] = useState(false)
  let [error, setError] = useState("")
  let [success, setSuccess] = useState(false)

  function setData(data) {
    if (data) {
      setLoading(true)
      setError("")
      let prom = null
      if (data.type === "add") {
        prom = api(data.data)
      } else {
        prom = api(data.id, data.data)
      }
      prom
        .then(({ data }) => {
          setSuccess(true)
          onSuccess && onSuccess(data)
          setLoading(false)
          setError("")
        })
        .catch(error => {
          const response = error.response
          console.error(error)

          setSuccess(false)
          setLoading(false)
          if (response) {
            setError(response.data.errors[0])
          }
        })
    }
  }

  return { loading, error, success, setLoading, setError, setSuccess, setData }
}

import React from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

export default function TableAction(props) {
  const { t } = useTranslation()
  const navigate = useHistory()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  return (
    <>
      {props.hasEdit &&
        localStorage.getItem("permissions").includes(props.editPermission) && (
          <Button
            color="primary"
            onClick={() => {
              navigate.push("edit-" + props.type + "/" + props.id)
            }}
          >
            <span className={isMobile ? "responsive-button-label" : ""}>
              {t("btns.Edit")}
            </span>

            <i className="bx bx-edit font-size-16 align-middle me-1" />
          </Button>
        )}

      {localStorage.getItem("permissions").includes(props.deletePermission) &&
        props.hasDelete && (
          <Button
            color="danger"
            onClick={() => {
              props.setShow(true)
              props.setDeleteIntitiy(props.id)
            }}
          >
            <i className="bx bx-trash font-size-16 align-middle" />
          </Button>
        )}
    </>
  )
}

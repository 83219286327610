import { revertPostVersion } from "api/posts"
import React, { useEffect } from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"

export default function RevertBtn(props) {
  const { t } = useTranslation()

  const {
    loading: revertLoading,
    error,
    success,
    setData,
  } = useResponse(revertPostVersion)

  useEffect(() => {
    if (success) {
      props.getVersions()
      props.putError("")
      props.setRevertSuccess(true)
    }
    if (error) {
      props.putError(error)
      props.setRevertSuccess(false)
    }
  }, [error, success])

  return (
    <>
      {!revertLoading && (
        <Button
          onClick={() => {
            setData({ type: "edit", id: props.postId, data: props.versionId })
          }}
          color="info"
        >
          {t("btns.Revert")}
        </Button>
      )}
      {revertLoading && (
        <Button type="button">
          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
          {t("btns.Loading")}
        </Button>
      )}
    </>
  )
}

import React, { useEffect, useState } from "react"
import {
  assignAuthors,
  assignUrgentAuthors,
  getPostAuthors,
  getUrgentPostAuthors,
} from "api/posts"
import { getAdminsDropdown } from "api/admins"

import { useGetInstence } from "hooks/useGetInstence"
import ResultHandler from "components/ResultHandler"
import Author from "./Author"
import Select from "react-select"
import { usePagination } from "hooks/usePagination"
import { useResponse } from "hooks/useResponse"
import { PERMISSIONS } from "api/permissions"
import { useTranslation } from "react-i18next"

export default function AuthorsList({
  postId,
  handleRemove,
  handleAdd,
  urgent,
}) {
  const { t } = useTranslation()

  // get authors
  const [authors, setAuthors] = useState(null)

  const {
    instenceLoading,
    instenceError,
    setPayload: reloadData,
  } = useGetInstence(
    urgent ? getUrgentPostAuthors : getPostAuthors,
    postId,
    res => {
      setAuthors(res.data.data.authors)
    }
  )

  // admins drop down

  const [newAuthor, setNewAuthor] = useState(null)

  const [searchTxt, setSearchTxt] = useState("")
  const {
    response: authorsResponse,
    error: authorsError,
    loading: authorsLoading,
    setPayload: setAuthorsPayload,
  } = usePagination(getAdminsDropdown) //add

  // assign new admin

  const { setData: assignAuthor, success: assignSuccessfully } = useResponse(
    urgent ? assignUrgentAuthors : assignAuthors,
    () => {
      reloadData({})
      setNewAuthor(null)

      if (handleAdd) {
        handleAdd()
      }
    }
  )

  function assignAdmin() {
    if (newAuthor) {
      const data = {
        post_id: postId,
        author_id: newAuthor.value,
      }
      assignAuthor({ type: "add", data })
    }
  }

  useEffect(() => {
    setAuthorsPayload({ txt: searchTxt })
  }, [searchTxt])

  return (
    <>
      <ResultHandler loading={instenceLoading} error={instenceError} />
      {!instenceLoading &&
        !instenceError &&
        localStorage
          .getItem("permissions")
          .includes(PERMISSIONS.POST_ASSIGN_AUTHOR) && (
          <div className="d-flex" style={{ width: "100%" }}>
            {authors && (
              <div style={{ width: "100%" }}>
                <Select
                  placeholder={t("forms.Select")+" ..."}
                  value={newAuthor}
                  onChange={data => {
                    setNewAuthor(data)
                  }}
                  isMulti={false}
                  inputValue={searchTxt}
                  onInputChange={setSearchTxt}
                  options={authorsResponse?.data?.admins
                    .filter(admin => !authors.map(a => a.id).includes(admin.id))
                    .map(c => {
                      return {
                        label:
                          c.username +
                          (c.Role ? " ( " + c.Role.name + " )" : ""),
                        value: c.id,
                      }
                    })}
                />
              </div>
            )}
            <button
              style={{ float: "right" }}
              onClick={assignAdmin}
              className="btn btn-primary"
              type="button"
              id="inputGroupFileAddon04"
            >
              {t("btns.Assign")}
            </button>
          </div>
        )}

      <div style={{ marginTop: "15px" }}>
        {authors &&
          authors.map(author => {
            return (
              <Author
                urgent={urgent}
                handleRemove={handleRemove}
                key={"author-" + author.id}
                author={author}
                reloadData={reloadData}
                postId={postId}
              />
            )
          })}
      </div>
    </>
  )
}

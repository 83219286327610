import i18next from "i18next"
import { myAxios } from "./axios"

export function login(data) {
  const config = {
    headers: {
      'Accept-language': i18next.language
    }
  }
  return myAxios.post("/admin/login", data, config)
}

export function twoFactorAuth(data) {
  const config = {
    headers: {
      'Accept-language': i18next.language
    }
  }
  return myAxios.post("/admin/2fa-check", data, config)
}


export function create2FA() {
  return myAxios.post("/admin/get-2fa-secret")
}

export function enable2FA(data) {
  return myAxios.post("/admin/enable-2fa",data)
}

export function disable2FA(data) {
  return myAxios.post("/admin/disable-2fa",data)
}
import { emptyTrash, getTrash, restorePost, trashPostDelete } from "api/posts"
import React, { useState } from "react"
import { usePagination } from "../../../../hooks/usePagination"
import {
  Button
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import ResultHandler from "components/ResultHandler"
import PaginationHandler from "components/PaginationHandler"
import TableHeaderHandler from "components/TableHeaderHandler"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import EmptyData from "components/EmptyData"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

export default function TrashTable() {
  const { t } = useTranslation()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const [postId, setPostId] = useState(null)

  // get trash
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getTrash)

  const [emptyShow, setEmptyShow] = useState(false)
  const [deleteShow, setDeleteShow] = useState(false)
  const [restoreShow, setRestoreShow] = useState(false)

  function onCloseClick() {
    setEmptyShow(false)
    setDeleteShow(false)
    setRestoreShow(false)
    setSelectedPosts([])
  }

  /** handle empty  */
  const {
    loading: emptyLoading,
    error: emptyError,
    success: emptySuccess,
    setData: setEmpty,
  } = useResponse(emptyTrash, () => {
    setPayload({ txt: "" })
    onCloseClick()
  })

  function handleEmptyTrash() {
    setEmpty({ type: "add", data: true })
  }

  /** restore post */
  const {
    loading: restoreLoading,
    error: restoreError,
    success: restoreSuccess,
    setData: setRestore,
  } = useResponse(restorePost, () => {
    setPayload({ txt: "" })
    onCloseClick()
  })

  function handleRestore() {
    setRestore({ type: "add", data: { id: postId } })
  }

  /** delete post */
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
    setData: setDelete,
  } = useResponse(trashPostDelete, () => {
    setPayload({ txt: "" })
    onCloseClick()
    setSelectedPosts([])
  })

  function handleConfirmDelete() {
    setDelete({ type: "add", data: { posts: selectedPosts } })
  }

  /** bulk delete */
  const [selectedPosts, setSelectedPosts] = useState([])

  function handleSelectToDelete(id) {
    if (!selectedPosts.includes(id)) {
      setSelectedPosts([...selectedPosts, id])
    } else {
      setSelectedPosts(
        selectedPosts.filter(p => {
          return p != id
        })
      )
    }
  }

  return (
    <>
      {response.data && response.data.posts.length > 0 && (
        <>
          <TableHeaderHandler setPage={setPage} setPerPage={setPerPage} />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <>
              <Button
                onClick={() => {
                  setEmptyShow(true)
                }}
              >
                <i className="bx bx-trash font-size-16 align-middle me-1" />
                {t("btns.Empty")}
              </Button>
              {selectedPosts.length > 0 && (
                <Button
                  onClick={() => {
                    setDeleteShow(true)
                  }}
                >
                  <i className="bx bx-trash font-size-16 align-middle me-1" />
                  {t("btns.Delete Selected")}
                </Button>
              )}
            </>
          </div>
        </>
      )}
      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                <Th style={{ width: "5%" }}>
                  {isMobile && t('forms.Select')}
                </Th>
                <Th style={{ width: "55%" }}>{t("forms.Title")}</Th>
                <Th style={{ width: "20%" }}>
                  {t("pages.Stage")}
                </Th>

                <Th style={{ width: "20%" }}>
                  {t("tables.Action")}
                </Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <tbody>
                {response.data.posts.map(row => {
                  return (
                    <Tr key={row.id}>
                      <Td>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={() => {
                            handleSelectToDelete(row.id)
                          }}
                        />
                      </Td>
                      <Td>{row.title}</Td>
                      <Td>
                        {row.Stage ? row.Stage.name : t("Draft")}
                      </Td>
                      <Td>
                        <Button
                          color="primary"
                          onClick={() => {
                            setRestoreShow(true)
                            setPostId(row.id)
                          }}
                        >
                          <span className={isMobile ? "responsive-button-label" : ""}>
                            {t("btns.Restore")}
                          </span>
                          <i className="mdi mdi-undo font-size-16 align-middle me-1" />
                        </Button>

                        <Button
                          color="danger"
                          onClick={() => {
                            setDeleteShow(true)
                            setSelectedPosts([row.id])
                          }}
                        >
                          <span className={isMobile ? "responsive-button-label" : ""}>

                            {t("btns.Delete")}
                          </span>
                          <i className="bx bx-trash font-size-16 align-middle me-1" />
                        </Button>
                      </Td>
                    </Tr>
                  )
                })}
              </tbody>
            )}
          </Table>

          {!loading && (
            <EmptyData
              empty={response.data && response.data.posts.length === 0}
              msg="No Posts Found."
            />
          )}
          <DeleteModal
            show={emptyShow}
            onDeleteClick={handleEmptyTrash}
            onCloseClick={onCloseClick}
          />
          <DeleteModal
            show={deleteShow}
            onDeleteClick={handleConfirmDelete}
            onCloseClick={onCloseClick}
          />
          <DeleteModal
            show={restoreShow}
            onDeleteClick={handleRestore}
            onCloseClick={onCloseClick}
          />

          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          <ResultHandler error={error} loading={loading} />
        </div>
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"
import { usePagination } from "hooks/usePagination"
import ResultHandler from "components/ResultHandler"
import PaginationHandler from "components/PaginationHandler"
import TableHeaderHandler from "components/TableHeaderHandler"
import { PERMISSIONS } from "api/permissions"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import TableAction from "components/TableAction"
import EmptyData from "components/EmptyData"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { deleteArticle, getArticles, publishArticle } from "api/articles"
import { Button } from "reactstrap"

export default function ArticlesTable() {
  const { t } = useTranslation()
  const navigate = useHistory()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getArticles)

  // delete
  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }

  const [deletingArticle, setDeletingArticle] = useState(null)

  const { setData } = useResponse(deleteArticle, () => {
    setShow(false)
    setDeletingArticle(null)
    setPayload({})
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingArticle })
  }

  // publish modal
  const [post, setPost] = useState(null)
  const [publishModalShow, setPublishModalShow] = useState(false)

  const {
    loading: publishLoading,
    error: publishError,
    success: publishSuccess,
    setData: publishPost,
  } = useResponse(publishArticle, res => {
    setPayload({ txt: "" })
  })

  function handlePublish() {
    publishPost({ type: "edit", id: post.id, data: { type: post.action } })
  }

  useEffect(() => {
    if (!publishLoading) {
      setPublishModalShow(false)
    }
  }, [publishLoading])

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.ARTICLE_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl="/add-article"
      />
      <div className="table-rep-plugin">

        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                <Th style={{ width: "20%" }}>{t("forms.Title")}</Th>
                <Th style={{ width: "30%" }}>{t("pages.Creator")}</Th>
                <Th style={{ width: "30%" }}>
                  {t("tables.Action")}
                </Th>
                <Th style={{ width: "20%" }}>
                  {t("btns.Publish")}
                </Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <Tbody>
                {response.data.articles.map(row => {
                  return (
                    <Tr key={row.id}>
                      <Td>
                        {row.title}
                      </Td>
                      <Td>
                        {row.creator.name}
                      </Td>
                      <Td>
                        <TableAction
                          hasEdit={true}
                          hasDelete={true}
                          hasVersions={false}
                          editPermission={PERMISSIONS.ARTICLE_EDIT}
                          deletePermission={PERMISSIONS.ARTICLE_DELETE}
                          type="article"
                          id={row.id}
                          setShow={setShow}
                          setDeleteIntitiy={setDeletingArticle}
                        />
                      </Td>
                      <Td>
                        {localStorage
                          .getItem("permissions")
                          .includes(PERMISSIONS.ARTICLE_PUBLISH) && <>
                            {row.isPublished && (
                              <Button
                                className="btn-cancel"
                                color="primary"
                                onClick={() => {
                                  setPost({ ...row, action: "unpublish" })
                                  setPublishModalShow(true)
                                }}
                              >

                                <i className="bx bx-window-close font-size-16 align-middle" />
                                <span className={isMobile ? "responsive-button-label" : ""}>
                                  {t("btns.Unpublish")}
                                </span>
                              </Button>
                            )}
                            {!row.isPublished && (
                              <Button
                                color="primary"
                                onClick={() => {
                                  setPost({ ...row, action: "publish" })
                                  setPublishModalShow(true)
                                }}
                              >

                                <i className="bx bx-share font-size-16 align-middle" />
                                <span className={isMobile ? "responsive-button-label" : ""}>
                                  {t("btns.Publish")}
                                </span>
                              </Button>
                            )}
                          </>}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          {!loading && (
            <EmptyData
              empty={response.data && response.data.articles.length === 0}
              msg="No Articles Found."
            />
          )}

          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />

          {publishModalShow && (
            <DeleteModal
              show={publishModalShow}
              onDeleteClick={handlePublish}
              onCloseClick={() => {
                setPublishModalShow(false)
              }}
            />
          )}

          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          <ResultHandler error={publishError} />

          <ResultHandler error={error} loading={loading} />
        </div>
      </div>
    </>
  )
}


import { getVideoThumbnail } from "api/videos";
import CustomImage from "components/CustomImage";
import { useResponse } from "hooks/useResponse";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Progress, Tooltip } from "reactstrap";


export default function VideoCard({video, handleSelectVideo, onCloseClick}){
    const [img, setImg] = useState(null)
    const {loading , success, error, setData} = useResponse(getVideoThumbnail, (res) => {
        setImg(res.data?.base_link)
    })

    useEffect(() => {
        setData({type: "add", data: video.id})
    }, [video])

    const [tooltipOpen, setTooltipOpen] = useState(false)

    return(
        <>
        <Card
            id={'vid'+video.id}
            className="shadow-none border"
            onDoubleClick={() => {
                if(video.status != 'pending'){
                    handleSelectVideo(video.embed)
                    onCloseClick()
                }
            }}
        >
            <CardBody className="p-3" style={{textAlign: "center"}}>
                <div className="text-center mb-2">
                    <CustomImage
                    src={img}
                    style={{ width: "45px", height: "45px" }}
                    />
                </div>
                <h5 className="font-size-14 text-truncate text-center mb-1">
                    {video.title}
                    { video.status === 'pending' && 
                    <span 
                        style={{
                            position: "absolute",
                            left: "5px",
                            top: "5px"
                        }} 
                        className="badge badge-soft-warning"
                    >
                        {t("status.pending")}
                    </span>}
                </h5>
                {video.status === 'pending' && <Progress value={video.progress ? video.progress.replace("%",'') : 20} animated={true} color="info" />}
            </CardBody>
        </Card>
        {video.status === 'pending' && <Tooltip target={'vid'+video.id} placement="bottom" isOpen={tooltipOpen} toggle={() => setTooltipOpen(!tooltipOpen)}>
            {t("modals.Video Can't Be Selected While Status Is Pending")}
        </Tooltip>}
        </>
    )
}
import { myAxios } from "./axios"

export function getPresenters(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      name: data ? data.txt : null,
    },
  }
  return myAxios.get("/presenters", config)
}

export function getPresentersDropdown(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      name: data ? data.txt : null,
    },
  }
  return myAxios.get("/dropdown/presenters", config)
}

export function getPresenter(id) {
  return myAxios.get("/presenters/" + id)
}

export function addPresenter(data) {
  return myAxios.post("/presenters/create", data)
}

export function editPresenter(id, data) {
  return myAxios.post("/presenters/" + id + "/update", data)
}

export function deletePresenter(id) {
  return myAxios.post("/presenters/" + id + "/delete")
}

import moment from "moment"
import React from "react"
import AuthorTooltip from "../board/AuthorTooltip"

export default function VerticalTimeline(props) {
  return (
    <ul className="verti-timeline list-unstyled">
      {props.response.map((s, i) => {
        return (
          <li
            key={s.id}
            className="event-list"
            style={{
              padding: "0px 10px 20px 0px",
            }}
          >
            <div
              className="event-timeline-dot"
              style={{ transform: "translateX(50%)", right: "0px" }}
            >
              <AuthorTooltip
                direction={"right"}
                author={s.Admin}
                postId={s.id}
              />
            </div>
            <div className="d-flex">
              <div className="me-3"></div>
              <div className="flex-grow-1">
                <div>
                  <h6>{s.new_stage ? s.new_stage.name : "مسودة"}</h6>
                  <p className="text-muted" style={{ margin: "0px" }}>
                    {moment(s.createdAt).format("h:mm a - ddd DD MMM YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

import { forwardRef } from "react"
import React from "react"
import { Draggable } from "react-beautiful-dnd"
import Card from "./components/Card"
import withDroppable from "../../../withDroppable"
import CardAdder from "./components/CardAdder"
import { pickPropOut } from "../../../../services/utils"

const ColumnEmptyPlaceholder = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{ minHeight: "inherit", height: "inherit" }}
      {...props}
    ></div>
  )
})
ColumnEmptyPlaceholder.displayName = "ColumnEmptyPlaceholder"

const DroppableColumn = withDroppable(ColumnEmptyPlaceholder)
function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}
export default function MyColumn({
  children,
  index: columnIndex,
  renderCard,
  renderColumnHeader,
  disableColumnDrag,
  disableCardDrag,
  onCardNew,
  allowAddCard,
}) {
  return (
    <Draggable
      draggableId={`column-draggable-${children.id}`}
      index={columnIndex}
      isDragDisabled={disableColumnDrag}
    >
      {columnProvided => {
        const draggablePropsWithoutStyle = pickPropOut(
          columnProvided.draggableProps,
          "style"
        )

        return (
          <div
            ref={columnProvided.innerRef}
            {...draggablePropsWithoutStyle}
            style={{
              backgroundColor: "transparent",
              padding: "0px",
              width: "100%",
              height: "100%",
              minHeight: "28px",
              display: "inline-block",
              verticalAlign: "top",
              margin: "0px",
              ...columnProvided.draggableProps.style,
            }}
            className="react-kanban-column"
            data-testid={`column-${children.id}`}
          >
            <div {...columnProvided.dragHandleProps}>
              {renderColumnHeader(children)}
            </div>
            {allowAddCard && (
              <CardAdder column={children} onConfirm={onCardNew} />
            )}{" "}
            <DroppableColumn
              style={{ width: "100%" }}
              droppableId={String(children.id)}
            >
              {children.cards.map((card, index) => (
                <Card
                  key={card.id}
                  index={index}
                  renderCard={dragging => renderCard(children, card, dragging)}
                  disableCardDrag={disableCardDrag}
                >
                  {card}
                </Card>
              ))}
            </DroppableColumn>
          </div>
        )
      }}
    </Draggable>
  )
}

import React, { useState } from "react"

import { Row, Col, Card, CardBody, Container, Form } from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import { editStadium, getStadium } from "api/stadiums"
import FormButtonHandler from "components/FormButtonHandler"
import { stadiumSchema } from "../../validationSchema"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "hooks/useGetInstence"
import StadiumForm from "./StadiumForm"

const EditStadium = () => {
  let navigate = useHistory()

  const { t } = useTranslation()

  const params = useParams()
  const [editedStadium, setEditedStadium] = useState(null)

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getStadium,
    params.id,
    response => {
      setEditedStadium(response.data.data.stadium)
      setJsonDescription(response.data.data.json_description)
      setHtmlDescription(response.data.data.html_description)
    }
  )

  const { setData, loading, error, success } = useResponse(editStadium, () => {
    navigate.push("/stadiums")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: editedStadium ? editedStadium.name : "",
      slug: editedStadium ? editedStadium.slug : "",
      img: editedStadium ? editedStadium.image_url : "",
      location: editedStadium ? editedStadium.location : "",
      floor: editedStadium
        ? { label: editedStadium.floorType, value: editedStadium.floorType }
        : null,
      capacity: editedStadium ? editedStadium.capacity : "",
      year: editedStadium ? editedStadium.openingYear : "",
      raw_description: editedStadium ? editedStadium.raw_description : "",
    },
    validationSchema: stadiumSchema,
    onSubmit: values => {
      let data = {
        ...values,
        openingYear: values.year,
        floorType: values.floor.value,
        image_url: values.img,
        html_description: htmlDescription,
        json_description: jsonDescription,
      }
      setData({ type: "edit", id: params.id, data })
    },
  })

  // text editor

  const [jsonDescription, setJsonDescription] = useState(null)
  const [htmlDescription, setHtmlDescription] = useState(null)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Stadiums")}
            breadcrumbItem={t("btns.Edit") + " " + t("pages.Stadium")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedStadium && (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <StadiumForm
                        validation={validation}
                        jsonDescription={jsonDescription}
                        setJsonDescription={setJsonDescription}
                        htmlDescription={htmlDescription}
                        setHtmlDescription={setHtmlDescription}
                        defaultDescription={editedStadium.json_description}
                      />

                      <FormButtonHandler btnTxt="Edit" loading={loading} />
                    </Form>
                  )}

                  <ResultHandler
                    loading={instenceLoading}
                    error={error}
                    success={success}
                    successMsg={"Updated Successfully!"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditStadium

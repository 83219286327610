import React from "react"
import { UncontrolledAlert } from "reactstrap"
import { useTranslation } from "react-i18next"

export default function ResultHandler(props) {
  const { t } = useTranslation()

  return (
    <>
      {props.loading && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <span className="loader"></span>
        </div>
      )}
      {props.error && (
        <div style={{ marginTop: "20px" }}>
          <UncontrolledAlert color="danger" className="fade show" role="alert">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.error}
          </UncontrolledAlert>
        </div>
      )}
      {props.success && (
        <div style={{ marginTop: "20px" }}>
          <UncontrolledAlert color="success" className="fade show" role="alert">
            {t("messages." + props.successMsg)}
          </UncontrolledAlert>
        </div>
      )}
    </>
  )
}

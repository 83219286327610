import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Collapse, FormGroup, Input, Label, Row } from "reactstrap"
import ContentEditor from "../ContentEditor"
import ChooseImage from "components/ChooseImage"
import { useTranslation } from "react-i18next"
import classnames from "classnames"
import "./ContentCard.css"
import ChooseVideo from "pages/dashboard/videos/ChooseVideo"
import CustomImage from "components/CustomImage"
const ContentCard = props => {
  const { t } = useTranslation()
  const pattern = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  )
  const data = props.content
  const [open, setOpen] = useState(data.status === "new" ? true : false)

  return (
    <div>
      {data.type === "html" && (
        <div
          className="accordion-item"
          style={{ marginBottom: "15px", backgroundColor: "white" }}
        >
          <h2
            className={
              "accordion-header " +
              classnames("accordion-button", "fw-medium", {
                collapsed: !open,
              })
            }
            onClick={() => {
              setOpen(!open)
            }}
          >
            <Button
              style={{ marginLeft: "15px" }}
              color="danger"
              type="button"
              onClick={() => {
                props.removeContent(data.order)
              }}
              disabled={props.disabled}
            >
              <i className="bx bx-trash font-size-16 align-middle" />
            </Button>
            <span
              style={{
                whiteSpace: "nowrap",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "right",
              }}
            >
              {t("forms.Text")} : {data.raw_content}
            </span>
          </h2>
          <Collapse isOpen={open} className="accordion-collapse">
            <div className="accordion-body">
              <ContentEditor
                defaultContent={
                  props.defaultContent ? props.defaultContent : null
                }
                onEditorStateChange={props.onEditorStateChange}
                order={data.order}
              />
            </div>
          </Collapse>
        </div>
      )}
      {data.type === "img" && (
        <div
          className="accordion-item"
          style={{ marginBottom: "15px", backgroundColor: "white" }}
        >
          <h2
            className={
              "accordion-header " +
              classnames("accordion-button", "fw-medium", {
                collapsed: !open,
              })
            }
            onClick={() => {
              setOpen(!open)
            }}
          >
            <Button
              style={{ marginLeft: "15px" }}
              color="danger"
              type="button"
              onClick={() => {
                props.removeContent(data.order)
              }}
              disabled={props.disabled}
            >
              <i className="bx bx-trash font-size-16 align-middle" />
            </Button>
            <span
              style={{
                width: "100%",
                textAlign: "right",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "10px",
              }}
            >
              {t("forms.Image")}
              {data.content && (
                <CustomImage
                  style={{
                    width: "35px",
                    objectFit: "cover",
                    height: "35px",
                    borderRadius: "100%",
                  }}
                  src={data.content}
                />
              )}
            </span>
          </h2>
          <Collapse isOpen={open} className="accordion-collapse">
            <div className="accordion-body">
              <ChooseImage
                hideLabel={true}
                error={null}
                handleChooseImage={url => {
                  props.setImage(url, data.order)
                }}
                image={data.content}
                disabled={props.disabled}
              />
              <Input
                style={{ marginTop: "15px" }}
                className="form-control"
                placeholder={t("forms.Comment")}
                type="text"
                value={data.meta?.comment}
                onChange={e => {
                  props.setImageComment(e.target.value, data.order)
                }}
              />
            </div>
          </Collapse>
        </div>
      )}
      {data.type === "video" && (
        <div
          className="accordion-item"
          style={{ marginBottom: "15px", backgroundColor: "white" }}
        >
          <h2
            className={
              "accordion-header " +
              classnames("accordion-button", "fw-medium", {
                collapsed: !open,
              })
            }
            onClick={() => {
              setOpen(!open)
            }}
          >
            <Button
              style={{ marginLeft: "15px" }}
              color="danger"
              type="button"
              onClick={() => {
                props.removeContent(data.order)
              }}
              disabled={props.disabled}
            >
              <i className="bx bx-trash font-size-16 align-middle" />
            </Button>
            <span
              style={{
                width: "100%",
                textAlign: "right",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "10px",
              }}
            >
              {t("forms.Video")}
            </span>
          </h2>
          <Collapse isOpen={open} className="accordion-collapse">
            <div className="accordion-body">
              <ChooseVideo
                handleSelectVideo={embeded => {
                  props.setImage(embeded, data.order)
                }}
                video={data.content}
                disabled={props.disabled}
              />
            </div>
          </Collapse>
        </div>
      )}
      {data.type === "embeded" && (
        <div
          className="accordion-item"
          style={{ marginBottom: "15px", backgroundColor: "white" }}
        >
          <h2
            className={
              "accordion-header " +
              classnames("accordion-button", "fw-medium", {
                collapsed: !open,
              })
            }
            onClick={() => {
              setOpen(!open)
            }}
          >
            <Button
              style={{ marginLeft: "15px" }}
              color="danger"
              type="button"
              onClick={() => {
                props.removeContent(data.order)
              }}
              disabled={props.disabled}
            >
              <i className="bx bx-trash font-size-16 align-middle" />
            </Button>
            <span
              style={{
                whiteSpace: "nowrap",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "right",
              }}
            >
              {t("forms.embeded")} : {data.content}
            </span>
          </h2>
          <Collapse isOpen={open} className="accordion-collapse">
            <div className="accordion-body">
              <FormGroup className="mb-3">
                <Label>{t("forms.Url")}</Label>
                <Input
                  value={data.content}
                  onChange={e => {
                    props.setImage(e.target.value, data.order)
                  }}
                  placeholder={t("forms.Url")}
                  type="url"
                  className="form-control"
                  disabled={props.disabled}
                />
              </FormGroup>
              {pattern.test(data.content) && (
                <iframe
                  style={{ width: "100%", height: "350px" }}
                  src={data.content}
                  title="Iframe Example"
                ></iframe>
              )}
            </div>
          </Collapse>
        </div>
      )}
      {data.type === "code" && (
        <div
          className="accordion-item"
          style={{ marginBottom: "15px", backgroundColor: "white" }}
        >
          <h2
            className={
              "accordion-header " +
              classnames("accordion-button", "fw-medium", {
                collapsed: !open,
              })
            }
            onClick={() => {
              setOpen(!open)
            }}
          >
            <Button
              style={{ marginLeft: "15px" }}
              color="danger"
              type="button"
              onClick={() => {
                props.removeContent(data.order)
              }}
              disabled={props.disabled}
            >
              <i className="bx bx-trash font-size-16 align-middle" />
            </Button>
            <span
              style={{
                whiteSpace: "nowrap",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "right",
              }}
            >
              Code : {data.content}
            </span>
          </h2>
          <Collapse isOpen={open} className="accordion-collapse">
            <div className="accordion-body">
              <FormGroup className="mb-3">
                <Label>Code</Label>
                <textarea
                  value={data.content}
                  onChange={e => {
                    props.setImage(e.target.value, data.order)
                  }}
                  placeholder="Code"
                  className="form-control"
                  style={{ textAlign: "left" }}
                  disabled={props.disabled}
                />
                {data.content && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.content,
                    }}
                    className="embededCode"
                    style={{ width: "100%" }}
                  />
                )}
              </FormGroup>
            </div>
          </Collapse>
        </div>
      )}
      {data.type === "slider" && (
        <div
          className="accordion-item"
          style={{ marginBottom: "15px", backgroundColor: "white" }}
        >
          <h2
            className={
              "accordion-header " +
              classnames("accordion-button", "fw-medium", {
                collapsed: !open,
              })
            }
            onClick={() => {
              setOpen(!open)
            }}
          >
            {!props.hideRemoveBtn && (
              <Button
                style={{ marginLeft: "15px" }}
                color="danger"
                type="button"
                onClick={() => {
                  props.removeContent(data.order)
                }}
                disabled={props.disabled}
              >
                <i className="bx bx-trash font-size-16 align-middle" />
              </Button>
            )}
            <span
              style={{
                whiteSpace: "nowrap",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "right",
                alignItems: "center",
                display: "flex",
              }}
            >
              {t("forms.Slider") + ": "}
              <div
                className="avatar-group float-start"
                style={{ marginRight: "10px" }}
              >
                {data.meta.map(c => {
                  return (
                    <div className="avatar-group-item" key={c.order}>
                      <CustomImage
                        src={c.imgUrl}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    </div>
                  )
                })}
              </div>
            </span>
          </h2>
          <Collapse isOpen={open} className="accordion-collapse">
            <div className="accordion-body">
              <Row>
                {data.meta.map(c => {
                  return (
                    <Col key={c.order} xs={3} className="mb-4 ">
                      <ChooseImage
                        height="110px"
                        hideRemoveIcon={true}
                        hideLabel={true}
                        image={c.imgUrl}
                        error={false}
                        handleChooseImage={url => {
                          props.setSliderImg(url, data.order, c.order)
                        }}
                        disabled={props.disabled}
                      />

                      <div
                        className="d-flex"
                        style={{ alignItems: "center", marginTop: "15px" }}
                      >
                        <Input
                          className="form-control"
                          placeholder={t("forms.Comment")}
                          type="text"
                          value={c.comment}
                          onChange={e => {
                            props.setSliderImgComment(
                              e.target.value,
                              data.order,
                              c.order
                            )
                          }}
                        />
                        <Button
                          color="danger"
                          onClick={() => {
                            props.removeSliderImg(data.order, c.order)
                          }}
                          disabled={props.disabled}
                        >
                          {t("btns.Remove")}
                        </Button>
                      </div>
                    </Col>
                  )
                })}
                <Col xs={3}>
                  <ChooseImage
                    height="110px"
                    hideLabel={true}
                    image={null}
                    error={false}
                    handleChooseImage={url => {
                      props.addSliderImg(url, data.order)
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Collapse>
        </div>
      )}
    </div>
  )
}

export default ContentCard


import React from "react";
import { Card, CardBody } from "reactstrap";


export default function Counter(props) {

  return (
    <>
      <Card className="mini-stats-wid" style={{ marginBottom: "0px" }}>
        <CardBody>
          <div className="d-flex">
            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
              <span className="avatar-title rounded-circle bg-primary">
                <i
                  className={
                    "bx " + props.iconClass + " font-size-20"
                  }
                ></i>
              </span>
            </div>
            <div className="flex-grow-1" style={{ marginRight: "10px" }}>
              <p className="text-muted fw-medium">
                {props.title}
              </p>
              <h4 className="mb-0">{props.description}</h4>
            </div>

          </div>
        </CardBody>
      </Card>
    </>
  )
}
import { myAxios } from "./axios"

export function getArticles(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            title: data ? data.txt : null,
        },
    }
    return myAxios.get("/articles", config)
}


export function getArticle(id) {
    return myAxios.get("/articles/" + id)
}

export function addArticle(data) {
    return myAxios.post("/articles/create", data)
}

export function editArticle(id, data) {
    return myAxios.post("/articles/" + id + "/update", data)
}

export function deleteArticle(id) {
    return myAxios.post("/articles/" + id + "/delete")
}

export function publishArticle(id, data) {
    if (data.type === "publish") {
        return myAxios.post("/articles/" + id + "/publish")
    } else {
        return myAxios.post("/articles/" + id + "/unpublish")
    }
}
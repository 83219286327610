import React from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useTranslation } from "react-i18next"

export default function FeaturedCard({
  content,
  handleRemoveCard,
  handleChangeCategoryLayout,
  handleChangeCategoryPostsNum,
  homeCategories,
}) {
  const { t } = useTranslation()

  const c = content
  return (
    <Card key={c.value}>
      <CardBody>
        <Row>
          <Col xs={12}>
            <h5
              className="d-flex"
              style={{
                justifyContent: "space-between",
                alignItems: "baseline",
                marginBottom: "0px",
              }}
            >
              {c.label}
              <Button
                color="danger"
                onClick={() => {
                  handleRemoveCard(c.id)
                }}
              >
                {t("btns.Remove")}
              </Button>
            </h5>
            {homeCategories && (
              <>
                <div
                  className="btn-group btn-group-example"
                  role="group"
                  style={{ marginBottom: "15px" }}
                >
                  <Button
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    disabled={c.layout == "grid"}
                    onClick={() => {
                      handleChangeCategoryLayout(c.id, "grid")
                    }}
                  >
                    <i className="bx bx-grid-alt"></i>
                  </Button>
                  <Button
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    disabled={c.layout == "list"}
                    onClick={() => {
                      handleChangeCategoryLayout(c.id, "list")
                    }}
                  >
                    <i className="bx bx-list-ul"></i>
                  </Button>
                </div>
                <FormGroup>
                  <Label>{t("forms.Posts Number")}</Label>
                  <Input
                    min={4}
                    max={16}
                    placeholder={t("forms.Posts Number")}
                    type="number"
                    className="form-control"
                    onChange={e => {
                      handleChangeCategoryPostsNum(c.id, e.target.value)
                    }}
                    value={c.postsNum}
                  />
                </FormGroup>
              </>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

import { myAxios } from "./axios"

export function getSettings(data, meta = null) {
  let config = {
    params: {
      key: data,
    },
  }
  return myAxios.get("/settings", config)
}

export function updateSettings(id, data) {
  return myAxios.post("/settings/update", { key: id, value: data })
}

import { getSettings, updateSettings } from "api/settings"
import ChooseImage from "components/ChooseImage"
import FormButtonHandler from "components/FormButtonHandler"
import ResultHandler from "components/ResultHandler"
import { usePagination } from "hooks/usePagination"
import { useResponse } from "hooks/useResponse"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export default function HeaderSettings({ lang }) {
  const { t } = useTranslation()
  const settingCategory = "header" + (lang === "en" ? "_" + lang : "")
  const { response, loading, error } = usePagination(
    getSettings,
    settingCategory
  )

  const [logo, setLogo] = useState(null)

  useEffect(() => {
    if (response.data) {
      setLogo(response.data.data[settingCategory].logo)
    }
  }, [response])

  const {
    setData: setUpdateSettings,
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useResponse(updateSettings)

  const submit = () => {
    setUpdateSettings({
      type: "edit",
      id: settingCategory,
      data: {
        logo,
      },
    })
  }

  return (
    <>
      <ResultHandler loading={loading} />
      {!loading && (
        <ChooseImage
          handleChooseImage={img => {
            setLogo(img)
          }}
          image={logo}
        />
      )}
      <div style={{ marginBottom: "20px" }}></div>
      {!loading && (
        <FormButtonHandler
          btnTxt="Save"
          type="button"
          handleClick={submit}
          loading={updateLoading}
        />
      )}
      <ResultHandler
        error={updateError}
        success={updateSuccess}
        successMsg={"Updated Successfully!"}
      />
    </>
  )
}

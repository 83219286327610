import React, { useState } from "react"
import classnames from "classnames"

import HomeSettings from "./HomeSettings"
import MenuSettings from "./MenuSettings"
import UrgentSettings from "./UrgentSettings"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { useTranslation } from "react-i18next"
import AboutSettings from "./AboutSettings"
import HeaderSettings from "./HeaderSettings"
import FooterSettings from "./FooterSettings"

export default function ConfigrationLang({ lang }) {
  const { t } = useTranslation()
  const [activeTab, setactiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", background: "transparent" }}
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => {
              toggle("1")
            }}
          >
            {t("pages.Home")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", background: "transparent" }}
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              toggle("2")
            }}
          >
            {t("pages.Urgent Posts")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", background: "transparent" }}
            className={classnames({
              active: activeTab === "3",
            })}
            onClick={() => {
              toggle("3")
            }}
          >
            {t("wedgits.Menu")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", background: "transparent" }}
            className={classnames({
              active: activeTab === "4",
            })}
            onClick={() => {
              toggle("4")
            }}
          >
            {t("pages.About Us")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", background: "transparent" }}
            className={classnames({
              active: activeTab === "5",
            })}
            onClick={() => {
              toggle("5")
            }}
          >
            {t("pages.Header")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", background: "transparent" }}
            className={classnames({
              active: activeTab === "6",
            })}
            onClick={() => {
              toggle("6")
            }}
          >
            {t("pages.Footer")}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="p-3 text-muted">
        {activeTab == "1" && (
          <TabPane tabId="1">
            <HomeSettings lang={lang} />
          </TabPane>
        )}
        {activeTab == "2" && (
          <TabPane tabId="2">
            <UrgentSettings lang={lang} />
          </TabPane>
        )}
        {activeTab == "3" && (
          <TabPane tabId="3">
            <MenuSettings lang={lang} />
          </TabPane>
        )}
        {activeTab == "4" && (
          <TabPane tabId="4">
            <AboutSettings lang={lang} />
          </TabPane>
        )}
        {activeTab == "5" && (
          <TabPane tabId="5">
            <HeaderSettings lang={lang} />
          </TabPane>
        )}
        {activeTab == "6" && (
          <TabPane tabId="6">
            <FooterSettings lang={lang} />
          </TabPane>
        )}
      </TabContent>
    </>
  )
}

import ContentEditor from "../posts/crud/ContentEditor"
import React, { useEffect, useState } from "react"
import { getSettings, updateSettings } from "api/settings"
import { usePagination } from "hooks/usePagination"
import FormButtonHandler from "components/FormButtonHandler"
import ResultHandler from "components/ResultHandler"
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import draftToHtml from "draftjs-to-html"
import { convertToRaw } from "draft-js"

export default function AboutSettings({ lang }) {
  const { t } = useTranslation()
  const settingCategory = "about" + (lang === "en" ? "_" + lang : "")
  const { response, loading, error } = usePagination(
    getSettings,
    settingCategory
  )

  const [aboutHtml, setAboutHtml] = useState(null)
  const [aboutJson, setAboutJson] = useState(null)

  const [policiesHtml, setPoliciesHtml] = useState(null)
  const [policiesJson, setPoliciesJson] = useState(null)

  const onAboutEditorStateChange = editorState => {
    let meta = convertToRaw(editorState.getCurrentContent())

    setAboutJson(meta)
    setAboutHtml(draftToHtml(meta))
  }

  const onPoliciesEditorStateChange = editorState => {
    let meta = convertToRaw(editorState.getCurrentContent())

    setPoliciesJson(meta)
    setPoliciesHtml(draftToHtml(meta))
  }

  useEffect(() => {
    if (response.data) {
      setPoliciesJson(response.data.data[settingCategory].policiesJson)
      setPoliciesHtml(response.data.data[settingCategory].policiesJson)
      setAboutJson(response.data.data[settingCategory].aboutJson)
      setAboutHtml(response.data.data[settingCategory].aboutJson)
    }
  }, [response])

  const {
    setData: setUpdateSettings,
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useResponse(updateSettings)

  const submit = () => {
    setUpdateSettings({
      type: "edit",
      id: settingCategory,
      data: {
        aboutJson,
        policiesJson,
        aboutHtml,
        policiesHtml,
      },
    })
  }

  return (
    <>
      <ResultHandler loading={loading} />
      {!loading && (
        <Row>
          <Col md={6}>
            <Label>{t("pages.About Us")}</Label>
            <ContentEditor
              onEditorStateChange={onAboutEditorStateChange}
              order={0}
              defaultContent={aboutJson}
            />
          </Col>
          <Col md={6}>
            <Label>{t("pages.Policies")}</Label>
            <ContentEditor
              onEditorStateChange={onPoliciesEditorStateChange}
              order={0}
              defaultContent={policiesJson}
            />
          </Col>
        </Row>
      )}
      <div style={{ marginBottom: "20px" }}></div>
      {!loading && (
        <FormButtonHandler
          btnTxt="Save"
          type="button"
          handleClick={submit}
          loading={updateLoading}
        />
      )}
      <ResultHandler
        error={updateError}
        success={updateSuccess}
        successMsg={"Updated Successfully!"}
      />
    </>
  )
}

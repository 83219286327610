import React from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useTranslation } from "react-i18next"
import StadiumsTable from "./StadiumsTable"

export default function StadiumsList() {
  const { t } = useTranslation()
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.Stadiums")} />
        <StadiumsTable />
      </div>
    </div>
  )
}

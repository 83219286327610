import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import { editProgram, getProgram } from "api/programs"
import FormButtonHandler from "components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "hooks/useGetInstence"
import ProgramForm from "./ProgramForm"

const EditProgram = () => {
  let navigate = useHistory()
  const { programSchema } = useCustomValidation()

  const { t } = useTranslation()

  const params = useParams()
  const [editedProgram, setEditedProgram] = useState(null)

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getProgram,
    params.id,
    response => {
      setEditedProgram({
        ...response.data.data.program,
        Presenters: response.data.data.program.Presenters.map(p => {
          return { value: p.id, label: p.name }
        }),
      })
    }
  )

  const { setData, loading, error, success } = useResponse(editProgram, () => {
    navigate.push("/programs")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: editedProgram ? editedProgram.name : "",
      slug: editedProgram ? editedProgram.slug : "",
      summary: editedProgram ? editedProgram.summary : "",
      img: editedProgram ? editedProgram.image_url : "",
      presenters: editedProgram ? editedProgram.Presenters : null,
      cover_url: editedProgram ? editedProgram.cover_url : "",
      order: editedProgram ? editedProgram.order : 0
    },
    validationSchema: programSchema,
    onSubmit: values => {
      let data = {
        ...values,
        image_url: values.img,
        Presenters: values.presenters.map(p => {
          return p.value
        }),
      }
      setData({ type: "edit", id: params.id, data })
    },
  })



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Programs")}
            breadcrumbItem={t("btns.Edit") + " " + t("pages.Program")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedProgram && (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <ProgramForm validation={validation} />

                      <FormButtonHandler btnTxt="Edit" loading={loading} />
                    </Form>
                  )}

                  <ResultHandler
                    loading={instenceLoading}
                    error={error}
                    success={success}
                    successMsg={"Updated Successfully!"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditProgram

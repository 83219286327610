import React, { useState } from "react"
import { Carousel, CarouselItem, CarouselControl } from "reactstrap"

export default function PostSliderContent({ items }) {
  // slider

  const [activeIndex, setActiveIndex] = useState(0)

  function next() {
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  function previous() {
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }
  return (
    <div className="hori-timeline">
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        {items.map((item, index) => {
          return (
            <CarouselItem key={index}>
              <div
                className="owl-carousel owl-theme  navs-carousel events"
                id="timeline-carousel"
                style={{ whiteSpace: "nowrap" }}
              >
                <div
                  key={"img-" + item.order}
                  className="item event-list"
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "350px",
                    backgroundSize: "cover",
                    backgroundImage: "url(" + item.imgUrl + ")",
                  }}
                ></div>
              </div>
            </CarouselItem>
          )
        })}
        {activeIndex > 0 && (
          <CarouselControl
            direction="next"
            directionText=""
            onClickHandler={previous}
          />
        )}
        {activeIndex < items.length - 1 && (
          <CarouselControl
            direction="prev"
            directionText=""
            onClickHandler={next}
          />
        )}
      </Carousel>
    </div>
  )
}

import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
// MetisMenu
import MetisMenu from "metismenujs"
import { Link, useLocation } from "react-router-dom"
import { PERMISSIONS } from "api/permissions"

const SidebarContent = props => {
  const { t } = useTranslation()

  const location = useLocation()
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  useEffect(() => {
    document.body.classList.remove("sidebar-enable")
  }, [location])

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.MONITOR_VIEW) && (
              <li>
                <Link to="/monitor">
                  <i className="bx bx-home-circle"></i>
                  <span>{t("pages.Home")}</span>
                </Link>
              </li>
            )}
            {(localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.URGENT_VIEW_ALL) ||
              localStorage
                .getItem("permissions")
                .includes(PERMISSIONS.URGENT_VIEW_MINE)) && (
              <li>
                <Link to="/urgent-posts">
                  <i className="bx bx-alarm"></i>
                  <span>{t("pages.Urgent Posts")}</span>
                </Link>
              </li>
            )}
            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.CATEGORY_VIEW) && (
              <li>
                <Link to="/categories">
                  <i className="bx bx-customize"></i>
                  <span>{t("pages.Categories")}</span>
                </Link>
              </li>
            )}

            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.KICKER_VIEW) && (
              <li>
                <Link to="/kickers">
                  <i className="bx bx-customize"></i>
                  <span>{t("pages.Kickers")}</span>
                </Link>
              </li>
            )}

            <li>
              <Link to="/posts">
                <i className="bx bx-news"></i>
                <span>{t("pages.Posts")}</span>
              </Link>
            </li>

            {JSON.parse(localStorage.getItem("permissions")).includes(
              PERMISSIONS.AUDIT_VIEW
            ) && (
              <li>
                <Link to="/audit">
                  <i className="bx bx-news"></i>
                  <span>{t("pages.Audit")}</span>
                </Link>
              </li>
            )}

            <li>
              <Link to="/archive">
                <i className="bx bx-box"></i>
                <span>{t("pages.Archive")}</span>
              </Link>
            </li>

            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.SETTINGS_VIEW) && (
              <li>
                <Link to="/configration">
                  <i className="bx bx-cog"></i>
                  <span>{t("pages.Configration") + " " + t("pages.Home")}</span>
                </Link>
              </li>
            )}

            {JSON.parse(localStorage.getItem("permissions")).includes(
              PERMISSIONS.ADMIN_VIEW
            ) && (
              <li>
                <Link to="/admins">
                  <i className="bx bx-user"></i>
                  <span>{t("pages.Admins")}</span>
                </Link>
              </li>
            )}
            {JSON.parse(localStorage.getItem("permissions")).includes(
              PERMISSIONS.MY_ADMIN_VIEW
            ) && (
              <li>
                <Link to="/my-admins">
                  <i className="bx bx-user"></i>
                  <span>{t("pages.My Admins")}</span>
                </Link>
              </li>
            )}
            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.STAGE_VIEW) && (
              <li>
                <Link to="/stages">
                  <i className="bx bx-shape-circle"></i>
                  <span>{t("pages.Stages")}</span>
                </Link>
              </li>
            )}

            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.ROLE_VIEW) && (
              <li>
                <Link to="/roles">
                  <i className="bx bx-lock"></i>
                  <span>{t("pages.Roles")}</span>
                </Link>
              </li>
            )}

            {localStorage.getItem("permissions").includes("") && (
              <li>
                <Link to="/file-manager">
                  <i className="bx bx-folder"></i>
                  <span>{t("pages.Filemanager")}</span>
                </Link>
              </li>
            )}

            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.VIDEO_VIEW) && (
              <li>
                <Link to="/videos">
                  <i className="bx bx-video-recording"></i>
                  <span>{t("pages.Videos")}</span>
                </Link>
              </li>
            )}

            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.POLL_VIEW) && (
              <li>
                <Link to="/polls">
                  <i className="bx bx-question-mark"></i>
                  <span>{t("pages.Polls")}</span>
                </Link>
              </li>
            )}
            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.CREATOR_VIEW) && (
              <li>
                <Link to="/creators">
                  <i
                    className="bx bx-pencil
"
                  ></i>
                  <span>{t("pages.Creators")}</span>
                </Link>
              </li>
            )}
            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.ARTICLE_VIEW) && (
              <li>
                <Link to="/articles">
                  <i
                    className="bx bx-news
"
                  ></i>
                  <span>{t("pages.Articles")}</span>
                </Link>
              </li>
            )}

            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.PROGRAM_VIEW) && (
              <li>
                <Link to="/programs">
                  <i className="bx bx-tv"></i>
                  <span>{t("pages.Programs")}</span>
                </Link>
              </li>
            )}
            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.PRESENTER_VIEW) && (
              <li>
                <Link to="/presenters">
                  <i className="bx bx-user-voice"></i>
                  <span>{t("pages.Presenters")}</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default SidebarContent

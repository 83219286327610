import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalBody,
} from "reactstrap"
import { useFormik } from "formik"

import ResultHandler from "components/ResultHandler"

import FormButtonHandler from "components/FormButtonHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { createUrgentPost } from "api/posts"
import useCustomValidation  from "../../validationSchema"

const AddUrgentPost = ({ show, reloadPosts, setShow }) => {
  const { t } = useTranslation()
  const { urgentSchema } = useCustomValidation()
  function handleClose() {
    setShow(false)
  }

  const { setData, loading, error, success } = useResponse(
    createUrgentPost,
    () => {
      setShow(false)
      reloadPosts()
    }
  )

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: "",
      language: "ar"
    },
    validationSchema: urgentSchema,
    onSubmit: data => {
      setData({ data, type: "add" })
    },
  })

  return (
    <Modal isOpen={show} toggle={handleClose} centered={true}>
      <ModalBody className="py-5 px-5">
        <Form
          className="needs-validation"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col md="12">
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">{t("forms.Title")}</Label>
                <Input
                  name="title"
                  placeholder={t("forms.Title")}
                  type="text"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.title || ""}
                  invalid={
                    validation.touched.title && validation.errors.title
                      ? true
                      : false
                  }
                />
                {validation.touched.title && validation.errors.title ? (
                  <FormFeedback type="invalid">
                    {validation.errors.title}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              {/*<FormGroup className="mb-3">
                <Label>{t("forms.Language")}</Label>

                <select
                  name="language"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.language || ""}
                >
                  <option value="ar">العربية</option>
                  <option value="en">English</option>
                </select>
                
                </FormGroup>*/}
            </Col>
          </Row>

          <FormButtonHandler btnTxt="Add" loading={loading} />
          <ResultHandler
            error={error}
            success={success}
            successMsg={"Created Successfully!"}
          />
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AddUrgentPost

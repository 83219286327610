import React from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useTranslation } from "react-i18next"
import PollsTable from "./PollsTable"

export default function PollsList() {
  const { t } = useTranslation()
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.Polls")} />
        <PollsTable />
      </div>
    </div>
  )
}

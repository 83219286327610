import React from "react"

import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"

import { Card, CardBody, CardHeader } from "reactstrap"
import { useTranslation } from "react-i18next"
import { getPosts } from "api/posts"
import { usePagination } from "hooks/usePagination"
import ResultHandler from "components/ResultHandler"
import moment from "moment"
import placeholderImg from "../../../assets/images/post_placeholder.jpg"
import CustomImage from "components/CustomImage"
export default function RecentPosts() {
  const { t } = useTranslation()
  const { response, loading, error } = usePagination(getPosts)

  return (
    <Card>
      <CardHeader className="bg-transparent border-bottom">
        <div className="d-flex flex-wrap">
          <div className="me-2">
            <h5 className="card-title mt-1 mb-0">
              {t("wedgits.Recent Posts")}
            </h5>
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <ResultHandler loading={loading} error={error} />
        <SimpleBar style={{ maxHeight: "295px" }}>
          <div>
            <ul className="list-group list-group-flush">
              {response.data &&
                response.data.posts.map(p => {
                  return (
                    <li key={p.id} className="list-group-item py-3">
                      <div className="d-flex">
                        <div className="me-3">
                          <CustomImage
                            src={
                              p.mainImage_url ? p.mainImage_url : placeholderImg
                            }
                            alt=""
                            className="d-block rounded"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </div>

                        <div className="align-self-center overflow-hidden me-auto">
                          <div>
                            <h5 className="font-size-14 text-truncate text-dark">
                              {p.title}
                            </h5>
                            <p className="text-muted mb-0">
                              {moment(p.createdAt).format("h:mm YYYY-MM-DD")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
            </ul>
          </div>
        </SimpleBar>
      </CardBody>
    </Card>
  )
}

import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody, Container, Form } from "reactstrap"
import { useFormik } from "formik"

import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import FormButtonHandler from "components/FormButtonHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"

import PollForm from "./PollForm"
import { addPoll } from "api/polls"
import useCustomValidation from "pages/dashboard/validationSchema"

const AddPoll = () => {
  const { t } = useTranslation()
  let navigate = useHistory()
  const { pollSchema } = useCustomValidation()
  const { setData, loading, error, success } = useResponse(addPoll, () => {
    navigate.push("/polls")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      text: "",
      cover_url: "",
      competitors: [],
      resultVisibility: false,
    },
    validationSchema: pollSchema,
    onSubmit: values => {
      let data = {
        ...values,
      }
      setData({ data, type: "add" })
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Polls")}
            breadcrumbItem={t("btns.Add") + " " + t("pages.Poll")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <PollForm validation={validation} />

                    <FormButtonHandler btnTxt="Add" loading={loading} />

                    <ResultHandler
                      error={error}
                      success={success}
                      successMsg={"Created Successfully!"}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddPoll

import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"
import { usePagination } from "hooks/usePagination"
import ResultHandler from "components/ResultHandler"
import PaginationHandler from "components/PaginationHandler"
import { deleteProgram, getPrograms, publishProgram } from "api/programs"
import TableHeaderHandler from "components/TableHeaderHandler"
import { PERMISSIONS } from "api/permissions"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import TableAction from "components/TableAction"
import EmptyData from "components/EmptyData"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Button } from "reactstrap"
import CustomImage from "components/CustomImage"

export default function ProgramsTable() {

  const { t } = useTranslation()


  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getPrograms)

  //// delete 
  const [show, setShow] = useState(false)

  function onCloseClick() {
    setShow(false)
  }

  const [deletingProgram, setDeletingProgram] = useState(null)

  const { setData } = useResponse(deleteProgram, () => {
    setShow(false)
    setDeletingProgram(null)
    setPayload({})
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingProgram })
  }
  //// publish 
  const [publishShow, setPublishShow] = useState(false)

  function onClosePublish() {
    setPublishShow(false)
  }

  const { setData: setPublishProgram } = useResponse(publishProgram, () => {
    setPublishShow(false)
    setDeletingProgram(null)
    setPayload({})
  })

  function onPublishClick() {
    setPublishProgram({ type: "add", data: deletingProgram })
  }

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.PROGRAM_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl="/add-program"
      />

      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                <Th style={{ width: "20%" }}>{t("forms.Image")}</Th>
                <Th style={{ width: "50%" }}>{t("forms.Name")}</Th>
                <Th style={{ width: "30%" }}>{t("tables.Action")}</Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <Tbody>
                {response.data.programs.map(row => {
                  return (
                    <Tr key={row.id}>
                      <Td>
                        <CustomImage src={row.image_url} width="40px" height="40px" />
                      </Td>
                      <Td><Link to={"/view-program/" + row.id}>{row.name}</Link></Td>
                      <Td>
                        <TableAction
                          hasEdit={true}
                          hasDelete={true}
                          editPermission={PERMISSIONS.PROGRAM_EDIT}
                          deletePermission={PERMISSIONS.PROGRAM_DELETE}
                          type="program"
                          id={row.id}
                          setShow={setShow}
                          setDeleteIntitiy={setDeletingProgram}
                        />
                        <Button onClick={() => {
                          setPublishShow(true)
                          setDeletingProgram(row.id)
                        }}>
                            {row.is_published ? t("btns.Unpublish") : t("btns.Publish")}
                        </Button>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          {!loading &&
            <EmptyData empty={response.data && response.data.programs.length === 0} msg="No Programs Found." />
          }
          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />

          <DeleteModal
            show={publishShow}
            onDeleteClick={onPublishClick}
            onCloseClick={onClosePublish}
          />

          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          <ResultHandler error={error} loading={loading} />
        </div>
      </div>
    </>
  )
}

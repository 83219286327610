import React, { useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useTranslation } from "react-i18next"
import ConfigrationLang from "./ConfigrationLang"

export default function Configration() {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const [lang, setLang] = useState("ar")

  return (
    <div className="page-content">
      <Container fluid={true}>
        <Card>
          <CardBody>
            <Breadcrumbs title={""} breadcrumbItem={t("pages.Configration")} />

            <Dropdown
              isOpen={open}
              toggle={() => setOpen(!open)}
              style={{ marginBottom: "20px" }}
            >
              <DropdownToggle
                type="button"
                tag="button"
                className="btn btn-primary"
              >
                {lang === "ar" ? "العربية" : "English"}{" "}
                <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setLang("ar")
                  }}
                >
                  العربية
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setLang("en")
                  }}
                >
                  English
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <ConfigrationLang key={lang} lang={lang} />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

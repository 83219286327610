import { myAxios } from "./axios"

export function getPolls(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      name: data ? data.txt : null,
    },
  }
  return myAxios.get("/polls", config)
}

export function getPoll(id) {
  return myAxios.get("/polls/" + id)
}

export function addPoll(data) {
  return myAxios.post("/polls/create", data)
}

export function editPoll(id, data) {
  return myAxios.post("/polls/" + id + "/update", data)
}

export function deletePoll(id) {
  return myAxios.post("/polls/" + id + "/delete")
}

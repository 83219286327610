import React from "react"
import { Redirect } from "react-router-dom"

import Login from "pages/Login"

import AddAdmin from "pages/dashboard/admins/AddAdmin"
import AdminsList from "pages/dashboard/admins/AdminsList"

import AddCategory from "pages/dashboard/categories/AddCategory"
import CategoriesList from "pages/dashboard/categories/CategoriesList"
import EditCategory from "pages/dashboard/categories/EditCategory"

import AddStage from "pages/dashboard/stages/AddStage"
import StagesList from "pages/dashboard/stages/StagesList"
import EditStage from "pages/dashboard/stages/EditStage"

import AddRole from "pages/dashboard/roles/AddRole"
import RolesList from "pages/dashboard/roles/RolesList"
import EditRole from "pages/dashboard/roles/EditRole"

import AddPost from "pages/dashboard/posts/crud/add-post/AddPost"
import PostsList from "pages/dashboard/posts/crud/PostsList"
import EditPost from "pages/dashboard/posts/crud/EditPost"
import StagesBoard from "pages/dashboard/posts/board/StagesBoard"

import FileManagerLayout from "pages/dashboard/file-manager/FileManagerLayout"

import ProgramsList from "pages/dashboard/programs/ProgramsList"
import AddProgram from "pages/dashboard/programs/AddProgram"
import EditProgram from "pages/dashboard/programs/EditProgram"

import PresentersList from "pages/dashboard/presenters/PresentersList"
import AddPresenter from "pages/dashboard/presenters/AddPresenter"
import EditPresenter from "pages/dashboard/presenters/EditPresenter"

import PostsVersionsList from "pages/dashboard/posts/versions/PostVersionsList"

import EditProfile from "pages/dashboard/admins/EditProfile"
import { PERMISSIONS } from "api/permissions"
import Home from "pages/dashboard/home/Home"
import EditAdmin from "pages/dashboard/admins/EditAdmin"
import ArchiveList from "pages/dashboard/archive/ArchiveList"
import AuthCodeForm from "pages/AuthCodeForm"
import PostHistory from "pages/dashboard/posts/history/PostHistory"
import TrashList from "pages/dashboard/posts/trash/TrashList"
import Monitor from "pages/dashboard/posts/monitor/Monitor"
import PostView from "pages/dashboard/posts/crud/PostView"
import AdminProfile from "pages/dashboard/admins/AdminProfile"
import UrgentPostsList from "pages/dashboard/posts/urgent/UrgentPostsList"
import Configration from "pages/dashboard/configration/Configration"
import PresenterPreview from "pages/dashboard/presenters/PresenterPreview"
import ProgramPreview from "pages/dashboard/programs/ProgramPreview"
import AllNotifications from "pages/dashboard/admins/AllNotifications"
import CreatorsList from "pages/dashboard/creators/CreatorsList"
import AddCreator from "pages/dashboard/creators/AddCreator"
import EditCreator from "pages/dashboard/creators/EditCreator"
import ArticlesList from "pages/dashboard/articles/ArticlesList"
import AddArticle from "pages/dashboard/articles/AddArticle"
import EditArticle from "pages/dashboard/articles/EditArticle"
import MyAdminsList from "pages/dashboard/admins/myAdmins/MyAdminsList"
import AuditList from "pages/dashboard/audit/AuditList"
import StadiumsList from "pages/dashboard/sports/stadiums/StadiumsList"
import AddStadium from "pages/dashboard/sports/stadiums/AddStadium"
import EditStadium from "pages/dashboard/sports/stadiums/EditStadium"
import PollsList from "pages/dashboard/sports/polls/PollsList"
import AddPoll from "pages/dashboard/sports/polls/AddPoll"
import EditPoll from "pages/dashboard/sports/polls/EditPoll"
import AddKicker from "pages/dashboard/categories/kickers/AddKicker"
import KickersList from "pages/dashboard/categories/kickers/KickersList"
import EditKicker from "pages/dashboard/categories/kickers/EditKicker"
import VideosList from "pages/dashboard/videos/VideosList"

const postsRoutes = [
  { path: "/posts", component: PostsList },
  { path: "/view-post/:id", component: PostView },
  {
    path: "/archive",
    component: ArchiveList,
  },
  {
    path: "/add-post",
    component: AddPost,
    permission: PERMISSIONS.POSTS_CREATE,
  },
  { path: "/edit-post/:id", component: EditPost },
  { path: "/post-versions/:postId", component: PostsVersionsList },

  { path: "/urgent-posts", component: UrgentPostsList },

  { path: "/trash", component: TrashList },
  { path: "/post-history/:postId", component: PostHistory },
]

const presenterRoutes = [
  {
    path: "/presenters",
    component: PresentersList,
    permission: PERMISSIONS.PRESENTER_VIEW,
  },
  {
    path: "/add-presenter",
    component: AddPresenter,
    permission: PERMISSIONS.PRESENTER_CREATE,
  },
  {
    path: "/edit-presenter/:id",
    component: EditPresenter,
    permission: PERMISSIONS.PRESENTER_EDIT,
  },
  {
    path: "/view-presenter/:id",
    component: PresenterPreview,
  },
]

const adminRoutes = [
  {
    path: "/admins",
    component: AdminsList,
    permission: PERMISSIONS.ADMIN_VIEW,
  },
  {
    path: "/audit",
    component: AuditList,
    permission: PERMISSIONS.AUDIT_VIEW,
  },
  {
    path: "/my-admins",
    component: MyAdminsList,
    permission: PERMISSIONS.MY_ADMIN_VIEW,
  },
  {
    path: "/add-admin",
    component: AddAdmin,
    permission: PERMISSIONS.ADMIN_CREATE,
  },
  {
    path: "/edit-admin/:id",
    component: EditAdmin,
    permissions: PERMISSIONS.ADMIN_EDIT,
  },
  {
    path: "/get-admin/:id",
    component: AdminProfile,
  },
  { path: "/edit-profile", component: EditProfile },
  {
    path: "/configration",
    component: Configration,
    permissions: PERMISSIONS.SETTINGS_VIEW,
  },
  {
    path: "/all-notifications",
    component: AllNotifications,
  },
]

const sportsRoutes = [
  {
    path: "/stadiums",
    component: StadiumsList,
  },
  {
    path: "/add-stadium",
    component: AddStadium,
  },
  {
    path: "/edit-stadium/:id",
    component: EditStadium,
  },

  {
    path: "/polls",
    component: PollsList,
  },
  {
    path: "/add-poll",
    component: AddPoll,
  },
  {
    path: "/edit-poll/:id",
    component: EditPoll,
  },
]

const authProtectedRoutes = [
  { path: "/home", component: Home },

  {
    path: "/monitor",
    component: Monitor,
    permission: PERMISSIONS.MONITOR_VIEW,
  },
  ...postsRoutes,
  ...adminRoutes,
  ...sportsRoutes,
  {
    path: "/videos",
    component: VideosList,
    permission: PERMISSIONS.VIDEO_VIEW,
  },

  {
    path: "/categories",
    component: CategoriesList,
    permission: PERMISSIONS.CATEGORY_VIEW,
  },
  {
    path: "/add-category",
    component: AddCategory,
    permission: PERMISSIONS.CATEGORY_CREATE,
  },
  {
    path: "/edit-category/:id",
    component: EditCategory,
    permission: PERMISSIONS.CATEGORY_EDIT,
  },
  {
    path: "/kickers",
    component: KickersList,
    permission: PERMISSIONS.KICKER_VIEW,
  },
  {
    path: "/add-kicker",
    component: AddKicker,
    permission: PERMISSIONS.KICKER_CREATE,
  },
  {
    path: "/edit-kicker/:id",
    component: EditKicker,
    permission: PERMISSIONS.KICKER_EDIT,
  },

  {
    path: "/stages",
    component: StagesList,
    permission: PERMISSIONS.STAGE_VIEW,
  },
  {
    path: "/add-stage",
    component: AddStage,
    permission: PERMISSIONS.STAGE_CREATE,
  },
  {
    path: "/edit-stage/:id",
    component: EditStage,
    permission: PERMISSIONS.STAGE_EDIT,
  },

  { path: "/roles", component: RolesList, permission: PERMISSIONS.ROLE_VIEW },
  {
    path: "/add-role",
    component: AddRole,
    permission: PERMISSIONS.ROLE_CREATE,
  },
  {
    path: "/edit-role/:id",
    component: EditRole,
    permission: PERMISSIONS.ROLE_EDIT,
  },

  { path: "/stages-posts", component: StagesBoard },

  { path: "/file-manager", component: FileManagerLayout },

  {
    path: "/programs",
    component: ProgramsList,
    permission: PERMISSIONS.PROGRAM_VIEW,
  },
  {
    path: "/add-program",
    component: AddProgram,
    permission: PERMISSIONS.PROGRAM_CREATE,
  },
  {
    path: "/edit-program/:id",
    component: EditProgram,
    permission: PERMISSIONS.PROGRAM_EDIT,
  },
  {
    path: "/view-program/:id",
    component: ProgramPreview,
  },
  ...presenterRoutes,
  {
    path: "/creators",
    component: CreatorsList,
    permission: PERMISSIONS.CREATOR_VIEW,
  },
  {
    path: "/add-creator",
    component: AddCreator,
    permission: PERMISSIONS.CREATOR_CREATE,
  },
  {
    path: "/edit-creator/:id",
    component: EditCreator,
    permission: PERMISSIONS.CREATOR_EDIT,
  },
  {
    path: "/articles",
    component: ArticlesList,
    permission: PERMISSIONS.ARTICLE_VIEW,
  },
  {
    path: "/add-article",
    component: AddArticle,
    permission: PERMISSIONS.ARTICLE_CREATE,
  },
  {
    path: "/edit-article/:id",
    component: EditArticle,
    permission: PERMISSIONS.ARTICLE_EDIT,
  },
  { path: "/", exact: true, component: () => <Redirect to="/admins" /> },
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/auth-code", component: AuthCodeForm },
]

export { authProtectedRoutes, publicRoutes }

import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { useHistory } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { useLogout } from "hooks/useLogout"
import { t } from "i18next"
import { useTranslation } from "react-i18next"
import CustomImage from "components/CustomImage"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const {t} = useTranslation()
  const navigate = useHistory()

  const { setLogout } = useLogout()

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          style={{ display: "flex", alignItems: "center" }}
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {JSON.parse(localStorage.getItem("user")).profile_pic_url && (
            <CustomImage
              className="rounded-circle header-profile-user"
              src={JSON.parse(localStorage.getItem("user")).profile_pic_url}
              alt="Header Avatar"
            />
          )}
          {!JSON.parse(localStorage.getItem("user")).profile_pic_url && (
            <div className="rounded-circle header-profile-user">
              <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                {JSON.parse(localStorage.getItem("user")).username.charAt(0)}
              </span>
            </div>
          )}
          <div style={{ marginRight: "10px" }}>
            <span className="d-none d-xl-block">
              {JSON.parse(localStorage.getItem("user")).username}
            </span>
            {JSON.parse(localStorage.getItem("user")).Categories.length ==
              1 && (
              <>
                <span
                  style={{ fontSize: "10px" }}
                  className="d-none d-xl-block"
                >
                  {JSON.parse(localStorage.getItem("user")).Categories[0].name}
                </span>
              </>
            )}
            {JSON.parse(localStorage.getItem("user")).Categories.length > 1 && (
              <>
                <span
                  style={{ fontSize: "10px" }}
                  className="d-none d-xl-block"
                >
                  {JSON.parse(localStorage.getItem("user")).Categories.length +
                    " " +
                    t("pages.Categories")}
                </span>
              </>
            )}
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            tag="a"
            onClick={() => {
              navigate.push("/edit-profile")
            }}
          >
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {t("pages.Profile")}
          </DropdownItem>

          <div className="dropdown-divider" />
          <DropdownItem
            onClick={() => {
              setLogout(true)
            }}
            className="dropdown-item"
          >
            <i className="bx bx-log-out font-size-16 align-middle me-1 text-danger"></i>
            <span>{t("btns.logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileMenu

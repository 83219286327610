import React, { useState } from "react"

import { Row, Col } from "reactstrap"
import { Modal, ModalBody } from "reactstrap"

import { useGetInstence } from "hooks/useGetInstence"
import { getVideo } from "api/videos"
import ResultHandler from "components/ResultHandler"

const ShowVideoModal = ({ show, videoId, onCloseClick }) => {
  const [editedVideo, setEditedVideo] = useState(null)

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getVideo,
    videoId,
    response => {
      setEditedVideo(response.data.data.video)
    }
  )

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <ResultHandler loading={instenceLoading} error={instenceError} />
        {!instenceLoading && (
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <h2>{editedVideo.title}</h2>
              </div>
            </Col>
            <Col xl="12">
              <section
                contentEditable="true"
                dangerouslySetInnerHTML={{
                  __html: editedVideo.embed.replace("allowfullscreen", ""),
                }}
                style={{ width: "100%" }}
              ></section>
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ShowVideoModal

import axios from "axios"
import { myAxios } from "./axios"

/** crud */

export function getPosts(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      title: data ? data.txt : null,
      stages: data ? data.stages : null,
      categories: data ? data.categories : null,
      type: data ? data.type : null,
      language: data ? data.language : null,
      created_by: data ? data.created_by : null,
    },
  }
  return myAxios.get("/posts", config)
}

export function createPost(data) {
  return myAxios.post("/posts/create", data)
}

export function editPost(id, data) {
  return myAxios.post("/posts/" + id + "/update", data)
}

export function deletePost(id) {
  return myAxios.post("/posts/" + id + "/delete")
}
export function extractKeyWords(data) {
  return axios.post("https://41ffjsxwsalh8.alqaheranews.net/kw-extract", data)
}

export function getPost(id) {
  return myAxios.get("/posts/" + id)
}

export function getPostPlagarism(id) {
  return myAxios.get("/plagiarism-checker/" + id)
}

export function movePost(data) {
  return myAxios.post("/posts/move-post", data)
}



/** urgent */

export function getUrgentPosts(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      title: data ? data.txt : null,
    },
  }
  return myAxios.get("/urgent-posts", config)
}

export function createUrgentPost(data) {
  return myAxios.post("/urgent-posts/create", data)
}

export function editUrgentPost(id, data) {
  return myAxios.post("/urgent-posts/" + id + "/update", data)
}

export function deleteUrgentPost(id) {
  return myAxios.post("/urgent-posts/" + id + "/delete")
}

export function getUrgentPost(id) {
  return myAxios.get("/urgent-posts/" + id)
}

export function publishUrgentPost(id, data) {
  if (data.type === "publish") {
    const headers = {
    
        with_sound: data.with_sound
      
    }
    return myAxios.post("/urgent-posts/" + id + "/publish", headers)
  } else {
    return myAxios.post("/urgent-posts/" + id + "/unpublish")
  }
}

export function addRelatedUrgentPost(id) {
  return myAxios.post("/posts/create-from-urgent/" + id)
}

/** versions */

export function getPostVersions(post, data = null) {
  return myAxios.get("/versions/post/" + post.id)
}

export function revertPostVersion(postId, versionId) {
  return myAxios.post("/versions/revert/" + postId + "/" + versionId)
}

export function getVersion(versionId) {
  return myAxios.get("/versions/" + versionId)
}

/** more */
export function getPostsDropdown(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      title: data ? data.txt : null,
    },
  }
  return myAxios.get("/dropdown/posts", config)
}

export function getPublishedPostsDropdown(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      type: data ? data.type : null,
      title: data ? data.txt : null,
    },
    headers: {
      "Accept-Language": data ? (data.lang ? data.lang : "ar") : "ar",
    },
  }
  return myAxios.get("/dropdown/published_posts", config)
}

export function getStagePosts(id, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
    },
  }
  return myAxios.get("/stages/" + id + "/posts", config)
}

/** related pages */

export function getPostHistory(id, config = null) {
  return myAxios.get("/post-stages-history/" + id)
}

export function getArchive(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
    },
  }
  return myAxios.get("/posts/my-archived-posts", config)
}

/** trash */

export function getTrash(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
    },
  }
  return myAxios.get("/posts/trash", config)
}
export function trashPostDelete(data) {
  return myAxios.post("/posts/trash/delete", data)
}
export function restorePost(data) {
  return myAxios.post("posts/" + data.id + "/restore")
}
export function emptyTrash(data = null) {
  return myAxios.post("/posts/trash/empty")
}

/** monitor */
export function getMonitorPosts(data, { page, perPage }) {
  let config = {
    params: {
      page,
      perPage,
      title: data ? data.txt : null,
      tags: data ? data.tags : null,
      categories: data ? data.categories : null,
      priorities: data ? data.priorities : null,
      stages: data ? data.stages : null,
      dates: data ? data.dates : null,
    },
  }
  return myAxios.get("/posts/monitor", config)
}

/** tasks */

export function getPostTasks(id) {
  return myAxios.get("/posts/" + id + "/tasks")
}

export function createTask(id, data) {
  return myAxios.post("/tasks/create", { ...data, post_id: id })
}
export function editTask(id, data) {
  return myAxios.post("/tasks/" + id + "/update/", data)
}
export function deleteTask(id) {
  return myAxios.post("/tasks/" + id + "/delete/")
}

export function changeTask(id, data) {
  return myAxios.post("/tasks/" + id + "/change-status/", data)
}

// authors
export function getPostAuthors(id) {
  return myAxios.get("/posts/" + id + "/authors")
}

export function assignAuthors(data) {
  return myAxios.post("/posts/assign-author", data)
}

export function removeAuthors(data) {
  return myAxios.post("/posts/remove-author", data)
}

// urgent authors
export function getUrgentPostAuthors(id) {
  return myAxios.get("/urgent-posts/" + id + "/authors")
}

export function assignUrgentAuthors(data) {
  return myAxios.post("/urgent-posts/assign-author", data)
}

export function removeUrgentAuthors(data) {
  return myAxios.post("/urgent-posts/remove-author", data)
}

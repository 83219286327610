import { myAxios } from "./axios"

export function getCreators(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            name: data ? data.txt : null,
        },
    }
    return myAxios.get("/creators", config)
}


export function getCreator(id) {
    return myAxios.get("/creators/" + id)
}

export function addCreator(data) {
    return myAxios.post("/creators/create", data)
}

export function editCreator(id, data) {
    return myAxios.post("/creators/" + id + "/edit", data)
}

export function deleteCreator(id) {
    return myAxios.post("/creators/" + id + "/delete")
}

export function getCreatorsDropdown(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            name: data ? data.txt : null,
        },
    }
    return myAxios.get("/dropdown/creators", config)
}
import React from "react"
import ReactApexChart from "react-apexcharts"

export function Graph({ data }) {
  const keys = Object.keys(data)
    .filter(a => a.startsWith("http"))
    .sort((a, b) => (data[a].avg_percent > data[b].avg_percent ? -1 : 1))

  const state = {
    series: [
      {
        data: [...keys.map(a => data[a].avg_percent).map(Math.round)],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: keys.map(k => (data[k].avg_percent > 50 ? "#ca1411" : "#25dd4f")),
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "%"
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [...keys.map(a => /https?:\/\/([^\/]+)/g.exec(a)[1])],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: "مواقع الاقتباس",
        align: "center",
        floating: true,
      },
      subtitle: {
        text: "بينما نحاول أن نكون دقيقين قدر الإمكان ، يرجى إلقاء نظرة سريعة قبل اتخاذ قرار",
        align: "center",
      },
      legend: { show: false },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return ""
            },
          },
        },
      },
    },
  }

  return (
    <div>
      <div id="chart" style={{ direction: "ltr" }}>
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={380}
        />
      </div>
      <h5>الكلمات المقتبسه</h5>
      <div className="mb-4">
        {keys
          .map(k => data[k].ems[0])
          .map(a => (
            <span key={a} className="badge badge-soft-secondary mx-1 mb-1">
              {a}
            </span>
          ))}
      </div>
    </div>
  )
}

import React from "react"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import PresentersTable from "./PresentersTable"
import { useTranslation } from "react-i18next"
export default function PresentersList() {
  const { t } = useTranslation()
  return (
    <div className="page-content">
      <div className="container-fluid">
      <Breadcrumbs title="" breadcrumbItem={t("pages.Presenters")} />

        <PresentersTable />
      </div>
    </div>
  )
}

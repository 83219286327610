import React, { useEffect } from "react"
import { Button, Card, Col, Input, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import ResultHandler from "components/ResultHandler"
import { useResponse } from "hooks/useResponse"
import { uploadFile } from "api/file-manager"
import CustomImage from "components/CustomImage"

export default function UploadImgCard({
  f,
  handleUploadSuccess,
  handleChangeUploadeStatuse,
  handleChangeUploadedFilename,
  handleChangeUploadedFileAlt,
  setFile, // crop
  setSelectedFileIndex,
  parentId,
}) {
  const { t } = useTranslation()
  const { setData, loading, success, error, setError } = useResponse(
    uploadFile,
    () => {
      handleUploadSuccess()
    }
  )

  useEffect(() => {
    if (error) {
      handleChangeUploadeStatuse()
    }
  }, [error])

  useEffect(() => {
    if (f.uploaded) {
      const data = new FormData()
      data.append("file", f)
      data.append("name", f.fileName)
      data.append("alt_text", f.alt_text)
      if (parentId > 0) {
        data.append("parentId", parentId)
      }
      setData({ type: "add", data })
    }
  }, [f.uploaded])

  return (
    <>
      {f && (
        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
          <ResultHandler error={error} />
          <div className="p-2">
            <Row>
              <Col>
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div className="d-flex">
                    <CustomImage
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={f.name}
                      src={f.preview}
                      style={{ marginLeft: "15px" }}
                    />
                    <div>
                      <p className="text-muted font-weight-bold">{f.name}</p>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </div>
                  </div>
                  <div style={{ paddingLeft: "0px" }}>
                    {!loading && (
                      <Button
                        color="primary"
                        onClick={() => {
                          setFile(f)
                          setSelectedFileIndex()
                        }}
                      >
                        {t("btns.Crop")}
                      </Button>
                    )}
                    <div style={{ paddingLeft: "20px" }}>
                      <ResultHandler loading={loading} />
                    </div>
                  </div>
                </div>

                <Input
                  name="name"
                  placeholder={t("forms.Name")}
                  type="text"
                  value={f.fileName}
                  onChange={e => {
                    handleChangeUploadedFilename(e.target.value)
                  }}
                  className="form-control"
                />
                <Input
                  style={{ marginTop: "10px" }}
                  name="alt_text"
                  placeholder={t("forms.Comment")}
                  type="text"
                  value={f.alt_text}
                  onChange={e => {
                    handleChangeUploadedFileAlt(e.target.value)
                  }}
                  className="form-control"
                />
              </Col>
            </Row>
          </div>
        </Card>
      )}
    </>
  )
}

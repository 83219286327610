import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import ChooseImage from "components/ChooseImage"
import { editPresenter, getPresenter } from "api/presenters"
import FormButtonHandler from "components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "hooks/useGetInstence"

const EditPresenter = () => {
  const { t } = useTranslation()
  const { presenterSchema } = useCustomValidation()

  const params = useParams()
  const [editedPresenter, setEditedPresenter] = useState(null)

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getPresenter,
    params.id,
    response => {
      setEditedPresenter(response.data.data.presenter)
    }
  )

  const { setData, loading, error, success } = useResponse(editPresenter)

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: editedPresenter ? editedPresenter.name : "",
      summary: editedPresenter ? editedPresenter.summary : "",
      img: editedPresenter ? editedPresenter.image_url : "",
    },
    validationSchema: presenterSchema,
    onSubmit: values => {
      let data = {
        name: values.name,
        summary: values.summary,
        image_url: values.img,
      }

      setData({ id: params.id, data, type: "edit" })
    },
  })

  let navigate = useHistory()

  useEffect(() => {
    if (success) {
      navigate.push("/presenters")
    }
  }, [success])

  function handleChooseImage(url) {
    validation.setFieldValue("img", url)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Presenters")}
            breadcrumbItem={t("btns.Edit") + " " + t("pages.Presenter")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedPresenter && (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label>{t("forms.Name")}</Label>
                            <Input
                              name="name"
                              placeholder={t("forms.Name")}
                              type="text"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label>{t("forms.Summary")}</Label>
                            <Input
                              name="summary"
                              placeholder={t("forms.Summary")}
                              type="textarea"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.summary || ""}
                              invalid={
                                validation.touched.summary &&
                                validation.errors.summary
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.summary &&
                            validation.errors.summary ? (
                              <FormFeedback type="invalid">
                                {validation.errors.summary}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <ChooseImage
                            name="img"
                            image={validation.values.img}
                            error={
                              validation.touched.img && validation.errors.img
                            }
                            handleChooseImage={handleChooseImage}
                          />
                        </Col>
                      </Row>

                      <FormButtonHandler btnTxt="Edit" loading={loading} />
                    </Form>
                  )}

                  <ResultHandler
                    loading={instenceLoading}
                    error={error}
                    success={success}
                    successMsg={"Updated Successfully!"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditPresenter

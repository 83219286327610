import { createTag, getTags } from "api/tags"
import { usePagination } from "hooks/usePagination"
import { t } from "i18next"
import { useCallback, useEffect, useState } from "react"
import { Card, CardBody, FormGroup, Label } from "reactstrap"
import React from "react"
import CreatableSelect from "react-select/creatable"

function debounce(func, timeout = 500){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export default function TagsSelect({validation, disabled}){

      // tags

  const [tagSearchTxt, setTagSearchTxt] = useState("")
  const { response: tagsResponse, setMeta: setTagsPayload, meta } =
    usePagination(getTags, null, false)

  useEffect(() => {
    
    setSearch(tagSearchTxt)
    
  }, [tagSearchTxt])

  function handleCreate(newTag) {
    const data = {
      name: newTag,
    }
    createTag(data)
      .then(res => {
        validation.setFieldValue(
          "tags",
          validation.values.tags.concat([
            { value: res.data.data.tag.id, label: res.data.data.tag.name },
          ])
        )
      })
      .catch(err => {
        console.log(err)
      })
  }

  const setSearch = useCallback(debounce((e) => setTagsPayload({ active:true, payload: {txt: e} })),[])

  if(!tagsResponse){
    return <></>
  }

    return(
        <Card>
        <CardBody>
          <FormGroup>
            <Label>{t("forms.Tags")}</Label>
            {tagsResponse.data && (
              <CreatableSelect
                isDisabled={disabled}
                placeholder={t("forms.Select")+" ..."}
                isClearable
                onCreateOption={handleCreate}
                value={validation.values.tags}
                onChange={data => {
                  validation.setFieldValue("tags", data)
                }}
                isMulti={true}
                inputValue={tagSearchTxt}
                onInputChange={value => {
                  setTagSearchTxt(value)
             
                }}
                options={tagsResponse.data.tags.map(c => {
                  return { label: c.name, value: c.id }
                })}
              />
            )}
          </FormGroup>
        </CardBody>
      </Card>
    )
}
import React, { useState, useEffect, useRef } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useTranslation } from "react-i18next"
import Graph from "./Graph"
import {
  getCategoryPostsGraph,
  getCounters,
  getDateCounters,
  getDatePosts,
  getRoleAdminsGraph,
  getStagePostsGraph,
  getTopTags,
} from "api/dashboard"

import { Card, CardBody, Col, Row } from "reactstrap"
import Counter from "./Counter"
import ResultHandler from "components/ResultHandler"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import ReactWordcloud from "react-wordcloud"
import RecentPosts from "./RecentPosts"

export default function Home() {
  const { t } = useTranslation()

  const [counters, setCounters] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  const [filteredCounters, setFilteredCounters] = useState([])
  const [filteredCountersLoading, setFilteredCountersLoading] = useState(true)
  const [filteredCountersError, setFilteredCountersError] = useState("")

  const [tags, setTags] = useState([])
  const [tagsLoading, setTagsLoading] = useState(true)
  const [tagsError, setTagsError] = useState("")

  useEffect(() => {
    getCounters()
      .then(res => {
        setCounters(res.data.data.counters)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        setError("هناك خطأ!")
      })

    getDateCounters()
      .then(res => {
        setFilteredCounters(res.data.data.counters)
        setFilteredCountersLoading(false)
      })
      .catch(err => {
        setFilteredCountersLoading(false)
        setFilteredCountersError("هناك خطأ!")
      })

    getTopTags()
      .then(res => {
        setTags(res.data.data)
        setTagsLoading(false)
      })
      .catch(err => {
        setTagsLoading(false)
        setTagsError("هناك خطأ!")
      })
  }, [])

  const [dates, setDates] = useState([null, null])

  const [graphs, setGraphs] = useState({
    roles: true,
    stages: true,
    categories: true,
  })

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.Home")} />

        <Row style={{ marginBottom: "30px" }}>
          {!filteredCountersError && !error && (
            <ResultHandler
              loading={loading && filteredCounters}
              error={error}
            />
          )}
          {!loading && !error && (
            <>
              {counters.map(c => {
                return (
                  <Col key={c.label} md={3} style={{ marginBottom: "10px" }}>
                    <Counter
                      title={c.label}
                      description={c.counter}
                      iconClass="bx-copy-alt"
                    />
                  </Col>
                )
              })}
              {filteredCounters.map(c => {
                return (
                  <Col key={c.label} md={3} style={{ marginBottom: "10px" }}>
                    <Counter
                      title={c.label}
                      description={c.counter}
                      iconClass="bx-copy-alt"
                    />
                  </Col>
                )
              })}
            </>
          )}
        </Row>

        <Row>
          <Col md={8}>
            <Card>
              <CardBody>
                <Row style={{ marginBottom: "15px" }}>
                  <Col md={9}>
                    <h4
                      style={{
                        margin: "0px",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {t("pages.Posts")}
                    </h4>
                  </Col>
                  <Col
                    md={3}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "40px",
                        overflow: "hidden",
                      }}
                    >
                      <i
                        className="bx bx-time"
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          left: "0px",
                          background: "#c4a050",
                          color: "white",
                          borderRadius: "5px",
                        }}
                      ></i>
                      <Flatpickr
                        style={{ opacity: 0 }}
                        className="form-control d-block"
                        placeholder="dd M,yyyy"
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                        }}
                        value={dates}
                        onChange={v => {
                          setDates(v)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={12} style={{ direction: "ltr" }}>
                    <Graph
                      type="area"
                      dates={dates}
                      title="Posts"
                      color="#f46a6a"
                      api={getDatePosts}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <RecentPosts />
          </Col>
        </Row>
        <Row>
          {graphs.roles && (
            <Col md={6} style={{ direction: "ltr", marginBottom: "30px" }}>
              <Card>
                <CardBody>
                  <h4 style={{ marginBottom: "15px" }}>{t("pages.Admins")}</h4>
                  <Graph
                    handleHideIfError={() => {
                      setGraphs({ ...graphs, roles: false })
                    }}
                    title="Admins"
                    color="#f46a6a"
                    api={getRoleAdminsGraph}
                  />
                </CardBody>
              </Card>
            </Col>
          )}
          {graphs.stages && (
            <Col md={6} style={{ direction: "ltr", marginBottom: "30px" }}>
              <Card>
                <CardBody>
                  <h4 style={{ marginBottom: "15px" }}>{t("pages.Stages")}</h4>
                  <Graph
                    handleHideIfError={() => {
                      setGraphs({ ...graphs, stages: false })
                    }}
                    title="Posts"
                    color="#f46a6a"
                    api={getStagePostsGraph}
                  />
                </CardBody>
              </Card>
            </Col>
          )}
          {graphs.categories && (
            <Col md={12} style={{ direction: "ltr", marginBottom: "30px" }}>
              <Card>
                <CardBody>
                  <h4 style={{ marginBottom: "15px" }}>
                    {t("pages.Categories")}
                  </h4>
                  <Graph
                    handleHideIfError={() => {
                      setGraphs({ ...graphs, categories: false })
                    }}
                    title="Posts"
                    color="#f46a6a"
                    api={getCategoryPostsGraph}
                  />
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </div>
  )
}

import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

export default function ArchiveAction({ row }) {
  const { t } = useTranslation()
  const navigate = useHistory()
  const [open, setOpen] = useState(false)
  return (
    <Dropdown direction="left" isOpen={open} toggle={() => setOpen(!open)}>
      <DropdownToggle>
        <i className="mdi mdi-dots-horizontal"></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem color="primary">
          <a
            href={
              process.env.REACT_APP_FRONT_URL +
              "/blog/" +
              row.id +
              "/" +
              row.slug
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            {t("btns.Live View")}
          </a>
        </DropdownItem>

        <DropdownItem
          color="primary"
          onClick={() => {
            navigate.push("view-post/" + row.id)
          }}
        >
          {t("btns.View")}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

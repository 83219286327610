import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody, Container, Form } from "reactstrap"
import { useFormik } from "formik"

import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import FormButtonHandler from "components/FormButtonHandler"
import { stadiumSchema } from "../../validationSchema"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"

import StadiumForm from "./StadiumForm"
import { addStadium } from "api/stadiums"

const AddStadium = () => {
  const { t } = useTranslation()
  let navigate = useHistory()

  const { setData, loading, error, success } = useResponse(addStadium, () => {
    navigate.push("/stadiums")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      slug: "",
      img: "",
      location: "",
      floor: null,
      capacity: "",
      year: "",
      raw_description: "",
    },
    validationSchema: stadiumSchema,
    onSubmit: values => {
      let data = {
        ...values,
        openingYear: values.year,
        floorType: values.floor.value,
        image_url: values.img,
        html_description: htmlDescription,
        json_description: jsonDescription,
      }
      setData({ data, type: "add" })
    },
  })

  // text editor

  const [jsonDescription, setJsonDescription] = useState(null)
  const [htmlDescription, setHtmlDescription] = useState(null)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Stadiums")}
            breadcrumbItem={t("btns.Add") + " " + t("pages.Stadium")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <StadiumForm
                      validation={validation}
                      jsonDescription={jsonDescription}
                      setJsonDescription={setJsonDescription}
                      htmlDescription={htmlDescription}
                      setHtmlDescription={setHtmlDescription}
                    />

                    <FormButtonHandler btnTxt="Add" loading={loading} />

                    <ResultHandler
                      error={error}
                      success={success}
                      successMsg={"Created Successfully!"}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddStadium

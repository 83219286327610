import React, { useState } from "react"
import { Button, Col, Row } from "reactstrap"
import ResultHandler from "components/ResultHandler"
import { useParams } from "react-router-dom"
import { getPostVersions } from "api/posts"
import CompareVersionsModal from "./CompareVersionsModal"
import RevertBtn from "./RevertBtn"
import { useTranslation } from "react-i18next"
import { usePagination } from "hooks/usePagination"
import EmptyData from "components/EmptyData"
import moment from "moment"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

export default function PostsVersionsTable() {
  const { t } = useTranslation()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  // fetching states

  const { postId } = useParams()

  const { response, loading, error, setError, setPayload } = usePagination(
    getPostVersions,
    { id: postId }
  )

  const [revertSuccess, setRevertSuccess] = useState(false)

  const [comparedVersions, setComparedVersions] = useState([])

  const [show, setShow] = useState(false)

  function handleSelectToCompare(version) {
    if (!comparedVersions.includes(version)) {
      setComparedVersions([...comparedVersions, version])
    } else {
      setComparedVersions(
        comparedVersions.filter(v => {
          return v.id != version.id
        })
      )
    }
  }

  function handleCompareVersions() {
    setShow(true)
  }

  return (
    <>

      {comparedVersions.length > 0 && (
        <Row style={{ margin: "0px 0px 30px 0px", width: "100%" }}>
          <Col xs={9}>
            {comparedVersions.map(version => {
              return (
                <Button
                  key={version.id}
                  color="primary"
                  onClick={() => { }}
                  style={{ marginLeft: "15px" }}
                >
                  {moment(version.createdAt).format("h:mm YYYY-MM-DD")}
                </Button>
              )
            })}
          </Col>

          {comparedVersions.length === 2 && (
            <Col xs={3}>
              <Button
                style={{ float: "left" }}
                color="success"
                onClick={handleCompareVersions}
              >
                {t("btns.Compare")}
              </Button>
            </Col>
          )}
        </Row>
      )}
      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                <Th style={{ width: "5%" }}>{isMobile && t('forms.Select')}</Th>

                <Th style={{ width: "20%" }}>{t("forms.Date")}</Th>
                <Th style={{ width: "20%" }}>{t("forms.Title")}</Th>
                <Th style={{ width: "25%" }}>{t("forms.Author")}</Th>
                <Th style={{ width: "30%" }}>
                  {t("tables.Action")}
                </Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <Tbody>
                {response.data.data.versions.map(row => {
                  return (
                    <Tr key={row.id}>
                      <Td>
                        <input
                          disabled={
                            comparedVersions.length === 2 &&
                            !comparedVersions.includes(row)
                          }
                          className="form-check-input"
                          type="checkbox"
                          onChange={() => {
                            handleSelectToCompare(row)
                          }}
                        />
                      </Td>
                      <Td>
                        {moment(row.createdAt).format("h:mm YYYY-MM-DD")}
                      </Td>
                      <Td>{row.title}</Td>
                      <Td>{row.editedBy.username}</Td>
                      <Td>
                        {!row.active && (
                          <RevertBtn
                            postId={postId}
                            setRevertSuccess={setRevertSuccess}
                            putError={msg => {
                              if (setError) {
                                setError(msg)
                              }
                            }}
                            getVersions={() => {
                              setPayload({ id: postId, txt: {} })
                            }}
                            versionId={row.id}
                          />
                        )}
                        {row.active && (
                          <Button disabled>{t("btns.Active")}</Button>
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          <EmptyData
            empty={response.data && response.data.data.versions.length === 0}
            msg="This Post Has No Versions."
          />

          <ResultHandler
            error={error}
            success={revertSuccess}
            successMsg="Version Reverted Successfully."
            loading={loading}
          />

          {comparedVersions.length === 2 && (
            <CompareVersionsModal
              versions={comparedVersions}
              show={show}
              onCloseClick={() => {
                setShow(false)
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}

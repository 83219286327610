import { deleteEpisode, getEpisodes } from "api/programs";
import DeleteModal from "components/Common/DeleteModal";
import EmptyData from "components/EmptyData";
import PaginationHandler from "components/PaginationHandler";
import ResultHandler from "components/ResultHandler";
import { usePagination } from "hooks/usePagination";
import { useResponse } from "hooks/useResponse";
import React, { useState } from "react";
import { useTranslation } from "react-i18next"
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import CreateEpisodeModal from "./CreateEpisodeModal";
import CustomImage from "components/CustomImage";

export default function EpisodesList({ programId }) {
    const { t } = useTranslation()

    const {
        response,
        loading,
        error,
        setPage,
        page,
        setPerPage,
        totalPages,
        setPayload,
    } = usePagination(getEpisodes, { id: programId })

    function reloadData() {
        setPayload({ id: programId })
    }

    // modals

    function handleCloseModals() {
        setAddModalShow(false)
        setDeleteModalShow(false)
        setEpisode(null)
    }

    // create

    const [addModalShow, setAddModalShow] = useState(false)

    const [episode, setEpisode] = useState(null)

    // delete

    const [deleteModalShow, setDeleteModalShow] = useState(false)
    const [deletingEpisode, setDeletingEpisode] = useState(null)

    const { setData } = useResponse(deleteEpisode, () => {
        setDeleteModalShow(false)
        setDeletingEpisode(null)
        reloadData()
    })

    function handleDelete() {
        setData({ type: "add", data: deletingEpisode })
    }

    return (
        <div>

            <h4 className="d-flex mb-4" style={{ flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
                {t('pages.Episodes')}
                <Button onClick={() => { setAddModalShow(true) }} color="primary">{t('btns.Add')}</Button>
            </h4>

            <ResultHandler loading={loading} error={error} />
            <EmptyData empty={response.data && response.data.episodes.length === 0} msg={"NO EPISODES FOUND."} />

            {!loading && <Row>

                {response.data && response.data.episodes.map(e => {
                    return <Col md={4} key={e.id} className="mb-4">
                        <Card className="mini-stats-wid" style={{ marginBottom: "0px" }}>
                            <CardBody>
                                <div className="d-flex">
                                    <div style={{ width: "100px", height: "70px" }}>
                                        <CustomImage src={e.image_url} style={{ width: "100%", height: "100%" }} />
                                    </div>
                                    <div className="flex-grow-1" style={{ marginRight: "15px" }}>
                                        <h5 className="mb-1 d-flex" style={{ alignItems: "baseline", justifyContent: "space-between" }}>
                                            {e.name}
                                            <div className="d-flex">
                                                <span
                                                    style={{ cursor: "pointer", width: "20px", height: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}
                                                    onClick={() => {
                                                        setDeletingEpisode(e.id)
                                                        setDeleteModalShow(true)
                                                    }}
                                                    className="badge rounded-circle font-size-11 badge-soft-danger"
                                                >
                                                    <i className="bx bxs-trash"></i>
                                                </span>
                                                <span
                                                    style={{ cursor: "pointer", marginRight: "5px", width: "20px", height: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}
                                                    onClick={() => {
                                                        setEpisode(e)
                                                        setAddModalShow(true)
                                                    }}
                                                    className="badge rounded-circle font-size-11 badge-soft-info"
                                                >
                                                    <i className="bx bx-pencil"></i>
                                                </span>
                                            </div>
                                        </h5>
                                        <p className="text-muted fw-medium">
                                            {e.date}
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                })}

                {response.data && !loading && (
                    <Col xs={12}>
                        <PaginationHandler
                            page={page}
                            setPage={setPage}
                            totalPages={totalPages}
                        />
                    </Col>
                )}
            </Row>}

            {
                addModalShow &&

                <CreateEpisodeModal handleClose={handleCloseModals} episodeData={episode} show={addModalShow} programId={programId} reloadPrograms={reloadData} />
            }

            <DeleteModal
                show={deleteModalShow}
                onDeleteClick={handleDelete}
                onCloseClick={handleCloseModals}
            />

        </div>
    )

}
import FileManagerModal from "pages/dashboard/file-manager/FileManagerModal"
import React, { useState } from "react"
import { FormFeedback, Input } from "reactstrap"
import { useTranslation } from "react-i18next"

import "./ChooseImage.css"
import CustomImage from "./CustomImage"

export default function ChooseImage(props) {
  const [open, setOpen] = useState(false)

  function onSelectFile(url) {
    props.handleChooseImage(url)
    setOpen(false)
  }

  function removeImage() {
    if (!props.disabled) {
      props.handleChooseImage("")
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <label
        style={{
          display: props.hideLabel ? "none" : "block",
          marginBottom: "10px",
        }}
      >
        {props.label ? t('forms.'+props.label) : t("forms.Image")}
      </label>
      <Input
        style={{ display: "none" }}
        type="text"
        value={props.image}
        readOnly
        invalid={props.error ? true : false}
      />
      {props.error ? (
        <FormFeedback type="invalid">{props.error}</FormFeedback>
      ) : null}

      <div
        className={props.error ? "ChooseImage invalid" : "ChooseImage"}
        onClick={() => {
          if (!props.disabled) {
            setOpen(true)
          }
        }}
        style={{ height: props.height ? props.height : "200px" }}
      >
        {!props.image && <div>+</div>}
        {props.image && (
          <>
            <CustomImage
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
              src={props.image}
            />

            <i
              onClick={removeImage}
              style={{
                position: "absolute",
                top: "15px",
                right: "20px",
                fontSize: "20px",
                cursor: "pointer",
                display: props.hideRemoveIcon ? "none" : "block",
              }}
              className="mdi mdi-close"
            ></i>
          </>
        )}
      </div>

      {open && (
        <FileManagerModal
          onCloseClick={() => {
            setOpen(false)
          }}
          onSelectFile={onSelectFile}
          open={open}
        />
      )}
    </>
  )
}

import { deleteFile, deleteFolder, editFile, getFolder } from "api/file-manager"
import { PERMISSIONS } from "api/permissions"
import DeleteModal from "components/Common/DeleteModal"
import ResultHandler from "components/ResultHandler"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  UncontrolledDropdown,
} from "reactstrap"
import CreateFolderModal from "./CreateFolderModal"
import EditFolderModal from "./EditFolderModal"
import UploadFileModal from "./UploadFileModal"
import { useTranslation } from "react-i18next"
import { UndrawEmpty } from "react-undraw-illustrations"
import { COLORS } from "utils/constants"
import { useResponse } from "hooks/useResponse"
import "./fileslist.css"
import CropImageModal from "./CropImageModal"
import ShowImgModal from "./ShowImgModal"
import PaginationHandler from "components/PaginationHandler"
import { usePagination } from "hooks/usePagination"
import CustomImage from "components/CustomImage"

const FilesList = props => {
  const { t } = useTranslation()

  // search

  const [searchTxt, setSearchTxt] = useState("")

  const [runSearch, setRunSearch] = useState(false)

  // contents

  const [allContent, setAllContent] = useState({ files: [], folders: [] })

  const {
    response,
    loading: responseLoading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setMeta,
    setPayload,
  } = usePagination(getFolder, { id: null })

  useEffect(() => {
    props.setLoading(responseLoading)
  }, [responseLoading])

  useEffect(() => {
    if (response.data) {
      setAllContent({
        folders: response.data.subFolders,
        files: response.data.files,
      })
    }
  }, [response])

  function getContent(p) {
    if (props.folderId === 0) {
      setMeta({
        page: p,
        payload: {
          id: null,
          searchTxt,
        },
      })
    } else {
      setMeta({
        page: p,
        payload: {
          id: props.folderId,
          searchTxt,
        },
      })
    }
    setRunSearch(false)
  }

  useEffect(() => {
    getContent(1)
  }, [props.folderId])

  useEffect(() => {
    if (runSearch) {
      getContent(1)
    }
  }, [runSearch])

  /////////////// OPEN FOLDER

  function handleOpen(id, name) {
    props.handleOpen(id, name)
    props.setLoading(true)
  }

  ///////////////////// DELETE FOLDER FLOW

  const [deleteModalshow, setdeleteModalShow] = useState(false)
  const [deletingFolder, setDeletingFolder] = useState(null)
  const [deletedType, setDeletedType] = useState(null)

  function handleDelete(id, type) {
    setDeletingFolder(id)
    setdeleteModalShow(true)
    setDeletedType(type)
  }

  // delete folder - file

  const { setData: setDeleteFolder } = useResponse(deleteFolder, () => {
    setdeleteModalShow(false)
    setDeletingFolder(null)
    getContent()
  })

  const { setData: setDeleteFile } = useResponse(deleteFile, () => {
    setdeleteModalShow(false)
    setDeletingFolder(null)
    getContent()
  })

  function onDeleteClick() {
    // modal confirmation
    if (deletedType === "folder") {
      setDeleteFolder({ type: "add", data: deletingFolder })
    } else if (deletedType === "file") {
      setDeleteFile({ type: "add", data: deletingFolder })
    }
  }

  ////////////////////// CREATE FOLDER FLOW

  const [createModalshow, setCreateModalShow] = useState(false)

  ////////////////////// UPLOAD FILE FLOW

  const [uploadModalshow, setUploadModalShow] = useState(false)

  ////////////////////// Edit Folder FLOW

  const [editModalshow, setEditModalShow] = useState(false)
  const [editedFolder, setEditedFolder] = useState(null)

  function handleEdit(folder) {
    setEditModalShow(true)
    setEditedFolder(folder)
  }

  // CROP IMAGE

  const [file, setFile] = useState(null)

  const {
    setData,
    loading,
    success,
    error: cropError,
  } = useResponse(editFile, () => {
    getContent(page)
  })

  function handleCrop(f) {
    if (f) {
      const data = new FormData()
      data.append("file", f.file)
      data.append("name", f.file.name.split(".")[0])
      setData({ type: "edit", id: file.id, data })
    }
  }

  ////////////////////// Show IMAGE FLOW

  const [imageModalshow, setImageModalShow] = useState(false)

  const [imgUrl, setImgUrl] = useState(null)

  function handleOpenImg(url) {
    setImgUrl(url)
    setImageModalShow(true)
  }

  return (
    <React.Fragment>
      <div>
        <Row className="mb-2">
          <Col xl={8}>
            <FormGroup className="d-flex" style={{ alignItems: "center" }}>
              <InputGroup style={{ width: "200px" }}>
                <InputGroupText
                  style={{
                    background: "#252525",
                    color: "white",
                    border: "0px",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  <i className="bx bx-search-alt-2"></i>
                </InputGroupText>
                <Input
                  name="name"
                  placeholder={t("forms.Search")}
                  type="text"
                  className="form-control"
                  onChange={e => {
                    setSearchTxt(e.target.value)
                    setRunSearch(false)
                  }}
                  onBlur={() => {
                    setRunSearch(true)
                  }}
                  value={searchTxt}
                  invalid={false}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col
            xl={4}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "baseline",
            }}
          >
            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.FILES_CREATE) && (
              <>
                <Button
                  style={{ marginLeft: "15px" }}
                  type="button"
                  color="success"
                  onClick={() => {
                    setUploadModalShow(true)
                  }}
                >
                  <i className="mdi mdi-plus me-1" />
                  {t("btns.Upload") + " " + t("modals.File")}
                </Button>
                <Button
                  type="button"
                  color="success"
                  onClick={() => {
                    setCreateModalShow(true)
                  }}
                >
                  <i className="mdi mdi-plus me-1" />
                  {t("btns.Add") + " " + t("modals.Folder")}
                </Button>
              </>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          {allContent &&
            !props.loading &&
            allContent.folders.map(folder => {
              return (
                <Col xl={2} key={folder.id}>
                  <Card
                    className="shadow-none border"
                    onDoubleClick={() => {
                      handleOpen(folder.id, folder.name)
                    }}
                  >
                    <CardBody className="p-3">
                      <UncontrolledDropdown direction="left">
                        <DropdownToggle
                          color="white"
                          className="btn btn-link text-muted mt-n2"
                        >
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>

                        <DropdownMenu>
                          <Link
                            to="#"
                            onClick={() => {
                              handleOpen(folder.id, folder.name)
                            }}
                            className="dropdown-item"
                          >
                            {t("btns.Open")}
                          </Link>
                          <Link
                            onClick={() => {
                              handleEdit(folder)
                            }}
                            className="dropdown-item"
                            to="#"
                          >
                            {t("btns.Edit")}
                          </Link>
                          {localStorage
                            .getItem("permissions")
                            .includes(PERMISSIONS.FILES_DELETE) && (
                            <Link
                              to="#"
                              className="dropdown-item"
                              onClick={() => {
                                handleDelete(folder.id, "folder")
                              }}
                            >
                              {t("btns.Remove")}
                            </Link>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <div className="text-center">
                        <i
                          className="bx bxs-folder text-warning"
                          style={{ fontSize: 60 }}
                        ></i>
                      </div>
                      <h5 className="font-size-14 text-truncate text-center mb-1">
                        {folder.name}
                      </h5>
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          {allContent &&
            !props.loading &&
            allContent.files.map(file => {
              return (
                <Col
                  xl={2}
                  onClick={() => {
                    if (props.isModal) {
                      props.handleSelectFile(file.url)
                    } else {
                      handleOpenImg(file.url)
                    }
                  }}
                  key={file.id}
                >
                  <Card
                    className="shadow-none border fileBox"
                    style={{ overflow: "hidden" }}
                  >
                    <CardBody className="p-3 d-flex postion-relative flex-column">
                      <div className="d-flex editFileBtns">
                        <span className="badge rounded-circle font-size-13 badge-soft-info">
                          <i className="bx bx-pencil"></i>
                        </span>
                        {localStorage
                          .getItem("permissions")
                          .includes(PERMISSIONS.FILES_DELETE) && (
                          <span
                            onClick={e => {
                              e.stopPropagation()
                              handleDelete(file.id, "file")
                            }}
                            className="badge rounded-circle font-size-13 badge-soft-danger"
                          >
                            <i className="bx bxs-trash"></i>
                          </span>
                        )}

                        <span
                          onClick={e => {
                            e.stopPropagation()
                            setFile(file)
                          }}
                          className="badge rounded-circle font-size-13 badge-soft-success"
                        >
                          <i className="bx bx-cut"></i>
                        </span>
                      </div>
                      <div className="d-flex mb-3 w-100 justify-content-center">
                        <CustomImage
                          src={file.url}
                          style={{ height: 60, width: 60, objectFit: "cover" }}
                        />
                      </div>
                      <div className="overflow-hidden">
                        <h5 className="font-size-14 text-truncate mb-0 w-100 h-100 text-center">
                          {file.name || "Unknown"}
                        </h5>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          {!props.loading &&
            allContent &&
            allContent.files.length === 0 &&
            allContent.folders.length === 0 && (
              <div style={{ textAlign: "center" }}>
                {t("emptyMsgs.No Files Found")}
                <UndrawEmpty primaryColor={COLORS.royalGold} />
              </div>
            )}
          {allContent.files && !props.loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          <ResultHandler error={error} loading={props.loading} />
        </Row>
      </div>

      <DeleteModal
        show={deleteModalshow}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => {
          setdeleteModalShow(false)
        }}
      />
      <ShowImgModal
        show={imageModalshow}
        onCloseClick={() => {
          setImageModalShow(false)
        }}
        imgUrl={imgUrl}
      />
      {createModalshow && (
        <CreateFolderModal
          reloadContentMeth={getContent}
          show={createModalshow}
          parentId={props.folderId}
          onCloseClick={() => {
            setCreateModalShow(false)
          }}
        />
      )}
      {uploadModalshow && (
        <UploadFileModal
          reloadContentMeth={getContent}
          show={uploadModalshow}
          parentId={props.folderId}
          onCloseClick={() => {
            setUploadModalShow(false)
          }}
        />
      )}
      {editModalshow && (
        <EditFolderModal
          reloadContentMeth={getContent}
          show={editModalshow}
          folderData={editedFolder}
          onCloseClick={() => {
            setEditModalShow(false)
          }}
        />
      )}

      {file && <CropImageModal onConfirm={handleCrop} file={file} />}
    </React.Fragment>
  )
}

export default FilesList

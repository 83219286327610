import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"
import draftToHtml from "draftjs-to-html"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { convertToRaw } from "draft-js"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import FormButtonHandler from "components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "hooks/useGetInstence"
import { editArticle, getArticle } from "api/articles"
import { getCreatorsDropdown } from "api/creators"
import SearchSelectBox from "components/SearchSelectBox"
import ContentCard from "../posts/crud/add-post/ContentCard"
import Board, { moveCard } from "../../../packages/react-kanban/index"
import SlugInput from "components/SlugInput"

const EditArticle = () => {
  const { t } = useTranslation()
  const {articleSchema} = useCustomValidation()
  const params = useParams()
  const navigate = useHistory()

  const [editedArticle, setEditedArticle] = useState(null)

  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getArticle,
    params.id,
    response => {
      setEditedArticle({
        ...response.data.data.article,
        creator: {
          label: response.data.data.article.creator.name,
          value: response.data.data.article.creator.id,
        },
      })
      setBoard({
        columns: [
          {
            ...board.columns[0],
            cards: response.data.data.article.morphArticleContent.map(c => {
              if (c.type == "slider") {
                return { ...c, meta: c.meta.imgs, status: "default" }
              } else {
                return c
              }
            }),
          },
        ],
      })
    }
  )

  const { setData, loading, error, success } = useResponse(editArticle, () => {
    navigate.push("/articles")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: editedArticle ? editedArticle.title : "",
      slug: editedArticle ? editedArticle.slug : "",
      creator: editedArticle ? editedArticle.creator : "",
    },
    validationSchema: articleSchema,
    onSubmit: values => {
      setData({
        id: params.id,
        data: {
          ...values,
          contents: board.columns[0].cards.map((c, i) => {
            return {
              ...c,
              order: i,
              meta: c.type === "slider" ? { imgs: c.meta } : c.meta,
              cardRef: null,
            }
          }),
          raw_content: generateRawContent(),
          creatorId: values.creator.value,
        },
        type: "edit",
      })
    },
  })

  // content type choose dropdown

  const [open, setOpen] = useState(false)

  function addContent(type) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: [
            ...board.columns[0].cards,
            {
              id:
                board.columns[0].cards.length > 0
                  ? board.columns[0].cards[board.columns[0].cards.length - 1]
                      .id + 1
                  : 1,
              order:
                board.columns[0].cards.length > 0
                  ? board.columns[0].cards[board.columns[0].cards.length - 1]
                      .order + 1
                  : 1,
              type,
              cardRef: null,
              content: "",
              status: "new",
              meta: type === "slider" ? [] : null,
              isOpen: true,
            },
          ],
        },
      ],
    })
  }

  function removeContent(order) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.filter(c => c.order != order),
        },
      ],
    })
  }

  function setCardRef(order, ref) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(c => {
            if (c.order === order) {
              return { ...c, cardRef: ref }
            } else {
              return c
            }
          }),
        },
      ],
    })

    window.scrollTo({
      top: ref.offsetTop,
      behavior: "smooth",
    })
  }

  // board

  const [board, setBoard] = useState({
    columns: [
      {
        id: 1,
        title: "",
        cards: [],
      },
    ],
  })

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(board, source, destination)
    setBoard(updatedBoard)
  }

  //////// text editor change

  const onEditorStateChange = (editorState, order) => {
    let meta = convertToRaw(editorState.getCurrentContent())
    let plain = editorState.getCurrentContent().getPlainText("\n")
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(c => {
            if (c.order === order) {
              return {
                ...c,
                raw_content: plain,
                meta: meta,
                content: draftToHtml(meta),
              }
            } else {
              return c
            }
          }),
        },
      ],
    })
  }

  // generate raw_content

  function generateRawContent() {
    let txtContent = ""

    board.columns[0].cards.map(c => {
      if (c.type === "html") {
        if (c.meta) {
          c.meta.blocks.map(b => {
            txtContent += b.text
          })
          txtContent += "\n"
        }
      }
    })

    return txtContent
  }

  // set Image content

  function setImage(url, order) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(c => {
            if (c.order === order) {
              return { ...c, content: url }
            } else {
              return c
            }
          }),
        },
      ],
    })
  }

  // slider set img

  function setSliderImg(url, order, imgOrder) {
    if (
      !board.columns[0].cards
        .filter(c => c.order === order)[0]
        .meta.filter(m => m.order === imgOrder)[0].imgUrl
    ) {
      setBoard({
        columns: [
          {
            ...board.columns[0],
            cards: board.columns[0].cards.map(c => {
              if (c.order === order) {
                return {
                  ...c,
                  meta: [
                    ...c.meta.map(m => {
                      if (m.order === imgOrder) {
                        return { ...m, imgUrl: url }
                      } else {
                        return m
                      }
                    }),
                    {
                      order: c.meta[c.meta.length - 1].order + 1,
                      imgUrl: null,
                    },
                  ],
                }
              } else {
                return c
              }
            }),
          },
        ],
      })
    } else {
      setBoard({
        columns: [
          {
            ...board.columns[0],
            cards: board.columns[0].cards.map(c => {
              if (c.order === order) {
                return {
                  ...c,
                  meta: c.meta.map(m => {
                    if (m.order === imgOrder) {
                      return { ...m, imgUrl: url }
                    } else {
                      return m
                    }
                  }),
                }
              } else {
                return c
              }
            }),
          },
        ],
      })
    }
  }

  function addSliderImg(url, order) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(c => {
            if (c.order === order) {
              return {
                ...c,
                meta: c.meta.concat([
                  {
                    order:
                      c.meta.length > 0
                        ? c.meta[c.meta.length - 1].order + 1
                        : 1,
                    imgUrl: url,
                  },
                ]),
              }
            } else {
              return c
            }
          }),
        },
      ],
    })
  }

  function removeSliderImg(order, imgOrder) {
    setBoard({
      columns: [
        {
          ...board.columns[0],
          cards: board.columns[0].cards.map(c => {
            if (c.order === order) {
              return {
                ...c,
                meta: c.meta.filter(m => {
                  return m.order != imgOrder
                }),
              }
            } else {
              return c
            }
          }),
        },
      ],
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Articles")}
            breadcrumbItem={t("btns.Edit") + " " + t("pages.Article")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedArticle && (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label>{t("forms.Title")}</Label>
                            <Input
                              name="title"
                              placeholder={t("forms.Title")}
                              type="text"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.title || ""}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <SlugInput
                            validation={validation}
                            source={validation.values.title}
                          />
                          <FormGroup className="mb-3">
                            <Label>{t("pages.Creator")}</Label>
                            <SearchSelectBox
                              value={validation.values.creator || null}
                              setValue={option => {
                                validation.setFieldValue("creator", option)
                              }}
                              error={
                                validation.touched.creator &&
                                validation.errors.creator
                              }
                              handleFocus={() => {
                                validation.setFieldTouched("creator")
                              }}
                              handleBlur={validation.handleBlur}
                              isMulti={false}
                              api={getCreatorsDropdown}
                              type="creators"
                              label="name"
                              name="creator" // for formik
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Label style={{ margin: "0px" }}>
                              {t("forms.Content")}
                            </Label>

                            <Dropdown
                              style={{ marginRight: "15px" }}
                              isOpen={open}
                              toggle={() => setOpen(!open)}
                            >
                              <DropdownToggle
                                type="button"
                                tag="button"
                                className="btn btn-primary"
                              >
                                {t("btns.Add")}{" "}
                                <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    addContent("html")
                                  }}
                                >
                                  {t("forms.Text")}
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    addContent("img")
                                  }}
                                >
                                  {t("forms.Image")}
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    addContent("embeded")
                                  }}
                                >
                                  {t("forms.embeded")}
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    addContent("code")
                                  }}
                                >
                                  Embeded Code
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    addContent("slider")
                                  }}
                                >
                                  {t("forms.Slider")}
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                          {board && board.columns[0].cards.length > 0 && (
                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "-15px",
                              }}
                              className="accordion"
                            >
                              <div className="accordion" id="accordion">
                                <Board
                                  onCardDragEnd={handleCardMove}
                                  renderCard={content => (
                                    <ContentCard
                                      setSliderImg={setSliderImg}
                                      removeSliderImg={removeSliderImg}
                                      addSliderImg={addSliderImg}
                                      defaultContent={content.meta}
                                      setImage={setImage}
                                      onEditorStateChange={onEditorStateChange}
                                      removeContent={removeContent}
                                      setCardRef={setCardRef}
                                      content={content}
                                    />
                                  )}
                                  disableColumnDrag
                                >
                                  {board}
                                </Board>
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>

                      <FormButtonHandler btnTxt="Edit" loading={loading} />
                    </Form>
                  )}

                  <ResultHandler
                    loading={instenceLoading}
                    error={error}
                    success={success}
                    successMsg={"Updated Successfully!"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditArticle

import { readNotification } from "api/admins"
import notificationState from "atom/notification"
import { useResponse } from "hooks/useResponse"
import moment from "moment"
import { Link, useHistory } from "react-router-dom"
import { useRecoilState } from "recoil"
import React, { useEffect } from "react"
import unreadState from "atom/unread"
import CustomImage from "components/CustomImage"

export default function NotificationCard(props) {
  const navigate = useHistory()
  const [notifications, setNotifications] = useRecoilState(notificationState)
  const [unread, setUnread] = useRecoilState(unreadState)
  const n = props.n
  useEffect(() => {
    moment.locale('ar')
}, [])
  function handleReadNotification(notification) {
    //setNotifications(n => n.filter(notifi => notifi.id != notification.id))
    /*if (!notification.read) {
      setData({ type: "add", data: notification.id })
      //setUnread(e => e - 1)
    }*/
  }

  return (
    <>
      {n.payload && (
        <Link to="#" className="text-reset notification-item">
          <div
            className="d-flex"
            onClick={() => {
              navigate.push("/view-post/" + n.payload.data.post_id)
              //handleReadNotification(n)
            }}
          >
            <div className="avatar-xs me-3">
              <span
                className="avatar-title bg-primary rounded-circle font-size-16"
                style={{ overflow: "hidden" }}
              >
                {n.payload.data.userImage && (
                  <CustomImage
                    style={{ height: "100%" }}
                    src={n.payload.data.userImage}
                  />
                )}
                {!n.payload.data.userImage && (
                  <div>{n.payload.data.edited_by_name[0]}</div>
                )}
              </span>
            </div>
            <div className="flex-grow-1" style={{ width: "80%" }}>
              <h6 className="mt-0 mb-1">{n.payload.data.title}</h6>
              <div className="font-size-12 text-muted">
                <p className="mb-1">{n.payload.data.description}</p>
                <p className="mb-0">
                  <i className="mdi mdi-clock-outline" />
                  {moment(n.createdAt, "YYYY-MM-DD[T]hh:mm:ssZ").fromNow()}
                </p>
              </div>
            </div>
          </div>
        </Link>
      )}
    </>
  )
}

import React from "react"
//import components
import Breadcrumbs from "components/Common/Breadcrumb"

import PostsVersionsTable from "./PostsVersionsTable"
import { useTranslation } from "react-i18next"

export default function PostsVersionsList() {
  const { t } = useTranslation()

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title=""
          breadcrumbItem={t("btns.Versions") + " " + t("pages.Post")}
        />
        <PostsVersionsTable />
      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"

import ChooseImage from "components/ChooseImage"

import { useTranslation } from "react-i18next"
import { getCategoriesDropdown } from "api/categories"
import SearchSelectBox from "components/SearchSelectBox"
import SlugInput from "components/SlugInput"

const PollForm = ({ validation }) => {
  const { t } = useTranslation()

  function handleChooseImage(url) {
    validation.setFieldValue("image_url", url)
  }

  return (
    <Row>
      <Col md="6">
        <FormGroup className="mb-3">
          <Label>{t("forms.Name")}</Label>
          <Input
            name="name"
            placeholder={t("forms.Name")}
            type="text"
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ""}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
          ) : null}
        </FormGroup>
        <SlugInput validation={validation} source={validation.values.name} />
        {/*<FormGroup className="mb-3">
          <Label>{t("forms.Language")}</Label>
          <select value={validation.values.language} onChange={(e) => {
            validation.handleChange(e)
            validation.setFieldValue('category', null)
          }} name="language" className="form-control">
            <option value={"ar"}>العربية</option>
            <option value={"en"}>English</option>
          </select>
        </FormGroup>*/}
        <FormGroup className="mb-3" key={validation.values.language}>
          <Label>{t("pages.Category")}</Label>
          <SearchSelectBox
            value={validation.values.category || null}
            setValue={option => {
              validation.setFieldValue("category", option)
            }}
            error={validation.touched.category && validation.errors.category}
            handleFocus={() => {
              validation.setFieldTouched("category")
            }}
            handleBlur={validation.handleBlur}
            isMulti={false}
            api={getCategoriesDropdown}
            apiMeta={{lang: validation.values.language}}
            type="categories"
            label="name"
            name="category" // for formik
          />
          {validation.touched.category && validation.errors.category ? (
            <FormFeedback type="invalid">
              {validation.errors.category}
            </FormFeedback>
          ) : null}
        </FormGroup>
      </Col>
      <Col xs={6}>
        <ChooseImage
          name="image_url"
          image={validation.values.image_url}
          error={validation.touched.image_url && validation.errors.image_url}
          handleChooseImage={handleChooseImage}
        />
      </Col>
    </Row>
  )
}

export default PollForm

import React, { useEffect, useState } from "react"
import { getSettings, updateSettings } from "api/settings"
import { usePagination } from "hooks/usePagination"
import { getCategoriesDropdown } from "api/categories"
import SearchSelectBox from "components/SearchSelectBox"
import FormButtonHandler from "components/FormButtonHandler"
import ResultHandler from "components/ResultHandler"
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useFormik } from "formik"
import useCustomValidation from "../validationSchema"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import { getPublishedPostsDropdown } from "api/posts"

import classnames from "classnames"
import ChooseImage from "components/ChooseImage"
import FeaturedBoard from "./home-settings/FeaturedBoard"

export default function HomeSettings({ lang }) {
  const { t } = useTranslation()
  const { homeSettingsSchema } = useCustomValidation()
  const settingCategory = "home" + (lang === "en" ? "_" + lang : "")
  const { response, loading, error } = usePagination(
    getSettings,
    settingCategory
  )

  const {
    setData: setUpdateSettings,
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useResponse(updateSettings)

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      featuredCategory: response.data
        ? response.data.data[settingCategory].featuredCategory
        : null,
      featuredCategoryImg: response.data
        ? response.data.data[settingCategory].featuredCategoryImg
        : "",
      secondFeaturedCategory: response.data
        ? response.data.data[settingCategory].secondFeaturedCategory
        : null,
      secondFeaturedCategoryImg: response.data
        ? response.data.data[settingCategory].secondFeaturedCategoryImg
        : "",
      liveUrl: response.data ? response.data.data[settingCategory].liveUrl : "",
    },
    validationSchema: homeSettingsSchema,
    onSubmit: values => {
      setUpdateSettings({
        type: "edit",
        id: settingCategory,
        data: {
          ...values,
          categories: categories.columns[0].cards.map((c, i) => {
            return { ...c, order: i }
          }),
          slider: slider.columns[0].cards.map((c, i) => {
            return { ...c, order: i }
          }),
          featuredPosts: featuredPosts.columns[0].cards.map((c, i) => {
            return { ...c, order: i }
          }),
          featuredInfographs: featuredInfographs.columns[0].cards.map(
            (c, i) => {
              return { ...c, order: i }
            }
          ),
          featuredVideos: featuredVideos.columns[0].cards.map((c, i) => {
            return { ...c, order: i }
          }),
          featuredAlbums: featuredAlbums.columns[0].cards.map((c, i) => {
            return { ...c, order: i }
          }),
        },
      })
    },
  })

  // categories accordion

  const [categories, setCategories] = useState({
    columns: [
      {
        id: 1,
        title: "",
        cards: [],
      },
    ],
  })

  const [categoriesAccOpen, setCategoriesAccOpen] = useState(false)

  // featured categories accordion

  const [featuredCategoryAccOpen, setFeaturedCategoryAccOpen] = useState(false)
  const [secondFeaturedCategoryAccOpen, setSecondFeaturedCategoryAccOpen] = useState(false)

  // slider posts

  const [slider, setSlider] = useState({
    columns: [
      {
        id: 1,
        title: "",
        cards: [],
      },
    ],
  })

  const [postsSliderAccOpen, setPostsSliderAccOpen] = useState(false)

  // featured posts

  const [featuredPosts, setFeaturedPosts] = useState({
    columns: [
      {
        id: 1,
        title: "",
        cards: [],
      },
    ],
  })

  const [featuredPostsAccOpen, setFeaturedPostsAccOpen] = useState(false)

  // featured Infographs

  const [featuredInfographs, setFeaturedInfographs] = useState({
    columns: [
      {
        id: 1,
        title: "",
        cards: [],
      },
    ],
  })

  const [featuredInfographsAccOpen, setFeaturedInfographsAccOpen] =
    useState(false)

  // featured Videos

  const [featuredVideos, setFeaturedVideos] = useState({
    columns: [
      {
        id: 1,
        title: "",
        cards: [],
      },
    ],
  })

  const [featuredVideosAccOpen, setFeaturedVideosAccOpen] = useState(false)

  // featured albums

  const [featuredAlbums, setFeaturedAlbums] = useState({
    columns: [
      {
        id: 1,
        title: "",
        cards: [],
      },
    ],
  })

  const [featuredAlbumsAccOpen, setFeaturedAlbumsAccOpen] = useState(false)

  // live accordion

  const [liveAccOpen, setLiveAccOpen] = useState(false)

  return (
    <>
      <Form
        className="needs-validation"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <ResultHandler loading={loading} />
        {response.data && (
          <Row>
            <Col sm="6" className="accordion">
              <div
                className="accordion-item"
                style={{
                  marginBottom: "15px",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <h2
                  className={
                    "accordion-header " +
                    classnames("accordion-button", "fw-medium", {
                      collapsed: !liveAccOpen,
                    })
                  }
                  onClick={() => {
                    setLiveAccOpen(!liveAccOpen)
                  }}
                >
                  {t("pages.Live")}
                </h2>
                <Collapse isOpen={liveAccOpen} className="accordion-collapse">
                  <div className="accordion-body">
                    <FormGroup>
                      <Label>{t("forms.Url")}</Label>
                      <Input
                        min={5}
                        max={120}
                        type="text"
                        name="liveUrl"
                        value={validation.values.liveUrl}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                      />
                    </FormGroup>
                  </div>
                </Collapse>
              </div>
            </Col>
            <Col sm="6" className="accordion">
              <div
                className="accordion-item"
                style={{
                  marginBottom: "15px",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <h2
                  className={
                    "accordion-header " +
                    classnames("accordion-button", "fw-medium", {
                      collapsed: !categoriesAccOpen,
                    })
                  }
                  onClick={() => {
                    setCategoriesAccOpen(!categoriesAccOpen)
                  }}
                >
                  {t("pages.Categories")}
                </h2>
                <Collapse
                  isOpen={categoriesAccOpen}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <FeaturedBoard
                      response={response}
                      settingKey={"categories"}
                      settingCategory={settingCategory}
                      type="categories"
                      api={getCategoriesDropdown}
                      apiMeta={{ lang }}
                      label={"name"}
                      board={categories}
                      setBoard={setCategories}
                    />
                  </div>
                </Collapse>
              </div>
            </Col>
            <Col sm="6" className="accordion">
              <div
                className="accordion-item"
                style={{
                  marginBottom: "15px",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <h2
                  className={
                    "accordion-header " +
                    classnames("accordion-button", "fw-medium", {
                      collapsed: !featuredCategoryAccOpen,
                    })
                  }
                  onClick={() => {
                    setFeaturedCategoryAccOpen(!featuredCategoryAccOpen)
                  }}
                >
                  {t("forms.Featured Category")}
                </h2>
                <Collapse
                  isOpen={featuredCategoryAccOpen}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <div style={{ marginBottom: "15px" }}>
                      <SearchSelectBox
                        value={validation.values.featuredCategory || null}
                        setValue={option => {
                          validation.setFieldValue("featuredCategory", option)
                        }}
                        error={
                          validation.touched.featuredCategory &&
                          validation.errors.featuredCategory
                        }
                        handleFocus={() => {
                          validation.setFieldTouched("featuredCategory")
                        }}
                        handleBlur={validation.handleBlur}
                        isMulti={false}
                        api={getCategoriesDropdown}
                        apiMeta={{ lang }}
                        type="categories"
                        label="name"
                        name="featuredCategory" // for formik
                      />
                    </div>
                    <ChooseImage
                      hideLabel={true}
                      name="featuredCategoryImg"
                      image={validation.values.featuredCategoryImg}
                      error={
                        validation.touched.featuredCategoryImg &&
                        validation.errors.featuredCategoryImg
                      }
                      handleChooseImage={url => {
                        validation.setFieldValue("featuredCategoryImg", url)
                      }}
                    />
                  </div>
                </Collapse>
              </div>
            </Col>
            <Col sm="6" className="accordion">
              <div
                className="accordion-item"
                style={{
                  marginBottom: "15px",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <h2
                  className={
                    "accordion-header " +
                    classnames("accordion-button", "fw-medium", {
                      collapsed: !secondFeaturedCategoryAccOpen,
                    })
                  }
                  onClick={() => {
                    setSecondFeaturedCategoryAccOpen(!secondFeaturedCategoryAccOpen)
                  }}
                >
                  {t("forms.Second Featured Category")}
                </h2>
                <Collapse
                  isOpen={secondFeaturedCategoryAccOpen}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <div style={{ marginBottom: "15px" }}>
                      <SearchSelectBox
                        value={validation.values.secondFeaturedCategory || null}
                        setValue={option => {
                          validation.setFieldValue("secondFeaturedCategory", option)
                        }}
                        error={
                          validation.touched.secondFeaturedCategory &&
                          validation.errors.secondFeaturedCategory
                        }
                        handleFocus={() => {
                          validation.setFieldTouched("secondFeaturedCategory")
                        }}
                        handleBlur={validation.handleBlur}
                        isMulti={false}
                        api={getCategoriesDropdown}
                        apiMeta={{ lang }}
                        type="categories"
                        label="name"
                        name="secondFeaturedCategory" // for formik
                      />
                    </div>
                    <ChooseImage
                      hideLabel={true}
                      name="secondFeaturedCategoryImg"
                      image={validation.values.secondFeaturedCategoryImg}
                      error={
                        validation.touched.secondFeaturedCategoryImg &&
                        validation.errors.secondFeaturedCategoryImg
                      }
                      handleChooseImage={url => {
                        validation.setFieldValue("secondFeaturedCategoryImg", url)
                      }}
                    />
                  </div>
                </Collapse>
              </div>
            </Col>
            <Col sm="6" className="accordion">
              <div
                className="accordion-item"
                style={{
                  marginBottom: "15px",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <h2
                  className={
                    "accordion-header " +
                    classnames("accordion-button", "fw-medium", {
                      collapsed: !postsSliderAccOpen,
                    })
                  }
                  onClick={() => {
                    setPostsSliderAccOpen(!postsSliderAccOpen)
                  }}
                >
                  {t("forms.Posts Slider")}
                </h2>
                <Collapse
                  isOpen={postsSliderAccOpen}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <FeaturedBoard
                      response={response}
                      settingKey="slider"
                      settingCategory={settingCategory}
                      type="posts"
                      api={getPublishedPostsDropdown}
                      apiMeta={{ lang }}
                      label={"title"}
                      board={slider}
                      setBoard={setSlider}
                    />
                  </div>
                </Collapse>
              </div>
            </Col>
            <Col sm="6" className="accordion">
              <div
                className="accordion-item"
                style={{
                  marginBottom: "15px",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <h2
                  className={
                    "accordion-header " +
                    classnames("accordion-button", "fw-medium", {
                      collapsed: !featuredPostsAccOpen,
                    })
                  }
                  onClick={() => {
                    setFeaturedPostsAccOpen(!featuredPostsAccOpen)
                  }}
                >
                  {t("forms.Featured Posts")}
                </h2>
                <Collapse
                  isOpen={featuredPostsAccOpen}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <FeaturedBoard
                      response={response}
                      settingKey="featuredPosts"
                      settingCategory={settingCategory}
                      type="posts"
                      api={getPublishedPostsDropdown}
                      apiMeta={{ type: "post", lang }}
                      label={"title"}
                      board={featuredPosts}
                      setBoard={setFeaturedPosts}
                    />
                  </div>
                </Collapse>
              </div>
            </Col>
            <Col sm="6" className="accordion">
              <div
                className="accordion-item"
                style={{
                  marginBottom: "15px",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <h2
                  className={
                    "accordion-header " +
                    classnames("accordion-button", "fw-medium", {
                      collapsed: !featuredInfographsAccOpen,
                    })
                  }
                  onClick={() => {
                    setFeaturedInfographsAccOpen(!featuredInfographsAccOpen)
                  }}
                >
                  {t("forms.Featured Infographs")}
                </h2>
                <Collapse
                  isOpen={featuredInfographsAccOpen}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <FeaturedBoard
                      response={response}
                      settingKey="featuredInfographs"
                      settingCategory={settingCategory}
                      type="posts"
                      api={getPublishedPostsDropdown}
                      apiMeta={{ type: "infographic", lang }}
                      label={"title"}
                      board={featuredInfographs}
                      setBoard={setFeaturedInfographs}
                    />
                  </div>
                </Collapse>
              </div>
            </Col>
            <Col sm="6" className="accordion">
              <div
                className="accordion-item"
                style={{
                  marginBottom: "15px",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <h2
                  className={
                    "accordion-header " +
                    classnames("accordion-button", "fw-medium", {
                      collapsed: !featuredVideosAccOpen,
                    })
                  }
                  onClick={() => {
                    setFeaturedVideosAccOpen(!featuredVideosAccOpen)
                  }}
                >
                  {t("forms.Featured Videos")}
                </h2>
                <Collapse
                  isOpen={featuredVideosAccOpen}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <FeaturedBoard
                      response={response}
                      settingKey="featuredVideos"
                      settingCategory={settingCategory}
                      type="posts"
                      api={getPublishedPostsDropdown}
                      apiMeta={{ type: "video", lang }}
                      label={"title"}
                      board={featuredVideos}
                      setBoard={setFeaturedVideos}
                    />
                  </div>
                </Collapse>
              </div>
            </Col>
            <Col sm="6" className="accordion">
              <div
                className="accordion-item"
                style={{
                  marginBottom: "15px",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <h2
                  className={
                    "accordion-header " +
                    classnames("accordion-button", "fw-medium", {
                      collapsed: !featuredAlbumsAccOpen,
                    })
                  }
                  onClick={() => {
                    setFeaturedAlbumsAccOpen(!featuredAlbumsAccOpen)
                  }}
                >
                  {t("forms.Featured Albums")}
                </h2>
                <Collapse
                  isOpen={featuredAlbumsAccOpen}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <FeaturedBoard
                      response={response}
                      settingKey="featuredAlbums"
                      type="posts"
                      api={getPublishedPostsDropdown}
                      apiMeta={{ type: "album", lang }}
                      label={"title"}
                      board={featuredAlbums}
                      setBoard={setFeaturedAlbums}
                    />
                  </div>
                </Collapse>
              </div>
            </Col>
          </Row>
        )}
        {!loading && (
          <FormButtonHandler btnTxt="Save" loading={updateLoading} />
        )}
      </Form>

      <ResultHandler
        error={updateError}
        success={updateSuccess}
        successMsg={"Updated Successfully!"}
      />
    </>
  )
}


import React, { useEffect, useState } from "react";
import { Col, Collapse, Row } from "reactstrap";
import classnames from "classnames"
import SearchSelectBox from "components/SearchSelectBox"
import { getRolesDropdown } from "api/roles"
import { useTranslation } from "react-i18next"

export default function TypeRules({ defaultRules, defaultAuthors, setApiRules, type }) {
    const { t } = useTranslation()

    const [rules, setRules] = useState(defaultRules ? defaultRules : {
        Title: "required:|minLength:|maxLength:",
        Content: "required:|minContent:|maxContent:",
        Slug: "required:|minLength:|maxLength:",
        Summary: "required:|minLength:|maxLength:",
        Image: "required:",
        Categories: "required:",
        Tags: "required:",
    })

    const [authorValid, setAuthorValid] = useState(defaultAuthors ? defaultAuthors : { checked: false, role: null })
    function handleCheck(key, inx) {
        setRules({
            ...rules,
            [key]: rules[key]
                .split("|")
                .map((r, i) => {
                    if (i === inx) {
                        if (r.split(":")[1]) {
                            return r.split(":")[0] + ":"
                        } else {
                            return r.split(":")[0] + ":" + "1"
                        }
                    } else {
                        return r
                    }
                })
                .join("|"),
        })
    }

    function handleNumberChange(key, inx, num) {
        setRules({
            ...rules,
            [key]: rules[key]
                .split("|")
                .map((r, i) => {
                    if (i === inx) {
                        return r.split(":")[0] + ":" + num
                    } else {
                        return r
                    }
                })
                .join("|"),
        })
    }

    useEffect(() => {
        const title = rules.Title.split("|")
            .filter(r => {
                if (r.split(":")[1].length > 0) {
                    return true
                }
            })
            .map(r => {
                if (r.split(":")[0] === "required") {
                    return "required"
                } else {
                    return r
                }
            })
            .join("|")

        const postContent = rules.Content.split("|")
            .filter(r => {
                if (r.split(":")[1].length > 0) {
                    return true
                }
            })
            .map(r => {
                if (r.split(":")[0] === "required") {
                    return "required"
                } else {
                    return r
                }
            })
            .join("|")

        const Tags = rules.Tags.split("|")
            .filter(r => {
                if (r.split(":")[1].length > 0) {
                    return true
                }
            })
            .map(r => {
                if (r.split(":")[0] === "required") {
                    return "required"
                } else {
                    return r
                }
            })
            .join("|")

        const Categories = rules.Categories.split("|")
            .filter(r => {
                if (r.split(":")[1].length > 0) {
                    return true
                }
            })
            .map(r => {
                if (r.split(":")[0] === "required") {
                    return "required"
                } else {
                    return r
                }
            })
            .join("|")

        const mainImage_url = rules.Image.split("|")
            .filter(r => {
                if (r.split(":")[1].length > 0) {
                    return true
                }
            })
            .map(r => {
                if (r.split(":")[0] === "required") {
                    return "required"
                } else {
                    return r
                }
            })
            .join("|")

        const slug = rules.Slug.split("|")
            .filter(r => {
                if (r.split(":")[1].length > 0) {
                    return true
                }
            })
            .map(r => {
                if (r.split(":")[0] === "required") {
                    return "required"
                } else {
                    return r
                }
            })
            .join("|")

        const summary = rules.Summary.split("|")
            .filter(r => {
                if (r.split(":")[1].length > 0) {
                    return true
                }
            })
            .map(r => {
                if (r.split(":")[0] === "required") {
                    return "required"
                } else {
                    return r
                }
            })
            .join("|")

        setApiRules({

            title: title.length > 0 ? title : null,

            postContent: postContent.length > 0 ? postContent : null,

            Tags: Tags.length > 0 ? Tags : null,

            Categories: Categories.length > 0 ? Categories : null,

            mainImage_url: mainImage_url.length > 0 ? mainImage_url : null,

            slug: slug.length > 0 ? slug : null,

            summary: summary.length > 0 ? summary : null,

            roleId: authorValid.role ? authorValid.role.value : null,

        })
    }, [rules, authorValid])



    return (
        <div className="accordion">
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button
                        className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                                collapsed: false,
                            }
                        )}
                        type="button"
                        style={{ cursor: "pointer" }}
                    >
                        {t("forms." + type)}
                    </button>
                </h2>
                <Collapse
                    isOpen={true}
                    className="accordion-collapse"
                >
                    <div className="accordion-body">
                        <Row>
                            {Object.keys(rules).map(key => {
                                return (
                                    <Col
                                        style={{ marginBottom: "30px" }}
                                        md="3"
                                        key={key}
                                    >
                                        <div className="accordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className={classnames(
                                                            "accordion-button",
                                                            "fw-medium",
                                                            {
                                                                collapsed: false,
                                                            }
                                                        )}
                                                        type="button"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {t("forms." + key)}
                                                    </button>
                                                </h2>
                                                <Collapse
                                                    isOpen={true}
                                                    className="accordion-collapse"
                                                >
                                                    <div className="accordion-body">
                                                        <div className="text-muted">
                                                            {rules[key].split("|").map((r, inx) => {
                                                                return (
                                                                    <div
                                                                        key={inx}
                                                                        style={{ marginBottom: "20px" }}
                                                                    >
                                                                        <input
                                                                            checked={
                                                                                r.split(":")[1] ? true : false
                                                                            }
                                                                            onChange={() => {
                                                                                handleCheck(key, inx)
                                                                            }}
                                                                            style={{ marginLeft: "10px" }}
                                                                            value={5}
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                        />
                                                                        {t("validation." + r.split(":")[0])}
                                                                        {r.split(":")[0] != "required" &&
                                                                            r.split(":")[1] && (
                                                                                <input
                                                                                    value={r.split(":")[1]}
                                                                                    onChange={e => {
                                                                                        handleNumberChange(
                                                                                            key,
                                                                                            inx,
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    style={{ marginTop: "10px" }}
                                                                                    type={"text"}
                                                                                    className="form-control"
                                                                                    placeholder="ادخل عدد"
                                                                                />
                                                                            )}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}

                            <Col md={3}>
                                <div className="accordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    "fw-medium",
                                                    {
                                                        collapsed: false,
                                                    }
                                                )}
                                                type="button"
                                                style={{ cursor: "pointer" }}
                                            >
                                                {t("forms.Authors")}
                                            </button>
                                        </h2>
                                        <Collapse
                                            isOpen={true}
                                            className="accordion-collapse"
                                        >
                                            <div className="accordion-body">
                                                <input
                                                    style={{ marginLeft: "10px" }}
                                                    onChange={() => {
                                                        if (!authorValid.checked) {
                                                            setAuthorValid({
                                                                ...authorValid,
                                                                checked: true,
                                                            })
                                                        } else {
                                                            setAuthorValid({
                                                                checked: false,
                                                                role: null,
                                                            })
                                                        }
                                                    }}
                                                    checked={authorValid.checked ? true : false}
                                                    type={"checkbox"}
                                                />
                                                <label>{t("validation.required")}</label>
                                                {authorValid.checked && (
                                                    <div>
                                                        <label>{t("pages.Role")}</label>

                                                        <SearchSelectBox
                                                            name="role"
                                                            error={false}
                                                            isMulti={false}
                                                            value={authorValid.role}
                                                            type="roles"
                                                            label="name"
                                                            api={getRolesDropdown}
                                                            setValue={option => {
                                                                setAuthorValid({
                                                                    ...authorValid,
                                                                    role: option,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}
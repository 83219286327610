import React, { useEffect, useState } from "react"
import { getSettings, updateSettings } from "api/settings"
import { usePagination } from "hooks/usePagination"
import classnames from "classnames"
import FormButtonHandler from "components/FormButtonHandler"
import ResultHandler from "components/ResultHandler"
import {
  Row,
  Col,
  Form,
  Collapse,
  Input,
  Label,
  FormGroup,
  Button,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"
import { getCategoriesDropdown, getMonitorCategories } from "api/categories"
import Board, { moveCard } from "../../../packages/react-kanban/index"
import NavItem from "./NavItem"

export default function MenuSettings({ lang }) {
  const { t } = useTranslation()
  const settingCategory = "menu" + (lang === "en" ? "_" + lang : "")

  const { response, loading, error } = usePagination(
    getSettings,
    settingCategory
  )

  useEffect(() => {
    if (response.data) {
      setItems({
        columns: [
          {
            id: 1,
            title: "",
            cards: response.data.data[settingCategory].items
              ? response.data.data[settingCategory].items
              : [],
          },
        ],
      })
    }
  }, [response])

  const {
    setData: setUpdateSettings,
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useResponse(updateSettings)

  function handleUpdateSettings() {
    setUpdateSettings({
      type: "edit",
      id: settingCategory,
      data: {
        items: items.columns[0].cards.map((c, i) => {
          return { ...c, order: i }
        }),
      },
    })
  }

  // items board

  const [items, setItems] = useState({
    columns: [
      {
        id: 1,
        title: "",
        cards: [],
      },
    ],
  })

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(items, source, destination)
    setItems(updatedBoard)
  }

  function handleRemoveItem(id) {
    setItems({
      columns: [
        {
          ...items.columns[0],
          cards: items.columns[0].cards.filter(c => c.id != id),
        },
      ],
    })
  }

  function handleChangeCustomLink(id, key, value) {
    setItems({
      columns: [
        {
          ...items.columns[0],
          cards: items.columns[0].cards.map(c => {
            if (c.id == id) {
              return { ...c, [key]: value }
            } else {
              return c
            }
          }),
        },
      ],
    })
  }

  // categories accordion

  const [categoriesAccordionOpen, setCategoriesAccordionOpen] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [catsSearchTxt, setCatsSearchTxt] = useState("")
  const {
    loading: catsSearchLoading,
    error: catsSearchError,
    response: catsSearchResponse,
    setPayload: setCatsSearch,
  } = usePagination(getCategoriesDropdown, { lang })

  function handleChangeCategory(category) {
    if (selectedCategories.map(c => c.id).includes(category.id)) {
      setSelectedCategories(selectedCategories.filter(c => c.id != category.id))
    } else {
      setSelectedCategories([...selectedCategories, category])
    }
  }

  function handleAddCategory() {
    setItems({
      columns: [
        {
          ...items.columns[0],
          cards: [
            ...items.columns[0].cards,
            ...selectedCategories.map(c => {
              return { ...c, type: "category", url: null, kickers: [] }
            }),
          ],
        },
      ],
    })
    setSelectedCategories([])
  }

  function handleCheckKicker(newKickerId, categoryId) {

    setItems({
      columns: [
        {
          ...items.columns[0],
          cards: items.columns[0].cards.map(c => {
            if (c.id === categoryId) {
              let newKickers = c.kickers || []
              if (newKickers.includes(newKickerId)) {
                newKickers = newKickers.filter(kickerId => kickerId != newKickerId)
              } else {
                newKickers.push(newKickerId)
              }

              return { ...c, type: "category", url: null, kickers: newKickers }
            } else {
              return c
            }

          }),

        },
      ],
    })
  }

  // custom link

  const [customLinkAccordionOpen, setCustomLinkAccordionOpen] = useState(false)

  const [customLink, setCustomLink] = useState({ url: "", name: "" })

  function handleAddCustomLink() {
    setItems({
      columns: [
        {
          ...items.columns[0],
          cards: [
            ...items.columns[0].cards,
            {
              ...customLink,
              id: "link-" + customLink.url,
              type: "custom",
              slug: null,
            },
          ],
        },
      ],
    })
    setCustomLink({ url: "", name: "" })
  }

  return (
    <>
      <ResultHandler loading={loading} />
      {response.data && (
        <Row>
          <Col sm="9">
            <Board
              onCardDragEnd={handleCardMove}
              renderCard={content => (
                <NavItem
                  handleRemoveItem={handleRemoveItem}
                  handleChangeCustomLink={handleChangeCustomLink}
                  handleCheckKicker={handleCheckKicker}
                  content={content}
                />
              )}
              disableColumnDrag
            >
              {items}
            </Board>
          </Col>
          <Col sm="3" className="accordion">
            <div
              className="accordion-item"
              style={{
                marginBottom: "15px",
                backgroundColor: "white",
                border: "1px solid rgba(0, 0, 0, 0.125)",
              }}
            >
              <h2
                className={
                  "accordion-header " +
                  classnames("accordion-button", "fw-medium", {
                    collapsed: !categoriesAccordionOpen,
                  })
                }
                onClick={() => {
                  setCategoriesAccordionOpen(!categoriesAccordionOpen)
                }}
              >
                {t("pages.Categories")}
              </h2>
              <Collapse
                isOpen={categoriesAccordionOpen}
                className="accordion-collapse"
              >
                <div className="accordion-body">
                  <Input
                    type="text"
                    onChange={e => {
                      setCatsSearchTxt(e.target.value)
                      setCatsSearch({ txt: e.target.value, lang })
                    }}
                    value={catsSearchTxt}
                    className="form-control"
                    placeholder={t("forms.Search") + "..."}
                    style={{ marginBottom: "15px" }}
                  />

                  {catsSearchResponse.data &&
                    catsSearchResponse.data.categories
                      .filter(
                        t =>
                          !items.columns[0].cards.map(c => c.id).includes(t.id)
                      )
                      .map(t => {
                        return (
                          <div
                            className="d-flex"
                            style={{
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                            key={t.id}
                          >
                            <input
                              type={"checkbox"}
                              checked={selectedCategories
                                .map(c => c.id)
                                .includes(t.id)}
                              onChange={() => {
                                handleChangeCategory(t)
                              }}
                            />
                            <Label
                              style={{
                                marginRight: "10px",
                                marginBottom: "0px",
                              }}
                            >
                              {t.name}
                            </Label>
                          </div>
                        )
                      })}

                  <Button color="primary" onClick={handleAddCategory}>
                    {t("btns.Add")}
                  </Button>
                </div>
              </Collapse>
            </div>
            <div
              className="accordion-item"
              style={{
                marginBottom: "15px",
                backgroundColor: "white",
                border: "1px solid rgba(0, 0, 0, 0.125)",
              }}
            >
              <h2
                className={
                  "accordion-header " +
                  classnames("accordion-button", "fw-medium", {
                    collapsed: !customLinkAccordionOpen,
                  })
                }
                onClick={() => {
                  setCustomLinkAccordionOpen(!customLinkAccordionOpen)
                }}
              >
                {t("forms.Custom Link")}
              </h2>
              <Collapse
                isOpen={customLinkAccordionOpen}
                className="accordion-collapse"
              >
                <div className="accordion-body">
                  <FormGroup className="mb-3">
                    <Label>{t("forms.Url")}</Label>
                    <Input
                      type="text"
                      placeholder={t("forms.Url")}
                      className="form-control"
                      value={customLink.url}
                      onChange={e => {
                        setCustomLink({ ...customLink, url: e.target.value })
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <Label>{t("forms.Name")}</Label>
                    <Input
                      type="text"
                      placeholder={t("forms.Name")}
                      className="form-control"
                      value={customLink.name}
                      onChange={e => {
                        setCustomLink({ ...customLink, name: e.target.value })
                      }}
                    />
                  </FormGroup>
                  <Button color="primary" onClick={handleAddCustomLink}>
                    {t("btns.Add")}
                  </Button>
                </div>
              </Collapse>
            </div>
          </Col>
        </Row>
      )}
      {!loading && (
        <FormButtonHandler
          btnTxt="Save"
          type="button"
          handleClick={handleUpdateSettings}
          loading={updateLoading}
        />
      )}

      <ResultHandler
        error={updateError}
        success={updateSuccess}
        successMsg={"Updated Successfully!"}
      />
    </>
  )
}

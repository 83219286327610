import { forwardRef, useRef, useEffect } from "react"
import React from "react"
import { Draggable } from "react-beautiful-dnd"
import Card from "./components/Card"
import withDroppable from "../../../withDroppable"
import CardAdder from "./components/CardAdder"
import { pickPropOut } from "../../../../services/utils"
import { usePagination } from "hooks/usePagination"
import { getStagePosts } from "api/posts"
import ResultHandler from "components/ResultHandler"

const ColumnEmptyPlaceholder = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{ minHeight: "inherit", height: "inherit" }}
      {...props}
    ></div>
  )
})
ColumnEmptyPlaceholder.displayName = "ColumnEmptyPlaceholder"

const DroppableColumn = withDroppable(ColumnEmptyPlaceholder)
function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}
export default function Column({
  children,
  index: columnIndex,
  renderCard,
  renderColumnHeader,
  disableColumnDrag,
  disableCardDrag,
  onCardNew,
  allowAddCard,
  stageId,
  loadStagePosts,
}) {
  const target = useRef()
  const c = useRef()

  const {
    response: postsResponse,
    loading: postsLoading,
    error: postsError,
    setPage,
    page,
    totalPages,
  } = usePagination(getStagePosts, stageId)

  useEffect(() => {
    if (postsResponse.data) {
      loadStagePosts(stageId, [...children.cards, ...postsResponse.data.posts])
    }
  }, [postsResponse])

  useEffect(() => {
    function callback([entry]) {
      debounce(() => {
        if (!postsLoading && entry.isIntersecting) {
          setPage(p => p + 1)
        }
      }, 500)()
    }
    if (c.current && target.current) {
      let options = {
        root: c.current,
        rootMargin: "0px",
        threshold: 1,
      }
      let observer = new IntersectionObserver(callback, options)
      observer.observe(target.current)
    }
  }, [c, target, postsLoading, page, totalPages])

  return (
    <Draggable
      draggableId={`column-draggable-${children.id}`}
      index={columnIndex}
      isDragDisabled={disableColumnDrag}
    >
      {columnProvided => {
        const draggablePropsWithoutStyle = pickPropOut(
          columnProvided.draggableProps,
          "style"
        )

        return (
          <div
            ref={columnProvided.innerRef}
            {...draggablePropsWithoutStyle}
            style={{
              height: "100%",
              minHeight: "600px",
              width: "320px",
              display: "inline-block",
              verticalAlign: "top",
              ...columnProvided.draggableProps.style,
            }}
            className="react-kanban-column"
            data-testid={`column-${children.id}`}
          >
            <div {...columnProvided.dragHandleProps}>
              {renderColumnHeader(children)}
            </div>
            {allowAddCard && (
              <CardAdder column={children} onConfirm={onCardNew} />
            )}{" "}
            <div
              ref={c}
              className="columnCardsWraper"
              style={{ minHeight: "250px", minWidth: "100%" }}
            >
              <DroppableColumn droppableId={String(children.id)}>
                {children.cards.map((card, index) => (
                  <Card
                    key={card.id}
                    index={index}
                    renderCard={dragging =>
                      renderCard(children, card, dragging)
                    }
                    disableCardDrag={disableCardDrag}
                  >
                    {card}
                  </Card>
                ))}
                <ResultHandler loading={postsLoading} error={postsError} />
                {page < totalPages - 1 && (
                  <span
                    ref={target}
                    style={{
                      width: "100%",
                      height: "20px",
                      display: "block",
                      backgroundColor: "transparent",
                    }}
                  ></span>
                )}
              </DroppableColumn>
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}

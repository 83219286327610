import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"

import ChooseImage from "components/ChooseImage"

import { useTranslation } from "react-i18next"

const PollForm = ({ validation }) => {
  const { t } = useTranslation()

  function handleChooseImage(url) {
    validation.setFieldValue("cover_url", url)
  }

  function handleAddOption() {
    validation.setFieldValue("competitors", [
      ...validation.values.competitors,
      { text: "", image_url: "" },
    ])
  }

  function handleRemoveOption(index) {
    validation.setFieldValue(
      "competitors",
      validation.values.competitors.filter((comp, i) => i != index)
    )
  }

  return (
    <Row>
      <Col md="6">
        <FormGroup className="mb-3">
          <Label>{t("forms.Name")}</Label>
          <Input
            name="text"
            placeholder={t("forms.Name")}
            type="text"
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.text || ""}
            invalid={
              validation.touched.text && validation.errors.text ? true : false
            }
          />
          {validation.touched.text && validation.errors.text ? (
            <FormFeedback type="invalid">{validation.errors.text}</FormFeedback>
          ) : null}
        </FormGroup>
        <div className="form-check form-switch form-switch-md">
          <input
            type="checkbox"
            onChange={() => {
              validation.setFieldValue(
                "resultVisibility",
                validation.values.resultVisibility ? false : true
              )
            }}
            className="form-check-input"
            checked={validation.values.resultVisibility}
          />
          <Label>{t("forms.resultVisibility")}</Label>
        </div>
        <div className="mb-3">
          <ChooseImage
            name="cover_url"
            image={validation.values.cover_url}
            error={validation.touched.cover_url && validation.errors.cover_url}
            handleChooseImage={handleChooseImage}
          />
        </div>
      </Col>
      <Col md="6">
        <Label style={{ display: "flex", justifyContent: "space-between" }}>
          {t("forms.Options")}
          <Button
            color="primary"
            onClick={handleAddOption}
            type="button"
            className="btn-sm"
          >
            <i
              className="bx bx-plus"
              style={{ fontSize: "20px", cursor: "pointer" }}
            ></i>
          </Button>
        </Label>
        {validation.touched.competitors && validation.errors.competitors ? (
          <div
            className="invalid-feedback"
            style={{ display: "block", marginBottom: "10px" }}
            type="invalid"
          >
            {validation.errors.competitors}
          </div>
        ) : null}
        {validation.values.competitors.map((option, i) => {
          return (
            <div key={i}>
              <FormGroup className="mb-3">
                <ChooseImage
                  name={"optionImg" + i}
                  image={option.image_url}
                  error={null}
                  handleChooseImage={url => {
                    validation.setFieldValue(
                      "competitors",
                      validation.values.competitors.map((comp, index) => {
                        if (i === index) {
                          return { ...comp, image_url: url }
                        } else {
                          return comp
                        }
                      })
                    )
                  }}
                  hideLabel={true}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button color="danger" type="button" className="btn-sm">
                    <i
                      onClick={() => {
                        handleRemoveOption(i)
                      }}
                      className="bx bx-trash"
                      style={{ fontSize: "20px", cursor: "pointer" }}
                    ></i>
                  </Button>
                  <Input
                    placeholder={t("forms.Text")}
                    type="text"
                    className="form-control"
                    onChange={e => {
                      validation.setFieldValue(
                        "competitors",
                        validation.values.competitors.map((comp, index) => {
                          if (i === index) {
                            return { ...comp, text: e.target.value }
                          } else {
                            return comp
                          }
                        })
                      )
                    }}
                    value={option.text || ""}
                  />
                </div>
              </FormGroup>
            </div>
          )
        })}
        {validation.touched.competitors && validation.errors.competitors ? (
          <FormFeedback type="invalid">
            {validation.errors.competitors}
          </FormFeedback>
        ) : null}
      </Col>
    </Row>
  )
}

export default PollForm

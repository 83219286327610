

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
  } from "reactstrap"

  import React from "react"
  import SearchSelectBox from "components/SearchSelectBox"
  import SlugInput from "components/SlugInput"
  import { getPresentersDropdown } from "api/presenters"
  import ChooseImage from "components/ChooseImage"
  import { useTranslation } from "react-i18next"

export default function ProgramForm({validation}){
    const { t } = useTranslation()

    return(
       
        <Row>
            <Col md={6}>
                <FormGroup className="mb-3">
                <Label>{t("forms.Name")}</Label>
                <Input
                    name="name"
                    placeholder={t("forms.Name")}
                    type="text"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                    validation.touched.name &&
                    validation.errors.name
                        ? true
                        : false
                    }
                />
                {validation.touched.name &&
                validation.errors.name ? (
                    <FormFeedback type="invalid">
                    {validation.errors.name}
                    </FormFeedback>
                ) : null}
                </FormGroup>
            </Col>
            <Col md={6}>
                <SlugInput
                    validation={validation}
                    source={validation.values.name}
                />
            </Col>
            
            <Col md="6">
              <FormGroup className="mb-3">
              <Label>{t("pages.Presenters")}</Label>
              <SearchSelectBox
                  value={validation.values.presenters || null}
                  setValue={option => {
                  validation.setFieldValue("presenters", option)
                  }}
                  error={
                  validation.touched.presenters &&
                  validation.errors.presenters
                  }
                  handleFocus={() => {
                  validation.setFieldTouched("presenters")
                  }}
                  handleBlur={validation.handleBlur}
                  isMulti={true}
                  api={getPresentersDropdown}
                  type="presenters"
                  label="name"
                  name="presenters" // for formik
              />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
              <Label>{t("forms.Order")}</Label>
              <Input
                  name="order"
                  placeholder={t("forms.Order")}
                  type="text"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.order || ""}
                  invalid={
                  validation.touched.order &&
                  validation.errors.order
                      ? true
                      : false
                  }
              />
              {validation.touched.order &&
              validation.errors.order ? (
                  <FormFeedback type="invalid">
                  {validation.errors.order}
                  </FormFeedback>
              ) : null}
              </FormGroup>
            </Col>
            <Col md={12}>
            <FormGroup className="mb-3">
                <Label>{t("forms.Summary")}</Label>
                <Input
                    name="summary"
                    placeholder={t("forms.Summary")}
                    type="textarea"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.summary || ""}
                    invalid={
                    validation.touched.summary &&
                    validation.errors.summary
                        ? true
                        : false
                    }
                />

                {validation.touched.summary &&
                validation.errors.summary ? (
                    <FormFeedback type="invalid">
                    {validation.errors.summary}
                    </FormFeedback>
                ) : null}
                </FormGroup>
            </Col>
            <Col className="mb-3" md={6}>
                <ChooseImage
                    name="img"
                    image={validation.values.img}
                    error={
                        validation.touched.img && validation.errors.img
                    }
                    handleChooseImage={(url) => {
                        validation.setFieldValue("img", url)
                    }}
                />
            </Col>
            <Col md="6">
                <ChooseImage
                    name="cover_url"
                    label="cover_image"
                    image={validation.values.cover_url}
                    error={
                        validation.touched.cover_url && validation.errors.cover_url
                    }
                    handleChooseImage={(url) => {
                        validation.setFieldValue("cover_url", url)

                    }}
                />
            </Col>
        </Row>

    )
}
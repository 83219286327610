import React, { useState } from "react"

import { Link } from "react-router-dom"

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logo from "../../assets/images/QN-logo-mini.png"
import logoLightSvg from "../../assets/images/QN-logo-mini.png"
import { PERMISSIONS } from "api/permissions"
import CustomImage from "components/CustomImage"

const Header = props => {
  const [showInfo, setShowInfo] = useState(false)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <CustomImage src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <CustomImage src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            {process.env.REACT_APP_ENV == "next" && (
              <div className="d-flex align-items-center">
                <div
                  style={{ direction: "ltr" }}
                  className="text-white rounded text-center p-2 bg-danger font-size-14"
                  onClick={() => setShowInfo(a => !a)}
                >
                  <span className="font-size-16 mx-1">
                    <i className=" bx bx-info-circle" />
                  </span>
                  Warning
                  {showInfo && (
                    <span>
                      | This is a test version connected to the real data, any
                      change made here will reflect on the production system,
                      please use{" "}
                      <a
                        className="font-weight-semibold text-white"
                        href="https://crcdashboard.fjmf.xyz"
                      >
                        crcdashboard.fjmf.xyz
                      </a>
                      , for testing
                    </span>
                  )}
                </div>
              </div>
            )}
            {process.env.REACT_APP_ENV == "test" && (
              <div className="d-flex align-items-center">
                <div
                  style={{ direction: "ltr" }}
                  className="text-white rounded text-center p-2 bg-warning font-size-14"
                  onClick={() => setShowInfo(a => !a)}
                >
                  <span className="font-size-16 mx-1">
                    <i className=" bx bx-info-circle" />
                  </span>
                  Info{" "}
                  {showInfo && (
                    <span>
                      | this is a test system with dummy data, for the
                      production use{" "}
                      <a
                        className="font-weight-semibold text-white"
                        href="https://sdvfsrethjnbmnbkmbk.alqaheranews.net"
                      >
                        sdvfsrethjnbmnbkmbk.alqaheranews.net
                      </a>
                      , for next version testing use{" "}
                      <a
                        className="font-weight-semibold text-white"
                        href="https://admin.fjmf.xyz"
                      >
                        admin.fjmf.xyz
                      </a>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="d-flex">
            {localStorage
              .getItem("permissions")
              .includes(PERMISSIONS.TRASH_VIEW) && (
              <Link
                to="/trash"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "24px",
                  color: "#555b6d",
                  padding: "0px 10px",
                }}
              >
                <i className="mdi mdi-trash-can-outline"></i>
              </Link>
            )}

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <NotificationDropdown />

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header

import React from "react"
//import components
import Breadcrumbs from "components/Common/Breadcrumb"

import { useTranslation } from "react-i18next"
import UrgentTable from "./UrgentTable"
export default function UrgentPostsList() {
  const { t } = useTranslation()
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.Urgent Posts")} />
        <UrgentTable />
      </div>
    </div>
  )
}

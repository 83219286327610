import React, { useEffect, useState } from "react"
import {
  addCategory,
  getCategories,
  getCategoriesDropdown,
} from "api/categories"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import FormButtonHandler from "components/FormButtonHandler"
import ChooseImage from "components/ChooseImage"
import SearchSelectBox from "components/SearchSelectBox"
import useCustomValidation from "../validationSchema"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import SlugInput from "components/SlugInput"

const AddCategory = () => {
  const { t } = useTranslation()
  let navigate = useHistory()
  const { categorySchema } = useCustomValidation()

  // posting

  const { setData, loading, error, success } = useResponse(addCategory, () => {
    navigate.push("/categories")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      slug: "",
      category: null,
      img: "",
      isInternal: false,
      language: "ar",
      layout: "grid"
    },
    validationSchema: categorySchema,
    onSubmit: values => {
      let data = {
        ...values,
        parentCategoryId: values.category ? values.category.value : null,
        image_url: values.img,
      }
      setData({ data, type: "add" })
    },
  })

  function handleChooseImage(url) {
    validation.setFieldValue("img", url)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Categories")}
            breadcrumbItem={t("btns.Add") + " " + t("pages.Category")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label>{t("forms.Name")}</Label>
                          <Input
                            name="name"
                            placeholder={t("forms.Name")}
                            type="text"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        <SlugInput
                          validation={validation}
                          source={validation.values.name}
                        />
                        <FormGroup className="mb-3">
                          <Label>{t("forms.Parent Category")}</Label>
                          <SearchSelectBox
                            value={validation.values.category || null}
                            setValue={option => {
                              validation.setFieldValue("category", option)
                            }}
                            error={
                              validation.touched.category &&
                              validation.errors.category
                            }
                            handleFocus={() => {
                              validation.setFieldTouched("category")
                            }}
                            handleBlur={validation.handleBlur}
                            isMulti={false}
                            api={getCategoriesDropdown}
                            apiMeta={{ parentsOnly: "1" }}
                            type="categories"
                            label="name"
                            name="category" // for formik
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label>{t("forms.Description")}</Label>
                          <Input
                            name="description"
                            placeholder={t("forms.Description")}
                            type="text"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={
                              validation.touched.description &&
                              validation.errors.description
                                ? true
                                : false
                            }
                          />
                          {validation.touched.description &&
                          validation.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label>{t("forms.Layout")}</Label>
                          <select 
                            className="form-control" 
                            name="layout" 
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur} 
                            value={validation.values.layout || ""}
                          >
                              <option value={"grid"}>Grid</option>
                              <option value={"list"}>List</option>
                            </select>
                        </FormGroup>
                        
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <ChooseImage
                            name="img"
                            image={validation.values.img}
                            error={
                              validation.touched.img && validation.errors.img
                            }
                            handleChooseImage={handleChooseImage}
                          />
                        </div>
                        
                        {/*<FormGroup className="mb-3">
                          <Label>{t("forms.Language")}</Label>

                          <select
                            name="language"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.language || ""}
                          >
                            <option value="ar">العربية</option>
                            <option value="en">English</option>
                          </select>
                          {validation.touched.language &&
                          validation.errors.language ? (
                            <FormFeedback type="invalid">
                              {validation.errors.language}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>*/}
                      </Col>
                    </Row>
                    <div className="form-check form-switch form-switch-md">
                      <input
                        name="isInternal"
                        type="checkbox"
                        onChange={validation.handleChange}
                        className="form-check-input"
                        checked={validation.values.isInternal}
                      />

                      <Label>{t("forms.Internal Category")}</Label>
                    </div>
                    <FormButtonHandler btnTxt="Add" loading={loading} />
                  </Form>

                  <ResultHandler
                    error={error}
                    success={success}
                    successMsg={"Created Successfully!"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddCategory

import React from "react"
import { getSettings, updateSettings } from "api/settings"
import { usePagination } from "hooks/usePagination"
import FormButtonHandler from "components/FormButtonHandler"
import ResultHandler from "components/ResultHandler"
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { useFormik } from "formik"
import useCustomValidation from "../validationSchema"
import { useTranslation } from "react-i18next"
import { useResponse } from "hooks/useResponse"

export default function UrgentSettings({ lang }) {
  const { t } = useTranslation()
  const { homeSettingsSchema } = useCustomValidation()
  const settingCategory = "urgent" + (lang === "en" ? "_" + lang : "")
  const { response, loading, error } = usePagination(
    getSettings,
    settingCategory
  )
  const {
    setData: setUpdateSettings,
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = useResponse(updateSettings)

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      timeout: response.data ? response.data.data[settingCategory].timeout : "",
    },
    validationSchema: homeSettingsSchema,
    onSubmit: values => {
      setUpdateSettings({
        type: "edit",
        id: settingCategory,
        data: values,
      })
    },
  })

  return (
    <>
      <Form
        className="needs-validation"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <ResultHandler loading={loading} />
        {response.data && (
          <Row>
            <Col sm="6" className="accordion">
              <FormGroup>
                <Label>{t("forms.Timeout")}</Label>
                <Input
                  min={5}
                  max={120}
                  type="number"
                  name="timeout"
                  value={validation.values.timeout}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {!loading && (
          <FormButtonHandler btnTxt="Save" loading={updateLoading} />
        )}
      </Form>

      <ResultHandler
        error={updateError}
        success={updateSuccess}
        successMsg={"Updated Successfully!"}
      />
    </>
  )
}

import React from "react"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useTranslation } from "react-i18next"
import ArticlesTable from "./ArticlesTable"
export default function ArticlesList() {
  const { t } = useTranslation()
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("pages.Articles")} />

        <ArticlesTable />
      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react"
import "../../../packages/react-doka/lib/doka.min.css"
import { create } from "packages/react-doka/lib/doka.esm.min"
import { useTranslation } from "react-i18next"

export default function CropImageModal({ onConfirm, file }) {
  const {t} = useTranslation()
  var doka = create({
    cropAspectRatio: 7 / 12,
    outputWidth: 900,
    outputHeight: 506,
    outputQuality: 40,
    labelStatusLoadingImage: t("modals.Load Image"),
    labelStatusProcessingImage: t("modals.Editing"),
  })

  const [fileObj, setFileObj] = useState(null)

  useEffect(() => {
    if (file.url) {
      const objectKey = file.url.match(/[^\/]+$/g)[0]
      fetch("https://fmcdn.alqaheranews.net/"+objectKey)
        .then(e => {
          return e.blob()
        })
        .then(blob => {
          let b = blob
          b.lastModifiedDate = new Date()
          b.name = ""
          var f = new File([b], file.name ? file.name : file.fileName, {
            type: "image",
          })
          setFileObj(f)
        })
    } else {
      setFileObj(file)
    }
  }, [file])

  useEffect(() => {
    if (fileObj) {
      doka.edit(fileObj).then(img => {
        onConfirm(img)
      })
    }
  }, [fileObj])

  return <></>
}

import React from "react"

import { Row, Col, FormGroup, Label, Input, FormFeedback } from "reactstrap"

import ChooseImage from "components/ChooseImage"

import { useTranslation } from "react-i18next"
import SlugInput from "components/SlugInput"
import Select from "react-select"
import ContentEditor from "pages/dashboard/posts/crud/ContentEditor"
import { convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"

const StadiumForm = ({
  validation,
  setJsonDescription,
  setHtmlDescription,
  defaultDescription,
}) => {
  const { t } = useTranslation()

  function handleChooseImage(url) {
    validation.setFieldValue("img", url)
  }

  const floorTypes = [{ label: "test", value: "test" }]

  // text editor

  const onEditorStateChange = (editorState, order) => {
    let meta = convertToRaw(editorState.getCurrentContent())
    let plain = editorState.getCurrentContent().getPlainText("\n")
    setJsonDescription(meta)
    setHtmlDescription(draftToHtml(meta))
    validation.setFieldValue("raw_description", plain)
  }

  return (
    <Row>
      <Col md="6">
        <FormGroup className="mb-3">
          <Label>{t("forms.Name")}</Label>
          <Input
            name="name"
            placeholder={t("forms.Name")}
            type="text"
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ""}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
          ) : null}
        </FormGroup>
        <FormGroup className="mb-3">
          <Label>{t("forms.Description")}</Label>
          <ContentEditor
            defaultContent={defaultDescription}
            order={1}
            onEditorStateChange={onEditorStateChange}
          />
          {validation.touched.raw_description &&
          validation.errors.raw_description ? (
            <div
              style={{
                width: "100%",
                marginTop: "0.25rem",
                fontSize: "80%",
                color: "#f46a6a",
              }}
            >
              {validation.errors.raw_description}
            </div>
          ) : null}
        </FormGroup>
        <SlugInput validation={validation} source={validation.values.name} />
        <FormGroup className="mb-3">
          <Label>{t("forms.Opening Year")}</Label>
          <Input
            name="year"
            placeholder={t("forms.Opening Year")}
            type="number"
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.year || ""}
            invalid={
              validation.touched.year && validation.errors.year ? true : false
            }
          />
          {validation.touched.year && validation.errors.year ? (
            <FormFeedback type="invalid">{validation.errors.year}</FormFeedback>
          ) : null}
        </FormGroup>
        <FormGroup className="mb-3">
          <Label>{t("forms.Location")}</Label>
          <Input
            name="location"
            placeholder={t("forms.Location")}
            type="text"
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.location || ""}
            invalid={
              validation.touched.location && validation.errors.location
                ? true
                : false
            }
          />
          {validation.touched.location && validation.errors.location ? (
            <FormFeedback type="invalid">
              {validation.errors.location}
            </FormFeedback>
          ) : null}
        </FormGroup>
      </Col>
      <Col md="6">
        <div className="mb-3">
          <ChooseImage
            name="img"
            image={validation.values.img}
            error={validation.touched.img && validation.errors.img}
            handleChooseImage={handleChooseImage}
          />
        </div>
        <FormGroup className="mb-3">
          <Label>{t("forms.Capacity")}</Label>
          <Input
            name="capacity"
            placeholder={t("forms.Capacity")}
            type="text"
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.capacity || ""}
            invalid={
              validation.touched.capacity && validation.errors.capacity
                ? true
                : false
            }
          />
          {validation.touched.capacity && validation.errors.capacity ? (
            <FormFeedback type="invalid">
              {validation.errors.capacity}
            </FormFeedback>
          ) : null}
        </FormGroup>
        <FormGroup className="mb-3">
          <Label>{t("forms.Floor Type")}</Label>

          <Select
            placeholder={t("forms.Select")+" ..."}
            value={validation.values.floor}
            onChange={data => {
              validation.setFieldValue("floor", data)
            }}
            isClearable={true}
            onBlur={validation.handleBlur}
            isMulti={false}
            options={floorTypes}
            className={
              validation.touched.floor && validation.errors.floor
                ? "invalid"
                : ""
            }
          />

          {validation.touched.floor && validation.errors.floor ? (
            <div
              style={{
                width: "100%",
                marginTop: "0.25rem",
                fontSize: "80%",
                color: "#f46a6a",
              }}
            >
              {validation.errors.floor}
            </div>
          ) : null}
        </FormGroup>
      </Col>
    </Row>
  )
}

export default StadiumForm

import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Collapse,
} from "reactstrap"
import { useFormik } from "formik"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { addStage } from "api/stages"
import ResultHandler from "components/ResultHandler"
import FormButtonHandler from "components/FormButtonHandler"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import useCustomValidation from "../validationSchema"
import "./Accordion.css"
import TypeRules from "./TypeRules"

const AddStage = () => {
  const { t } = useTranslation()
  let navigate = useHistory()
  const { stageSchema } = useCustomValidation()
  const { setData, loading, error, success } = useResponse(addStage, () => {
    navigate.push("/stages")
  })
  const [postRules, setPostRules] = useState(null)
  const [infographicRules, setInfographicRules] = useState(null)
  const [videoRules, setVideoRules] = useState(null)
  const [albumRules, setAlbumRules] = useState(null)

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: stageSchema,
    onSubmit: values => {
      let data = {
        name: values.name,
        name_en: values.name_en,
        validation_rules: {
          post: postRules,
          infographic: infographicRules,
          video: videoRules,
          album: albumRules,
        },
      }

      setData({ data, type: "add" })
    },
  })

  return (
    <React.Fragment>
      <div className="page-content stage">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Stages")}
            breadcrumbItem={t("btns.Add") + " " + t("pages.Stage")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {t("forms.NameInArabic")}
                          </Label>
                          <Input
                            name="name"
                            placeholder={t("forms.NameInArabic")}
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {t("forms.NameInEnglish")}
                          </Label>
                          <Input
                            name="name_en"
                            placeholder={t("forms.NameInEnglish")}
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name_en || ""}
                            invalid={
                              validation.touched.name_en && validation.errors.name_en
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name_en && validation.errors.name_en ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name_en}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="12" className="mb-4">
                        <TypeRules setApiRules={setPostRules} type={"Post"} />
                      </Col>
                      <Col md="12" className="mb-4">
                        <TypeRules
                          setApiRules={setInfographicRules}
                          type={"Infographic"}
                        />
                      </Col>
                      <Col md="12" className="mb-4">
                        <TypeRules setApiRules={setVideoRules} type={"Video"} />
                      </Col>
                      <Col md="12" className="mb-4">
                        <TypeRules setApiRules={setAlbumRules} type={"Album"} />
                      </Col>
                    </Row>

                    <FormButtonHandler btnTxt="Add" loading={loading} />

                    <ResultHandler
                      error={error}
                      success={success}
                      successMsg={"Created Successfully!"}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddStage

import { getPost, getPostPlagarism, movePost } from "api/posts"
import DeleteModal from "components/Common/DeleteModal"
import ResultHandler from "components/ResultHandler"
import { useResponse } from "hooks/useResponse"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Col,
  Row,
} from "reactstrap"
import { PRIORITIES } from "utils/constants"
import SimpleBar from "simplebar-react"
import VerticalTimeline from "../history/VerticalTimeline"
import PostSliderContent from "./PostSliderContent"
import { useGetInstence } from "hooks/useGetInstence"
import ReadingTime from "./ReadingTiime"
import moment from "moment"
import FormButtonHandler from "components/FormButtonHandler"
import "./PostView.css"
import { Graph } from "./Graph"
import { useTranslation } from "react-i18next"
import CustomImage from "components/CustomImage"
export default function PostView() {
  const {t} = useTranslation()
  const { id } = useParams()
  const navigate = useHistory()
  const [postData, setPostData] = useState(null)
  const [initialStage, setInitialStage] = useState(null)

  const {
    setPayload,
    instenceError: postError,
    instenceLoading: postLoading,
  } = useGetInstence(getPost, id, res => {
    setPostData(res.data.data.post)
    setInitialStage(res.data.data.post.Stage)
    setPlagData(res.data.data.post.plagiarismScore)
  })

  useEffect(() => {
    setPayload({})
  }, [id])

  // move

  const [moveOpen, setMoveOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [stageId, setStageId] = useState(null)

  function onCloseClick() {
    setShow(false)
  }

  function handleOpenMove(id) {
    setShow(true)
    setStageId(id)
  }

  const { success, error, loading, setData } = useResponse(movePost, () => {
    setPayload({})
  })

  function onMoveClick() {
    setData({
      type: "add",
      data: {
        postId: id,
        targetId: stageId,
      },
    })
    onCloseClick()
  }

  // plagarism
  const [plagClicked, setPlagClicked] = useState(false)
  const [plagData, setPlagData] = useState(null)
  const {
    success: plagSuccess,
    setData: setPlag,
    error: plagError,
    loading: plagLoading,
  } = useResponse(getPostPlagarism, res => {
    setPlagData(res.data.plagiarismScore)
    setPlagClicked(true)
  })

  function handleRequestPlagarism() {
    setPlag({ type: "add", data: id })
  }

  return (
    <div className="page-content" key={id}>
      <div className="container-fluid">
        <ResultHandler loading={postLoading} error={postError} />
        {postData && !postLoading && (
          <Row>
            <Col md={8} xs={12}>
              <Card>
                <CardBody className="border-bottom">
                  <div
                    className="d-flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="d-flex">
                      {postData.Admin?.profile_pic_url ? (
                        <CustomImage
                          src={postData.Admin.profile_pic_url}
                          className="rounded avatar-sm"
                          alt=""
                        />
                      ) : (
                        <div className="avatar-sm">
                          <span className="avatar-title rounded bg-primary text-white font-size-16">
                            {postData.Admin?.username.charAt(0)}
                          </span>
                        </div>
                      )}
                      <div className="flex-grow-1 ms-3">
                        <h5 className="fw-semibold">
                          {postData.Admin?.username}
                        </h5>
                        <ul className="list-unstyled hstack gap-2 mb-0">
                          <li>
                            <span className="text-muted">
                              {postData.Admin?.Role &&
                                postData.Admin?.Role.name}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {postData.canEdit && (
                      <Button
                        onClick={() => {
                          navigate.push("/edit-post/" + id)
                        }}
                        color="primary"
                      >
                        {t("btns.Edit")}
                      </Button>
                    )}
                  </div>
                </CardBody>
                <CardBody>
                  <h5 className="fw-semibold mb-3">{postData.title}</h5>
                  <span className="d-block mb-3">
                    {t("forms.Tags") + " : "}
                  </span>
                  {postData.Tags.map(t => {
                    return (
                      <span
                        key={t.id}
                        style={{ fontSize: "13px" }}
                        className="badge badge-soft-warning me-1"
                      >
                        {t.name}
                      </span>
                    )
                  })}
                  {postData.type === "post" && (
                    <>
                      {postData.postContent.map(c => {
                        if (c.type == "img") {
                          return (
                            <CustomImage
                              style={{ width: "100%", marginBottom: "20px" }}
                              key={c.id}
                              src={c.content}
                            />
                          )
                        } else if (
                          c.type === "html" ||
                          c.type === "code" ||
                          c.type === "video"
                        ) {
                          return (
                            <div
                              key={c.id}
                              dangerouslySetInnerHTML={{
                                __html: c.content,
                              }}
                              className="embededCode"
                              style={{ width: "100%", marginBottom: "20px" }}
                            />
                          )
                        } else if (c.type === "embeded") {
                          return (
                            <iframe
                              key={c.id}
                              style={{
                                width: "100%",
                                height: "350px",
                                marginBottom: "20px",
                              }}
                              src={c.content}
                              title="Iframe Example"
                            ></iframe>
                          )
                        } else if (c.type === "slider") {
                          return (
                            <PostSliderContent key={c.id} items={c.meta.imgs} />
                          )
                        }
                      })}
                    </>
                  )}
                  {postData.type === "infographic" && (
                    <>
                      {postData.postContent.map(c => {
                        return c.meta.imgs.map(i => {
                          return (
                            <CustomImage
                              key={i.order}
                              src={i.imgUrl}
                              style={{ width: "100%" }}
                            />
                          )
                        })
                      })}
                    </>
                  )}
                  {postData.type === "video" && (
                    <>
                      {postData.postContent.map(c => {
                        return (
                          <div
                            key={c.id}
                            dangerouslySetInnerHTML={{
                              __html: c.content,
                            }}
                            className="embededCode"
                            style={{ width: "100%", marginBottom: "20px" }}
                          />
                        )
                      })}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col md={4} xs={12}>
              {!(
                !postData.Stage &&
                JSON.parse(localStorage.getItem("user")).id !=
                  postData.created_by
              ) && (
                <Card>
                  <CardBody
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {!loading && (
                      <>
                        <h5 style={{ margin: "0px" }}>{t("btns.Move")}</h5>

                        {postData.Stage &&
                          JSON.parse(localStorage.getItem("fromStages"))
                            .map(s => s.id)
                            .includes(postData.Stage.id) &&
                          JSON.parse(localStorage.getItem("toStages")).filter(
                            s => s.order > postData.Stage.order
                          ).length > 1 && (
                            <Dropdown
                              isOpen={moveOpen}
                              toggle={() => setMoveOpen(!moveOpen)}
                            >
                              <DropdownToggle
                                type="button"
                                tag="button"
                                className="btn btn-primary"
                              >
                                <i className="mdi mdi-chevron-down" />
                                {initialStage
                                  ? postData.stageId === initialStage.id
                                    ? initialStage.name
                                    : JSON.parse(
                                        localStorage.getItem("toStages")
                                      ).filter(
                                        s => s.id === postData.stageId
                                      )[0].name
                                  : t("btns.Draft")}
                              </DropdownToggle>
                              <DropdownMenu>
                                {JSON.parse(localStorage.getItem("toStages"))
                                  .filter(s => {
                                    return s.order > postData.Stage.order
                                  })
                                  .map(s => {
                                    return (
                                      <DropdownItem
                                        key={s.id}
                                        onClick={() => {
                                          handleOpenMove(s.id)
                                        }}
                                      >
                                        {s.name}
                                      </DropdownItem>
                                    )
                                  })}
                              </DropdownMenu>
                            </Dropdown>
                          )}

                        {postData.Stage &&
                          JSON.parse(localStorage.getItem("fromStages"))
                            .map(s => s.id)
                            .includes(postData.Stage.id) &&
                          JSON.parse(localStorage.getItem("toStages")).filter(
                            s => s.order > postData.Stage.order
                          ).length === 1 && (
                            <Button
                              type="submit"
                              color="primary"
                              onClick={() => {
                                handleOpenMove(
                                  JSON.parse(
                                    localStorage.getItem("toStages")
                                  ).filter(
                                    s => s.order > postData.Stage.order
                                  )[0].id
                                )
                              }}
                            >
                              {t("btns.Move To") +
                                " " +
                                JSON.parse(
                                  localStorage.getItem("toStages")
                                ).filter(s => s.order > postData.Stage.order)[0]
                                  .name}
                            </Button>
                          )}

                        {!postData.Stage &&
                          JSON.parse(localStorage.getItem("toStages")).length >
                            1 && (
                            <Dropdown
                              direction="right"
                              isOpen={moveOpen}
                              toggle={() => setMoveOpen(!moveOpen)}
                            >
                              <DropdownToggle
                                type="button"
                                tag="button"
                                className="btn btn-primary"
                              >
                                <i className="mdi mdi-chevron-down" />
                                {postData.stageId
                                  ? JSON.parse(
                                      localStorage.getItem("toStages")
                                    ).filter(s => s.id === postData.stageId)[0]
                                      .name
                                  : t("btns.Draft")}
                              </DropdownToggle>
                              <DropdownMenu>
                                {JSON.parse(
                                  localStorage.getItem("toStages")
                                ).map(s => {
                                  return (
                                    <DropdownItem
                                      key={s.id}
                                      onClick={() => {
                                        handleOpenMove(s.id)
                                      }}
                                    >
                                      {s.name}
                                    </DropdownItem>
                                  )
                                })}
                              </DropdownMenu>
                            </Dropdown>
                          )}

                        {!postData.Stage &&
                          JSON.parse(localStorage.getItem("toStages"))
                            .length === 1 && (
                            <Button
                              type="submit"
                              color="primary"
                              onClick={() => {
                                handleOpenMove(
                                  JSON.parse(
                                    localStorage.getItem("toStages")
                                  )[0].id
                                )
                              }}
                            >
                              {t("btns.Update").concat(" ") +
                                t("btns.Move To") +
                                " " +
                                JSON.parse(localStorage.getItem("toStages"))[0]
                                  .name}
                            </Button>
                          )}
                      </>
                    )}
                    <div style={{ width: "100%" }}>
                      <ResultHandler
                        error={error}
                        success={success}
                        loading={loading}
                        successMsg={"Moved Successfully!"}
                      />
                    </div>
                  </CardBody>
                </Card>
              )}
              <CustomImage
                className="mb-4"
                src={postData.mainImage_url}
                style={{ width: "100%", borderRadius: "5px" }}
              />
              <Card>
                <CardBody>
                  <h5>{t("wedgits.Overview")}</h5>
                  <div>
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="row">{t("forms.readTime")}</th>
                          <td>
                            <ReadingTime postContents={postData.postContent} />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("pages.Categories")}</th>
                          <td>
                            {postData.Categories.map(c => {
                              return (
                                <span
                                  key={c.id}
                                  className="badge badge-soft-primary"
                                >
                                  {c.name}
                                </span>
                              )
                            })}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("forms.Priority")}</th>
                          <td>
                            <span
                              className={
                                "badge badge-soft-" +
                                PRIORITIES.filter(
                                  p => p.num === postData.priority
                                )[0].color
                              }
                            >
                              {
                                PRIORITIES.filter(
                                  p => p.num === postData.priority
                                )[0].label
                              }
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("forms.publishDate")}</th>
                          <td>
                            {postData.publishedAt
                              ? moment(
                                  postData.publishedAt,
                                  "YYYY-MM-DD[T]hh:mm:ssZ"
                                ).fromNow()
                              : t("btns.Not Published yet")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("forms.createdAt")}</th>
                          <td>
                            {moment(
                              postData.createdAt,
                              "YYYY-MM-DD[T]hh:mm:ssZ"
                            ).fromNow()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h5
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <span>
                      {t("wedgits.Plagarism")}
                      {plagData && !plagLoading && (
                        <span
                          style={{ marginRight: "10px" }}
                          className={
                            Math.round(plagData.weighted_avg) > 50
                              ? "badge badge-soft-danger"
                              : "badge badge-soft-success"
                          }
                        >
                          {Math.round(plagData?.weighted_avg) + "%"}
                        </span>
                      )}
                    </span>
                    {!plagClicked && (
                      <FormButtonHandler
                        size="btn-md"
                        loading={plagLoading}
                        btnTxt="Find"
                        type="button"
                        handleClick={handleRequestPlagarism}
                      />
                    )}
                  </h5>
                  <div>{plagData && <Graph data={plagData} />}</div>
                  <div>
                    {plagData &&
                      !plagLoading &&
                      Object.keys(plagData)
                        .filter((a, i) => !["weighted_avg", "avg"].includes(a))
                        .sort((a, b) =>
                          plagData[a].avg_percent > plagData[b].avg_percent
                            ? -1
                            : 1
                        )
                        .map((key, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                marginBottom:
                                  i !=
                                  Object.keys(plagData).filter(
                                    (a, i) =>
                                      !["weighted_avg", "avg"].includes(a)
                                  ).length -
                                    2
                                    ? "15px"
                                    : "0px",
                              }}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "12px",
                                    marginLeft: "10px",
                                  }}
                                  className="badge badge-soft-primary"
                                >
                                  {Math.round(plagData[key].avg_percent) + "%"}
                                </span>{" "}
                                <a
                                  href={key}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    direction: "ltr",
                                  }}
                                >
                                  {key}
                                </a>
                              </p>
                              <div>
                                {plagData[key].htmls.map((line, index) => {
                                  return (
                                    <div
                                      key={"line" + index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <i
                                        className="bx bx-left-arrow-alt"
                                        style={{
                                          marginLeft: "10px",
                                          fontSize: "14px",
                                        }}
                                      ></i>

                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: line,
                                        }}
                                        className="badge badge-soft-danger stolenLines"
                                        style={{
                                          display: "block",
                                          width: "fit-content",
                                          fontSize: "12px",
                                          whiteSpace: "break-spaces",
                                          textalign: "revert",
                                        }}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )
                        })}
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h5 style={{ marginBottom: "20px" }}>
                    {t("pages.Post History")}
                  </h5>

                  <SimpleBar
                    style={{ maxHeight: "350px", paddingRight: "20px" }}
                  >
                    <VerticalTimeline response={postData.history} />
                  </SimpleBar>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
      <DeleteModal
        show={show}
        onDeleteClick={onMoveClick}
        onCloseClick={onCloseClick}
      />
    </div>
  )
}

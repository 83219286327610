import React, { useState } from "react"
import { Card, CardBody, Container } from "reactstrap"
import { useTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import Component
import FilesList from "./FilesList"

const FileManagerLayout = () => {
  const { t } = useTranslation()

  const [fullPath, setFullPath] = useState([
    { name: t("pages.Filemanager"), id: 0 },
  ])

  const [folderId, setFolderId] = useState(0) // root

  const [loading, setLoading] = useState(true)

  function handleOpen(id, name) {
    if (folderId != id) {
      setFolderId(id)
      setLoading(true)
      if (fullPath.filter(f => f.id === id).length === 0) {
        setFullPath([...fullPath, { name: name, id: id }])
      } else {
        setFullPath(fullPath.filter(p => p.id <= id))
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            className="paths"
            style={{ display: "flex", marginBottom: "20px" }}
          >
            {fullPath.map(p => {
              return (
                <h4
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  key={p.id}
                  onClick={() => {
                    handleOpen(p.id, p.name)
                  }}
                  className={"mb-0 font-size-18"}
                >
                  {p.name} /
                </h4>
              )
            })}
          </div>
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
                <div className="w-100">
                  <Card>
                    <CardBody>
                      <FilesList
                        loading={loading}
                        setLoading={setLoading}
                        handleOpen={handleOpen}
                        folderId={folderId}
                      />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FileManagerLayout

import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ResultHandler from "components/ResultHandler"
import ChooseImage from "components/ChooseImage"
import FormButtonHandler from "components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "hooks/useResponse"
import { useTranslation } from "react-i18next"
import { addCreator } from "api/creators"

const AddCreator = () => {
  const { t } = useTranslation()
  const { creatorSchema } = useCustomValidation()
  const { setData, loading, error, success } = useResponse(addCreator)

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      image_url: "",
    },
    validationSchema: creatorSchema,
    onSubmit: values => {
      setData({ data: values, type: "add" })
    },
  })

  let navigate = useHistory()

  useEffect(() => {
    if (success) {
      navigate.push("/creators")
    }
  }, [success])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Creators")}
            breadcrumbItem={t("btns.Add") + " " + t("pages.Creator")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label>{t("forms.Name")}</Label>
                          <Input
                            name="name"
                            placeholder={t("forms.Name")}
                            type="text"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label>{t("forms.Bio")}</Label>
                          <Input
                            name="description"
                            placeholder={t("forms.Bio")}
                            type="textarea"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={
                              validation.touched.description &&
                                validation.errors.description
                                ? true
                                : false
                            }
                          />
                          {validation.touched.description &&
                            validation.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <ChooseImage
                          name="image_url"
                          image={validation.values.image_url}
                          error={
                            validation.touched.image_url && validation.errors.image_url
                          }
                          handleChooseImage={(url) => {
                            validation.setFieldValue("image_url", url)
                          }}
                        />
                      </Col>
                    </Row>

                    <FormButtonHandler btnTxt="Add" loading={loading} />

                    <ResultHandler
                      error={error}
                      success={success}
                      successMsg={"Created Successfully!"}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddCreator

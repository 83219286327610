import ResultHandler from "components/ResultHandler"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Card, CardBody, Button, Col, Row } from "reactstrap"

import { getAdmin } from "api/admins"
import { useGetInstence } from "hooks/useGetInstence"
import { PERMISSIONS } from "api/permissions"
import PostsTable from "../posts/crud/PostsTable"
import CustomImage from "components/CustomImage"

export default function AdminProfile() {
  const { id } = useParams()

  const navigate = useHistory()
  const [adminData, setAdminData] = useState(null)

  const {
    instenceLoading: adminLoading,
    instenceError: adminError,
    instenceSuccess,
  } = useGetInstence(getAdmin, id, res => {
    setAdminData(res.data.data.admin)
  })

  return (
    <div className="page-content">
      <div className="container-fluid">
        <ResultHandler loading={adminLoading} error={adminError} />
        {adminData && (
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div
                    className="d-flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="d-flex">
                      {adminData.profile_pic_url ? (
                        <CustomImage
                          src={adminData.profile_pic_url}
                          className="rounded avatar-sm"
                          alt=""
                        />
                      ) : (
                        <div className="avatar-sm">
                          <span className="avatar-title rounded bg-primary text-white font-size-16">
                            {adminData.username.charAt(0)}
                          </span>
                        </div>
                      )}
                      <div className="flex-grow-1 ms-3">
                        <h5 className="fw-semibold">{adminData.username}</h5>
                        <ul className="list-unstyled hstack gap-2 mb-0">
                          <li>
                            <span className="text-muted">
                              {adminData.Role.name}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {JSON.parse(localStorage.getItem("permissions")).includes(
                      PERMISSIONS.ADMIN_EDIT
                    ) && (
                      <Button
                        color="primary"
                        onClick={() => {
                          navigate.push("/edit-admin/" + adminData.id)
                        }}
                      >
                        {t("btns.Edit")}
                      </Button>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <PostsTable created_by={id} />
          </Row>
        )}
      </div>
    </div>
  )
}
